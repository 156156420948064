import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Login from './../account/login/Login';
import Home from './../home/Home';
import PaymentMethod from '../paymentmethod/PaymentMethod';
import LandingPage from '../recurringpayment/landingpage/LandingPage';
import PayNow from '../paynow/PayNow';
import BulkSync from '../bulksync/BulkSync';
import ErrorPages from '../errorpages/ErrorPages';
import Receipt from '../paynow/Receipt';
import TrustAccount from '../trustaccount/TrustAccount';
import Merchants from '../merchant/Merchant';
import SignUp from './../account/signup/SignUp';
import AdminLogin from './../admin/Login';
import CheckLogin from '../admin/CheckLogin';
import Expired from '../paynow/Expired';
import PaidReceipt from '../paynow/PaidReceipt';
import TestTransaction from '../testtransactions/TestTransaction';
import Notification from '../notification/Notification';
import Reports from '../reports/Reports'
import AdminForgotPassword from '../admin/ForgotPassword';
import AdminResetPassword from '../admin/ResetPassword';

const Routes = () => {

	const renderRoutes = () => {
		return (
			[
				<Route component={TestTransaction} key="TEST_TRANSACTIONS" path="/testTransactions/:pennyTest" exact={true} />,
				<Route component={Receipt} key="PAY_NOW_RECEIPT" path="/receipt" exact={true} />,
				<Route component={Reports} key="REPORTS" path="/reports/:reports" exact={true} />,
				<Route component={PaidReceipt} key="PAID_RECEIPT" path="/paid-receipt" exact={true} />,
				<Route component={Expired} key="EXPIRED" path="/expired" exact={true} />,
				<Route component={Home} key="HOME" path="/:customer" exact={true} />,
				<Route component={Login} key="ACCOUNT_LOGIN" path="/account/login" exact={true} />,
				<Route component={SignUp} key="ACCOUNT_SIGNUP" path="/account/signup" exact={true} />,
				<Route component={PaymentMethod} key="PAYMENT_METHOD" path="/account/paymentMethod/:merchant" exact={true} />,
				<Route component={TrustAccount} key="TRUST_ACCOUNT" path="/account/trustAccount/:merchant" exact={true} />,
				<Route component={Notification} key="NOTIFICATION" path="/account/notifications/:merchant" exact={true} />,
				<Route component={PayNow} key="PAY_NOW" path="/account/payNow/:paynow" exact={true} />,
				<Route component={LandingPage} key="RECURRING_PAYMENT" path="/account/recurringPayment/:merchant" exact={true} />,
				<Route key="BULK_SYNC" path="/admin/sync" exact={true}>
					<CheckLogin>
						<BulkSync />
					</CheckLogin>
				</Route>,
				<Route key="SERVER_DOWN" path="/errorpage/serverDown" exact={true}>
					<ErrorPages errorType='siteMaintenance'/>
				</Route>,
				<Route key="MERCHANTS" path="/admin/merchants" exact={true}>
					<CheckLogin>
						<Merchants />
					</CheckLogin>
				</Route>,
				<Route component={AdminLogin} key="ADMIN_LOGIN" path="/admin/login" exact={true} />,
				<Route component={AdminForgotPassword} key="ADMIN_FORGOT_PASSWORD" path="/admin/forgot-password" exact={true} />,
				<Route component={AdminResetPassword} key="ADMIN_RESET_PASSWORD" path="/admin/reset-password/:token" exact={false} />,
				<Route key="PAGE_NOT_FOUND" path="*" > <ErrorPages errorType='pageNotFound'/></Route>
			]
		);
	};

	return <Switch>{renderRoutes()}</Switch>;
};

export default Routes;
