import React, { memo } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BankAccount from './BankAccount';
import CreditCard from './CreditCard';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { COLORS } from '../constants/Colors';
import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import discover from '../../assets/images/discover.png';
import amex from '../../assets/images/american-express.png';
import RadioButton from '../forms/RadioButton';
import { postcodeValidator } from 'postcode-validator';
import { STATES, STATESCA } from '../constants/Locations';

const useStyles = makeStyles({
	card: {
		backgroundColor: COLORS.grayContainer
	},
	creditCard: {
		backgroundColor: 'white',
		width: '65%',
		hight: 'auto',
		marginTop: 10,
	},
});

const Accordion = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		border: '1px solid #CFD8DC',
		backgroundColor: '#ECEFF1',
		boxSizing: 'border-box',
		marginBottom: -0.25,
		maxHeight: 50,
		'&$expanded': {
			maxHeight: 40,
			background: COLORS.selectedExpend,
			boxSizing: 'border-box',
			border: '1px solid #CFD8DC'
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

function PaymentMethod({
	selectedValue = 'creditcard',
	expanded = 'creditcard',
	handleChange = () => { },
	amount = '0',
	merchantId = '',
	setIsLoading = () => { },
	setBankInfo = () => { },
	setCreditCardInfo = () => { },
	bankInfo = {},
	creditCardInfo = {},
	validateRequired = false,
	invoiceId = '',
	requestOfPaymentMethodId = '',
	isPayNow = true,
	payerId = '',
	showExistingPaymentMethods = false,
	axiosConfig = {},
	merchantInfo = {},
	setValidCreditCard = () => { },
	setValidBankAccount = () => { },
	validCreditCard = {},
	validBankAccount = {},
	ccStates = [],
	setCCStates = () =>{}
}) {
	const classes = useStyles();
	const handleCcInputChange = ({ target: { value, id } }) => {
		validateCCFields(id, value);
		if (value.length > 1000)
			value = value.substring(0, 1000);
		setCreditCardInfo(prev => ({ ...prev, [id]: value }));
	};

	/**
	 *
	 * @param routingNumber
	 * @returns {boolean}
	 * this function validate bank routing number for more information please check out this link
	 * https://www.wikihow.com/Calculate-the-Check-Digit-of-a-Routing-Number-from-an-Illegible-Check#:~:text=To%20calculate%20the%20last%20digit,with%20the%20remaining%20six%20digits.
	 */

	const validateRoutingNumber = (routingNumber) =>{
		let sum = 0;
		const len = routingNumber.length;

		if (len !== 9) {
			return false;
		} else {
			sum = (7 * (parseInt(routingNumber.charAt(1)) + parseInt(routingNumber.charAt(4)) + parseInt(routingNumber.charAt(7))) +
				(3 * (parseInt(routingNumber.charAt(0)) + parseInt(routingNumber.charAt(3)) + parseInt(routingNumber.charAt(6)))) +
				(parseInt(routingNumber.charAt(2)) + parseInt(routingNumber.charAt(5)) + parseInt(routingNumber.charAt(8))));

			return sum % 10 === 0;

		}
	};

	const handleCcCountryChange = ({ target: { value } }) => {
		setCreditCardInfo(prev => ({ ...prev, country: value , state: '' }));
		if (value === 'USA') setCCStates(STATES);
		else if (value === 'CAN') setCCStates(STATESCA);
		else if (value === 'Others') {
			setCCStates([]);
			setCreditCardInfo(prev => ({ ...prev, state: '', address: '', city: '', postalCode: '',  address2: '' }));
		} else if (value === 'GBR') {
			setCCStates([]);
		}
	};

	const handleCcStateChange = ({ target: { value } }) => {
		setCreditCardInfo(prev => ({ ...prev, state: value }));
	};

	const handleInputChange = ({ target: { value, id } }) => {
		validateBankAccFields(id, value);
		if (value.length > 1000)
			value = value.substring(0, 1000);
		setBankInfo(prev => ({ ...prev, [id]: value }));
	};
	const handleSelectionChange = ({ target: { value } }) => {
		setBankInfo(prev => ({ ...prev, accountType: value }));
	};
	const handleCountryChange = ({ target: { value } }) => {
		setBankInfo(prev => ({ ...prev, country: value }));
	};

	const handleStateChange = ({ target: { value } }) => {
		setBankInfo(prev => ({ ...prev, state: value }));
	};

	const validateEmail = (value) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (value.trim() === '' || !regex.test(value.trim())) {
			setValidCreditCard(prev => ({ ...prev, emailError: 'Email address is invalid' }));
			return false;
		} else
			setValidCreditCard(prev => ({ ...prev, emailError: '' }));
		return true;
	};

	const validateBankAccFields = (id, value) => {
		if (id === 'accountNumber') {
			if (value.length < 5 || value.length > 17) {
				setValidBankAccount(prev => ({ ...prev, accountNoError: 'Please check and confirm account no.' }));
				return false;
			} else
				setValidBankAccount(prev => ({ ...prev, accountNoError: '' }));
			return true;
		}
		if (id === 'email') {
			const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (value.trim() === '' || !regex.test(value.trim())) {
				setValidBankAccount(prev => ({ ...prev, emailError: 'Email address is invalid' }));
				return false;
			} else
				setValidBankAccount(prev => ({ ...prev, emailError: '' }));
			return true;
		}
		if (id === 'postalCode') {
			if (!postcodeValidator(value.trim(), 'US')) {
				setValidBankAccount(prev => ({ ...prev, zipError: 'Zip is invalid' }));
			} else
				setValidBankAccount(prev => ({ ...prev, zipError: '' }));
		}
		if (id === 'routingNumber') {
			if (!validateRoutingNumber(value.trim().toString())) {
				setValidBankAccount(prev => ({ ...prev, routingError: 'Please check and confirm routing no.' }));
			} else
				setValidBankAccount(prev => ({ ...prev, routingError: '' }));
		}
	};

	const validateCCFields = (id, value) => {
		if (id === 'email')
			validateEmail(value);
		if (id === 'postalCode') {
			if (!postcodeValidator(value.trim(), 'US')) {
				setValidCreditCard(prev => ({ ...prev, zipError: 'Zip is invalid' }));
			} else
				setValidCreditCard(prev => ({ ...prev, zipError: '' }));
		}
	};

	return (
		<>
			<Grid>
				<Accordion square expanded={expanded === 'creditcard'} onChange={handleChange('creditcard')} >
					<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
						<Grid container spacing={0} direction='row' >
							<Grid item xs={8}>
								<RadioButton selectedValue={selectedValue === 'creditcard' ? 'creditcard' : ''} handleChange={handleChange} name='Credit Card & Debit Card' />
							</Grid>
							{selectedValue === 'creditcard' && <Grid item xs={1}><img src={visa} className={classes.creditCard} /></Grid>}
							{selectedValue === 'creditcard' && <Grid item xs={1}><img src={mastercard} className={classes.creditCard} /></Grid>}
							{selectedValue === 'creditcard' && <Grid item xs={1}><img src={amex} className={classes.creditCard} /></Grid>}
							{selectedValue === 'creditcard' && <Grid item xs={1}><img src={discover} className={classes.creditCard} /></Grid>}
						</Grid>

					</AccordionSummary>
					<AccordionDetails className={classes.card}>
						<CreditCard
							amount={amount}
							merchantId={merchantId}
							setIsLoading={setIsLoading}
							invoiceId={invoiceId}
							requestOfPaymentMethodId={requestOfPaymentMethodId}
							isPayNow={isPayNow}
							payerId={payerId}
							setCreditCardInfo={setCreditCardInfo}
							creditCardInfo={creditCardInfo}
							showExistingPaymentMethods={showExistingPaymentMethods}
							axiosConfig={axiosConfig}
							merchantInfo={merchantInfo}
							handleInputChange={handleCcInputChange}
							handleCountryChange={handleCcCountryChange}
							states={ccStates}
							handleStateChange={handleCcStateChange}
							validateRequired={validateRequired}
							validCreditCard={validCreditCard}
						/>
					</AccordionDetails>
				</Accordion>
				<Accordion square expanded={expanded === 'bankaccount'} onChange={handleChange('bankaccount')}>
					<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
						<div style={{ marginLeft: '0px' }}><RadioButton selectedValue={selectedValue === 'bankaccount' ? 'bankaccount' : ''} handleChange={handleChange} name=' eCheck / ACH' /></div>
					</AccordionSummary>
					<AccordionDetails className={classes.card}>
						<BankAccount
							validateRequired={validateRequired}
							onInputChange={handleInputChange}
							bankInfo={bankInfo}
							onSelectionChange={handleSelectionChange}
							onCountrySelectionChange={handleCountryChange}
							onStateSelectionChange={handleStateChange}
							isPayNow={isPayNow}
							validBankAccount={validBankAccount}
						/>
					</AccordionDetails>
				</Accordion>
			</Grid>
		</>
	);
}

PaymentMethod.propTypes = {
	selectedValue: PropTypes.string,
	expanded: PropTypes.string,
	handleChange: PropTypes.func,
	amount: PropTypes.string,
	merchantId: PropTypes.string,
	setIsLoading: PropTypes.func,
	setBankInfo: PropTypes.func,
	bankInfo: PropTypes.object,
	validateRequired: PropTypes.bool,
	invoiceId: PropTypes.string,
	requestOfPaymentMethodId: PropTypes.string,
	isPayNow: PropTypes.bool,
	payerId: PropTypes.string,
	showExistingPaymentMethods: PropTypes.bool,
	axiosConfig: PropTypes.object,
	merchantInfo: PropTypes.object,
	setValidCreditCard: PropTypes.func,
};

export default memo(PaymentMethod);