import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Grid, makeStyles, Radio, Typography, Dialog } from '@material-ui/core';
import FormDropDown from '../../forms/FormDropDown';
import FormTextField from '../../forms/FormTextField';
import recurringHeaderSchedule from '../../../assets/images/APX_header_popup_page@2x.png';
import FormDatePicker from '../../forms/FormDatePicker';
import { COLORS } from '../../constants/Colors';
import { DEPOSITLIST, RECURRINGTYPES } from '../../constants/RecurringPayments';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import { getApiUrl } from '../../utils/getApiUrl';
import Header from '../landingpage/Header';
import ListActionsToolbar from '../ListActionsToolbar';
import InProgress from '../../forms/InProgress';
import moment from 'moment';
import Messages from '../../forms/Messages';
import { appendPendoUrl } from '../../services/Services';

import FormCheckedBox from '../../forms/FormCheckedBox';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { RecurringFieldsInfo } from '../RecurringFieldsInfo';

const useStyles = makeStyles((theme) => ({
	header: {
		borderBottom: `1px solid ${COLORS.grayBorder}`
	},
	overflow: {
		overflow: 'auto'
	},
	container: {
		paddingRight: 40,
		paddingLeft: 40,
		paddingTop: 15,
		paddingBottom: 30,
		backgroundColor: COLORS.grayContainer
	},
	whiteContainer: {
		position: 'relative',
		backgroundColor: COLORS.whiteBackground,
		borderTop: `1px solid ${COLORS.grayBorder}`,
		height: 60
	},
	divider: {
		height: 2,
	},
	btnCreate: {
		position: 'absolute',
		top: '18%',
		bottom: '18%',
		right: 40,
		backgroundColor: COLORS.blueButtons,
		fontWeight: 'bold',
		'&:hover': {
			background: COLORS.onHoverBlueButtons,
		},
		'&:disabled': {
			backgroundColor: COLORS.disabledBlue,
			color: COLORS.whiteText,
		},
		color: COLORS.whiteText,
		marginLeft: '10px',
		textTransform: 'capitalize',
		minWidth: 100
	},
	btnClose: {
		borderColor: COLORS.blueButtons,
		fontWeight: 'bold',
		color: COLORS.blueButtons,
		marginLeft: '10px',
		textTransform: 'capitalize',
		position: 'absolute',
		top: '18%',
		bottom: '18%',
		right: 190,
	},
	paymentLabel: {
		display: 'none',
		alignItems: 'center',
		flexWrap: 'wrap',
		color: COLORS.blueButtons,
		padding: '5px'
	},
	marginLeft: {
		marginLeft: '10px',
	},
	headerText: {
		fontWeight: 'bold',
		position: 'absolute',
		top: '50%',
		left: '40%',
		transform: 'translate(-50%, -50%)'
	},
	headerDiv: {
		position: 'relative',
		textAlign: 'center',
		color: COLORS.whiteText,
	},
	emailText: {
		color: COLORS.darkGrayText
	},
	rightSpacing: {
		paddingRight: 20
	},
	overview: {
		fontWeight: 900,
		textTransform: 'capitalize',
		color: COLORS.blueButtons,
		borderColor: COLORS.blueButtons,
		border: '2px solid',
		position: 'absolute',
		top: '18%',
		bottom: '18%',
		left: 40,
	},
	bold:{
		fontWeight:'bold',
		marginTop:'10px'
	},
	lblEnteredBy:{
		float:'left',
		marginRight: '10px',
		marginTop:'10px'
	},
	lblSurcharge:{
		fontStyle: 'italic',
		marginTop: '10px',
		color: COLORS.secureMessage
	},
	greyText:{
		color: COLORS.darkerGray
	},
	help: {
		color: COLORS.blueButtons,
		opacity: '85%',
		verticalAlign: 'middle',
		marginLeft:'-7px'
	},
	disabledText:{
		opacity: 0.5,
		fontStyle: 'italic',
		marginTop: '10px',
	},
	infoIcon: {
		color: COLORS.darkerGray,
		verticalAlign:'middle',
		width: '0.8em'
	}
}));

function Schedule({
	applicationEntityId = '',
	selectedSchedule = {},
	onClose = () => {},
	onCloseAndRefresh = () => {},
	open = false,
	token = '',
	isEditMode = false,
	onOverViewClick = () => {},
	onDeleteClick = () => {},
	onCancelClick = () => {},
	isLoading = false,
	setCreationInProgress = () => {},
	setSelectedSchedule = () => {},
	onRefresh = () => {}
}) {
	const classes = useStyles();
	const history = useHistory();

	const [ isMessageOpen, setIsMessageOpen ] = useState(false);
	const [ message, setMessage ] = useState('');
	const [ severity, setSeverity ] = useState('');
	const [ validateRequired, setValidateRequired ] = useState(false);
	const [ labelEntity, setLabelEntity ] = useState('Entity');
	const [ paymentMethods, setPaymentMethods ] = useState([]);
	const [ selectedAmountValue, setSelectedAmountValue ] = useState('recurringAmount');
	const [ selectedValue, setSelectedValue ] = useState('occurrences');
	const [ recurringInfo, setRecurringInfo ] = useState({});
	const [ startDateError, setStartDateError ] = useState('');
	const [ endDateError, setEndDateError ] = useState('');
	const [ totalAmountError, setTotalAmountError ] = useState('');
	const [ recurringAmountError, setRecurringAmountError ] = useState('');
	const [ occurrenceError, setOccurrenceError ] = useState('');
	const [ emailListError, setEmailListError ] = useState('');
	const [ application, setApplication ] = useState('');
	const [ isFormValid, setIsFormValid ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ checkTransferSurcharge, setCheckTransferSurcharge ] = useState({ checked: false });
	const [ applicationSurchargeEnabled, setApplicationSurchargeEnabled ] = useState(false);
	const [ selectedTokenBinType, setSelectedTokenBinType ] = useState('')
	const headerTitle = isEditMode ? 'Recurring Payment Schedule' : 'New Recurring Payment Schedule';

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	useEffect(() => {
		if (selectedSchedule?.paymentScheduleId && isEditMode) {
			async function fetchExistingSchedule() {
				setRecurringInfo({});
				setPaymentMethods([]);
				await getExistingSchedule(selectedSchedule?.paymentScheduleId);
			}
			fetchExistingSchedule();
		}
	}, [ isEditMode ]);

	useEffect(() => {

		if (applicationEntityId && token && !isEditMode) {
			async function fetchScheduleCreationData() {
				await getScheduleCreationData(applicationEntityId);
			}
			fetchScheduleCreationData();
		}
	}, [ applicationEntityId, token, isEditMode ]);

	useEffect(() =>{
		if (isEditMode) {
			const isValidFields = requiredFieldValidated(recurringInfo);
			setIsValid(isValidFields);
		}
	},[ recurringInfo, isEditMode ]);

	const getPaymentMethodBin = async (tokenId) => {
		try{
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_payment_bin?tokenId=${tokenId}`, axiosConfig);
			if (data.code == 200){
				setSelectedTokenBinType(data.binType)
			}
		}
		catch(err){
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	}

	const getScheduleCreationData = async (applicationEntityId) => {

		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/applicationentity/get_schedule_creation_data?applicationEntityId=${applicationEntityId}`, axiosConfig);
			if (data.code === 200) {
				const paymentList = [];
				setRecurringInfo({
					payerId: data.applicationEntity.payerId,
					payerName: data.applicationEntity.payerName ?? '',
					applicationEntityName: data.applicationEntity.entityName,
					merchantId: data.applicationEntity.merchantId,
					applicationId: data.applicationEntity.applicationId,
					applicationEntityId: data.applicationEntity.entityId,
					firmName: data.applicationEntity.firmName,
					firmUserName: data.applicationEntity.userName,
					paymentMethodId: '',
					depositTo: data.applicationEntity.isDepositToTrustAccount ? 'Trust' : 'Operating (General)',
					scheduleType: '',
					totalAmount: null,
					recurringAmount: null,
					startDate: null,
					endDate: null,
					scheduledNumberOfPayments: null,
					description: null,
					notificationEmailTo: data.applicationEntity.userEmail,
					isBasedOnEndDate: false,
					isBasedOnTotalAmount: false,
					paymentNotificationUrl: data.applicationEntity.paymentNotificationUrl,
					transferSurcharge: data.applicationEntity.transferSurcharge
				});
				setApplicationSurchargeEnabled(data.applicationEntity.transferSurcharge);
				for (const payment of data.paymentMethods) {

					const type = payment.hasOwnProperty('creditCardType') ? payment.creditCardType : 'ACH';

					paymentList.push({
						paymentMethod: `${type} ···· ${payment.accountNumber}`,
						paymentMethodId: payment?.tokenId,
						name: `${payment.first} ${payment.last}` ,
						expiration: payment.expiration ? ` | Exp. ${payment?.expiration.slice(0, 2)}/${payment?.expiration.slice(2)}` : '',
						address1: payment.address1,
						city: payment.city,
						state: payment.state,
						zip: payment.zip,
						email: ` (${payment.email})`,
						bankAccountType: payment.bankAccountType ? `| ${payment.bankAccountType}` : ''
					});
				}
				setApplication(data?.application);
				setPaymentMethods(paymentList);
				setLabelEntity(data?.applicationDisplayName);

				setRecurringInfo(prev => ({ ...prev, paymentMethodId: paymentList?.[0]?.paymentMethodId }));
				appendPendoUrl('/NewSchedule');
			}
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const getExistingSchedule = async (paymentScheduleId) => {

		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_payment_schedule?paymentScheduleId=${paymentScheduleId}`, axiosConfig);
			if (data.code === 200) {
				const paymentList = [];
				setRecurringInfo({
					applicationEntityName: data.paymentSchedule.applicationEntityName,
					applicationEntityId: data.paymentSchedule.applicationEntityId,
					firmUserName: data.paymentSchedule.firmUserName,
					paymentMethodId: '',
					depositTo: data.paymentSchedule.isDepositToTrustAccount ? 'Trust' : 'Operating (General)',
					scheduleType: data.paymentSchedule.scheduleType,
					totalAmount: data.paymentSchedule.transferSurcharge ? data.paymentSchedule.originalAmount : data.paymentSchedule.totalAmount,
					recurringAmount: data.paymentSchedule.transferSurcharge ? data.paymentSchedule.originalRecurringAmount : data.paymentSchedule.recurringAmount,
					startDate: data.paymentSchedule.startDate,
					endDate: data.paymentSchedule.endDate,
					scheduledNumberOfPayments: data.paymentSchedule.scheduledNumberOfPayments,
					description: data.paymentSchedule.description,
					notificationEmailTo: data.paymentSchedule.notificationEmailTo,
					paymentNotificationUrl: data.paymentSchedule.paymentNotificationUrl,
					payerId: data.paymentSchedule.payerId,
					payerName: data.paymentSchedule.payerName,
					merchantId: data.paymentSchedule.merchantId,
					applicationId: data.paymentSchedule.applicationId,
					firmName: data.paymentSchedule.firmName,
					isBasedOnEndDate: false,
					isBasedOnTotalAmount: false,
					transferSurcharge: data.paymentSchedule.transferSurcharge,
					successfulPaymentCount: data.paymentSchedule.successfulPaymentCount ?? 0,
				});
				setCheckTransferSurcharge({ checked: data.paymentSchedule.transferSurcharge });
				for (const payment of data.paymentMethods) {
					// eslint-disable-next-line no-prototype-builtins
					const type = payment.hasOwnProperty('creditCardType') ? payment.creditCardType : 'ACH';
					paymentList.push({
						paymentMethod: `${type} ···· ${payment.accountNumber}`,
						paymentMethodId: payment?.tokenId,
						name: `${payment.first} ${payment.last}` ,
						expiration: payment.expiration ? ` | Exp. ${payment?.expiration.slice(0, 2)}/${payment?.expiration.slice(2)}` : '',
						address1: payment.address1,
						city: payment.city,
						state: payment.state,
						zip: payment.zip,
						email: ` (${payment.email})`,
						bankAccountType: payment.bankAccountType ? `| ${payment.bankAccountType}` : ''
					});
				}
				setPaymentMethods(paymentList);
				setLabelEntity(data.applicationDisplayName);
				setRecurringInfo(prev => ({ ...prev, paymentMethodId: data.paymentSchedule?.paymentMethodId }));
				if(data.paymentSchedule?.isDebitCard == null || data.paymentSchedule?.isDebitCard)
					setSelectedTokenBinType('debit');
				else
					setSelectedTokenBinType('credit');
				appendPendoUrl('/UpdateSchedule');
			}
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const onSaveClick = async () => {
		if (selectedSchedule?.paymentScheduleId) {
			await updateSchedule();
			onClose();
		}
	};

	const updateSchedule = async () => {

		try {
			const isValid = requiredFieldValidated(recurringInfo);
			if (isValid) {
				setCreationInProgress(true);
				setSelectedSchedule({});
				let schedule = { paymentSchedule: recurringInfo };
				schedule.paymentScheduleId = selectedSchedule?.paymentScheduleId;
				schedule.isLightUpdate = selectedSchedule?.status === 'Active';
				schedule.paymentSchedule.scheduleStatus = selectedSchedule?.status.replace(' ', '');
				schedule.paymentSchedule.isPerpetual = false;
				schedule.paymentSchedule.isBasedOnEndDate = selectedValue === 'endDate';
				schedule.paymentSchedule.isBasedOnTotalAmount = selectedAmountValue === 'totalAmount';
				schedule.paymentSchedule.isDepositToTrustAccount = recurringInfo.depositTo === 'Trust';
				schedule.paymentSchedule.paymentNotificationUrl = recurringInfo.paymentNotificationUrl;
				schedule.paymentSchedule.endDate = moment(recurringInfo.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
				schedule.paymentSchedule.startDate = moment(recurringInfo.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
				schedule.paymentSchedule.transferSurcharge = applicationSurchargeEnabled || checkTransferSurcharge.checked;
				const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/recurringpayments/update_payment_schedule`, schedule, axiosConfig);
				if (data.code === 200) {
					setCreationInProgress(false);
					setMessage('Changes have been saved successfully');
					setIsMessageOpen(true);
					setSeverity('success');
					onRefresh();
				} else {
					setCreationInProgress(false);
					throw new Error(data);
				}
			}
		} catch (err) {
			setCreationInProgress(false);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			setMessage(err?.response?.data?.error?.toString() || err?.toString());
			setIsMessageOpen(true);
			setSeverity('error');
		}
	};

	const createSchedule = async () => {
		try {
			const isValid = requiredFieldValidated(recurringInfo) && validateTotalAmount() && validateRecurringAmount() && validateStartDate() && validateEndDate() && validateOccurrences();
			if (isValid) {
				setCreationInProgress(true);
				setSelectedSchedule({});
				const schedule = { paymentSchedule: recurringInfo };
				schedule.paymentSchedule.isPerpetual = false;
				schedule.paymentSchedule.isBasedOnEndDate = selectedValue === 'endDate';
				schedule.paymentSchedule.isBasedOnTotalAmount = selectedAmountValue === 'totalAmount';
				schedule.paymentSchedule.isDepositToTrustAccount = recurringInfo.depositTo === 'Trust';
				schedule.paymentSchedule.paymentNotificationUrl = recurringInfo.paymentNotificationUrl;
				schedule.paymentSchedule.endDate = moment(recurringInfo.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
				schedule.paymentSchedule.startDate = moment(recurringInfo.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
				schedule.paymentSchedule.transferSurcharge = applicationSurchargeEnabled || checkTransferSurcharge.checked;

				const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/recurringpayments/create_payment_schedule`, schedule, axiosConfig);
				if (data.code === 200) {
					setCreationInProgress(false);
					onCloseAndRefresh();
				} else {
					setCreationInProgress(false);
				}
			}
		} catch (err) {
			setCreationInProgress(false);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			setMessage(err.response.data?.error?.toString());
			setIsMessageOpen(true);
			setSeverity('error');
			console.log(err);
		}
	};

	const validateTotalAmount = () => {

		if (recurringInfo.isBasedOnTotalAmount && recurringInfo.totalAmount < 1) {
			setTotalAmountError('Must be at least $1.00');
			return false;
		} else
			setTotalAmountError('');
		return true;
	};

	const validateRecurringAmount = () => {
		if (!recurringInfo.isBasedOnTotalAmount && recurringInfo.recurringAmount < 1) {
			setRecurringAmountError('Must be at least $1.00');
			return false;
		} else
			setRecurringAmountError('');
		return true;
	};

	const validateOccurrences = () => {
		if (!recurringInfo.isBasedOnEndDate) {
			if (recurringInfo.scheduleType === 'Single' && parseInt(recurringInfo.scheduledNumberOfPayments) !== 1) {
				setOccurrenceError('Must be 1 occurrence');
				return false;
			}
			if (recurringInfo.scheduleType !== 'Single' && !recurringInfo.isBasedOnEndDate && parseInt(recurringInfo.scheduledNumberOfPayments) < 2) {
				setOccurrenceError('Minimum 2 occurrences');
				return false;
			}
			setOccurrenceError('');
		}
		setOccurrenceError('');
		return true;
	};

	const validateStartDate = (start, end) => {
		const startDate = start ? new Date(start) : new Date(recurringInfo.startDate);
		const endDate = end ? end : recurringInfo.endDate;
		const isNotStarted = Object.keys(selectedSchedule).length === 0 || selectedSchedule?.status === 'Not Started';
		if (!moment(startDate, 'MM/DD/YYYY', true).isValid()) {
			setStartDateError('Invalid Date. Must be in a format MM/DD/YYYY');
			return false;
		}
		if (isNotStarted && startDate <= new Date()) {
			setStartDateError('Start date must be in future');
			return false;
		} else {
			setStartDateError('');
			if (endDate)
				return validateEndDate(endDate, startDate);
		}
		return true;
	};

	const validateEndDate = (end, start) => {
		const endDate = end ? end : recurringInfo.endDate;
		const startDate = start ? start : recurringInfo.startDate;
		const isNotStarted = Object.keys(selectedSchedule).length === 0 || selectedSchedule?.status === 'Not Started';
		if (isNotStarted && recurringInfo.isBasedOnEndDate &&
			(!moment(endDate, 'MM/DD/YYYY', true).isValid() && !moment(endDate, 'YYYY-MM-DD', true).isValid())) {
			setEndDateError('Invalid Date. Must be in a format MM/DD/YYYY');
			return false;
		}
		if (isNotStarted && recurringInfo.isBasedOnEndDate && recurringInfo.scheduleType !== 'Single' && moment(endDate).isSameOrBefore(startDate)) {
			setEndDateError('End date should be greater than start date');
			return false;
		} else
			setEndDateError('');

		return true;
	};

	const requiredFieldValidated = (params) => {
		const requiredFields = Object.keys(params);
		for (const field of requiredFields) {
			if (field !== 'isBasedOnTotalAmount' && field !== 'isBasedOnEndDate' && field !== 'isPerpetual' && field !== 'payerName'
				&& field !== 'isDepositToTrustAccount' && field !== 'successfulPaymentCount' && field !== 'paymentNotificationUrl' && field !== 'transferSurcharge' && !params[field]) {
				if ((field === 'scheduledNumberOfPayments' && recurringInfo.isBasedOnEndDate) || (field === 'recurringAmount' && recurringInfo.isBasedOnTotalAmount)
					|| (field === 'endDate' && !recurringInfo.isBasedOnEndDate) || (field === 'totalAmount' && !recurringInfo.isBasedOnTotalAmount)) // avoiding validation for disabled cases
					continue;
				setValidateRequired(true);
				return false;
			}
		}
		setValidateRequired(false);
		return true;
	};

	const getCalculatedFields = async (selectedAmount, selectedEndDateValue, scheduleType, startDate, endDate) => {
		if ((recurringInfo.scheduleType || scheduleType) && (recurringInfo.startDate || startDate) && (recurringInfo.endDate || endDate || recurringInfo.scheduledNumberOfPayments) && (recurringInfo.totalAmount || recurringInfo.recurringAmount)) {
			const schedule = {
				paymentSchedule: {
					scheduleType: scheduleType ? scheduleType : recurringInfo.scheduleType,
					startDate: startDate ? moment(startDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : moment(recurringInfo.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
					// eslint-disable-next-line no-nested-ternary
					endDate: endDate ? moment(endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : recurringInfo.endDate ? moment(recurringInfo.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD') : null,
					isBasedOnEndDate: selectedEndDateValue === 'endDate',
					isBasedOnTotalAmount: selectedAmount === 'totalAmount',
					scheduledNumberOfPayments: recurringInfo.scheduledNumberOfPayments,
					totalAmount: recurringInfo.totalAmount,
					recurringAmount: recurringInfo.recurringAmount
				}
			};
			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_schedule_calculated_fields`, schedule, axiosConfig);
			if (data.code === 200) {
				setRecurringInfo(prev => ({
					...prev,
					endDate: data.calculatedPaymentSchedule.endDate,
					scheduledNumberOfPayments: data.calculatedPaymentSchedule.scheduledNumberOfPayments,
					totalAmount: data.calculatedPaymentSchedule.totalAmount,
					recurringAmount: data.calculatedPaymentSchedule.recurringAmount,
				}));
			}
		}
	};

	const handleAmountChange = async (event) => {
		setSelectedAmountValue(event.target.value);
		setRecurringInfo(prev => ({ ...prev, isBasedOnTotalAmount: event.target.value === 'totalAmount' }));
		setRecurringAmountError(''); setTotalAmountError('');
		await getCalculatedFields(event.target.value, selectedValue);
	};

	const handleTypeChange = async ({ target: { value } }) => {
		setRecurringInfo(prev => ({ ...prev, scheduleType: value, description: `${value} payment schedule for ${recurringInfo.applicationEntityName} ` }));
		if (recurringInfo.endDate && recurringInfo.startDate)
			validateEndDate();
		validateForm(value, recurringInfo.startDate, recurringInfo.endDate);
		await getCalculatedFields(selectedAmountValue, selectedValue, value);
	};
	const handlePaymentChange = ({ target: { value } }) => {
		setRecurringInfo(prev => ({ ...prev, paymentMethodId: value }));
		getPaymentMethodBin(value);
	};
	const handleDepositChange = (event) => {
		setRecurringInfo(prev => ({ ...prev, depositTo: event.target.value }));
		if(event.target.value==='Trust')
			setCheckTransferSurcharge({ checked: false });
	};

	const handleChange = async (event) => {
		setSelectedValue(event.target.value);
		setRecurringInfo(prev => ({ ...prev, isBasedOnEndDate: event.target.value === 'endDate' }));
		setOccurrenceError(''); setEndDateError('');
	};
	const handleInputChange = ({ target: { value, id } }) => {
		// eslint-disable-next-line no-param-reassign
		if ((id === 'totalAmount' || id === 'recurringAmount') && value > 100000000) value = 10000000;
		// eslint-disable-next-line no-param-reassign
		if (id === 'scheduledNumberOfPayments' && value > 1000) value = 1000;
		setRecurringInfo(prev => ({ ...prev, [id]: value }));
		validateForm();
	};

	const handleDecimalInput = async ({ target: { value, id } }) => {
		setRecurringInfo(prev => ({ ...prev, [id]: value ? parseFloat(value).toFixed(2) : null }));
		if (id === 'totalAmount') validateTotalAmount();
		else validateRecurringAmount();

		validateForm();
		await getCalculatedFields(selectedAmountValue, selectedValue);
	};
	const handleStartDateChange = async (value) => {
		setRecurringInfo(prev => ({ ...prev, startDate: value }));
		validateStartDate(value); validateForm(recurringInfo.scheduleType, value, recurringInfo.endDate);
		await getCalculatedFields(selectedAmountValue, selectedValue, recurringInfo.scheduleType, value);
	};
	const handleEndDateChange = async (value) => {
		setRecurringInfo(prev => ({ ...prev, endDate: value }));
		validateEndDate(value);
		validateForm(recurringInfo.scheduleType, recurringInfo.startDate, value);
		await getCalculatedFields(selectedAmountValue, selectedValue, recurringInfo.scheduleType, recurringInfo.startDate, value);
	};
	const handleOccurrenceOnBlur = async () => {
		validateOccurrences();
		validateForm();
		await getCalculatedFields(selectedAmountValue, selectedValue);
	};
	const handleMessageClose = () => {
		setIsMessageOpen(false);
	};

	const validateForm = (scheduleType, startDate, endDate) => {
		const start = startDate ? startDate : recurringInfo.startDate;
		const end = endDate ? endDate : recurringInfo.endDate;
		const type = scheduleType ? scheduleType : recurringInfo.scheduleType;

		if (type && start && (recurringInfo.totalAmount || recurringInfo.recurringAmount) && (end || recurringInfo.scheduledNumberOfPayments) && recurringInfo.notificationEmailTo && recurringInfo.description) {
			const isValid = validateTotalAmount() && validateRecurringAmount() && validateStartDate(start, end) && validateEndDate(end,start) && validateOccurrences();
			setIsFormValid(isValid);
		} else setIsFormValid(false);
	};

	const onPopUpClose = () => {
		onClose();
		setStartDateError('');
		setEndDateError('');
		setTotalAmountError('');
		setRecurringAmountError('');
		setOccurrenceError('');
		setEmailListError('');
	};

	const handleIsToSaveChange = ({ target: { checked } }) => {
		setCheckTransferSurcharge({ checked });
	};

	let selectedPaymentMethod
	if (paymentMethods.length > 0 && recurringInfo.paymentMethodId) {
		selectedPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.paymentMethodId === recurringInfo.paymentMethodId)
	}

	return (
		<Dialog open={open} maxWidth='md'>
			<Grid item xs={12}>
				<Header title={headerTitle} headerLogo={recurringHeaderSchedule} isPopup={true} />
				{isEditMode && <ListActionsToolbar
					className={classes.header}
					isEditMode={true}
					isSelectedSchedule={true}
					scheduleStatus={selectedSchedule?.status}
					onDeleteClick={onDeleteClick}
					onCancelClick={onCancelClick}
					onSaveClick={onSaveClick}
				/>}
			</Grid>
			<Messages message={message} severity={severity} isOpen={isMessageOpen} handleClose={handleMessageClose} />
			<Grid className={classes.overflow}>
				<Grid container direction='row' className={classes.container} >
					{!isEditMode && (<Grid item xs={12}>
						<Typography>Please make sure that all information and payment method are inputed correctly.</Typography>
					</Grid>)}
					<Grid container direction='row' justifyContent='space-between'>
						<Grid item xs={7}>
							<FormTextField label={labelEntity} id='entityName' type='text' required={false} isDisabled={true} value={recurringInfo.applicationEntityName} />
						</Grid>
						<Grid item xs={3}>
							<FormDropDown disable={isEditMode && selectedSchedule?.status !== 'Not Started'} fullWidth={true} id='depositTo' title='Deposit to' value={recurringInfo.depositTo} list={application === 'OT' || application === 'OTW' ? [ 'Operating (General)' ] : DEPOSITLIST} required={false} onChange={handleDepositChange} />
						</Grid>
					</Grid>
					<Grid item xs={12} className={classes.rightSpacing}>
						<FormDropDown
							disable={isEditMode && recurringInfo.transferSurcharge && selectedSchedule?.status !== 'Not Started'}
							fullWidth={true}
							id='paymentMethod'
							title='Payment Method'
							value={recurringInfo.paymentMethodId}
							required={false} list={paymentMethods}
							onChange={handlePaymentChange}
							showInfo={selectedSchedule.status === 'Active' && isEditMode && recurringInfo.transferSurcharge}
							paymentInfo={RecurringFieldsInfo.paymentInfo}
						/>
					</Grid>

				</Grid >
				<Grid item className={classes.divider} xs={12}><Divider /></Grid>
				<Grid container className={classes.container}>
					<Grid item xs={4} className={classes.rightSpacing}>
						<FormDropDown disable={isEditMode && selectedSchedule?.status !== 'Not Started'} fullWidth={true} id='scheduleType' title='Recurring Type' list={RECURRINGTYPES} value={recurringInfo.scheduleType} onChange={handleTypeChange} />
					</Grid>
					<Grid item xs={4}>
						<Grid container>
							<Grid item xs={2}>
								<Radio onChange={handleAmountChange} checked={selectedAmountValue === 'recurringAmount'} value='recurringAmount' style={{ color: COLORS.blueButtons, marginTop: 33 }} name='amount' />
							</Grid>
							<Grid item xs={10} >
								<FormTextField validateRequired={validateRequired} value={recurringInfo.recurringAmount} isDisabled={selectedAmountValue === 'totalAmount' || (isEditMode && selectedSchedule?.status !== 'Not Started')} onChange={handleInputChange}
									label='Recurring Amount' id='recurringAmount' placeholder="$ 0.00" type='number' onFocusOut={handleDecimalInput} helperText={recurringAmountError} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<Grid container>
							<Grid item xs={2}>
								<Radio onChange={handleAmountChange} checked={selectedAmountValue === 'totalAmount'} value='totalAmount' name='amount' style={{ color: COLORS.blueButtons, marginTop: 33 }} />
							</Grid>
							<Grid item xs={10} className={classes.rightSpacing}>
								<FormTextField validateRequired={validateRequired} onChange={handleInputChange} isDisabled={selectedAmountValue === 'recurringAmount' || (isEditMode && selectedSchedule?.status !== 'Not Started')} label='Total Amount' id='totalAmount' placeholder="$ 0.00" value={recurringInfo.totalAmount} type='number' helperText={totalAmountError} onFocusOut={handleDecimalInput} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4} className={classes.rightSpacing}>
						<FormDatePicker isDisabled={isEditMode && selectedSchedule?.status !== 'Not Started'} validateRequired={validateRequired} label="Start Date" id="startDate" minDate={new Date()} value={recurringInfo.startDate} onChange={handleStartDateChange} helperText={startDateError} />
					</Grid>
					<Grid item xs={4}>
						<Grid container>
							<Grid item xs={2}>
								<Radio onChange={handleChange} checked={selectedValue === 'occurrences'} value='occurrences' style={{ color: COLORS.blueButtons, marginTop: 33 }} name='occurrence' />
							</Grid>
							<Grid item xs={10}>
								<FormTextField validateRequired={validateRequired} id='scheduledNumberOfPayments' type='number' isDisabled={selectedValue === 'endDate' || (isEditMode && selectedSchedule?.status !== 'Not Started')} value={recurringInfo.scheduledNumberOfPayments} onChange={handleInputChange}
									onFocusOut={handleOccurrenceOnBlur} onKeyUp={validateOccurrences} helperText={occurrenceError} label='Occurrences' />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={4}>
						<Grid container>
							<Grid item xs={2}>
								<Radio onChange={handleChange} checked={selectedValue === 'endDate'} value='endDate' style={{ color: COLORS.blueButtons, marginTop: 33 }} name='occurrence' />
							</Grid>
							<Grid item xs={10} className={classes.rightSpacing}>
								<FormDatePicker validateRequired={validateRequired} label='End Date' id="endDate" isDisabled={selectedValue === 'occurrences' || (isEditMode && selectedSchedule?.status !== 'Not Started')} minDate={new Date()} value={recurringInfo.endDate} onChange={handleEndDateChange} helperText={endDateError} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<FormTextField id='description' validateRequired={validateRequired} value={recurringInfo.description} type='text' label='Description' onChange={handleInputChange} onFocusOut={validateForm} />
					</Grid>
						{ applicationSurchargeEnabled && recurringInfo.depositTo === 'Operating (General)'
						&& selectedPaymentMethod?.paymentMethod && !(selectedPaymentMethod.paymentMethod.includes('ACH'))
						&& selectedTokenBinType === 'credit' && (<Grid item xs={12}>
							<Typography className={classes.lblSurcharge}>
								<InfoIcon className={classes.infoIcon} />
									<span className={classes.greyText} style={{verticalAlign:'middle'}}> Credit card surcharge will be transferred to your client</span>
							</Typography>
						</Grid>)}
				</Grid >
				<Grid item className={classes.divider} xs={12}><Divider /></Grid>
				<Grid container className={classes.container}>
					<Grid item xs={12}>
						<FormTextField fullWidth={true} id='notificationEmailTo' isDisabled={true} showInfo={true} helperText={emailListError} value={recurringInfo.notificationEmailTo} type='text' label='Notification will be sent to following email(s)' rows={3} isMultiline={true}
						info={recurringInfo.notificationEmailTo ? 'To manage recurring payments email notification, please reach out to your admin user to add email addresses in the APX email configuration setting for recurring payment transactions.' :
						'You currently have no email address set-up to receive notifications for recurring payments, please reach out to your admin user to add email addresses in the email configuration setting for recurring payment transactions'} />
					</Grid>
					<Grid item xs={12}>
						<Typography className={classes.lblEnteredBy}>Entered By:</Typography>
						<Typography className={classes.bold} id='enteredBy'>{recurringInfo.firmUserName}</Typography>
					</Grid>
				</Grid>
			</Grid>
			{isLoading && <InProgress />}
			<Grid container className={classes.whiteContainer} direction="row" alignItems="flex-end" alignContent='flex-end' justifyContent="flex-end" >
				{isEditMode && (
					<Button
						variant='outlined'
						className={classes.overview}
						onClick={onOverViewClick}
						id={'schedule-payment-overview'}
					>
						Payment Overview
					</Button>
				)}

				{!isEditMode && <Button
					id={'close-schedule'}
					variant="outlined"
					className={classes.btnClose}
					onClick={onPopUpClose}>
					Close
				</Button>}
				<Button
					id={'create-or-close-schedule'}
					variant="outlined"
					disabled={isLoading || (!isEditMode && !isFormValid)}
					className={!isEditMode ? classes.btnCreate : classes.btnClose}
					onClick={isEditMode ? onClose : createSchedule}
				>
					{isEditMode ? 'Close' : 'Create Schedule'}
				</Button>
				 {isEditMode && (
					<Button
						className={classes.btnCreate}
						variant='outlined'
						id={'save'}
						onClick={onSaveClick}
						disabled={!isValid}
					>
						Save & Close
					</Button>)}
			</Grid>
		</Dialog>
	);
}

export default memo(Schedule);
