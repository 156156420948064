import React, { useEffect, useState } from 'react';
import Carousel from "react-material-ui-carousel";
import { useHistory, useParams } from "react-router-dom";
import { Button, CardMedia } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { styled } from "@mui/material/styles";
import Welcomebackgroundimage from '../../assets/images/welcomepagebackground.png';
import TrustAndOperationgAccout from '../../assets/images/trustandoperatingaccounts.png';
import Onlinepaymentlink from '../../assets/images/onlinepaymentlink.png';
import Recurringpayments from '../../assets/images/recurringpayments.png';
import Apexreportingforreconciliation from '../../assets/images/apxreportingforreconciliation.png';
import Pcidatasecuritystandard from '../../assets/images/pcidatasecuritystandard.png';
import Quote from '../../assets/images/quote.png';
import Transparentpricing from '../../assets/images/transparentpricing.png';
import { WelcomePageIconsInfo } from "./WelcomePageIconsInfo";
import Header from './Header';
import Footer from './Footer';
import { initializePendo } from '../services/Services';
import { useQuery } from '../hooks/useQuery';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 17,
    },
}));

const styles = theme => ({
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    container: {
        position: 'relative',
        textAlign: 'center',
        color: 'white',
    },
    topasad: {
        color: 'blue',
        position: 'absolute',
        top: '10%',
        left: '50%',
    },
    icon: {
        width: '100%',
        margin: 'auto'
    },
    img: {
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: 'white',
    },
    button1: {
        color:'#E14428',
        backgroundColor: 'white',
        fontWeight: 700,
        marginTop: '1%'
    },
    button2: {
        color: 'white',
        backgroundColor: '#1070D7',
        border:  '1px solid white',
        '&:hover': {
            background: '#589BE3',
        },
    },
    button3: {
        background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
        fontWeight: 700,
        color: 'white'
    },
    card: {
        backgroundColor: '#FFFFFF',
        boxShadow: ' 0px 0px 20px 0px rgba(69,90,100,0.40)',
        borderRadius:'4px',
    },
    testimonialName: {
        color:'#E14126',
        fontWeight:  300,
        fontStyle: 'italic'
    },
    cardDiv: {
        padding: '2%',
        paddingRight: '3%',
        paddingLeft: '3%'
    },
    divOne: {
        position: 'absolute',
        color: 'white',
        top: '2%',
        left: '51%',
        transform: 'translateX(-50%)',
        width: '95%'
    },
    divTwo: {
        position: 'absolute',
        color: 'white',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    divThree: {
        position: 'absolute',
        color: 'white',
        top: '32%',
        left: '55%',
        transform: 'translateX(-50%)',
        width: '80%'
    },
    divFour: {
        position: 'absolute',
        color: 'red',
        top: '68%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%'
    },
    divFive: {
        position: 'absolute',
        color: 'white',
        top: '90%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '80%'
    }
})

const WelcomePage = (props) => {
    const { classes } = props
    const history = useHistory()
    const params = useParams();
    const query = useQuery();
    const [applicationCustomerId, setApplicationCustomerId] = useState('');

    useEffect(() => {
        if (params.customer) {

            const application = query.get('application');
            const acid = query.get('applicationCustomerId');

            if (!acid) history.push('/');
            if (acid) {
                setApplicationCustomerId(acid);
                initializePendo(application, acid);
            }
        }
    }, [ params ]);

    const showTestimonials = () => {
        const items = [
            {
                name: "OfficeTools Client",
                description: "APX option has been extremely helpful not only the speed of getting paid, but the ease for the clients. " +
                    "And it integrates with office tools, which we have been using for years. so it's really ideal.."
            },
            {
                name: " AbacusLaw Client",
                description: "APX for us was more like of having control and doing things. So we could put in client information, " +
                    "credit cards, ACH information and processing through the system. That was kind of one of my goals."
            },
            {
                name: " Amicus Attorney Client",
                description: "The big benefit with APX is that you always know " +
                    "what the fees are and we only get charged for it when we're going to use it."
            }
        ]

        return (<Carousel  activeIndicatorIconButtonProps={{style: {color: '#1070D7'}}}
                           navButtonsProps={{style: {color: '#1070D7'}}} indicatorIconButtonProps={{style: {color: '#CFD8DC'}}}>
            {items.map( (item, i) => <Item key={i} item={item} /> )}</Carousel>)}

    const Item = (props) => {
        return (
            <Card  className={classes.card}>
                <div className={classes.cardDiv}>
                    <Grid container direction='row' style={{marginTop: '0.75vh'}}>
                        <Grid item lg={1} md={1} sm={2}  xs={1} >
                            <img src={Quote} alt="APX" style={{width: '60%'}}/>
                        </Grid>
                        <Grid item md={11} sm={10} xs={10} lg={11}>
                            <Grid container  direction='column' spacing={1}>
                                <Typography sx={{ fontSize: { lg: 25, md: 18, sm: 10, xs: '50%' }}}>
                                    {props.item.description}
                                </Typography>
                                <Typography sx={{ fontSize: { lg: 25, md: 18, sm: 10, xs: '50%' }}} className={classes.testimonialName}>
                                    {props.item.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        )
    }

    const renderIcon = (width,top) => {return (  <div className={classes.divTwo}  style={{width: width,  top: top,}}>

        <Grid  container direction="row" >
            <Grid item md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.pciDataSecurityStandard}>
                    <Grid item md={12} lg={12} sm={12} xs={12} >
                        <Grid item md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}}>
                            <img src={Pcidatasecuritystandard} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '48%'}, textAlign: 'center'}} >
                            PCI Data Security Standard
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>

            <Grid item  md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.transparentPricing}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}}>
                            <img src={Transparentpricing} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '45%' }, textAlign: 'center'}} >
                            Transparent pricing
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>

            <Grid item  md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.advanceReporting}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Grid md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}} >
                            <img src={Apexreportingforreconciliation} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '45%'}, textAlign: 'center'}} >
                            Advanced Reporting
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>

            <Grid item  md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.trustOperatingAccount}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}}>
                            <img src={TrustAndOperationgAccout} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '45%' }, textAlign: 'center'}} >
                            Trust & Operating Accounts
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>

            <Grid item md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.payNowOnlinePayment}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}}>
                            <img src={Onlinepaymentlink} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '45%' }, textAlign: 'center'}} >
                            PayNow & Online Payments
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>

            <Grid item md={2} lg={2} sm={2} xs={2}>
                <LightTooltip title={WelcomePageIconsInfo.recurringPayments}>
                    <Grid item md={12} lg={12} sm={12} xs={12}>
                        <Grid item md={4} lg={4} sm={6} xs={4} style={{margin: 'auto'}} >
                            <img src={Recurringpayments} className={classes.icon}/>
                        </Grid>
                        <Typography sx={{ fontSize: { lg: 25, md: 16, sm: 10, xs: '45%' }, textAlign: 'center'}} >
                            Recurring payments
                        </Typography>
                    </Grid>
                </LightTooltip>
            </Grid>
        </Grid>
    </div>)}

    return (
        <Card style={{backgroundColor: 'gray'}}>

            <Header token={params?.customer} />
            <div style={{position: 'relative'}} >
                <CardMedia
                    component="img"
                    image={Welcomebackgroundimage}
                />

                <div className={classes.divOne} >
                    <Grid container direction="row"  alignItems="center"  style={{width: '100%'}}>
                        <Grid item md={12} lg={12} sm={12} xs={12}>
                            <Grid container >
                                <Container >
                                    <Grid container direction="column"  alignContent='space-between' spacing={2}>
                                        <Typography sx={{ fontSize: { lg: 43, md: 28, sm: 30, xs: '55%' }}}>Get Started Today!</Typography>
                                        <Typography sx={{ fontSize: { lg: 28, md: 18, sm: 15, xs: '50%' }}}>
                                            APX, Abacus Payment Exchange, allows you to process credit card payments and ACH
                                            transaction securely from within your practice management software
                                        </Typography>
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                onClick={() => history.push({ pathname: '/account/signup', state: { applicationCustomerId, params }})}
                                                className={classes.button1}
                                            >
                                                <Typography sx={{ fontSize: { lg: 15, md: 13, sm: 10, xs:'50%' }}}>SIGN UP FOR FREE</Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Grid>
                        </Grid>
                    </Grid>

                </div>

                <Box display={{xs: 'none', lg:'block', md:'none'}}>{renderIcon('70%', '19.75%')}</Box>
                <Box display={{xs: 'block', md: 'none', lg:'none', sm: 'none'}}>{renderIcon('99%', '17.75%')}</Box>
                <Box display={{xs: 'none', md: 'block', lg:'none', sm:'block' }}>{renderIcon('85%', '19.75%')}</Box>

                <div className={classes.divThree} >

                    <Typography sx={{ fontSize: { lg: 43, md: 28, sm: 18, xs: '55%' }, color: '#1070D7', marginBottom: '1%', marginLeft: '5%'}}>
                        APX integrated payment processing system
                    </Typography>
                    <div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0',position:'relative'}}>
                        <div className="wistia_responsive_wrapper" style={{height:'80%',left:'0',position:'absolute',top:'0',width:'80%'}}>
                            <iframe src="https://fast.wistia.net/embed/iframe/81gma5p4mq?videoFoam=true"
                                    title="APX (Abacus Payment Exchange) in 30 seconds Video"
                                    allow="autoplay; fullscreen"
                                    allowTransparency="true" frameBorder="0" scrolling="no"
                                    className="wistia_embed" name="wistia_embed"
                                    msallowfullscreen width="100%" height="100%">

                            </iframe>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Grid container alignContent='center' alignItems='center' justifyContent='center' style={{marginTop: '2%'}}>
                                    <Button variant="contained" onClick={() => window.open('https://www.abacusnext.com/solutions/apx-online-payments/')}
                                            disableElevation className={classes.button2}>
                                        <Typography sx={{ fontSize: { lg: 15, md: 13, sm: 10, xs: '50%' }}}>LEARN MORE ABOUT APX</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                </div>
                <div className={classes.divFour} >
                    <Grid container direction="row"   alignItems="center" >
                        <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                            <Typography sx={{ fontSize: { lg: 40, md: 25, sm: 15, xs: 10 }, color: '#1070D7' }}>
                                Testimonials
                            </Typography>
                        </Grid>
                        <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                            <Grid item md={7} lg={6} sm={8} xs={12}>
                                {showTestimonials()}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.divFive} >
                    <Grid container alignItems="center"  justifyContent="center" >
                        <Grid item md={12}>
                            <Grid container direction="column"  alignItems="center" alignContent='center' justifyContent="center" spacing={1} >
                                <Typography sx={{ fontSize: { lg: 43, md: 28, sm: 18, xs: '55%' }}}>
                                    Get Started Today!
                                </Typography>
                                <Typography  sx={{ fontSize: { lg: 23, md: 18, sm: 13, xs: '50%' }}}>
                                    Sign up for APX and get onboard immediately.
                                </Typography>
                                <Grid item lg={6} md={7} sm={12} xs={12}>
                                    <Button variant="contained" onClick={() => history.push({ pathname: '/account/signup', state: { applicationCustomerId, params }})}
                                            className={classes.button3}>
                                        <Typography sx={{ fontSize: { lg: 15, md: 13, sm: 15, xs: 6 }}}>SIGN UP FOR FREE</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <Footer/>
        </Card>
    );
}

export default withStyles(styles)(WelcomePage);
