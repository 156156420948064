import React, { memo } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import loadingImage from '../../../assets/images/loading_image.png';

const useStyles = makeStyles((theme) => ({
    noData: {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 500
    },
    loadingContainer: {
        width: '100%',
        marginTop: '30px'
    },
    messageWithIcon: {
        display: 'flex',
        marginTop: '6rem',
        alignItems: 'center',
        flexDirection: 'column'
    },
    icon: {
        width: '120px',
        marginBottom: '15px'
    }
}))

function Loader({
    isLoading = false,
    message = 'Please wait while we load your recurring payment transactions.'
}) {
	const classes = useStyles();

    if (!isLoading) {
        return <div className={classes.noData}>
            You currently don't have any recurring payment transactions.
        </div>
    }

    return (
        <div className={classes.loadingContainer}>
            <LinearProgress />
            <div className={classes.messageWithIcon}>
                <img src={loadingImage} className={classes.icon} alt='Loading' />
                <span>{message}</span>
            </div>
        </div>
    )
}

Loader.propTypes = {
	message: PropTypes.string,
    isLoading: PropTypes.bool
}

export default memo(Loader)
