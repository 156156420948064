import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { useForm,  Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SignupFieldsInfo } from './SignupFieldsInfo';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));


const useStyles = makeStyles({
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	selectedLabel: {
		color: COLORS.blueButtons,
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#455A64',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	}
});

// eslint-disable-next-line require-jsdoc
function SignUpAccount({
	savedData = {},
	setSignupFields = () => {},
	setCheckIsSateEmpty = () => {},
   	setPageError = () => {}
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);

	const schema = yup.object({
		password: yup.string().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/,
			function(props) {return ` Password must be at least eight characters long, must contain uppercase
			and lowercase letters, and include a number and a special character.`})
			.required(` password is required`).required('Password is required'),
		passwordConfirmation: yup.string().test('passwords-match', 'Passwords must match', function(value) {
			return this.parent.password === value;
		})
	});

	const { watch, control, reset, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			password: savedData?.['password'] || '',
			passwordConfirmation: savedData?.['passwordConfirmation'] || '',
		},
		resolver: yupResolver(schema)
	});

	useEffect(()=> {
		if (savedData?.['password']) {
			reset(savedData);
		}
	}, [ savedData ]);

	const onSubmitted = (data) => {
		setSelected(false);
		if (data['password']) { setSignupFields(prev => ({ ...prev,
			aboutBusiness: {
				...prev['aboutBusiness'],
				password: data['password']
			}
		}));}
	};

	useEffect(() => {
		if (!errors['password']?.message) setCheckIsSateEmpty(prev => ({ ...prev, password: watch()?.['password'] }));
		if (!errors['passwordConfirmation']?.message) {
			setCheckIsSateEmpty(prev => ({ ...prev, passwordConfirmation: watch()?.['passwordConfirmation'] }));
		} else {
			setCheckIsSateEmpty(prev => ({ ...prev, passwordConfirmation: '' }));
		}

	});

	useEffect(() => {
		setPageError(prev => ({ ...prev, password: errors['password']?.message }));
	}, [ errors['password']?.message ]);

	useEffect(() => {
		setPageError(prev => ({ ...prev, password: errors['passwordConfirmation']?.message }));
	}, [ errors['passwordConfirmation']?.message ]);

	return (
		<>
			<Grid item md={6} xs={12}>
				<Typography className={selected ? classes.selectedLabel : classes.label}>
					<span className={classes.asterisk} >*</span>
                Password <LightTooltip title={SignupFieldsInfo.password} classes={{ tooltip: classes.customWidth }}>
						<HelpIcon style={{ color: COLORS.blueButtons }} fontSize='small'/>
					</LightTooltip>
				</Typography>
				<Controller control={control} name='password'
					render={({ field }) => (
						<TextField {...field} onFocus={() => setSelected(true)} type='password'
							onBlur={ handleSubmit(onSubmitted)}
							fullWidth
							variant='outlined'
							size='small'
							InputProps={{ classes: { root: classes.selectedBorder } }}
							helperText={errors['password']?.message}
							error={!!errors['password']?.message}
						/>
					)}
				/>
			</Grid>
			<Grid item md={6} xs={12}  style={{ marginTop: '0.7%' }}>
				<Typography className={selected ? classes.selectedLabel : classes.label} >
					<span className={classes.asterisk} >*</span>
                Confirm Password
				</Typography>
				<Controller control={control} name='passwordConfirmation'
					render={({ field }) => (
						<TextField {...field} onFocus={() => setSelected(true)} type='password'
							onBlur={ handleSubmit(onSubmitted)}
							fullWidth
							variant='outlined'
							size='small'
							InputProps={{ classes: { root: classes.selectedBorder } }}
							helperText={errors['passwordConfirmation']?.message}
							error={!!errors['passwordConfirmation']?.message}
						/>
					)}
				/>
			</Grid>
		</>
	);

}

SignUpAccount.propTypes = {
	signupFields: PropTypes.object,
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	setCheckIsSateEmpty: PropTypes.func,
	setPageError: PropTypes.func
};

export default memo(SignUpAccount);