export const DisbursementEntryEventType = {
    TRAN_CAPTURE: 7,
    TRAN_REFUND: 8,
    CHARGEBACK: 11,
    ECHECK_SALE: 21,
    ECHECK_REFUND: 22,
    RETURNED: 23
    
}

export const IsRefundDisbursement = (eventType) => {

    if(eventType === DisbursementEntryEventType.TRAN_REFUND || eventType === DisbursementEntryEventType.ECHECK_REFUND 
        || eventType === DisbursementEntryEventType.RETURNED || eventType === DisbursementEntryEventType.CHARGEBACK) {
        return true;
    }

    return false;
}