import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const useStyles = makeStyles({
	inputGrid: {
		marginTop: 10,
	},
	input: {
		backgroundColor: COLORS.whiteText,
		'& .MuiIconButton-root': {
			right: 15
		},
		'& .MuiInputAdornment-positionStart': {
			marginRight: 0
		}
	},
	inputDisabled: {
		color: COLORS.darkGrayText,
		backgroundColor: COLORS.lightGray,
		'& .MuiIconButton-root': {
			right: 15
		},
		'& .MuiInputAdornment-positionStart': {
			marginRight: 0
		}
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	label: {
		color: COLORS.secureMessage,
	},
	selectedLabel: {
		color: COLORS.blueButtons,
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
	},
	datePicker: {
		'& .MuiPickersDay-daySelected': {
			backgroundColor: '#E14126 !important'
		},
		'& .MuiPickersDay-current': {
			color: '#E14126 !important'
		},
	},
	error: {
		'&.MuiFormHelperText-root': {
			color: COLORS.grayContainer
		},
		'&.MuiFormHelperText-root.Mui-error': {
			color: COLORS.error
		}

	},
	asteriskDisabled: {
		color: COLORS.footerText,
		marginRight: 5
	},
});

function FormDatePicker({
	label = '',
	value = '',
	id = '',
	fullWidth = true,
	size = 12,
	required = true,
	validateRequired = false,
	type = '',
	showInfo = false,
	info = '',
	isDisabled = false,
	onChange = () => { },
	onBlur = () => { },
	helperText = '',
	minDate = undefined
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);
	const checkIsRequiredError = () => {
		if (helperText.length > 0) return true;
		if ((required && validateRequired)) return !value;
		else return false;
	};

	return (
		<Grid item xs={size} className={classes.inputGrid}>
			<Typography className={selected ? classes.selectedLabel : classes.label}>{label && (required ? <span className={isDisabled ? classes.asteriskDisabled : classes.asterisk} >*</span> : null)}
				{label}  {showInfo && <Tooltip title={info} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon color='primary' fontSize='small' /></Tooltip>}</Typography>
			<MuiPickersUtilsProvider utils={DateFnsUtils} >
				<KeyboardDatePicker
					defaultValue={null}
					autoOk
					variant="inline"
					inputVariant="outlined"
					format="MM/dd/yyyy"
					value={value ? moment(value) : null}
					InputAdornmentProps={{ position: 'start' }}
					onChange={onChange}
					onBlur={onBlur}
					InputProps={{ className: isDisabled ? classes.inputDisabled : classes.input }}
					size='small'
					fullWidth={fullWidth}
					disabled={isDisabled}
					DialogProps={{ className: classes.datePicker }}
					id={id}
					minDate={minDate}
					disableToolbar="true"
					helperText={helperText}
					FormHelperTextProps={{ classes: classes.error }}
					error={checkIsRequiredError()}
					autoComplete='off'
				/>
			</MuiPickersUtilsProvider>
		</Grid>
	);

}

FormDatePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	fullWidth: PropTypes.bool,
	size: PropTypes.number,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	validateRequired: PropTypes.bool,
	type: PropTypes.string,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
};

export default memo(FormDatePicker);
