import React, { memo, useState} from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { COLORS } from '../constants/Colors';

function FormCheckedBox({
    handleIsToSaveChange = () => {},
    isToSave = {},
    label='',
    isDisabled = false
}) {
    return (
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isToSave.checked}
                        onChange={handleIsToSaveChange}
                        name='checked'
                        style={{ color: COLORS.blueButtons}}
                        disabled={isDisabled}
                   />
                }
                label={label}
            />
    );
}

FormCheckedBox.propTypes = {
    handleIsToSaveChange: PropTypes.func,
    isToSave: PropTypes.object,
}

export default memo(FormCheckedBox);
