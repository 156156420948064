import React, { memo, Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types'; import FormCheckedBox from '../forms/FormCheckedBox';
import { COUNTRIES_PAYMENTS } from '../constants/Locations';
import FormDropDown from '../forms/FormDropDown';
import FormTextField from '../forms/FormTextField';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import axios from 'axios';
import { getApiUrl } from '../utils/getApiUrl';
import { createPaymentMethodDataCallback, createPaymentMethodErrorCallback } from '../services/Services';
import { CREDIT_CARD_FORM_FIELDS, CREDIT_CARD_CONFIG_FIELDS } from '../constants/CreditCard.fields';
import { COLORS } from '../constants/Colors';
import PayFieldInput from '../forms/PayFieldInput';

const useStyles = makeStyles((theme) => ({
	emailNote: {
		height: 12,
		color: '#455A64',
		fontFamily: 'Roboto',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: '12px',
		marginTop: 5,
		marginBottom: 6
	}
}));

function CreditCard({
	merchantId = '',
	amount = '0',
	isPayNow = true,
	handleInputChange = () => { },
	handleCountryChange = () => { },
	handleStateChange = () => { },
	creditCardInfo = {},
	validateRequired = false,
	validCreditCard = {},
	showExistingPaymentMethods = true,
	axiosConfig = {},
	states = []
}) {
	const classes = useStyles();

	const history = useHistory();

	const [ hideFields, setHideFields ] = useState(false);

	useEffect(() => {
		if (window.PayFields.isValid() && isPayNow && !showExistingPaymentMethods) {
			window.PayFields.appendIframe();
			window.PayFields.reload();
		}
	}, [ showExistingPaymentMethods,isPayNow ]);

	const loadFormConfig = (errorCallback, callback, apiKey) => {

		window.PayFields.config.apiKey = apiKey;
		window.PayFields.config.merchant = merchantId;
		window.PayFields.fields = CREDIT_CARD_CONFIG_FIELDS;
		if (merchantId) {
			window.PayFields.config.mode = 'token';
			window.PayFields.config.amount = Number(amount) ? Number(amount * 100).toFixed(2) : '0';
		}
		window.PayFields.customizations = {
			style: {
				'.input': {
					color: COLORS.blackText,
					backgroundColor: COLORS.whiteText,
					fontSize: '1rem',
					borderRadius: '4px',
					height: '38px',
					width: '97%',
					border: '1px solid #ced4da',
					margin: 'auto'
				},
				'.expiration': {
					width: '78%'
				},
				'.card-icon': {
					display: 'inline',
					position: 'absolute',
					top: '40%',
					transform: 'translateY(-55%)',
					right: '18px',
					backgroundColor: COLORS.whiteText,
					color: COLORS.whiteText,
				},
				'.form-error': {
					color: '#F44336',
					fontSize: '12px',
					padding: '2px',
				}
			},
			placeholders: {
				'#payment_number': '',
				'#name': '',
				'#expiration': 'MM/YY'
			}
		};
	};

	useEffect(() => {
		const getApiKey = async () => {
			try {
				const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_api_key`, axiosConfig);
				if (data.code === 200) {
					loadFormConfig(createPaymentMethodErrorCallback, createPaymentMethodDataCallback, data.apiKey);
					window.PayFields.appendIframe();
				}
			} catch (err) {
				if (err.toString() === 'Error: Network Error') {
					history.push('/errorpage/serverDown');
				}
				console.error(err);
			}
		};
		getApiKey();
	}, []);

	useEffect(() => {
		if (!creditCardInfo.country) setHideFields(false);
		else {
			if (creditCardInfo.country === 'Others') setHideFields(false);
			else setHideFields(true);
		}
	}, [ creditCardInfo.country ]);

	const renderCreditCardForm = () => {
		CREDIT_CARD_CONFIG_FIELDS[1].values.name = creditCardInfo?.username;
		return (
			<Box width={1}>
				<PayFieldInput CREDIT_CARD_FORM_FIELDS={CREDIT_CARD_FORM_FIELDS}>
					<FormDropDown
						fullWidth={true}
						validateRequired={validateRequired}
						id='country'
						title='Country'
						list={COUNTRIES_PAYMENTS}
						value={creditCardInfo.country}
						onChange={handleCountryChange}
						width='94%'
					/>
				</PayFieldInput>
				<Grid container spacing={2}>
					{hideFields && (
						<>
							<Grid item xs={12} >
								<FormTextField
									width='97%'
									validateRequired={validateRequired}
									fullWidth={true}
									value={creditCardInfo.address}
									id='address'
									placeholder={ creditCardInfo.country !== 'Others' ? 'address' : ''}
									label='Billing Address Line 1'
									onChange={handleInputChange}
									isDisabled={creditCardInfo.country === 'Others'}
									required = {creditCardInfo.country !== 'Others'}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormTextField
									width='97%'
									validateRequired={validateRequired}
									fullWidth={true}
									value={creditCardInfo.address2}
									id='address2'
									placeholder={ creditCardInfo.country !== 'Others' ?
										'Apartment, suite, unit, etc.' : ''}
									label='Billing Address Line 2'
									onChange={handleInputChange}
									isDisabled={creditCardInfo.country === 'Others'}
									required = {false}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormTextField
									width='97%'
									validateRequired={validateRequired}
									fullWidth={true}
									id='city'
									label={creditCardInfo.country === 'GBR' ? 'Town/City' : 'City'}
									value={creditCardInfo.city}
									onChange={handleInputChange}
									isDisabled={creditCardInfo.country === 'Others'}
									required = {creditCardInfo.country !== 'Others'}
								/>
							</Grid>
							<Grid item xs={6}>
								{creditCardInfo.country === 'GBR' ? (
									<FormTextField
										width='97%'
										validateRequired={validateRequired}
										fullWidth={true}
										id='state'
										label="County"
										title="County"
										value={creditCardInfo.state}
										onChange={handleInputChange}
									/>
								) : (
									<FormDropDown
										width='93%'
										size={6}
										validateRequired={validateRequired}
										fullWidth={true}
										id='state'
										title={creditCardInfo.country === 'USA' ? 'State' : 'Province'}
										list={states}
										disable={states.length === 0}
										required = {creditCardInfo.country !== 'Others' }
										value={creditCardInfo.state}
										onChange={handleStateChange}
									/>
								)}
							</Grid>
							<Grid item xs={6}>
								<FormTextField
									width='97%'
									validateRequired={validateRequired}
									fullWidth={true}
									id='postalCode'
									label={creditCardInfo.country === 'USA' ? 'Zip' : creditCardInfo.country === 'CAN' ? 'Postal Code' : 'Postcode' }
									value={creditCardInfo.postalCode}
									required = {creditCardInfo.country !== 'Others'}
									isDisabled={creditCardInfo.country === 'Others'}
									onChange={handleInputChange}
								/>
							</Grid>
						</>
					)}
					<Grid item xs={12}>
						<FormTextField
							width='97%'
							validateRequired={validateRequired}
							fullWidth={true}
							id='email'
							label='Email'
							helperText={validCreditCard.emailError}
							value={creditCardInfo.email}
							onChange={handleInputChange}
						/>
					</Grid>
					{isPayNow && (
						<>
							<Grid item xs={12} className={classes.emailNote}>
								We will send payment details to this email address.
							</Grid>
						</>
					)}
				</Grid>
			</Box>
		);
	};

	return <Fragment>{renderCreditCardForm()}</Fragment>;
}

CreditCard.propTypes = {
	merchantId: PropTypes.string,
	amount: PropTypes.string,
	setIsLoading: PropTypes.func,
	isPayNow: PropTypes.bool,
	invoiceId: PropTypes.string,
	requestOfPaymentMethodId: PropTypes.string,
	payerId: PropTypes.string,
	showExistingPaymentMethods: PropTypes.bool,
	axiosConfig: PropTypes.object,
	merchantInfo: PropTypes.object,
	validateRequired: PropTypes.bool,
	cardNumberError: PropTypes.string,
	expirationError: PropTypes.string,
	userNameError: PropTypes.string
};

export default memo(CreditCard);
