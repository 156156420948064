import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { COLORS } from '../../constants/Colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import Header from './Header';
import { getApiUrl } from '../../utils/getApiUrl';
import SchedulesList from './SchedulesList';
import ListActionsToolbar from '../ListActionsToolbar';
import Button from '@material-ui/core/Button';
import Overview from '../overview/Overview';
import AlterSchedule from '../AlterSchedule';
import recurringHeaderSchedule from '../../../assets/images/APX_header_landing_page@1.5x.png';
import Schedule from '../schedule/Schedule';
import PieChart from '../pieChart/PieChart';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { appendPendoUrl, getMerchantData, initializePendo } from '../../services/Services';
import { useQuery } from '../../hooks/useQuery';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: COLORS.grayContainer,
		'& .MuiPaper-root': {
			boxShadow: 'none',
			border: `1px solid ${COLORS.grayBorder}`
		}
	},
	page: {
		marginTop: 50,
		marginLeft: '3%',
		maxWidth: '94%',
	},
	page1: {
		marginTop: 50,
	},
	overview: {
		height: 50,
		marginLeft: 50,
		fontWeight: 900,
		fontSize: 18,
		textTransform: 'capitalize',
		color: COLORS.blueButtons,
		borderColor: COLORS.blueButtons,
		border: '2px solid',
		marginBottom: 30,
		'&:disabled': {
			color: COLORS.disabledBlue,
			borderColor: COLORS.disabledBlue,
			border: '2px solid'
		  }
	},
	divider: {
		marginBottom: 10,
	},
	footer: {
		marginTop: '1%',
		backgroundColor: COLORS.whiteBackground
	},
	lblSurcharge:{
		fontStyle: 'italic',
		marginTop: '10px',
		color: COLORS.secureMessage,
		float: 'right',
		marginRight: 54,
	},
}));

function LandingPage() {

	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const lgMatches = useMediaQuery(theme.breakpoints.up('lg'));

	const [ merchantId, setMerchantId ] = useState('');
	const [ applicationEntityId, setApplicationEntityId ] = useState('');
	const [ isSelectedSchedule, setIsSelectedSchedule ] = useState(false);
	const [ isOverviewOpen, setIsOverviewOpen ] = useState(false);
	const [ isAlterOpen, setIsAlterOpen ] = useState(false);
	const [ selected, setSelected ] = useState('');
	const [ selectedSchedule, setSelectedSchedule ] = useState({});
	const [ totalCharged, setTotalCharged ] = useState('0.00');
	const [ totalRemaining, setTotalRemaining ] = useState('0.00');
	const [ totalAmount, setTotalAmount ] = useState('0.00');
	const [ applicationDisplayName, setApplicationDisplayName ] = useState('');
	const [ schedules, setSchedules ] = useState([]);
	const [ token, setToken ] = useState('');
	const [ openCreateSchedule, setOpenCreateSchedule ] = useState(false);
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ creationInProgress, setCreationInProgress ] = useState(false);
	const [ alterInProgress, setAlterInProgress ] = useState(false);
	const params = useParams();
	const [ alterTitle, setAlterTitle ] = useState('');
	const [ alterMessage, setAlterMessage ] = useState('');
	const [ alterAction, setAlterAction ] = useState('');
	const [ isRefreshRecurringPayments, setIsRefreshRecurringPayments ] = useState(false);
	const [ tableHeight, setTableHeight ] = useState(0);
	const [ transferSurcharge, setTransferSurcharge ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(true);

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	const handleRowClick = (data) => {
		if (data[0]?.dataIndex === selected) {
			setSelected('');
			setIsSelectedSchedule(false);
			setSelectedSchedule({});
		}
		else {
			setSelected(data[0]?.dataIndex);
			setIsSelectedSchedule(true);
			setSelectedSchedule(schedules[data[0]?.dataIndex]);
		}
	};

	const query = useQuery();

	useEffect(() => {
		if (params.merchant) {
			setToken(params.merchant);
			setMerchantId(query.get('merchantId'));
			setTransferSurcharge(query.get('transferSurcharge'));
			const appEntityId = query.get('applicationEntityId');
			if (appEntityId) {
				setOpenCreateSchedule(true);
				setApplicationEntityId(appEntityId);
			}
		}
	}, [ params, query ]);

	useEffect(() => {
		if (merchantId && token) {
			setToken(token);

			async function fetchInfo() {
				setIsLoading(true);
				const [, customer] = await Promise.all([
					getSchedules(merchantId),
					getMerchantData(merchantId, axiosConfig)
				])
				initializePendo(customer?.application, customer?.customerId, customer?.customerName);
			}
			fetchInfo();
		}
	}, [ merchantId, token ]);

	const getSchedules = async (merchantId) => {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_merchant_payment_schedules?merchantId=${merchantId}`, axiosConfig);
			setIsLoading(false);
			if (data.code === 200) {
				setSchedules(data?.paymentSchedules);
				setApplicationDisplayName(data?.applicationDisplayName);
				setTotalCharged(data?.totalCharged);
				setTotalRemaining(data?.totalRemaining);
				setTotalAmount(data?.totalAmount);
			}

		} catch (err) {
			setIsLoading(false);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const handleOnClick = () => {
		setIsRefreshRecurringPayments(true);
		setIsOverviewOpen(true);
	};

	const handleOnCloseDialog = () => {
		setIsOverviewOpen(false);
		setIsRefreshRecurringPayments(false);
		appendPendoUrl('/LandingPage');
	};

	const handleOnDeleteOpen = () => {
		setAlterAction('Delete');
		setAlterTitle('Delete Payment Schedule');
		setAlterMessage(`Are you sure want to delete a ${selectedSchedule?.recurring?.toLowerCase()} payment schedule for ${selectedSchedule?.entity} permanently ?`);
		setIsAlterOpen(true);
		appendPendoUrl('/LandingPage');
	};

	const handleOnCancelOpen = () => {
		setAlterAction('Cancel');
		setAlterTitle('Cancel Payment Schedule');
		setAlterMessage(`Are you sure want to cancel a ${selectedSchedule?.recurring?.toLowerCase()} payment schedule for ${selectedSchedule?.entity} permanently ?`);
		setIsAlterOpen(true);
		appendPendoUrl('/LandingPage');
	};

	const handleOnCloseDelete = () => {
		setIsAlterOpen(false);
	};

	const handleScheduleAlter = async () => {
		try {
			const alterRoute = alterAction === 'Delete' ? 'delete_merchant_payment_schedule' : 'cancel_merchant_payment_schedule';
			if (!selectedSchedule?.paymentScheduleId) {
				setIsAlterOpen(false);
				return;
			}
			setAlterInProgress(true);
			const { data } = await axios.delete(`${getApiUrl()}/api/merchantwebapi/recurringpayments/${alterRoute}?paymentScheduleId=${selectedSchedule?.paymentScheduleId}`, axiosConfig);
			if (data.code === 200) {
				await getSchedules(merchantId);
				setIsAlterOpen(false);
				setAlterInProgress(false);
				setSelectedSchedule({});
				handleClose();
			} else {
				setAlterInProgress(false);
				setSelectedSchedule({});
			}

		} catch (err) {
			setAlterInProgress(false);
			setSelectedSchedule({});
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const handleClose = () => {
		setOpenCreateSchedule(false);
		setIsEditMode(false);
		appendPendoUrl('/LandingPage');
	};

	const handleCloseAndRefresh = async () => {
		handleClose();
		setSelected('');
		setIsSelectedSchedule(false);
		await getSchedules(merchantId);
		appendPendoUrl('/LandingPage');
	};

	const handledRefresh = async () => {
		setSelected('');
		setIsSelectedSchedule(false);
		setSelectedSchedule({});
		await getSchedules(merchantId);
		appendPendoUrl('/LandingPage');
	};

	const handleOpenSchedule = () => {
		setOpenCreateSchedule(true);
		setIsEditMode(true);
	};

	useEffect(() => {
		const table = document.getElementById('table');
		setTableHeight(table.clientHeight);
	}, []);

	return (
		<Grid container direction='column' xs={12} justifyContent='center' className={classes.root}>
			{token && <AlterSchedule
				isLoading={alterInProgress}
				open={isAlterOpen}
				onCancel={handleOnCloseDelete}
				title={alterTitle}
				message={alterMessage}
				onSubmit={handleScheduleAlter}
			/>
			}
			{token && <Overview
				isRefreshRecurringPayments={isRefreshRecurringPayments}
				selectedSchedule={selectedSchedule}
				open={isOverviewOpen}
				onDialogClose={handleOnCloseDialog}
				token={token} />}
			{token && <Schedule
				onOverViewClick={handleOnClick}
				onDeleteClick={handleOnDeleteOpen}
				onCloseAndRefresh={handleCloseAndRefresh}
				onClose={handleClose}
				token={token}
				applicationEntityId={applicationEntityId}
				open={openCreateSchedule}
				selectedSchedule={selectedSchedule}
				isEditMode={isEditMode}
				onCancelClick={handleOnCancelOpen}
				isLoading={creationInProgress}
				setCreationInProgress={setCreationInProgress}
				setSelectedSchedule={setIsSelectedSchedule}
				onRefresh={handledRefresh}
			/>
			}
			<Grid item xs={12}>
				<Header title='Recurring Payments' headerLogo={recurringHeaderSchedule} info='Here you can manage recurring payment schedules you created.' />
			</Grid>
			<Grid item xs={12} className={lgMatches ? classes.page : classes.page1}>
				<ListActionsToolbar
					isSelectedSchedule={isSelectedSchedule}
					scheduleStatus={selectedSchedule?.status}
					onDeleteClick={handleOnDeleteOpen}
					onCancelClick={handleOnCancelOpen}
					onOpenClick={handleOpenSchedule}
				/>
				<Grid container direction='row'>
					<Grid item lg={selectedSchedule.totalAmount ? 9 : 12} md={selectedSchedule.totalAmount ? 9 : 12} id='table'>
						<SchedulesList
							isLoading={isLoading}
							onRowClick={handleRowClick}
							schedules={schedules}
							applicationDisplayName={applicationDisplayName}
							totalAmount={totalAmount}
							totalCharged={totalCharged}
							totalRemaining={totalRemaining}
						/>
					</Grid>
					{selectedSchedule.totalAmount && <Grid item lg={3} md={3}>
						<PieChart
							selectedSchedule={selectedSchedule}
							token={token}
							applicationEntityId={applicationEntityId}
							tableHeight={tableHeight}
						/>
					</Grid>}
				</Grid>
			</Grid>
			<Grid item xs={12} className={classes.footer}>
				<Divider orientation='horizontal' className={classes.divider} />
				<Grid container>
					<Grid item xs={6}>
						<Button
							variant='outlined'
							className={classes.overview}
							disabled={!isSelectedSchedule}
							onClick={handleOnClick}
							id={'payment-overview'}
						>
							Payment Overview
						</Button>
					</Grid>
					<Grid item xs={6}>
						{
							transferSurcharge ?
							<Typography className={classes.lblSurcharge}> *Transferred Surcharge Recurring Payment</Typography>
							: null
						}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default memo(LandingPage);
