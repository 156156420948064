import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import FormDatePicker from '../forms/FormDatePicker';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import { COLORS } from '../constants/Colors';
import { Typography } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Chip from './Chip';
import PropTypes from 'prop-types';
import ReportSearch from './ReportSearch';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 15
	},
}));

const ControlledTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow
		{...props} classes={{ popper: className }}
		disableFocusListener
		disableHoverListener
		disableTouchListener
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 25
	},
}));

const useStyles = makeStyles((theme) => ({
	item: {
		margin: '3% 2% 2% 2%',
		border: '1px solid #AFCFF2',
		borderRadius: '20px',
		padding: '1% 2% 1% 2%',
	},
	totalTransactions: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #0DC0D6',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalDisbursement: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #5DB761',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalRefund: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #E14428',
		paddingTop: '10px',
		borderRadius: '8px',

	},
	apply: {
		backgroundColor: 'Lightblue',
		marginRight: '4%'
	},
	commonMargin: {
		marginBottom: '2%',
		marginTop: '2%'
	},
	tooltip: {
		color: '#747779',
		fontFamily: 'Roboto',
		fontWeight: '400px',
		fontSize: '16px',
		lineHeight: '14px',
		textTransform: 'none',
		marginBottom: '1%'
	},
	runReports: {
		backgroundColor: '#1070D7',
		borderRadius: 4,
		fontFamily: 'Roboto',
		fontWeight: 700,
		fontSize: 16,
		lineHeight: '14px',
		color: '#FFFFFF',
		textTransform: 'none',
		padding: 10
	},
	expandAll: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '14px',
		textDecorationLine: 'underline',
		color: '#1070D7',
		marginTop: '3%',
		marginBottom: '0.5%'
	},
	total: {
		fontFamily: 'Roboto',
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '12px',
		color: '#747779',
		margin: 'auto'
	}

}));

const ReportsSelect = ({
	value = '',
	setValue = () => {},
	openSelectTooltip = false,
	setOpenSelectTooltip = () => {},
	setSearch = () => {}
}) => {
	const [ fromDate, setFromDate ] = useState('');
	const [ toDate, setToDate ] = useState('');
	const [ selectedMuiItems, setSelectedMuiItems ] = useState(0);

	const classes = useStyles();

	const getDate = (value) => {
		return value;
	};
	const handleDateValue = (event) => {
		setValue(event.target.value);
	};

	const handleStartDateChange = async (value) => {
		setFromDate(value);
	};

	const handleToDateChange = async (value) => {
		setToDate(value);
	};

	const applyDate = () => {
		setValue(`${moment(fromDate).format('MM/DD/YYYY')} - ${moment(toDate).format('MM/DD/YYYY')}`);
		getDate(`${moment(fromDate).format('MM/DD/YYYY')} - ${moment(toDate).format('MM/DD/YYYY')}`);
		window.hiddenDateField.click();
	};

	const cancel = () => {
		setValue('');
		getDate('');
		window.cancelDateField.click();
	};

	const handleTooltipClose = () => {
		setOpenSelectTooltip(false);
		setSearch(false);
	};

	return (
		<>
			<Typography  className={classes.tooltip}>
            Report Period
				<LightTooltip title={'Select a date range for your report.'} >
					<HelpIcon style={{ color: COLORS.blueButtons, marginLeft: '1.75%' }} fontSize='small'/>
				</LightTooltip>
			</Typography >
			<FormControl fullWidth >
				<ControlledTooltip
					title={
						<>
							<Typography>
                            Please select a date range and click on the Run Report button to
                            see the disbursement report for the selected range.
							</Typography>
							<Grid container direction='row' style={{ marginTop: '1.5%' }}>
								<Grid item sm={6} style={{ marginTop: '1.5%' }}> 1 of 5</Grid>
								<Grid item sm={6}>
									<Grid container direction='row' justifyContent='flex-end'>
										<Button id='skip'
											style={{ fontFamily: 'Roboto',
												fontWeight: 700,
												color: '#CDDBFF'
											}}
											onClick={handleTooltipClose}
										>
                                        Skip
										</Button>
										<Button id='next' style={{
											backgroundColor: '#CDDBFF',
											borderRadius: 4,
											fontFamily: 'Roboto',
											fontWeight: 700,
											color: '#151D33'
										}}
										onClick={() => {handleTooltipClose(); setSearch(true);}}
										>
                                        Next
										</Button>
									</Grid>
								</Grid>

							</Grid>
						</>
					}
					open={openSelectTooltip}
					onClose={handleTooltipClose}
				>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={value}
						renderValue={value => getDate(value)}
						size="small"
						onChange={(event) => {handleDateValue(event);}}
					>

						<Stack style={{ margin: '3%' }}
							direction={{ xs: 'column', sm: 'row' }}
							spacing={{ xs: 1, sm: 2, md: 4 }}
						>
							<Chip
								selectedNumber={1}
								dateOne={moment().format('MM/DD/YYYY')}
								dateTwo=''
								label='Today'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>
							<Chip
								selectedNumber={2}
								dateOne={moment().subtract(1, 'days').format('MM/DD/YYYY')}
								dateTwo=''
								label='Yesterday'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>
							<Chip
								selectedNumber={3}
								dateOne={moment().clone().weekday(1).format('MM/DD/YYYY')}
								dateTwo={moment().format('MM/DD/YYYY')}
								label='Week-to-date'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>
							<Chip
								selectedNumber={4}
								dateOne={moment().subtract(13, 'days').calendar()}
								dateTwo={moment().format('MM/DD/YYYY')}
								label='Last 2 Weeks'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>

						</Stack>

						<Stack style={{ margin: '2%' }}
							direction={{ xs: 'column', sm: 'row' }}
							spacing={{ xs: 1, sm: 2, md: 4 }}
						>
							<Chip
								selectedNumber={5}
								dateOne={moment().subtract(1,'months').startOf('month').format('MM/DD/YYYY')}
								dateTwo={moment().subtract(1,'months').endOf('month').format('MM/DD/YYYY')}
								label='Last Month'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>
							<Chip
								selectedNumber={6}
								dateOne={moment().subtract(0,'months').startOf('month').format('MM/DD/YYYY')}
								dateTwo={moment().format('MM/DD/YYYY')}
								label='Month-to-Date'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>
							<Chip
								selectedNumber={7}
								dateOne={moment().subtract(0,'years').startOf('years').format('MM/DD/YYYY')}
								dateTwo={moment().format('MM/DD/YYYY')}
								label='Year-to-date'
								handleStartDateChange={handleStartDateChange}
								handleToDateChange={handleToDateChange}
								selectedMuiItems={selectedMuiItems}
								setSelectedMuiItems={setSelectedMuiItems}
							/>

						</Stack>

						<MenuItem
							value={`${moment(fromDate).format('MM/DD/YYYY')} - ${moment(toDate).format('MM/DD/YYYY')}`}
							style={{ opacity: '0', height: '0', width: '10%' }} id='hiddenDateField'
						>
							{moment(fromDate).format('MM/DD/YYYY')} - {moment(toDate).format('MM/DD/YYYY')}
						</MenuItem>

						<MenuItem
							value={''}
							style={{ opacity: '0', height: '0', width: '10%' }}
							id='cancelDateField'>
                        ''
						</MenuItem>

						<Grid
							container
							direction="row"
							justifyContent={'space-around'}
							style={{ borderTop: '1px solid Lightgray', borderBottom: '1px solid Lightgray' }}
						>
							<Grid iem lg={5} sm={5} className={classes.commonMargin}>
								<Stack direction="row" spacing={1}>
									<Grid style={{ margin: 'auto' }}>From</Grid>
									<FormDatePicker
										id="startDate"
										value={fromDate}
										onChange={handleStartDateChange}
									/>
								</Stack>
							</Grid>
							<Grid item lg={5} sm={5} className={classes.commonMargin}>
								<Stack direction="row" spacing={1}>
									<Grid  style={{ margin: 'auto' }}>To</Grid>
									<FormDatePicker
										id="toDate"
										value={toDate}
										onChange={handleToDateChange}
									/>
								</Stack>
							</Grid>
						</Grid>
						<Grid container direction='row' justifyContent={'flex-end'} className={classes.commonMargin}>
							<Button id='Cancel' style={{
								borderRadius: '4px',
								color: '#1070D7',
								fontWeight: 700,
								fontSize: 16,
								marginRight: '3%',
								paddingLeft: 10,
								paddingRight: 10,
								textTransform: 'none',
								fontFamily: 'Roboto'
							}}
							onClick={cancel}
							>Cancel</Button>
							<Button id='apply' style={{
								fontWeight: 700,
								fontSize: 16,
								marginRight: '3%',
								paddingLeft: 20,
								paddingRight: 20,
								textTransform: 'none',
								fontFamily: 'Roboto'
							}}
							variant="contained"
							disabled={(fromDate && toDate) ? false : true}
							onClick={applyDate}
							>Apply
							</Button>

						</Grid>

					</Select>
				</ControlledTooltip>
			</FormControl>
		</>
	);
};

ReportsSelect.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	openSelectTooltip: PropTypes.bool,
	setOpenSelectTooltip: PropTypes.func,
	setSearch: PropTypes.func
};

export default ReportsSelect;
