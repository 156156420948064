import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { COLORS } from '../../constants/Colors';
import { Button } from '@material-ui/core';

function Header({
  title = '',
  headerLogo = '',
  isPopup = false,
  info=''
}) {
    const useStyles = makeStyles({
        logo: {
            width: '100%',
        },
		title: {
			position: 'absolute',
			color: COLORS.whiteText,
			left: isPopup ? '4%' : '17%',
			display: 'flex',
			top: 0,
			marginTop: '10px',
			marginBottom: '10px',
			paddingTop: '0px',
			paddingBottom: '0px'
		},
		iconInfoButton: {
			color: COLORS.whiteText,
			display: 'flex',
			position: 'absolute',
			top: 0,
			right: '2%',
			marginTop: '10px',
			marginBottom: '10px',
			paddingTop: '2px',
			paddingBottom: '0px'
		},
        infoIcon: {
            fontSize: 30,
        },
		tooltip: {
			maxWidth: 240,
			color: COLORS.whiteText,
        	backgroundColor:COLORS.darkGray
		},
    });

    const classes = useStyles();

    return (
        <Grid container >
            <img src={headerLogo} alt="APX" className={classes.logo} />
            <h2 className={classes.title}>{title}</h2>
			{ title == 'Recurring Payments' ? 
				<Button className={classes.iconInfoButton}>
					<Tooltip title={info} classes={{ tooltip: classes.tooltip }}>
						<HelpOutlineIcon className={classes.infoIcon}/>
					</Tooltip>
				</Button>
			: null }
        </Grid>
    );
}
export default Header