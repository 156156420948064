import React, { memo } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { COLORS } from '../constants/Colors';
import PropTypes from 'prop-types';
import FormTextField from '../forms/FormTextField';
import FormCheckedBox from '../forms/FormCheckedBox';
import { COUNTRIES_PAYMENTS, STATES } from '../constants/Locations';
import FormDropDown from '../forms/FormDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 252,
    },
    dropDown: {
        position: 'relative',
    },
    inLine: {
        display: 'flex',
    },
    accountNumber: {
        marginLeft: 20
    },
    asterisk: {
        color: COLORS.asteriskRed,
        marginRight: '5px'
    },
    input: {
        backgroundColor: COLORS.whiteText
    },
    label: {
        marginLeft: 10,
        color: COLORS.secureMessage
    },
    emailNote: {
        height: 12,
        color: '#455A64',
        fontFamily: 'Roboto',
        fontSize: 15,
        letterSpacing: 0,
        lineHeight: '12px',
        marginTop: 5,
    },
    ddl:{
        marginTop:'8px',
    }
}));
function BankAccount({
    bankInfo = {},
    onInputChange = () => { },
    onSelectionChange = () => { },
    onCountrySelectionChange = () => { },
    onStateSelectionChange = () => { },
    validateRequired = false,
    isPayNow = false,
    hideName = false,
    validBankAccount={}
}) {
    const classes = useStyles();

    return (
        <Grid container spacing={0} className={classes.root} >
            {!hideName && (<Grid item xs={12}>
                <FormTextField
                    validateRequired={validateRequired}
                    label='Account Owner' id='username'
                    value={bankInfo.username}
                    onChange={onInputChange} />
            </Grid>)}
            <Grid container spacing={4} direction='row' className={classes.inLine}>
                <Grid item xs={6}>
                    <FormTextField
                        validateRequired={validateRequired}
                        label='Routing Number'
                        id='routingNumber'
                        helperText={validBankAccount.routingError}
                        value={bankInfo.routingNumber}
                        onChange={onInputChange} type='text'
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField
                        validateRequired={validateRequired}
                        label='Account Number'
                        id='accountNumber'
                        value={bankInfo.accountNumber}
                        onChange={onInputChange}
                        type='number'
                        helperText={validBankAccount.accountNoError}
                    />
                </Grid>
            </Grid>
            <Grid item className={classes.dropDown} xs={12}>
                <Typography className={classes.label}><span className={classes.asterisk} >*</span>Account Type</Typography>
                <FormControl
                    required fullWidth={true}
                    className={classes.formControl}
                    variant="outlined"
                    size="small"
                    error={validateRequired && !bankInfo.accountType}
                >
                    <Select
                        labelId="Account Type"
                        id="accountType"
                        label="Account Type"
                        value={bankInfo.accountType}
                        className={classes.input}
                        onChange={onSelectionChange}
                    >
                        <MenuItem value={'8'}>Personal Checking</MenuItem>
                        <MenuItem value={'9'}>Personal Saving</MenuItem>
                        <MenuItem value={'10'}>Business Checking</MenuItem>
                        <MenuItem value={'11'}>Business Saving</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {!hideName ? <Grid item xs={12}>
                <Grid container direction='column' spacing={0} className={classes.container}>
                    <Grid item xs={12}>
                        <FormTextField
                            validateRequired={validateRequired}
                            fullWidth={true} id='address'
                            value={bankInfo.address}
                            placeholder='address'
                            label='Billing Address'
                            onChange={onInputChange}
                        />
                    </Grid>
                    <Grid container direction='row' spacing={4} className={classes.inLine}>
                        <Grid item xs={6}>
                            <FormTextField
                                validateRequired={validateRequired}
                                fullWidth={true} id='city'
                                value={bankInfo.city}
                                label='City'
                                onChange={onInputChange}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.ddl} >
                            <FormDropDown
                                size={6}
                                validateRequired={validateRequired}
                                fullWidth={true}
                                id='state'
                                value={bankInfo.state}
                                title='State'
                                list={STATES}
                                onChange={onStateSelectionChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} className={classes.container}>
                        <Grid item xs={6}>
                            <FormTextField
                                validateRequired={validateRequired}
                                fullWidth={true}
                                id='postalCode'
                                value={bankInfo.postalCode}
                                label='Zip'
                                onChange={onInputChange}
                                helperText={validBankAccount.zipError} />
                        </Grid>
                        <Grid item xs={6} className={classes.ddl}>
                            <FormDropDown
                                fullWidth={true}
                                validateRequired={validateRequired}
                                id='country'
                                value={bankInfo.country}
                                title='Country'
                                list={COUNTRIES_PAYMENTS}
                                disable={true}
                                onChange={onCountrySelectionChange} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            validateRequired={validateRequired}
                            fullWidth={true} id='email'
                            value={bankInfo.email}
                            label='Email'
                            onChange={onInputChange}
                            helperText={validBankAccount.emailError}
                        />
                    </Grid>
                    {isPayNow && (<Grid item xs={12} className={classes.emailNote}>
                        We will send payment details to this email address.
                    </Grid>)}
                </Grid>
            </Grid> : null}
        </Grid>
    );
}

BankAccount.propTypes = {
    bankInfo: PropTypes.object,
    onInputChange: PropTypes.func,
    onSelectionChange: PropTypes.func,
    validateRequired: PropTypes.bool,
    isPayNow: PropTypes.bool,
    hideName: PropTypes.bool,
}

export default memo(BankAccount);
