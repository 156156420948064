import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import PropTypes from 'prop-types';
import HttpsIcon from '@material-ui/icons/Https';
import trustlogo from '../../assets/images/truste_logo.png';
import APXlogo from '../../assets/images/apx_logo_add_pymt_method.png';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	btnPayNow: {
		background: COLORS.blueButtons,
		fontWeight: 900,
		'&:hover': {
			background: COLORS.onHoverBlueButtons,
		},
		'&.Mui-disabled': {
			backgroundColor: `${COLORS.disabledBlue} !important`,
			color: 'white',
		},
		boxShadow: '0 1px 4px 0 rgba(38,50,56,0.40)',
		borderRadius: 4,
		fontSize: 15,
		color: '#FFFFFF',
		letterSpacing: 0.2,
		lineHeight: '12px',
		height: 40,
		width: 220
	},
	secureIcon: {
		marginRight: theme.spacing(1),
	},
	gridContainer: {
		marginBottom: theme.spacing(2),
	},
	gridContainerColumnItems: { 
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
	},
	outerContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	img: {
		marginTop: theme.spacing(1),
		backgroundColor: 'white',
		height: 18,
		width: 17,
	},
	supportingTex: {
		height: 'auto',
		width: '100%',
		color: '#455A64',
		fontFamily: 'Roboto',
		fontSize: 15,
		fontStyle: 'italic',
		letterSpacing: 0,
		textAlign: 'center',
		marginTop: theme.spacing(1),
		lineHeight: '18px',
		marginBottom: theme.spacing(2),
	}
}));

function ProcessPayment({
	buttonText = '',
	onSubmit = () => { },
	isLoading = false,
}) {
	const classes = useStyles();
	return (
		<Container className={classes.outerContainer}>
			<Grid container direction='row' className={classes.gridContainer}>
				<Grid className={classes.gridContainerColumnItems} item xs={12}>
					<Button variant="outlined" className={classes.btnPayNow} onClick={onSubmit} disabled={isLoading}>
						{buttonText === 'PAY NOW' ? <HttpsIcon className={classes.secureIcon} /> : null}
						{isLoading ? 'Processing' : buttonText}
					</Button> 
				</Grid>
				{buttonText === 'PAY NOW' ? (
					<Grid className={`${classes.gridContainerColumnItems} ${classes.gridImg}`} item xs={12}><img src={trustlogo} className={classes.img} /></Grid>
				) : (
					<Grid className={`${classes.gridContainerColumnItems} ${classes.gridImg}`} item xs={12}><img src={APXlogo} /></Grid>
				)}
			</Grid>
			{buttonText === 'PAY NOW' && (
				<Grid item className={classes.supportingTex}>
                    When you click 'Pay Now' button you are agreeing to charge the payment method as specified. An email receipt will confirm this payment.
				</Grid>
			)}
		</Container>
	);
}

ProcessPayment.propTypes = {
	buttonText: PropTypes.string,
	onSubmit: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default memo(ProcessPayment);