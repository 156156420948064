import React, { memo } from 'react';
import Grid from "@material-ui/core/Grid";
import { Container, Typography } from '@material-ui/core';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { COLORS } from '../constants/Colors';
import Footer from './Footer';
import InfoIcon from '@material-ui/icons/Info';
import payment_received from '../../assets/images/payment_received.png';
import useWindowSize from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
    body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    spacer: {
        flex: 1
    },
    page: {
        backgroundColor: COLORS.grayContainer
    },
    paper: {
        padding: theme.spacing(3),
        textAlign: 'center'
    },
    container: {
        maxWidth: '512px'
    },
    blueContainer: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: COLORS.blueText,
        backgroundColor: COLORS.blueBackground,
        fontWeight: 'bold',
        borderColor: COLORS.blueBorder,
        border: '2px solid',
    },
    bgWhite: {
        backgroundColor: COLORS.whiteBackground
    },
    spacing: {
        padding: theme.spacing(2)
    },
    email: {
        fontWeight: 'bold',
        fontStyle: 'italic'
    },
    headerIcon: {
        verticalAlign: 'bottom',
        marginRight: '5px'
    }

}));
function PaidReceipt() {
    const classes = useStyles();
    const location = useLocation();
    const merchantInfo = location.state?.data.merchantInfo;
    const email = location.state?.data.email;
    const { width } = useWindowSize();
    const ContainerTag = width >= 600 ? Container : 'div';

    return (
        <>
            <div className={classes.page}>
                <ContainerTag className={classes.container}>
                    <Grid container direction='row' className={classes.bgWhite}>
                        <div className={classes.body}>
                            <div className={classes.content}>
                                <Grid item xs={12} className={classes.spacing}>
                                    <Header merchantInfo={merchantInfo} />
                                </Grid>
                                <Grid item xs={12} className={classes.blueContainer}>
                                    <Typography>
                                        <InfoIcon className={classes.headerIcon} />
                                        Payment Already received
                                    </Typography>
                                </Grid>                        
                                <Grid item xs={12} className={classes.paper}>
                                    <img src={payment_received} className={classes.paymentType} alt="payment received" />
                                    <Typography>We already received your payment.</Typography>
                                    <Typography>We sent a payment confirmation details to this email address:</Typography>
                                    <Typography className={classes.email}>{email}</Typography>
                                </Grid>
                            </div>
                            <div className={classes.spacer}></div>
                            <Footer />
                        </div>
                    </Grid>
                </ContainerTag>
            </div>
        </>
    )
}

export default memo(PaidReceipt);