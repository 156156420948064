import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import InProgress from './InProgress';

function SyncCard({
	title = '',
	sync = () => {},
	refresh = () => {},
	records = 0,
	isSyncing = false,
	inputField = () => {},
	showRecordsCount = true,
	showRefresh = true,
	actionTitle = 'Sync',
	actionTitle2 = 'Refresh',
	...props
}) {
	return (
		<Box sx={{ flexGrow: 1 }} {...props}>
			<Card>
				<CardActionArea>
					<CardContent>
						<Typography variant="h6" component="h2">{title}</Typography>
						{showRecordsCount && (
							<Typography color="textSecondary" gutterBottom>
								Total Records: {records}
							</Typography>
						)}
						{inputField()}
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button size="small" color="primary" onClick={sync}>
						{actionTitle}
					</Button>
					{showRefresh && (
						<Button size="small" color="primary" onClick={refresh}>
							{actionTitle2}
						</Button>
					)}
				</CardActions>
				{ isSyncing && <InProgress /> }
			</Card>
		</Box>
	);
}

SyncCard.propTypes = {
	title: PropTypes.string,
	sync: PropTypes.func,
	refresh: PropTypes.func,
	records: PropTypes.number,
	isSyncing: PropTypes.bool,
	showRecordsCount: PropTypes.bool,
	showRefresh: PropTypes.bool,
	actionTitle: PropTypes.string,
	actionTitle2: PropTypes.string,
}

export default memo(SyncCard)