import React, { memo, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { COLORS } from '../constants/Colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
	input: {
		height: 55,
		color: COLORS.blackText,
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5,
	},
	label: {
		color: COLORS.secureMessage,
		fontFamily: 'Roboto',
	},
	formGroup: {
		marginTop: 10,
	}
});

function PayFieldInput({
	CREDIT_CARD_FORM_FIELDS = [],
	children,
}) {
	const classes = useStyles();

	return (
		<Fragment>
			<Grid container direction='row' spacing={2}>
				<Grid item xs={12} md={6} lg={12}>
					<div className={classes.formGroup}>
						<Typography htmlFor={CREDIT_CARD_FORM_FIELDS[0]?.id} className={classes.label}>
							<span className={classes.asterisk}>*</span>
							{CREDIT_CARD_FORM_FIELDS[0]?.label}
						</Typography>
						<div id={CREDIT_CARD_FORM_FIELDS[0]?.id} className={classes.input}></div>
					</div>
				</Grid>
				<Grid item xs={12} md={6} lg={12}>
					<div className={classes.formGroup}>
						<Typography htmlFor={CREDIT_CARD_FORM_FIELDS[1]?.id} className={classes.label}>
							<span className={classes.asterisk}>*</span>
							{CREDIT_CARD_FORM_FIELDS[1]?.label}
						</Typography>
						<div id={CREDIT_CARD_FORM_FIELDS[1]?.id} className={classes.input}></div>
					</div>
				</Grid>
				<Grid item xs={6}>
					<div className={classes.formGroup}>
						<Typography htmlFor={CREDIT_CARD_FORM_FIELDS[2]?.id} className={classes.label}>
							<span className={classes.asterisk}>*</span>
							{CREDIT_CARD_FORM_FIELDS[2]?.label}
						</Typography>
						<div id={CREDIT_CARD_FORM_FIELDS[2]?.id} className={classes.input}></div>
					</div>
				</Grid>
				<Grid item xs={6}>
					{children}
				</Grid>
			</Grid>
		</Fragment>
	);
}

PayFieldInput.propTypes = {
	CREDIT_CARD_FORM_FIELDS: PropTypes.array
};

export default memo(PayFieldInput);
