import { Button, Grid, makeStyles, Switch, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState, memo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import headerLogo from '../../assets/images/CARETPaymentsLogo.png';
import { COLORS } from '../constants/Colors';
import { useQuery } from '../hooks/useQuery';
import { getApiUrl } from '../utils/getApiUrl';
import Recepients from './Recepients';
import { getMerchantData, initializePendo } from '../services/Services';

const useStyles = makeStyles((theme) => ({

    title: {
        position: 'absolute',
        color: COLORS.whiteText,
        left: '17%',
        display: 'flex',
        top: 0
    },
    listItem: {
        borderRadius: '5px',
        marginTop: '2%',
        marginLeft: '2%',
        marginRight: '2%',
        backgroundColor: COLORS.whiteBackground,
        border: `1px solid ${COLORS.grayBorder}`
    },
    listHeading: {
        color: COLORS.textBrown,
        padding: '10px',
        borderBottom: `1px solid ${COLORS.grayBorder}`
    },
    listItemType: {
        padding: '10px',
        borderBottom: `1px solid ${COLORS.grayBorder}`
    },
    padding: {
        padding: '10px',
    },
    bold: {
        fontWeight: 'bold',
    },
    textInfo: {
        color: COLORS.grayText
    },
    link: {
        marginTop: '5px',
        color: COLORS.textPink,
        textDecoration: 'underline',
        backgroundColor: 'transparent',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    toggle: {
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: COLORS.whiteBackground,
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: COLORS.textPink,
            opacity: 1
        }
    },
    fontHeader: {
        fontSize: '20px',
    },
    switchLabel: {
        float: 'left',
        marginTop: '8px'
    },
    logo: {
        marginLeft: '1%'
    }
}));

function Notification() {

    const classes = useStyles();
    const history = useHistory();
    const [merchantId, setMerchantId] = useState('');
    const [title, setTitle] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [emailId, setEmailId] = useState('');
    const [open, setOpen] = useState(false);
    const [application, setApplication] = useState('');
    const [recipients, setRecepients] = useState({
        TransactionCreated: '',
        TransactionFailed: '',
        TransactionReturned: '',
        DisbursementCreated: '',
        DisbursementProcessed: '',
        DisbursementFailed: '',
        DisbursementDenied: '',
        DisbursementReturned: '',
        Refund: '',
        ChargebackCreated: '',
        ChargebackClosed: '',
        RPTransactionProcessed: '',
        RPTransactionFailed: '',
        ScheduleCreated: '',
        ScheduleCancelled: '',
        ScheduleDeleted: '',
        APXServiceSubscriptionCreated: ''
    })
    const [enabled, setEnabled] = useState({
        TransactionCreated: true,
        TransactionFailed: true,
        TransactionReturned: true,
        DisbursementCreated: true,
        DisbursementProcessed: true,
        DisbursementFailed: true,
        DisbursementDenied: true,
        DisbursementReturned: true,
        Refund: true,
        ChargebackCreated: true,
        ChargebackClosed: true,
        RPTransactionProcessed: true,
        RPTransactionFailed: true,
        ScheduleCreated: true,
        ScheduleCancelled: true,
        ScheduleDeleted: true,
        APXServiceSubscriptionCreated: true
    });
    const [count, setCount] = useState({
        TransactionCreated: 0,
        TransactionFailed: 0,
        TransactionReturned: 0,
        DisbursementCreated: 0,
        DisbursementProcessed: 0,
        DisbursementFailed: 0,
        DisbursementDenied: 0,
        DisbursementReturned: 0,
        Refund: 0,
        ChargebackCreated: 0,
        ChargebackClosed: 0,
        RPTransactionProcessed: 0,
        RPTransactionFailed: 0,
        ScheduleCreated: 0,
        ScheduleCancelled: 0,
        ScheduleDeleted: 0,
        APXServiceSubscriptionCreated: 0
    });

    const params = useParams();
    const query = useQuery();

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${params?.merchant}`
        }
    };

    const handleChange = async (event) => {
        setEnabled(prev => ({ ...prev, [event.target.name]: event.target.checked }));
        try {
            const payload = { notification: { merchantId: merchantId, type: event.target.name, enabled: event.target.checked } };
            const { data } = await axios.put(`${getApiUrl()}/api/merchantwebapi/update_notification_status`, payload, axiosConfig);
            if (data.code === 200) {
            }
        } catch (err) {
            if (err.toString() === 'Error: Network Error') {
                history.push('/errorpage/serverDown');
            }
            console.log(err);
        }
    };

    const handleOnClick = (type) => () => {
        setOpen(true);
        setSelectedType(type);
        switch (type) {
            case 'APXServiceSubscriptionCreated':
                setTitle('Service Alert Subscription');
                setEmailId(recipients.APXServiceSubscriptionCreated);
                return;
            case 'TransactionCreated':
                setTitle('Online Payment Transaction Notification');
                setEmailId(recipients.TransactionCreated);
                return;
            case 'TransactionFailed':
                setTitle('Transaction Failed');
                setEmailId(recipients.TransactionFailed);
                return;
            case 'TransactionReturned':
                setTitle('Transaction Returned');
                setEmailId(recipients.TransactionReturned);
                return;
            case 'DisbursementCreated':
                setTitle('Disbursement Created');
                setEmailId(recipients.DisbursementCreated);
                return;
            case 'DisbursementProcessed':
                setTitle('Disbursement Processed');
                setEmailId(recipients.DisbursementProcessed);
                return;
            case 'DisbursementFailed':
                setTitle('Disbursement Failed');
                setEmailId(recipients.DisbursementFailed);
                return;
            case 'DisbursementDenied':
                setTitle('Disbursement Denied');
                setEmailId(recipients.DisbursementDenied);
                return;
            case 'DisbursementReturned':
                setTitle('Disbursement Returned');
                setEmailId(recipients.DisbursementReturned);
                return;
            case 'Refund':
                setTitle('Refund');
                setEmailId(recipients.Refund);
                return;
            case 'ChargebackCreated':
                setTitle('Chargeback Created');
                setEmailId(recipients.ChargebackCreated);
                return;
            case 'ChargebackClosed':
                setTitle('Chargeback Closed');
                setEmailId(recipients.ChargebackClosed);
                return;
            case 'RPTransactionProcessed':
                setTitle('Recurring Payment Transaction Notification');
                setEmailId(recipients.RPTransactionProcessed);
                return;
            case 'RPTransactionFailed':
                setTitle('Recurring Transaction Failed');
                setEmailId(recipients.RPTransactionFailed);
                return;
            case 'ScheduleCreated':
                setTitle('Recurring Transaction Created');
                setEmailId(recipients.ScheduleCreated);
                return;
            case 'ScheduleCancelled':
                setTitle('Recurring Transaction Cancelled');
                setEmailId(recipients.ScheduleCancelled);
                return;
            case 'ScheduleDeleted':
                setTitle('Recurring Transaction Deleted');
                setEmailId(recipients.ScheduleDeleted);
                return;
            default:
                return 'foo';
        }
    };

    useEffect(() => {
        const merchantId = query.get('merchantId');
        if (merchantId) {
            setMerchantId(merchantId);
        }
    }, [params]);

    useEffect(() => {
        if (merchantId) {
            async function fetchNotificationInfo() {
                await getNotificationInfo(merchantId);
            }
            fetchNotificationInfo();

            async function fetchMerchant(axiosConfig) {
                const customer = await getMerchantData(merchantId, axiosConfig);
                initializePendo(customer?.application, customer?.customerId, customer?.customerName);

            }
            fetchMerchant(axiosConfig);
        }
    }, [merchantId]);

    const getNotificationInfo = async (merchantId) => {
        try {
            const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_notifications_config?merchantId=${merchantId}`, axiosConfig);
            if (data.code === 200) {
                data.notifications.map((obj) => {
                    setCount(prev => ({ ...prev, [obj.type]: obj.recipients_count }));
                    setEnabled(prev => ({ ...prev, [obj.type]: obj.enabled }));
                    setRecepients(prev => ({ ...prev, [obj.type]: obj.recipients }));
                });
                setApplication(data.applicationName);
            }
        } catch (err) {
            if (err.toString() === 'Error: Network Error') {
                history.push('/errorpage/serverDown');
            }
            console.log(err);
        }
    };

    const onRefresh = async () => {
        await getNotificationInfo(merchantId);
    };


    return (
        <Grid container direction="row" xs={12} md={12} >
            <Recepients title={title} open={open} setOpen={setOpen}
                emailId={emailId}
                setEmailId={setEmailId}
                merchantId={merchantId}
                type={selectedType}
                token={params?.merchant}
                onRefresh={onRefresh}
            />
            <Grid item xs={12} style={{backgroundColor:COLORS.darkBlackText}}>
                <img src={headerLogo} alt="Caret" width={150} className={classes.logo} />
                <h2 className={classes.title}>Firm/Business Email Notifications</h2>
            </Grid>
            { application !== 'Zola Suite' ?
            <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader}>Service alerts</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Service alert subscription</Typography>
                        <Typography className={classes.textInfo}>Receive a notification for the CARET Payments service alert subscription.</Typography>
                        {enabled.APXServiceSubscriptionCreated ? <Button id='service-subscription-created' variant="text" className={classes.link} onClick={handleOnClick("APXServiceSubscriptionCreated")}>Manage Recipient List({count.APXServiceSubscriptionCreated})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.APXServiceSubscriptionCreated ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.APXServiceSubscriptionCreated} onChange={handleChange} name="APXServiceSubscriptionCreated" />
                    </Grid>
                </Grid>
            </Grid>
            : null }
            <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader} >Transaction</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Online Payment Transaction Notification</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when credit card or ACH (eCheck) transaction has been created.</Typography>
                        {enabled.TransactionCreated ? <Button id='transaction-created' variant="text" className={classes.link} onClick={handleOnClick("TransactionCreated")}>Manage Recipient List({count.TransactionCreated})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.TransactionCreated ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.TransactionCreated} onChange={handleChange} name="TransactionCreated" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Transaction Failed</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when credit card or ACH (eCheck) transaction has been failed.</Typography>
                        {enabled.TransactionFailed ? <Button id='transaction-failed' variant="text" className={classes.link} onClick={handleOnClick('TransactionFailed')}>Manage Recipient List({count.TransactionFailed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.TransactionFailed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.TransactionFailed} onChange={handleChange} name="TransactionFailed" />
                    </Grid>
                    <Grid item xs={10} className={classes.padding}>
                        <Typography className={classes.bold}>Transaction Returned</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when credit card or ACH (eCheck) transaction has been returned.</Typography>
                        {enabled.TransactionReturned ? <Button id='transaction-returned' variant="text" className={classes.link} onClick={handleOnClick('TransactionReturned')}>Manage Recipient List({count.TransactionReturned})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.padding}>
                        <Typography className={classes.switchLabel}>{enabled.TransactionReturned ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.TransactionReturned} onChange={handleChange} name="TransactionReturned" />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader}>Disbursement</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Disbursement Created</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a disbursement has been initiated.</Typography>
                        {enabled.DisbursementCreated ? <Button id='disbursement-returned' variant="text" className={classes.link} onClick={handleOnClick('DisbursementCreated')}>Manage Recipient List({count.DisbursementCreated})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.DisbursementCreated ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.DisbursementCreated} onChange={handleChange} name="DisbursementCreated" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Disbursement Processed</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a disbursement has been processed.</Typography>
                        {enabled.DisbursementProcessed ? <Button id='disbursement-processed' variant="text" className={classes.link} onClick={handleOnClick('DisbursementProcessed')}>Manage Recipient List({count.DisbursementProcessed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.DisbursementProcessed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.DisbursementProcessed} onChange={handleChange} name="DisbursementProcessed" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Disbursement Failed</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a disbursement has been failed.</Typography>
                        {enabled.DisbursementFailed ? <Button id='disbursement-processed' variant="text" className={classes.link} onClick={handleOnClick('DisbursementFailed')}>Manage Recipient List({count.DisbursementFailed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.DisbursementFailed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.DisbursementFailed} onChange={handleChange} name="DisbursementFailed" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Disbursement Denied</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a disbursement has been denied.</Typography>
                        {enabled.DisbursementDenied ? <Button id='disbursement-denied' variant="text" className={classes.link} onClick={handleOnClick('DisbursementDenied')}>Manage Recipient List({count.DisbursementDenied})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.DisbursementDenied ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.DisbursementDenied} onChange={handleChange} name="DisbursementDenied" />
                    </Grid>
                    <Grid item xs={10} className={classes.padding}>
                        <Typography className={classes.bold}>Disbursement Returned</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a disbursement has been returned.</Typography>
                        {enabled.DisbursementReturned ? <Button id='disbursement-returned' variant="text" className={classes.link} onClick={handleOnClick('DisbursementReturned')}>Manage Recipient List({count.DisbursementReturned})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.padding}>
                        <Typography className={classes.switchLabel}>{enabled.DisbursementReturned ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.DisbursementReturned} onChange={handleChange} name="DisbursementReturned" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader}>Refund</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.padding}>
                        <Typography className={classes.bold}>Refund</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a transaction has been requested for a refund.</Typography>
                        {enabled.Refund ? <Button variant="text" className={classes.link} onClick={handleOnClick('Refund')}>Manage Recipient List({count.Refund})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.padding}>
                        <Typography className={classes.switchLabel}>{enabled.Refund ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.Refund} onChange={handleChange} name="Refund" />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader}>Chargeback</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Chargeback Created</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a chargeback transaction has been created.</Typography>
                        {enabled.ChargebackCreated ? <Button id='chargeback-created' variant="text" className={classes.link} onClick={handleOnClick('ChargebackCreated')}>Manage Recipient List({count.ChargebackCreated})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.ChargebackCreated ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.ChargebackCreated} onChange={handleChange} name="ChargebackCreated" />
                    </Grid>
                    <Grid item xs={10} className={classes.padding}>
                        <Typography className={classes.bold}>Chargeback Closed</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a chargeback transaction has been closed.</Typography>
                        {enabled.ChargebackClosed ? <Button id='chargeback-closed' variant="text" className={classes.link} onClick={handleOnClick('ChargebackClosed')}>Manage Recipient List({count.ChargebackClosed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.padding}>
                        <Typography className={classes.switchLabel}>{enabled.ChargebackClosed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.ChargebackClosed} onChange={handleChange} name="ChargebackClosed" />
                    </Grid>
                </Grid>
            </Grid>
            { application !== 'Zola Suite' ?
             <Grid item xs={12} className={classes.listItem}>
                <Grid container>
                    <Grid item xs={12} className={classes.listHeading}>
                        <Typography className={classes.fontHeader}>Recurring Payments</Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Recurring Payment Transaction Notification</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a recurring transaction has been proceed.</Typography>
                        {enabled.RPTransactionProcessed ? <Button id='RPTransaction-processed' variant="text" className={classes.link} onClick={handleOnClick('RPTransactionProcessed')}>Manage Recipient List({count.RPTransactionProcessed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.RPTransactionProcessed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.RPTransactionProcessed} onChange={handleChange} name="RPTransactionProcessed" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Recurring Transaction Failed</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a recurring transaction failed.</Typography>
                        {enabled.RPTransactionFailed ? <Button id='RPTransaction-failed' variant="text" className={classes.link} onClick={handleOnClick('RPTransactionFailed')}>Manage Recipient List({count.RPTransactionFailed})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.RPTransactionFailed ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.RPTransactionFailed} onChange={handleChange} name="RPTransactionFailed" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Recurring Payment Schedule Created</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a recurring payment schedule has been created.</Typography>
                        {enabled.ScheduleCreated ? <Button id='RPTransaction-created' variant="text" className={classes.link} onClick={handleOnClick('ScheduleCreated')}>Manage Recipient List({count.ScheduleCreated})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.ScheduleCreated ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.ScheduleCreated} onChange={handleChange} name="ScheduleCreated" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Recurring Payment Schedule Cancelled</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a recurring payment schedule is cancelled.</Typography>
                        {enabled.ScheduleCancelled ? <Button id='RPTransaction-cancelled' variant="text" className={classes.link} onClick={handleOnClick('ScheduleCancelled')}>Manage Recipient List({count.ScheduleCancelled})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.listItemType}>
                        <Typography className={classes.switchLabel}>{enabled.ScheduleCancelled ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.ScheduleCancelled} onChange={handleChange} name="ScheduleCancelled" />
                    </Grid>
                    <Grid item xs={10} className={classes.listItemType}>
                        <Typography className={classes.bold}>Recurring Payment Schedule Deleted</Typography>
                        <Typography className={classes.textInfo}>Receive a notification when a recurring payment schedule is deleted.</Typography>
                        {enabled.ScheduleDeleted ? <Button id='RPTransaction-deleted' variant="text" className={classes.link} onClick={handleOnClick('ScheduleDeleted')}>Manage Recipient List({count.ScheduleDeleted})</Button> : null}
                    </Grid>
                    <Grid item xs={2} className={classes.padding}>
                        <Typography className={classes.switchLabel}>{enabled.ScheduleDeleted ? 'ON' : 'OFF'}</Typography>
                        <Switch color="primary" className={classes.toggle} checked={enabled.ScheduleDeleted} onChange={handleChange} name="ScheduleDeleted" />
                    </Grid>
                </Grid>
            </Grid>
            : null }
        </Grid>
    );
}

export default memo(Notification);
