import React from 'react';

import PropTypes from 'prop-types';
import {
	createTheme,
	MuiThemeProvider
} from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';

function AbacusTable({
	 options = {},
	 data,
	 columns,
	 themeOptions = {}
}) {
	const getMuiTheme = () => createTheme(themeOptions);

	return (
		<MuiThemeProvider theme={getMuiTheme()}>
			<MUIDataTable
				data={data}
				columns={columns}
				options={options}
			/>
		</MuiThemeProvider>
	);
}

AbacusTable.propTypes = {
	options: PropTypes.object,
	onChangeRowsPerPage: PropTypes.func,
	onRowClick: PropTypes.func,
};

export default AbacusTable;