import store from 'store2';

export const clearApiToken = () => store.remove('admin_token');
export const getApiToken = () => store.get('admin_token');
export const setApiToken = (value) => store.set('admin_token', value);

export const getApiUrl = () => {
	switch (process.env.REACT_APP_ENV) {
		case 'local': return 'http://localhost:3000';
		case 'development': return 'https://apxn-api-dev.azurewebsites.net';
		case 'qa': return 'https://apxn-api-qa.azurewebsites.net';
		case 'stage': return 'https://apxn-api-stage.azurewebsites.net';
		case 'staging': return 'https://apxn-api-fd-stg.azurefd.net';
		case 'production': return 'https://payments-api.abacusnext.com';
		case 'docker': return 'http://api:3000';
	}
};
