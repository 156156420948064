import { makeStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { COLORS } from '../constants/Colors';
import React from 'react';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{
			borderBottom: '1px solid #CFD8DC',
			position: 'relative',
		}}
		{...other}
		>
			<Grid container direction='row' spacing={1}>
				<Grid item>
					{props.title !== 'Payment method was deleted' ?  <ErrorIcon style={{ color: 'B00020', height: 45, width: 45 }}/> :
						<Avatar  style={{
							height: 40,
							width: 40,
							backgroundColor: '#41B832'
						}}
						>
							<CheckIcon style={{ color: 'white' }}/>
					 </Avatar>}
				</Grid>
				<Grid item style={{
					fontFamily: 'Roboto',
					fontWeight: 700,
					color: '#44464B',
					marginTop: 5
				}}>{children}</Grid>
			</Grid>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 3,
						top: 14,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(5,4),
		paddingTop: 10
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		backgroundColor: '#ECEFF1',
		borderTop: '1px solid #CFD8DC'
	},
}))(MuiDialogActions);
const useStyles = makeStyles((theme) => ({
	okButton: {
		backgroundColor: COLORS.blueButtons,
		fontWeight: 700,
		fontFamily: 'Roboto',
		textTransform: 'uppercase',
		color: '#FFFFFF',
		'&:hover': {
			background: '3A1BD0',
		},

	},
	message: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		color: '#44464B',
	},
	title: {
		fontFamily: 'Roboto',
		fontWeight: 700,
		color: '#44464B'
	}

}));

function PayNowDeletePaymentMethodResponse({
	cancelButton = () => {},
	message = '',
	title = ''
}) {

	const classes = useStyles();
	return (
		<>
			<BootstrapDialogTitle id='customized-dialog-title' onClose={cancelButton} title={title} >
				{title}
			</BootstrapDialogTitle>
			<DialogContent >
				<Typography gutterBottom component={'span'} className={classes.message}>
					{message}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancelButton} className={classes.okButton} id={'cancel'}  variant="contained" >Ok</Button>
			</DialogActions>
		</>
	);
}

export default PayNowDeletePaymentMethodResponse;