import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { COLORS } from '../constants/Colors';

const useStyles = makeStyles({
	snackbar: {
		'& .MuiPaper-root': {
			backgroundColor: COLORS.blackText,
			color: COLORS.whiteText
		}
	}
});

function Message({
     message = '',
     isOpen = false,
	 handleClose = () => {},
	 severity = ''
}) {
	const classes = useStyles();

	return (
		<Snackbar autoHideDuration={6000} onClose={handleClose} className={classes.snackbar} open={isOpen} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
				{message}
			</Alert>
		</Snackbar>
	);

}


Message.propTypes = {
	message: PropTypes.string,
}

export default memo(Message);