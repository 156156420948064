import React, { memo } from 'react';
import APXLogo from '../../assets/images/apxlogo.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

	root: {
		alignItems: 'center',
		columnGap: theme.spacing(1),
		background: '#E3E5E8',
		borderTop: '1px solid #CFD8DC',
		color: '#707682',
		display: 'flex',
		fontFamily: 'Roboto',
		fontSize: 14,
		padding: theme.spacing(2),
	},

	text: {
		overflow: 'hidden',
		fontSize: '95%',
		marginTop: 4
	}

}));

function Footer() {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<img src={APXLogo} alt="APX" style={{ width: '9.5%' }} />
			<div className={classes.text}>
                © {new Date().getFullYear()} - AbacusNext, All Rights Reserved
			</div>
		</div>
	);
}

export default memo(Footer);