import React, {memo, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory, useLocation} from 'react-router-dom';
import { COLORS } from '../constants/Colors';
import Footer from './Footer';
import Error from '@material-ui/icons/Error';
import expired from '../../assets/images/paynow_expired_link.png';
import Button from "@material-ui/core/Button";
import Messages from "../forms/Messages";
import PayNowServices from "../services/PayNowServices";
import useWindowSize from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
    page: {
        backgroundColor: COLORS.grayContainer
    },
    bold: {
        fontWeight: 'bold',
    },
    paper: {
        minHeight:'740px',
        padding: theme.spacing(3),
        textAlign: 'center'
    },
    container: {
        maxWidth: '512px'
    },
    redContainer: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: COLORS.redText,
        backgroundColor: COLORS.redBg,
        fontWeight: 'bold',
        borderColor: COLORS.redBorder,
        border: '1px solid',
    },
    bgWhite: {
        backgroundColor: COLORS.whiteBackground
    },
    spacing: {
        padding: theme.spacing(1)
    },
    headerIcon: {
        verticalAlign: 'bottom',
        marginRight: '5px'
    },
    button: {
        color: COLORS.blueButtons,
        border: ` 1px solid ${COLORS.blueButtons}`,
        marginTop: '3%',
        paddingRight: '3%',
        paddingLeft: '3%'
    },
    headerBold: {
        fontWeight: 600
    }

}));
function Expired() {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const merchantInfo = location.state?.merchantInfo;
    const requestOfPayment = location.state?.requestOfPayment;
    const axiosConfig = location.state?.axiosConfig;
    const isNewPayNowLinkRequested = location.state?.isNewPayNowLinkRequested;
    const payNowTokenId = location.state?.payNowTokenId;

    const [ isMessageOpen, setIsMessageOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ severity, setSeverity ] = useState('');
    const [changeDisplayedMessage, setChangeDisplayedMessage] = useState(false);


    const sendEmailToMerchant = async () => {

        const originalState = {...location.state}

        const merchantData = {
            merchantName: merchantInfo?.name,
            entityName: requestOfPayment?.entityInfo?.name,
            amount:requestOfPayment?.amount,
            issueDate: requestOfPayment?.createdAt,
            emailId: requestOfPayment?.firmEmail || merchantInfo?.email,
            payNowTokenId: payNowTokenId
        }
        const response = await PayNowServices.sendEmailNewLinkRequest(merchantData, axiosConfig)

        history.replace({ pathname: "/expired", search: '', state:{...originalState, isNewPayNowLinkRequested : response.isNewPayNowLinkRequested}});

        if (response.status === 'error') {
            setChangeDisplayedMessage(false)
            setMessage(response?.err);
            setIsMessageOpen(true);
            setSeverity('error');
        } else {
            setChangeDisplayedMessage(true)
            setMessage('Request has been sent');
            setIsMessageOpen(true);
            setSeverity('success');
        }
    }

    const handleMessageClose = () => {
        setIsMessageOpen(false);
    };

    const { width } = useWindowSize();
    const ContainerTag = width >= 600 ? Container : 'div';

    return (
        <>
            <div className={classes.page}>
                <Messages message={message} severity={severity} isOpen={isMessageOpen} handleClose={handleMessageClose} />
                <ContainerTag className={classes.container}>
                    <Grid container direction='row' className={classes.bgWhite}>
                        <Grid item xs={12} className={classes.spacing}>
                            <Grid item lg={8} md={8} sm={8} xs={8} style={{paddingLeft: '3%'}}>
                                <Typography className={classes.headerBold}>
                                    {merchantInfo.name || ''}
                                </Typography>
                                <Typography>
                                    {merchantInfo.address1 || ''}
                                </Typography>
                                <Typography>
                                    {merchantInfo.address1 && (merchantInfo.city + ',' + merchantInfo.state + ' ' + merchantInfo.zip)}
                                </Typography>
                                <Typography >
                                    {merchantInfo.phone || ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.redContainer}>
                            <Typography  className={classes.bold}>
                                <Error className={classes.headerIcon} />
                                Expired Link
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.paper}>
                            <img src={expired}></img>
                            { !changeDisplayedMessage && !isNewPayNowLinkRequested &&<>
                            <Typography className={classes.bold}  style={{marginTop:'15px'}}> This link no longer exists.</Typography>
                            <Typography>Please contact the firm for further assistance</Typography>
                            <Button  className={classes.button} onClick={() => sendEmailToMerchant()} id={'request-new-link'}>
                                REQUEST NEW LINK
                            </Button>
                                </>}

                            { (changeDisplayedMessage || isNewPayNowLinkRequested) && <>
                                <Typography className={classes.bold}  style={{marginTop:'15px'}}>Request a new PayNow link has been sent.</Typography>
                                <Typography>Please contact {merchantInfo?.name || ''} if you have any questions.</Typography>
                            </>}
                        </Grid>
                        <Grid item xs={12} >
                            <Footer />
                        </Grid>
                    </Grid>
                </ContainerTag>
            </div>
        </>
    )
}

export default memo(Expired);