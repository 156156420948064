import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import PropTypes from 'prop-types';
import FormTextField from '../forms/FormTextField';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';

const useStyles = makeStyles((theme) => ({
	emailText: {
		color: '#5F6365',
		fontFamily: 'Roboto',
		fontSize: 18,
		marginTop: '1%'

	},
	text: {
		fontFamily: 'Roboto',
		lineHeight: '19px',
		color: '#13191C',
		marginTop: '1%',
		marginBottom: '1%',
	},
	label: {
		fontFamily: 'Roboto',
		fontSize: 24,
		lineHeight: '12px,',
		color: '#404547'
	},
	multilineColor: {
		color: 'red'
	},
	input: {
		'& input.Mui-disabled': {
			color: '#5F6365',
			fontFamily: 'Roboto',
			fontSize: 24
		}
	},
	sendEmails: {
		fontFamily: 'Roboto',
		color: '#13191C',
		fontSize: 24
	}
}));

const TestTransactionForm = ({
	merchantAccount = {},
	handleInputChange = () => {},
	emailList = () => {},
	validateEmailList = () => {},
	emailListError = '',
	validateRequired = false
}) => {
	const classes = useStyles();

	const [ accountType, setAccountType ] = useState('');

	useEffect(() => {
		if (merchantAccount.accountType === 8) setAccountType('Checking');
		if (merchantAccount.accountType === 9) setAccountType('Saving');
		if (merchantAccount.accountType === 10) setAccountType('Corporate Checking');
		if (merchantAccount.accountType === 11) setAccountType('Corporate Savings');

	}, [ merchantAccount.accountType ]);

	return (
		<>
			<Grid container directions='row' spacing={2}>
				<Grid item xs={12} md={3}  lg={3} style={{ marginTop: '1%' }}>
					<Typography className={classes.label}>Transaction Date</Typography>
					<TextField
						className={classes.input}
						fullWidth
						variant='outlined'
						size='small'
						value={moment().format('MM/DD/YYYY')}
						disabled
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<EventIcon style={{ color: '#A3A5A6' }}/>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4} lg={3} style={{ marginTop: '1%' }}>
					<Typography className={classes.label}>Transaction Amount</Typography>
					<TextField
						className={classes.input}
						fullWidth
						variant='outlined'
						size='small'
						value='$5.00'
						disabled
					/>
				</Grid>
				<Grid item xs={12} md={5}  lg={6} style={{ marginTop: '1%' }}>
					<Typography className={classes.label}>Description</Typography>
					<TextField
						className={classes.input}
						fullWidth
						variant='outlined'
						size='small'
						value='APX Test Transaction'
						disabled
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={12} lg={12} style={{ marginTop: '1%', marginBottom: '3%' }}>
				<Typography className={classes.label} >Deposit To</Typography>
				<TextField
					className={classes.input}
					fullWidth
					variant='outlined'
					size='small'
					value={`Acct # ...${merchantAccount.accountNumber || ''} | ${accountType}`}
					disabled
				/>
			</Grid>

			<Typography className={classes.sendEmails}>
                We will send a transaction details to this email address(es).
			</Typography>

			<Grid item xs={12} md={12} lg={12} style={{ marginTop: '1%', fontFamily: 'Roboto' }}>

				<Grid item xs={12} lg={12} md={12} sm={12}>
					<FormTextField
						fullWidth={true}
						id='notificationEmailTo'
						helperText={emailListError}
						validateRequired={validateRequired}
						value={emailList.notificationEmailTo}
						type='text'
						onFocusOut={validateEmailList}
						label=''
						onChange={handleInputChange}
						rows={3}
						isMultiline={true} />

					<Typography className={classes.emailText} >Enter up to 10 email addresses, separated by commas.</Typography>
				</Grid>
			</Grid>
			<Grid container justifyContent='center'>
				<Grid sm={12} sx={12} md={12} lg={12} >
					<Alert  sx={{
						marginTop: '3%',
						marginBottom: '5%',
						background: '#CFE4F6',
						color: '#1070D7',
						border: '1px solid #96C0E8',
						fontFamily: 'Roboto',
						fontWeight: '500',
					}}
					iconMapping={{
						success: <InfoIcon style={{ color: '#1070D7', width: '35px', height: '35px' }}/>,
					}}
					>
						<span style={{ fontSize: 23, fontFamily: 'Roboto', fontWeight: '500',color: '#1070D7', }}>Please note you can receive this promotion only once.</span>
					</Alert>
				</Grid>
			</Grid>
		</>
	);
};

TestTransactionForm.propTypes = {
	merchantAccount: PropTypes.object,
	handleInputChange: PropTypes.func,
	emailList: PropTypes.object,
	validateEmailList: PropTypes.func,
	emailListError: PropTypes.string,
	validateRequired: PropTypes.bool
};

export default TestTransactionForm;