import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../constants/Colors';

import PropTypes from 'prop-types';
import { COUNTRIES, STATES, SIGNIFICATIONRESPONSIBILITY } from '../../constants/Locations';
import Divider from '@material-ui/core/Divider';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SignUpTextField from './SignUpTextField';
import SignUpSelectField from './SignUpSelect';
import SignUpNumber from './SignupNumber';
import { SignupFieldsInfo } from './SignupFieldsInfo';
import SignUpDatePicker from './SignUpDatePicker';

const useStyles = makeStyles((theme) => ({
	container: {
		height: 'auto',
		width: '100%',
	},
	lastname: {
		marginLeft: 10
	},
	required: {
		color: '#B90023',
		// fontSize: '2vh',
		letterSpacing: 0,
		// lineHeight: '2vh',
		textAlign: 'right'
	},
	title: {
		color: '#2D4B69',
		// fontSize: '2vh',
		fontWeight: 'bold',
		letterSpacing: 0,
		// lineHeight: '2vh',
		marginBottom: '1%'
	},
	remove: {
		color: '#E14126',
		fontWeight: 500,
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: '0.50%',
		cursor: 'pointer'
	},
	addressText: {
		color: '#455A64',
		marginTop: 5
	},
	divider: {
		backgroundColor: COLORS.dividerColor
	}
}));

// eslint-disable-next-line require-jsdoc
function AboutOwnerFields({
	signupFields = {},
	setSignupFields = () => { },

	ownerNumber = 1,
	removeOwner = () => { },
	aboutOwnerPage = [ 1 ],

	setAboutOwner = () => {},
	field = 1,

	setAboutOwnerError = () => {},
	disableOwnerShip = false,
	defaultValueForOwnerShip = '',
	aboutOwnerSavedData = {}
}) {

	const classes = useStyles();

	const renderAboutOwnerFields = () => {
		return (
			<Grid container direction='column' spacing={0} className={classes.container}>
				<Grid container style={{ marginTop: '2%' }}  justifyContent='space-between'>
					<Grid item md={9} xs={7} lg={7} className={classes.title}>
						<Grid container direction='row' >

							<Grid  item md={2} xs={4} lg={2}>Owner {ownerNumber}</Grid>

							{ (aboutOwnerPage.length !== 1) && <Grid item md={10} lg={8} xs={8} onClick={() =>  removeOwner(field)}>
								<Grid container direction='row' spacing={1} >
									<Grid item ><HighlightOffIcon style={{ color: '#E14126' }}/></Grid>
									<Grid item className={classes.remove}> remove</Grid>
								</Grid>
							</Grid>}
						</Grid>
					</Grid>
					<Grid  item md={3} xs={5} className={classes.required}>* Required Field</Grid>
				</Grid>

				<Divider className={classes.divider} />

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='First Name'
							name={`firstname${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							info={SignupFieldsInfo.firstname}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Middle Name'
							name={`middleName${field}`}
							type=''
							componentName={`aboutOwner${field}`}
							required={false}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							// setCheckIsSateEmpty={setAboutOwner}
							info={SignupFieldsInfo.middleName}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							pageNumber={2}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Last Name'
							name={`lastname${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							info={SignupFieldsInfo.lastname}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpDatePicker
							format="##-##-####"
							formatType='text'
							showIcon={true}
							label='DOB (MM-DD-YYYY)'
							name={`DOB${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							componentName={`aboutOwner${field}`}
							info={SignupFieldsInfo.dateOfBirth}
							savedData={aboutOwnerSavedData}
							type='date'
							setPageError={setAboutOwnerError}
							maximumDate = {new Date(Date.now() - (86400000 * 2))}
							pageNumber={2}
						/>
						<Grid item md={12} className={classes.addressText}>Use DOB on your Driver’s license/Govt ID</Grid>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							name={`SSN${field}`}
							format='###-##-####'
							label='Owner SSN'
							componentName={`aboutOwner${field}`}
							type='regex'
							regex='ssn'
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							info={SignupFieldsInfo.SSN}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Owner Role'
							name={`Role${field}`}
							type='regex'
							regex='letter'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							info={SignupFieldsInfo.role}
							pageNumber={2}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							format={null}
							formatType='text'
							label='Ownership %'
							name={`ownerShip${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							componentName={`aboutOwner${field}`}
							defaultName={defaultValueForOwnerShip}
							type= 'regex'
							regex='percentage'
							setPageError={setAboutOwnerError}
							suffix='%'
							info={SignupFieldsInfo.ownership}
							disableOwnerShip={disableOwnerShip}
							savedData={aboutOwnerSavedData}
							ownerShipfieldName={`ownerShip${field}`}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Significant Responsibility'
							name={`significantResponsibility${field}`}
							type='string'
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							componentName={`aboutOwner${field}`}
							list={SIGNIFICATIONRESPONSIBILITY}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							info={SignupFieldsInfo.significantResponsibility}
							pageNumber={2}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={12} lg={12}>
						<SignUpTextField
							label='Owner Home Address'
							name={`address${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							showInfo={true}
							info={SignupFieldsInfo.ownerAddress}
							placeHolder='Building Number, Street Name, Unit/Apt. Number'
							pageNumber={2}
						/>
						<Grid item md={12} className={classes.addressText}>Using a PO Box for address will cause this application to be delayed</Grid>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='City'
							name={`city${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							showInfo={false}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='State'
							name={`state${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							list={STATES}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							showInfo={false}
							pageNumber={2}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='ZIP'
							name={`zip${field}`}
							type='number'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							type= 'regex'
							regex='zip'
							showInfo={false}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Country'
							name={`country${field}`}
							type='string'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							savedData={aboutOwnerSavedData}
							list={COUNTRIES}
							setPageError={setAboutOwnerError}
							showInfo={false}
							pageNumber={2}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Owner Email'
							name={`contactEmail${field}`}
							type='email'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							info={SignupFieldsInfo.emailAddress}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							pageNumber={2}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							label='Owner Phone'
							formatType='tel'
							format="(###) ###-####"
							name={`contactPhone${field}`}
							type='regex'
							regex='phone'
							componentName={`aboutOwner${field}`}
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							setCheckIsSateEmpty={setAboutOwner}
							info={SignupFieldsInfo.phoneNumber}
							savedData={aboutOwnerSavedData}
							setPageError={setAboutOwnerError}
							pageNumber={2}
						/>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	return renderAboutOwnerFields();
}
AboutOwnerFields.propTypes = {
	signupFields: PropTypes.object,
	setSignupFields: PropTypes.func,
	setDisableButton: PropTypes.func,
	ownerNumber: PropTypes.number,
	removeOwner: PropTypes.func,
	aboutOwnerPage: PropTypes.array,
	aboutOwner: PropTypes.object,
	setAboutOwner: PropTypes.func,
	field: PropTypes.number,
	params: PropTypes.object,
	setAboutOwnerError: PropTypes.func,
	aboutOwnerError: PropTypes.object,
	setresetAboutOwnerPage: PropTypes.func,
	resteAboutOwnerPageNumber: PropTypes.bool,
	disableOwnerShip: PropTypes.bool,
	defaultValueForOwnerShip: PropTypes.any,
	aboutOwnerSavedData: PropTypes.object
};

export default memo(AboutOwnerFields);