import React, { useEffect, useState, memo } from 'react';
import SyncCard from '../forms/SyncCard';
import axios from 'axios';
import moment from 'moment';
import {getApiToken, getApiUrl} from '../utils/getApiUrl';
import FormTextField from '../forms/FormTextField';
import FormDatePicker from '../forms/FormDatePicker';

function SyncFees(props) {
	const [isSyncing, setIsSyncing] = useState(false);
	const [merchantId, setMerchantId] = useState('');
	const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
	const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

	const sync = async () => {
		setIsSyncing(true);
		try {

			if(!merchantId) throw new Error('Please enter merchant Id');
			const from = fromDate ? moment(fromDate).format('YYYY-MM-DD') : '';
			const to = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
			if(!moment(from).isValid()) throw new Error('Please enter valid from date');
			if(!moment(to).isValid()) throw new Error('Please enter valid to date');

			const response = await axios.post(`${getApiUrl()}/api/admin/sync_fees?merchantId=${merchantId}&fromDate=${from}&toDate=${to}`, null, {
				headers: {'Authorization': `Bearer ${getApiToken()}`}
			})
			if (response.data.code === 200) {
                setIsSyncing(false);
			}
		} catch (err) {
            window.alert(err);
			setIsSyncing(false);
		}
	}


	const onInputChange = ({ target: { value } }) => {
		setMerchantId(value)
	}

	const feesSyncForm = () => {
		return  <>
		<FormTextField  required={false} placeholder='Merchant Id' id='merchantId' value={merchantId} onChange={onInputChange} />
		<FormDatePicker
			id="transFromDate"
			onChange={(value) => setFromDate(value)}
			placeholder="From date"
			required
			value={fromDate}
		/>
		<FormDatePicker
			id="transToDate"
			onChange={(value) => setToDate(value)}
			placeholder="To date"
			required
			value={toDate}
		/>
		</>
	}

	return (
		<SyncCard showRecordsCount={false}
		          showRefresh={false}
				  title="Sync Fees for Merchant"
				  sync={sync}
				  isSyncing={isSyncing}
				  inputField={feesSyncForm}
				  {...props} />
	)
}

export default memo(SyncFees);