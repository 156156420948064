import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import SignUpService from '../services/SignUpService';
import APXlogowelcome from '../../assets/images/APXlogowelcome.png';
import TermsAndConditionsPdf from '../account/signup/TermsAndConditionsPdf';
import Typography from "@mui/material/Typography";
import Messages from '../forms/Messages';

const useStyles = makeStyles(() => ({
    button: {
        all: 'unset',
        padding: '0.300rem',
        border: '1px solid white',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center'
    }
}));

function Header({ token }) {
    const classes = useStyles();

    const [pdf, setPdf] = useState('');
    const [content, setContent] = useState('');
    const [dialogName, setDialogName] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [showPdfBankDisclosure, setShowPdfBankDisclosure] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        await getPdf(token);
    }

    const getPdf = async (token) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        };
        const response = await SignUpService.getPdf('APXOnboardingChecklist', { headers });
        setContent(response?.data?.contents);
    };

    const openOnBoardingChecklist = async () => {
        setDialogName('');
        if (!content) {
            try {
                setShowLoader(true);
                await fetchData();
                setShowLoader(false);
            } catch (err) {
                setMessage(`Failed - Onboarding file could not be retrieved: ${err.response?.data?.error}`);
                setIsOpen(true);
            }
        }
        setShowPdfBankDisclosure(false);
        setPdf(content);
        setDialogName('APX Onboarding Checklist');
        setShowPdfBankDisclosure(true);
    }

    return (
        <div >
            <div style={{backgroundColor:'#091B2C'}} >
                <Container>
                    <Toolbar>
                        <Grid item lg={1} md={2} sm={2} xs={3} >
                            <img src={APXlogowelcome} alt="APX" style={{width: '80%'}}/>
                        </Grid>
                        <Grid item lg={11} md={10} sm={10} xs={12} style={{ display: 'flex',  justifyContent: 'end'}}>
                            <Typography
                                sx={{ fontSize: { lg: 13, md: 13, sm: 10, xs: 8 }, marginLeft: 'auto', marginRight: '2rem', color: 'white', marginBottom: 'auto', marginTop: 'auto'}}>
                                Call us: (888) 633-9668
                            </Typography>
                            <button onClick={openOnBoardingChecklist} className={classes.button}>
                                <Typography sx={{ fontSize: { lg: 13, md: 13, sm: 10, xs: 8 }, color: 'white'}}>ONBOARDING CHECKLIST</Typography>
                            </button>
                        </Grid>
                        <Messages message={message} severity='error' isOpen={isOpen} handleClose={() => setIsOpen(false)} />
                        {showPdfBankDisclosure && pdf &&
                        <TermsAndConditionsPdf
                            setDisable={() => {}}
                            pdfNameToggle={() => {}}
                            showPdfBankDisclosure={showPdfBankDisclosure}
                            setShowPdfBankDisclosure={setShowPdfBankDisclosure} pdf={pdf}
                            dialogName={dialogName}
                            showLoader={showLoader}
                            setShowLoader={setShowLoader}
                            pdfName='APXOnboardingChecklist'
                        />}
                    </Toolbar>
                </Container>
            </div>
        </div>
    );
}

Header.propTypes = {
    fontSize: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    marginBottom: PropTypes.string,
}
export default Header