import React, { useState } from 'react';
import axios from "axios";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useParams, useHistory } from 'react-router-dom';

import { COLORS } from '../constants/Colors';
import FormTextField from "../forms/FormTextField";
import Button from "@material-ui/core/Button";
import { getApiUrl } from "../utils/getApiUrl";
import InProgress from "../forms/InProgress";
import Message from "../forms/Messages";

const useStyles = makeStyles({
    card: {
        backgroundColor: COLORS.secureBackground,
        marginTop: 100,
        marginBottom: 10,
    },
});

function AdminResetPassword() {
    const params = useParams();
    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [severity, setSeverity] = useState('error');
    const [isLoading, setIsLoading] = useState(false);

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.token
        }
    };

    const resetPassword = async () => {
        setIsLoading(true);
        setError('');
        try {
            await axios.post(`${getApiUrl()}/api/admin/reset_password`, { email, password }, axiosConfig);
            setSeverity('success');
            const message = 'Reset Password Successful.';
            setError(message);
            history.push('/admin/login');
        } catch (e) {
            console.error(e);
            setSeverity('error');
            const message = e.response?.status === 400 ? e.response?.data?.error : 'Internal server error.';
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container maxWidth="xs">
            <Message isOpen={!!error} handleClose={() => setError('')} message={error} severity={severity} />
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3">Reset Password</Typography>
                        <Typography gutterBottom variant="caption" component="p">
                            Please update your Password.
                        </Typography>
                        <FormTextField
                            required
                            placeholder="Email Address"
                            id="reset-email"
                            value={email}
                            onChange={({ target: { value } }) => setEmail(value)}
                        />
                        <FormTextField
                            required
                            type="password"
                            placeholder="Password"
                            id="reset-password"
                            value={password}
                            onChange={({ target: { value } }) => setPassword(value)}
                        />

                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button disabled={isLoading} size="small" color="primary" onClick={resetPassword}>Reset</Button>
                </CardActions>
                {isLoading && <InProgress />}
            </Card>
        </Container>
    )
};

export default AdminResetPassword;
