import React, { useState, memo, useEffect } from 'react'

import PropTypes from 'prop-types'

import AbacusTable from '../../shared/AbacusTable';
import { COLORS } from '../../constants/Colors';
import { formatAmountCurrency } from '../../utils/formatUtils';
import Summary from '../Summary';

import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from './Loader';

let themeOptions = {
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MUIDataTableHeadCell: {
			root: {
				backgroundColor: `${COLORS.lightGray} !important`,
				color: `${COLORS.darkGrayText} !important`,
				fontSize: 17,
				lineHeight: '12px',
			}
		},
		MuiTableRow: {
			root: {
				'&$selected': {
					backgroundColor: `${COLORS.blueButtons} !important`,
					'& > .MuiTableCell-root': {
						color: `white !important`,
					},
					'& .MuiSvgIcon-root': {
						color: `white !important`,
					},
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: 16,
				lineHeight: '16px',
				color: `${COLORS.darkBlackText} !important`,
				padding: '8px'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: "14px",
				color: `${COLORS.lightGray}`,
				backgroundColor: `${COLORS.darkGrayText}`
			}
		},
	}
};

const useStyles = makeStyles((theme) => ({
	failedIcon: {
		color: COLORS.error,
		padding: '0px',
		marginLeft: '10px',
		marginBottom: '2px',
		verticalAlign: 'middle',
		height: '20px',
		fontSize: '20px'
	}
}));

function SchedulesList({
   schedules = [],
   totalCharged = 0,
   totalRemaining = 0,
   totalAmount = 0,
   onRowClick = () => {},
   applicationDisplayName = '',
   isLoading = false
}) {
	const classes = useStyles();

	const [label, setLabel] = useState('Entity');

	useEffect(() => {
		if (applicationDisplayName) {
			setLabel(applicationDisplayName);
		}
	}, [applicationDisplayName]);

	useEffect(() => {
		if (isLoading) {
			themeOptions = {
				overrides: {
					...themeOptions.overrides,
					MuiTableCell: {
						root: {
							padding: '8px 0',
							border: 'none !important'
						}
					}
				}
			}
		}
	}, [isLoading]);

	const columns = [
		{
			label: label,
			name: 'entity',
			options: {
				filter: true,
				sort: true,
				customBodyRenderLite: (index) => {
					let asterisk = schedules[index]?.isSurchargeApplied ? <span>* </span>: null;
					let failureIcon = (schedules[index]?.hasFailedPayments === 'Failed') ?
						<span>
							<Tooltip title={(schedules[index]?.failureMessage ?? '')}>
								<ErrorOutlineIcon className={classes.failedIcon} />
							</Tooltip>
						</span> :
						<span></span>;
					return (
						<div>
							{asterisk}
							<span>{schedules[index]?.isSurchargeApplied}{(schedules[index]?.entity)}</span>
							{failureIcon}
						</div>
					);
				},
				setCellProps: () => { return { style: { padding: '2px' } }; },
			}
		},
		{
			label: 'Recurring',
			name: 'recurring',
			options: {
				filter: true,
				sort: false
			}
		},
		{
			label: 'Occur',
			name: 'occur',
			options: {
				filter: true,
				sort: false
			}
		},
		{
			label: 'StartDate',
			name: 'startDate',
			options: {
				filter: true,
				sort: true
			}
		},
		{
			label: 'NextPymt',
			name: 'nextPayment',
			options: {
				filter: true,
				sort: true
			}
		},
		{
			label: 'TotalAmt',
			name: 'totalAmount',
			options: {
				filter: true,
				sort: false,
				customBodyRenderLite: (index) => {
					return (
						<span>{formatAmountCurrency(schedules[index]?.totalAmount)}</span>
					);
				},
				setCellHeaderProps: () => { return { style: { textAlign: 'right', paddingRight: '20px' } }; },
				setCellProps: () => { return { style: { textAlign: 'right', paddingRight: '20px' } }; },
			}
		},
		{
			label: 'Installment',
			name: 'periodicAmount',
			options: {
				filter: true,
				sort: false,
				customBodyRenderLite: (index) => {
					return (
						<span>{formatAmountCurrency(schedules[index]?.periodicAmount)}</span>
					);
				},
				setCellHeaderProps: () => { return { style: { textAlign: 'right', paddingRight: '20px' } }; },
				setCellProps: () => { return { style: { textAlign: 'right', paddingRight: '20px' } }; },
			}
		},
		{
			label: 'Status',
			name: 'status',
			options: {
				filter: true,
				sort: true
			}
		},
		{
			label: 'Show Failed transactions',
			name: 'hasFailedPayments',
			options: {
				filter: true,
				sort: false,
				viewColumns:false,
				display:false
			}
		},
	]

	const options = {
		download: !!schedules.length ? true : 'disabled',
		filter: true,
		print: false,
		search: true,
		tableBodyHeight: '64vh',
		tableBodyMaxHeight: '67%',
		viewColumns: false,
		selectableRows: 'single',
		selectableRowsHideCheckboxes: true,
		selectableRowsOnClick: true,
		pagination: false,
		customFooter: (count) => {
			return (
				<Summary
					count={count}
					totalCharged={totalCharged}
					totalRemaining={totalRemaining}
					totalAmount={totalAmount}
				/>
			);
		},
		setRowProps: (row) => {
			const color = (row[7] === 'Canceled' || row[7] === 'Suspended') ? COLORS.lightBackgroundRed :
				(row[7] === 'Completed') ? COLORS.lightBackgroundGreen : '';
			return {
				style: { background: color }
			};
		},
		onRowsSelect: onRowClick,
		textLabels: {
			body: {
			  noMatch: <Loader isLoading={isLoading} />
			}
		}
	}

	return (
		<AbacusTable
			columns={columns}
			data={schedules}
			options={options}
			themeOptions={themeOptions}
		/>
	)
}

SchedulesList.propTypes = {
	emptyDataMessage: PropTypes.string,
	schedules: PropTypes.array,
	onRowClick: PropTypes.func,
}

export default memo(SchedulesList)