import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import { Button, Typography, Grid } from '@material-ui/core';

export default function ListActionsToolbar({
	isSelectedSchedule = false,
	scheduleStatus = '',
	displayActions = true,
	onDeleteClick = () => {},
	onOpenClick = () => {},
	isEditMode = false,
	onCancelClick = () => {}
}) {
	const useStyles = makeStyles((theme) => ({
		root: {
			backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.blueHeader,
			borderBottom: `1px solid ${COLORS.grayBorder}`,
			paddingRight: 0,
			'& .MuiToolbar-gutters': {
				paddingRight: 0,
			}
		},
		icon: {
			marginRight: 6,
			fontSize: 28,
			'&.Mui-disabled': {
				color: COLORS.disabledIcon,
				backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.blueHeader,
			},
		},
		buttonDivider: {
			borderRadius: 0,
			color: isEditMode ? COLORS.blueButtons : COLORS.whiteText,
			fontWeight: 900,
			fontSize: 17,
			textTransform: 'capitalize',
			'&.Mui-disabled': {
				color: COLORS.disabledIcon,
				backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.blueHeader,
			},
			'&:hover $icon': {
				backgroundColor: isEditMode ? '' : COLORS.blueHeader
			},
			'& .MuiTouchRipple-root span': {
				backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.pressedBackground,
				opacity: .3,
			},
		},
		button: {
			color: isEditMode ? COLORS.blueButtons : COLORS.whiteText,
			fontWeight: 900,
			fontSize: 17,
			textTransform: 'capitalize',
			'&.Mui-disabled': {
				color: COLORS.disabledIcon,
				backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.blueHeader,
			},
			'&:hover $icon': {
				backgroundColor: isEditMode ? '' : COLORS.blueHeader
			},
			'& .MuiTouchRipple-root span': {
				backgroundColor: isEditMode ? COLORS.whiteBackground : COLORS.pressedBackground,
				opacity: .3,
			},
		},
		status: {
			color: scheduleStatus === 'Canceled' ? COLORS.error : scheduleStatus === 'Completed' ? COLORS.greenText : COLORS.grayText,
			fontWeight: 900,
			marginLeft: 55
		},
		statusDiv: {
			paddingTop: 15,
			height: 50,
			borderLeft: `1px solid ${COLORS.grayBorder}`,
			backgroundColor: scheduleStatus === 'Canceled' ? COLORS.lightBackgroundRed : scheduleStatus === 'Completed' ? COLORS.lightBackgroundGreen : COLORS.grayContainer
		},
		actions: {
			height: 50,
		}
	}));

	const classes = useStyles();
	
	return (
		<AppBar position='static' className={classes.root}>
			<Toolbar variant='dense'>
				{displayActions && (
					<>
						<Grid container direction='row' xs={12} className={classes.actions}>
							{!isEditMode && (<Button onClick={onOpenClick} className={classes.button} disabled={!isSelectedSchedule} id={'open'}>
								<OpenInNewIcon className={classes.icon}/>
									Open
							</Button>)}
							<Button onClick={onCancelClick} id={'cancel'} className={classes.buttonDivider} disabled={!isSelectedSchedule || (scheduleStatus !== 'Active' && scheduleStatus !== 'Incomplete')}>
								<StopCircleIcon className={classes.icon}/>
									Cancel
							</Button>
							<Button 
								className={classes.buttonDivider} 
								id={'delete'} 
								onClick={onDeleteClick} 
								disabled={!isSelectedSchedule || (scheduleStatus !== 'Not Started' && scheduleStatus !== 'Incomplete')}
								style={{ borderLeft: `1px solid ${COLORS.grayBorder}` }}
							>
								<DeleteIcon className={classes.icon}/>
									Delete
							</Button>
						</Grid>
						{isEditMode && (
							<>
								<Grid container direction='row' xs={3} className={classes.statusDiv}>
									<Typography className={classes.status}>{scheduleStatus}</Typography>
								</Grid>
							</>
						)}
					</>
				)}
			</Toolbar>
		</AppBar>
	);
}