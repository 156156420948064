import React, { useState, memo } from 'react';
import axios from 'axios';
import { getApiToken, getApiUrl } from '../utils/getApiUrl';
import FormDatePicker from '../forms/FormDatePicker';
import FormDropDown from '../forms/FormDropDown';
import FormTextField from '../forms/FormTextField';
import Message from '../forms/Messages';
import SyncCard from '../forms/SyncCard';
import moment from 'moment';

const downloadAsFile = (response, fileName) => {
	const mime = response.headers['content-type'];
	const name = mime === 'text/csv' ? `${fileName}.csv` : `${fileName}.pdf`;
    const base64 = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = base64;
    a.setAttribute('download', name);
    document.body.appendChild(a);
    a.click();
	document.body.removeChild(a);
}

const reportTypes = [
	{ name: 'Chargeback', value: 'chargeback_report' },
	{ name: 'Daily transactions', value: 'daily_transaction_report' },
	{ name: 'Daily transactions (Per merchant)', value: 'daily_transaction_report_per_merchant' },
	{ name: 'Quarterly transactions', value: 'qtly_transaction_report' },
	{ name: 'Transactions', value: 'transaction_report' },
	{ name: 'International Transactions', value: 'international_transaction_report' },
	{ name: 'Weekly transactions', value: 'weekly_transaction_report' },
	{ name: 'Yearly credit card transactions', value: 'yearly_credit_card_transaction_report' },
	{ name: 'APX ACH(echeck) rejected transactions', value: 'echeck_rejected_transactions' },
	{ name: 'Total Chargeback Report By Month', value: 'total_chargeback_report' },
	{ name: 'Merchants Information', value: 'merchants_info_report' },
	{ name: 'Surcharge Report', value: 'surcharge_report' }
];

const apiWithDateQuery = [
	'chargeback_report', 'transaction_report', 'international_transaction_report', 'daily_transaction_report',
	'daily_transaction_report_per_merchant', 'echeck_rejected_transactions',
	'total_chargeback_report'
];

function TransactionReport() {
    const [error, setError] = useState('');
	const [isSubmitting, setSubmitting] = useState(false);
	const [reportType, setReportType] = useState('chargeback_report');
	const [merchantId, setMerchantId] = useState('');
	const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
	const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

	const getReportFields = () => {
		if (['chargeback_report', 'transaction_report'].indexOf(reportType) >= 0) {
			return {
				merchantId,
				fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
				toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
			};
		}

		if (['daily_transaction_report', 'international_transaction_report', 'daily_transaction_report_per_merchant', 'echeck_rejected_transactions', 'total_chargeback_report'].indexOf(reportType) >= 0) {
			return {
				fromDate: fromDate ? moment(fromDate).format('YYYY-MM-DD') : '',
				toDate: toDate ? moment(toDate).format('YYYY-MM-DD') : '',
			};
		}

		if ('yearly_credit_card_transaction_report' === reportType) {
			return { merchantId };
		}

		return {};
	};
	const getReportParams = () => ({ ...getReportFields(), isAdmin: true, token: getApiToken() });
	const getReportUrl = () => `${getApiUrl()}/api/admin/${reportType}`;

	const downloadReport = async () => {
		setError('');
		setSubmitting(true);
		const params = getReportParams();
		return axios.get(getReportUrl(), { params, responseType: 'blob' })
			.then((response) => {
				const reportTypeName = reportTypes.find((x) => x.value === reportType).name;
				let downloadFileName = reportTypeName;
				downloadFileName += ` ${params.merchantId || ''}`;
				downloadFileName += ` ${params.fromDate || ''}`;
				downloadFileName += ` ${params.toDate || ''}`;
				downloadAsFile(response, downloadFileName.trim());
			})
			.catch((err) => {
				if (err?.response?.data) {
					const reader = new FileReader();
					reader.onload = function() {
						setError(JSON.parse(reader.result).error);
					};
					reader.readAsText(err?.response?.data);
				} else {
					setError('There was an internal server error.');
				}
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	const formFields = () => {
		return  (
			<>
				<FormDropDown
					id="reportType"
					list={reportTypes}
					onChange={(e) => setReportType(e.target.value)}
					required
					value={reportType}
				/>
				{['chargeback_report', 'transaction_report', 'yearly_credit_card_transaction_report'].indexOf(reportType) >= 0 && (
					<FormTextField
						id="merchantId"
						onChange={(e) => setMerchantId(e.target.value)}
						placeholder="Merchant ID"
						required
						value={merchantId}
					/>
				)}

				{apiWithDateQuery.indexOf(reportType) >= 0 && (
					<>
						<FormDatePicker
							id="fromDate"
							onChange={(value) => setFromDate(value)}
							placeholder="From date"
							required
							value={fromDate}
						/>
						<FormDatePicker
							id="toDate"
							onChange={(value) => setToDate(value)}
							placeholder="To date"
							required
							value={toDate}
						/>
					</>
				)}
			</>
        );
	}

	return (
		<>
			<Message isOpen={!!error} handleClose={() => setError('')} message={error} severity="error" />
			<SyncCard
				actionTitle="Generate"
				inputField={formFields}
				isSyncing={isSubmitting}
				showRecordsCount={false}
				showRefresh={false}
				sync={downloadReport}
				title="Reports"
			/>
		</>
	)
}

export default memo(TransactionReport);
