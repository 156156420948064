import moment from 'moment';
import axios from 'axios';
import { getApiUrl } from '../utils/getApiUrl';

export const createPaymentMethodDataCallback = (data, isCreditCard) => {
	const portalData = { paymentMethod: data, isCreditCard, Event: 'Success' }
	window.parent?.postMessage?.(JSON.stringify(portalData), '*');

	window.chrome?.webview?.hostObjects?.paymentMethods?.PaymentResponse(
		data.tokenId,
		data.accountNumber,
		isCreditCard ? data.expiration : '',
		isCreditCard ? data.creditCardType : data.bankAccountType,
		data.first + ' ' + data.last,
		isCreditCard
	)
}

export const createTransactionDataCallback = (data) => {
	const portalData = { transaction: data, Event: 'Success' }
	window.parent?.postMessage?.(JSON.stringify(portalData), '*');
}

export const createTransactionErrorCallback = (errorCode, errorMessage) => {
	const portalData = { Event: 'Error', ErrorCode: errorCode, ErrorMessage: errorMessage }
	window.parent?.postMessage?.(JSON.stringify(portalData), "*");
}

export const createPaymentMethodErrorCallback = (errorCode, errorMessage, isCreditCard) => {

	const portalData = { Event: 'Error', ErrorCode: errorCode, ErrorMessage: errorMessage }
	window.parent?.postMessage?.(JSON.stringify(portalData), "*");

	window.chrome?.webview?.hostObjects?.paymentMethods?.PaymentResponse('', '', '', '', '',
		isCreditCard,
		errorCode,
		errorMessage
	)
}


export const createReceiptData = (paymentMethod, transactionId,  invoiceAlreadyPaid, confirmationNumber, transactionData) => {

	const transactionCreatedAt = transactionData?.created || paymentMethod?.createdAt
	const transaction = {
		name: `${paymentMethod?.first} ${paymentMethod?.last}`,
		email: paymentMethod?.email,
		address1: paymentMethod?.address1,
		address2: paymentMethod?.address2,
		city: paymentMethod?.city,
		state: paymentMethod?.state,
		country: paymentMethod?.country,
		transactionId: transactionId,
		type: paymentMethod?.hasOwnProperty('creditCardType') ? paymentMethod?.creditCardType : paymentMethod?.bankAccountType,
		number: paymentMethod?.accountNumber,
		expire: `${paymentMethod?.expiration?.substring(0, 2)}/20${paymentMethod?.expiration?.substring(2, 4)}`,
		zip:paymentMethod?.zip,
		transactionDate: moment(transactionCreatedAt?.toString(), 'YYYY-MM-DD').format('MMM DD, YYYY'),
		isBankAccount: !paymentMethod?.hasOwnProperty('creditCardType'),
		invoiceAlreadyPaid: invoiceAlreadyPaid,
		confirmationNumber: confirmationNumber,
		surcharge: transactionData?.surchargeFee,
		totalPaid: transactionData?.amount,
	}
	return transaction;
}

export const createReceiptDataMultipleTxns = (paymentMethod, invoiceAlreadyPaid, transactions) => {
	let surcharge = 0;
	let totalPaid = 0;
	let transactionIds = [];
	let confirmationNumbers = [];
	for(let i = 0; i < transactions.length; i++) {
		surcharge += Number(transactions[i].transaction.surchargeFee);
		totalPaid += Number(transactions[i].transaction.amount);
		transactionIds.push(transactions[i].transaction.transactionId);
		confirmationNumbers.push(transactions[i].transaction.confirmationNumber);
	}

	transactionIds = transactionIds.join(', ');
	confirmationNumbers = confirmationNumbers.join(', ');

	const transaction = {
		name: `${paymentMethod?.first} ${paymentMethod?.last}`,
		email: paymentMethod?.email,
		address1: paymentMethod?.address1,
		address2: paymentMethod?.address2,
		city: paymentMethod?.city,
		state: paymentMethod?.state,
		country: paymentMethod?.country,
		transactionId: transactionIds,
		type: paymentMethod?.hasOwnProperty('creditCardType') ? paymentMethod?.creditCardType : paymentMethod?.bankAccountType,
		number: paymentMethod?.accountNumber,
		expire: `${paymentMethod?.expiration?.substring(0, 2)}/20${paymentMethod?.expiration?.substring(2, 4)}`,
		zip:paymentMethod?.zip,
		transactionDate: moment(transactions[0].transaction?.createdAt?.toString(), 'YYYY-MM-DD').format('MMM DD, YYYY'), // for multiple transactions, take the date of the first transaction
		isBankAccount: !paymentMethod?.hasOwnProperty('creditCardType'),
		invoiceAlreadyPaid: invoiceAlreadyPaid,
		confirmationNumber: confirmationNumbers,
		surcharge: surcharge.toFixed(2),
		totalPaid: totalPaid.toFixed(2),
	}
	return transaction;
}

export const createTrustAccountDataCallback = (token, routing, account) => {
	window.chrome?.webview?.hostObjects?.trustAccount?.TrustAccount(
		token,
		routing,
		account
	)
}

export const initializePendo = (application, account_id, account_name, visitor_id, visitor_name) => {

	if (account_id) {
		window.pendo.initialize({
			excludeAllText: true,
			visitor: {
				id: visitor_id,   // Required if user is logged in
				full_name: visitor_name  // Recommended if using Pendo Feedback

				// You can add any additional visitor level key-values here,
				// as long as it's not one of the above reserved names.
			},

			account: {
				id: account_id, // Required if using Pendo Feedback
				name: account_name,
				application: application
				// You can add any additional account level key-values here,
				// as long as it's not one of the above reserved names.
			}
		});
	}
}

export const appendPendoUrl = (metadata) => {
	if (window.pendo.location) {
		window.pendo.location.setUrl(window.location.href + metadata);
	}
}

// track event via installed pendo client/agent
export function trackPendoEvent(eventName, eventProperties, error = '') {
	console.log(`Event named ${eventName} is submitted with error`, error);
	if (window.pendo && window.pendo.isReady && window.pendo.isReady()) {
		if (error) {
			const errString = (typeof error === 'string') ? error : JSON.stringify(error);
			if(errString)
				Object.assign(eventProperties, { error: errString.length > 1024 ? errString.substring(0, 1024) : errString });
		}
		console.log('Pendo is ready, tracking event -', eventProperties);
		return window.pendo.track(eventName, eventProperties);
	}
	setTimeout(function() {
		trackPendoEvent(eventName, eventProperties, error);
	}, 500);
}

export const getMerchantData = async (merchantId, axiosConfig) => {

	try {
		const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_merchant_data?merchantId=${merchantId}` , axiosConfig);

		return {
			status: 'success',
			customerId: data.customerId,
			customerName: data.customerName,
			application: data.application
		};

	} catch (error) {
		return {
			status: 'error',
			err: error?.response?.data?.error?.toString()
		};
	}
}
