import React, {useEffect} from 'react';
import { useLocation } from "react-router-dom";
import NotFoundPage from './NotFoundPage';
import ServerDownErrorPage from './ServerDownErrorPage';
import {COLORS} from '../constants/Colors';

const ErrorPages= ({
    errorType = '',
    title = "Sorry, this page isn't available",
    messages = [
        'The link you followed may be broken, or the page may have been removed.',
        'Please contact your service provider.'
    ]
}) => {
    const location = useLocation();

    useEffect(() =>{
        document.body.style.backgroundColor = COLORS.grayContainer;
    },[])
    const renderErrorpages = (errorType) => {
        switch (errorType) {
            case 'pageNotFound':
                return (
                <NotFoundPage
                    title={location.state?.title || title}
                    messages={location.state?.messages || messages}
                />);
            case 'siteMaintenance':
                return <ServerDownErrorPage/>;
            case 'timeOutPage':
                return <NotFoundPage/>;
            default:
                return null;
        }
    }
    return (renderErrorpages(errorType));
}

export default ErrorPages;
