export const CREDIT_CARD_FORM_FIELDS = [
	{ id: 'name', label: 'Name on Card', placeholder: "Name on Card" },
	{ id: 'number', label: 'Card Number' },
	{ id: 'expiration', label: 'Expiration' },
]

export const CREDIT_CARD_CONFIG_FIELDS = [
	{ type: "number", element: "#number" },
	{ type: "name", element: "#name", values: { name: '' } },
	{ type: "expiration", element: "#expiration" }
]

export const PAYMENTMETHOD_TYPES = {
	'American Express': 1,
	Visa: 2,
	Mastercard: 3,
	Maestro: 3,
	'Diners Club': 4,
	Discover: 5
}
