import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { DialogTitle } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import FormTextField from '../forms/FormTextField';
import CloseIcon from '@material-ui/icons/Close';
import { COLORS } from '../constants/Colors';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getApiUrl } from '../utils/getApiUrl';

const useStyles = makeStyles((theme) => ({
    header: {
        color: COLORS.whiteText,
        backgroundColor: COLORS.darkBlackText
    },
    closeIconBtn: {
        color: COLORS.whiteText,
        padding: 0,

    },
    closeIcon: {
        width: '0.9em'
    },
    closeBtn: {
        position: 'absolute',
        top: '9px',
        right: '15px'
    },
    content: {
        backgroundColor: COLORS.grayContainer
    },
    btnCancel: {
        border: '1px solid #E61C55',
        color: COLORS.textPink,
        minWidth: '74px',
        height: '36px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: 'transparent'
        },
    },
    btnSave: {
        backgroundColor: COLORS.textPink,
        color: COLORS.whiteText,
        textTransform: 'capitalize',
        marginRight:'16px',
        minWidth: '76px',
        minHeight: '28px',
        '&:hover': {
            backgroundColor: COLORS.textPink
        },
    },
    footer: {
        paddingTop: '7px',
        paddingBottom: '7px',
        borderTop: '1px solid #C6C8C9'
    }
}));

function Recepients({
    emailId = '',
    setEmailId = () => { },
    merchantId = '',
    title = '',
    type = '',
    token = '',
    open = false,
    setOpen = () => { },
    onRefresh = () => { }
}) {

    const classes = useStyles();
    const history = useHistory();
    const [emailList, setEmailList] = useState(emailId);
    const [emailListError, setEmailListError] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        setEmailList(emailId);
    }, [emailId]);

    const validateEmailList = () => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const list = emailList?.trim() !=="" ? emailList?.split(',') : null;
        if (list) {
            if (list.length > 50) {
                setEmailListError('Please enter up to 50 email addresses only.');
                return false;
            }
            else
                setEmailListError('');
            for (let email of list) {
                if (email.trim() === '' || !regex.test(email.trim())) {
                    setEmailListError('Please enter a valid email address.');
                    return false;
                } else
                    setEmailListError('');
            }
        }
        return true;
    };

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    const handleInputChange = ({ target: { value, id } }) => {
        setEmailList(value);
    }

    const handleClose = () => {
        if (emailList?.toLowerCase() === emailId?.toLowerCase()) {
            setOpen(false);
            setEmailId('');
            setEmailListError('');
        }
        else
        setOpenDialog(true);
    }

    const closeDialog = () => {
        setOpenDialog(false);
    }

    const handleDiscard = () => {
        setOpenDialog(false);
        setOpen(false);
        setEmailId('');
        setEmailListError('');
    }

    const handleSave = async () => {
        setOpenDialog(false);
        if (validateEmailList()) {
            try {
                const payload = { notification: { merchantId: merchantId, type: type, recipients: emailList } };
                const { data } = await axios.put(`${getApiUrl()}/api/merchantwebapi/update_notification_recipients`, payload, axiosConfig);
                if (data.code === 200) {
                    setOpen(false);
                    setEmailId('');
                    setEmailListError('');
                    onRefresh();
                }
            } catch (err) {
                if (err.toString() === 'Error: Network Error') {
                    history.push('/errorpage/serverDown');
                }
                console.log(err);
            }
        }
    }

    return (
        <div>
            <Dialog open={open} fullWidth maxWidth='md'>
                <DialogTitle className={classes.header}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography>Manage Recepient List - {title}</Typography>
                        </Grid>
                        <Grid item xs={1} className={classes.closeBtn}>
                            <IconButton onClick={handleClose} className={classes.closeIconBtn}>
                                <CloseIcon className={classes.closeIcon} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>Please provide email address of who should receive this notification.</Typography>
                            <Typography>Separate email addresses with commas.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField fullWidth={true} id='emailList' value={emailList} type='text' helperText={emailListError} onChange={handleInputChange} rows={10} isMultiline={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <Button onClick={handleClose} className={classes.btnCancel}>Cancel</Button>
                    <Button onClick={handleSave} className={classes.btnSave}>Save</Button>
                </DialogActions>
            </Dialog>
            <Dialog fullWidth open={openDialog} maxWidth='xs' aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Grid item xs={11}>
                    <DialogTitle id="alert-dialog-title">
                        {"Do you want to save the changes?"}
                    </DialogTitle>
                </Grid>
                <Grid item xs={1} className={classes.closeBtn}>
                    <IconButton onClick={closeDialog} >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </Grid>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.btnCancel} onClick={handleDiscard}>Discard</Button>
                    <Button onClick={handleSave} className={classes.btnSave} autoFocus>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default memo(Recepients);