import axios from 'axios';
import React, { useEffect, useState, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Paper } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import InProgress from '../forms/InProgress';
import Messages from '../forms/Messages';
import { useQuery } from '../hooks/useQuery';
import { createTrustAccountDataCallback } from '../services/Services';
import BankAccount from '../shared/BankAccount';
import ProcessPayment from '../shared/ProcessPayment';
import { getApiUrl } from '../utils/getApiUrl';

const useStyles = makeStyles((theme) => ({
    page: {
        backgroundColor: COLORS.grayContainer
    },
    root: {
        flexGrow: 1,
        fontFamily: 'Roboto,sans-serif',
        // marginTop: '10px',
        backgroundColor: COLORS.secureBackground
    },
    paper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
    },
    container: {
        maxWidth: '100%',
    },
    header: {
        display: 'flex',
        backgroundColor: COLORS.blueHeader,
        color: COLORS.whiteText,
        padding: 8,
        marginTop: 15,
        maxHeight: 40
    },
}));

function TrustAccount() {

    const classes = useStyles();
    const history = useHistory();
    const [validateRequired, setValidateRequired] = useState(false);

    const initialBankInfoState = {
        routingNumber: '',
        accountNumber: '',
        accountType: '',
    }

    const [merchantId, setMerchantId] = useState('');
    const [bankInfo, setBankInfo] = useState(initialBankInfoState);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');

    const params = useParams();
    const query = useQuery();

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + params.merchant
        }
    };

    useEffect(() => {
        setMerchantId(query.get('merchantId'));
    }, [query])

    const handleOnSubmit = async () => {
        if (requiredFieldValidated(bankInfo)) {
            setIsLoading(true);
            const payload = {
                merchantId: merchantId,
                method: bankInfo.accountType,
                number: bankInfo.accountNumber,
                routing: bankInfo.routingNumber,
            }
            try {
                const response = await axios.post(`${getApiUrl()}/api/merchantwebapi/add_trust_account`, payload, axiosConfig)

                if (response.data.code !== 200) {
                    setIsLoading(false);
                    setMessage(`Failed - The trust bank account was not added. ${response.data.error}`);
                    setIsOpen(true);
                    throw new Error(response.data);
                }
                const { token, routing, account } = response.data.bankAccount;
                createTrustAccountDataCallback(token, routing, account)
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                if(err.toString() === 'Error: Network Error') {
                    history.push('/errorpage/serverDown');
                }
            }
        }
    }

    const handleInputChange = ({ target: { value, id } }) => {
        setBankInfo( prev => ({...prev, [id]: value}))
    }

    const handleSelectionChange = ({ target: { value } }) => {
        setBankInfo( prev => ({...prev, accountType: value}))
    }

    const requiredFieldValidated = (bankInfo) => {
        const requiredFields = Object.keys(bankInfo)
        for (const field of requiredFields) {
            if (!bankInfo[field]) {
                setValidateRequired(true)
                return false
            }
        }
        setValidateRequired(false)
        return true
    }

    return (
        <Container className={classes.container} >
            { isLoading && <InProgress /> }
            <Card className={classes.root} >
                <Paper className={classes.paper}>
                    <Grid container >
                        <Grid item xs={12} className={classes.header}>
                            <Grid item xs={12}><Typography className={classes.title}>Add Trust Account</Typography>
                            </Grid>
                        </Grid>
		                <Messages
                            message={message}
                            severity='error'
                            isOpen={isOpen}
                            handleClose={() => setIsOpen(false)}
                        />
                        <BankAccount
                            onInputChange={handleInputChange}
                            bankInfo={bankInfo}
                            onSelectionChange={handleSelectionChange}
                            isPayNow={false}
                            hideName={true}
                            validateRequired={validateRequired}
                        />
                        <Grid item xs={12}>
                            <ProcessPayment
                                buttonText='SUBMIT'
                                onSubmit={handleOnSubmit}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Card>
        </Container>
    )
}

export default memo(TrustAccount);