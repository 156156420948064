import React, { useEffect, useState } from 'react';
import Header from './Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import testTransaction from '../../assets/images/test_transactions.png';
import { Container } from '@material-ui/core';
import TestTransactionForm from './TestTransactionforms';
import SuccessfulTestTransactionPage from './SuccessfulTestTransactionPage';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CheckIcon from '@material-ui/icons/Check';
import { Divider } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '../hooks/useQuery';
import { getApiUrl } from '../utils/getApiUrl';
import InProgress from '../forms/InProgress';
import Messages from '../forms/Messages';
import Box from '@material-ui/core/Box';
import { getMerchantData, initializePendo } from '../services/Services';

const useStyles = makeStyles((theme) => ({

	congratulationHeader: {
		height: 68,
		backgroundColor: '#ECF7EA',
		background: '#ECF7EA',
		border: '1px solid #C0E7BB',
		boxSizing: 'border-box',
		fontFamily: 'Roboto',
	},
	successfulMessage: {
		color: '#16800D',
		fontSize: 19,
		fontWeight: 'bold',
		letterSpacing: 0,
		marginTop: '1%',
		fontFamily: 'Roboto',
	},
	successfulIcon: {
		height: 35,
		width: 35,
		backgroundColor: '#16800D',
	},
	divider: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		width: 65,
		marginTop: '2%',
		height: 1.5,
		marginBottom: '4%'
	},
	button: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		marginTop: '0.75%',
		marginBottom: '0.75%',
		marginRight: '3%',
		color: '#FFFFFF',
		fontFamily: 'Roboto',
		paddingRight: '1.5%',
		paddingLeft: '1.5%',
		textTransform: 'none',
		fontSize: 20,
		fontWeight: 'bold'
	}
}));

const TestTransaction = () => {

	const classes = useStyles();
	const query = useQuery();
	const params = useParams();
	const history = useHistory();
	const [ isPennyTestProcessed, setIsPennyTestProcessed ] = useState(false);
	const [ merchantId, setMerchantId ] = useState('');
	const [ merchantAccount, setMerchantAccount ] = useState({});
	const [ emailList, setEmailList ] = useState({ notificationEmailTo: '' });
	const [ emailListError, setEmailListError ] = useState('');
	const [ validateRequired, setValidateRequired ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isOpen, setIsOpen ] = useState(false);
	const [ message, setMessage ] = useState('');

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${params?.pennyTest}`
		}
	};

	useEffect(() => {
		if (params?.pennyTest) {
			setMerchantId(query.get('merchantId'));
			async function fetchMerchantAccount() {
				await getMerchantAccount(query.get('merchantId'));
			}

			fetchMerchantAccount();

			async function fetchMerchant(axiosConfig) {
				const customer = await getMerchantData(query.get('merchantId'), axiosConfig);
				initializePendo(customer?.application, customer?.customerId, customer?.customerName);

			}
			fetchMerchant(axiosConfig);
		}
	}, [ params?.pennyTest ]);

	const requiredFieldValidated = (emailLists) => {
		const requiredFields = Object.keys(emailLists);
		for (const field of requiredFields) {
			if (!emailLists[field]) {
				setValidateRequired(true);
				return false;
			}
		}
		setValidateRequired(false);
		return true;
	};

	const validateEmailList = () => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const emails =  emailList.notificationEmailTo.split(',');
		if (emails.length > 10) {
			setEmailListError('Please enter up to 10 email addresses only.');
			return false;
		} else setEmailListError('');

		for (let email of emails) {
			if (email.trim() === '' || !regex.test(email.trim())) {
				setEmailListError('Separate emails by commas. Enter a valid email address.');
				return false;
			} else setEmailListError('');
		}
		return true;
	};

	const handleInputChange = ({ target: { value, id } }) => {
		setEmailList(prev => ({ ...prev, [id]: value }));
	};

	const processPennyTestTransaction = async () => {
		const todayDate = new Date();
		const payTestTransaction = {
			payment: {
				amount: 5.00,
				paymentMethodId: '',
				externalId: todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString() + merchantId,
				isTrustTransaction: false,
				isPayNow: false,
				entityInfo: '',
				customerId: '',
				merchantId: merchantId,
				emailList: emailList,
				isPennyTest: true
			},
		};

		if (requiredFieldValidated(emailList) && !emailListError) {
			setIsLoading(true);
			try {
				const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/process_penny_payment_transaction`, payTestTransaction, axiosConfig);
				if (data.code === 200) {
					setIsLoading(false);
					setIsPennyTestProcessed(true);
				}
			} catch (e) {
				setMessage(`Failed - Payment could not be processed: ${e.response?.data?.error}`);
				setIsOpen(true);
				setIsLoading(false);
				if (e.toString() === 'Error: Network Error') {
					history.push('/errorpage/serverDown');
				}
				console.log(e);
			}
		}
	};

	const getMerchantAccount = async (merchantId) => {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_merchant_operating_account?merchantId=${merchantId}`, axiosConfig);
			if (data.code === 200) {
				setMerchantAccount(data.merchantAccount);
				setEmailList(prev => ({ notificationEmailTo: `${data.merchantAccount.merchantEmail}` }));
				setIsPennyTestProcessed(data.merchantAccount.isPennyTestProcessed);
			}
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	return (
		<>
			<Header/>
			<Container maxWidth='xl' style={{ marginTop: '3%', marginBottom: '3%' }}>
				<Card style={{ border: '2px solid Lightgray' }}>
					<Grid container  sx={{ display: 'flex' }}>
						<Grid >
							<CardMedia
								component="img"
								image={testTransaction}
								alt="testTransaction"

							/>
						</Grid>
						<Grid sx={{ display: 'flex', flexDirection: 'column' }} sm={5} md={7} lg={6}>
							<CardContent sx={{ flex: '1 0 auto' }}>
								<Typography sx={{ fontSize: { lg: 26, md: 23, sm: 20, xs: 18 }, fontWeight: 'bold', fontFamily: 'Roboto', marginBottom: '1%' }}>
									Get a $5 APX Account Credit
								</Typography>
								<Divider className={classes.divider}/>
								<Typography  sx={{ fontSize: { lg: 24, md: 23, sm: 20, xs: 18 }, fontFamily: 'Roboto',
									color: '#13191C', marginBottom: '0.75%', marginTop: '2%' }}>
									We’ll pay you $5 credit to experience the simple and
									seamless payment process flow provided with Abacus Payment Exchange.<br/>
									<span style={{ fontStyle: 'italic', color: '#5F6365' }}>Note: Transaction fee $0.15 will be deducted.</span>
								</Typography>
							</CardContent>
						</Grid>
					</Grid>
					<Messages message={message} severity='error' isOpen={isOpen} handleClose={() => setIsOpen(false)} />
					{!isPennyTestProcessed ? <>
						<CardContent style={{ background: '#F3F4F4', borderTop: '2px solid Lightgray' }}>
							<div style={{ margin: 'auto', width: '95%' }}>
								<Grid container direction='column'  alignItems="center" >
									<Typography  sx={{ fontSize: { lg: 24, md: 23, sm: 20, xs: 18 },  fontFamily: 'Roboto',
										lineHeight: '19px',
										color: '#13191C',
										marginTop: '1%',
										marginBottom: '1%' }}>
                                    Please complete this form to get a $5.00 deposit into your account.
									</Typography>
								</Grid>
								<TestTransactionForm
									merchantAccount={merchantAccount}
									handleInputChange={handleInputChange}
									emailList={emailList}
									validateEmailList={validateEmailList}
									emailListError={emailListError}
									validateRequired={validateRequired}
								/>
							</div>
						</CardContent>
						{isLoading && <InProgress/>}

						<Box display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}>	<CardContent style={{ borderTop: '2px solid Lightgray' }}>
							<Grid container justifyContent={'flex-end'} >
								<Button id={'process-penny-test'} onClick={() => processPennyTestTransaction()} className={classes.button} >Get $5 Gift</Button>
							</Grid>
						</CardContent></Box>
						<Box display={{ xs: 'block', lg: 'none', md: 'none', sm: 'none' }}>	<CardContent style={{ borderTop: '2px solid Lightgray' }}>
							<Grid container justifyContent={'flex-end'} >
								<Button id={'process-penny-test'} onClick={() => processPennyTestTransaction()} className={classes.button} fullWidth >Get $5 Gift</Button>
							</Grid>
						</CardContent></Box>

					</> : <>
						<CardContent  className={classes.congratulationHeader}>
							<Grid  container direction='column'  alignItems="center" >
								<Grid item lg={4} md={4} sm={5} xs={12}   style={{ width: '100%' }}>
									<Grid  container direction='row' spacing={1} >
										<Grid item lg={2} md={2} sm={2} sx={3}>
											<Avatar  className={classes.successfulIcon} >
												<CheckIcon style={{ color: 'white', margin: 'auto' }}/>
											</Avatar>
										</Grid>
										<Grid item  lg={10} md={10} sm={10} sx={8}>
											<Typography  sx={{ fontSize: { lg: 24, md: 21, sm: 20, xs: 18 },color: '#16800D',
												fontWeight: 'bold',
												letterSpacing: 0,
												fontFamily: 'Roboto', }}>
                                Transaction Confirmation
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
						<CardContent >
							<Grid container direction='column'  alignItems="center" >
								<Typography  sx={{ fontSize: { lg: 24, md: 23, sm: 20, xs: 18 },  fontFamily: 'Roboto',
									lineHeight: '19px',
									color: '#13191C',
									marginTop: '1%',
									marginBottom: '1%' }}>
                            We sent a transaction confirmation details to this email address(es):
								</Typography>
							</Grid>
							<SuccessfulTestTransactionPage  merchantAccount={merchantAccount} emailList={emailList}/>
						</CardContent>
					</>}
				</Card>
			</Container>
		</>
	);
};

export default TestTransaction;
