export const SignupFieldsInfo = {
	businessType: 'Type of incorporation of your business',
	username: 'Only alphanumeric values are allowed. Email cannot be utilized',
	password: `Password must be at least eight characters long, 
	must contain uppercase and lowercase letters, and include a number and a special character`,
	irs: `IRS legal Falling Name. IRS Filling Name is required, 
    if the Business Legal Name provided is than 24 characters. Must match what was provided to IRS`,
	businessName: 'This is the full legal name of the business as registered with the IRS',
	statementDescriptor: 'This business name will be shown on the customer’s credit card or bank account statement',
	descriptorPreview: 'merchant\'s business name on card statement',
	businessCategory: 'Business category most relevant to your company',
	environment: 'A summary of how a merchant accepts payments',
	businessDescriptor: 'A brief description for what the business is processing payments for and how the goods are sold',
	secondaryPayoutDescriptor: 'this Secondary Descriptor will append to the merchant payout for disbursement',
	einType: 'Indicates if the EIN being used is a TIN or SSN',
	ein: 'The 9-digit business identification code as issued by the IRS',
	website: 'Business website or website used for processing online payments',
	annualProcessingVolume: 'The amount of sales you expect to process in year',
	averageTransactionAmount: 'Average credit card and ACH transaction amount',
	businessEmail: 'Email address of the business',
	businessPhone: 'Phone number of the business',
	customerServicePhone: 'This phone number will be shown on the customer’s statement',
	businessAddress: 'Please use your business street address from IRS document.',
	address2: 'Second line of the business address as filed with the IRS. e.g Suite 100,',
	irsNotProfitLetterStatus: 'Not-for-profit IRS letter status based on IRS Publication 557 and T.D.8812',
	irsNotProfitLetterIssuedDate: `The issued date of the Not-for-profit IRS letter. 
	This will be used for compliance recertification`,
	firstname: 'Business owner First name',
	middleName: 'Business owner Middle name',
	lastname: 'Business owner Last name',
	dateOfBirth: 'Date of birth of business owner',
	businessTitle: 'job Title of the business owner',
	significantResponsibility: `A controlling authority, or 'Control Prong',
	 or an individual with significant legal authority to enter the legal Entity into a commercial relationship`,
	country: 'Country of the Business owner',
	emailAddress: 'Email address of the business owner',
	phoneNumber: 'Phone number of the business owner',
	SSN: 'Input the 9 digit Social Security Number of the business owner',
	ownership: 'Percentage of ownership of this business owner',
	role: 'Business Owner job title',
	primaryContact: 'Primary contact of the business',
	ownerAddress: 'Business owner home or residential address. Please use the address on your Drivers License or Utility Bill for easy verification',
	loginAndNotificationEmail: `Email address for validating merchant access to APX merchant portal. This email will be
	 used for account and transaction notifications`,

};

