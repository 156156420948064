import React, {memo, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SignUpTextField from './SignUpTextField';
import Grid from '@material-ui/core/Grid';
import SignUpSelectField from './SignUpSelect';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DragAndDrop from './DragAndDrop';
import { BANKACCOUNTTYPE } from '../../constants/Locations';
import { Typography } from '@material-ui/core';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions);

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
	cancel: {
		color: '#E14126',
		fontWeight: 'bold',
		textAlign: 'center',
		textTransform: 'none'
	},
	rectangle: {
		borderRadius: 4,
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		color: '#FFFFFF',
		fontWeight: 'bold',
		lineHeight: '14px',
		height: 40
	},
	text: {
		color: '#263238',
		letterSpacing: 0,
	},
	supportingDocument: {
		color: '#263238',
		fontWeight: 'bold',
		letterSpacing: 0,
		marginBottom: 20,
		marginTop: 20,
		fontSize: 20
	},
	rectangleUploadDocument: {
		boxSizing: 'border-box',
		border: '2px dashed #B0BEC5',
		borderRadius: 4,
		backgroundColor: '#FFFFFF',
		height: 40,
		marginBottom: 20,
		width: '99%',
	},
	dragText: {
		color: '#90A4AE',
		fontWeight: 500,
	},
	browseFileText: {
		color: '#0E60B2',
		fontWeight: 500,
		lineHeight: '16px'
	},
	dialog: {
		width: '100%',
		maxWidth: '100%',
		overflow: 'auto',
	},
	disableDialogButton: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		color: COLORS.whiteText,
		fontWeight: 'bold',
		lineHeight: '14px',
		height: 35,
		textTransform: 'none',
		paddingRight: 20,
		paddingLeft: 20

	},
	enableDialogButton: {
		background: 'linear-gradient(90deg, #F68D91 0%, #F8AC94 100%)',
		fontWeight: 'bold',
		lineHeight: '14px',
		height: 35,
		color: COLORS.whiteText,
		textTransform: 'none',
		paddingRight: 20,
		paddingLeft: 20
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5,
		marginBottom: 20,
		marginTop: 20,
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 15,
	},
	contentHelper: {
		marginBottom: 20,
		marginTop: 20,
		color: COLORS.blueButtons
	},
	tooltiptext: {
		color: COLORS.blackText
	},
	ul: {
		color: '#E14126',
	},

}));

const BankInfoFields = ({
	handleClose = () => {},
	addBankAccount = () => {},
	buttonText = '',
	setBankInfo = () => {},
	disableDialogButton = true,
	setPageError = () => {},
	persistFiles = {},
	persitDocumentType = () => {},
	bankInfo = {},
	documentsType = {},
	persistedFiles= [],
	addAccManually= []

}) => {
	const classes = useStyles();
	const [ files, setFiles ] = useState({});

	return (
		<div className={classes.dialog}>
			<DialogContent >
				<Grid item lg={12} className={classes.text}>
					<Typography>
						By providing this banking information, you are attesting this bank account belongs to this
            			merchant and is under the name of account holder or merchant with the financial institution.
					</Typography>
				</Grid>
				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6} >
						<SignUpTextField
							label='Bank Name'
							componentName='addbankInfo'
							name='bankName'
							type='string'
							showInfo={false}
							setCheckIsSateEmpty={setBankInfo}
							setPageError={setPageError}
							defaultName={addAccManually[0]?.bankName}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Account Owner'
							componentName='addbankInfo'
							name='accountOwner'
							type='string'
							showInfo={false}
							setCheckIsSateEmpty={setBankInfo}
							setPageError={setPageError}
							defaultName={addAccManually[0]?.accountOwner}
						/>
					</Grid>
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6} >
						<SignUpTextField
							label='Routing Number'
							componentName='addbankInfo'
							name='routingNumber'
							type='routingNumber'
							showInfo={false}
							setCheckIsSateEmpty={setBankInfo}
							setPageError={setPageError}
							defaultName={addAccManually[0]?.routingNumber}

						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Account Number'
							componentName='addbankInfo'
							name='bankAccountNumber'
							type='bankAccountNumber'
							showInfo={false}
							setCheckIsSateEmpty={setBankInfo}
							setPageError={setPageError}
							numberLengthRegx={/^[0-9]{5,15}$/}
							errorMessage= 'Must be 5 to 15 digits long'
							defaultName={addAccManually[0]?.bankAccountNumber}
						/>
					</Grid>
				</Grid>
				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Account Type'
							componentName='addbankInfo'
							name='bankAccountType'
							type='string'
							showInfo={false}
							setCheckIsSateEmpty={setBankInfo}
							list={BANKACCOUNTTYPE}
							setPageError={setPageError}
							defaultName={addAccManually[0]?.bankAccountType}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={1}>
					<Grid className={classes.asterisk} item>*</Grid>
					<Grid item className={classes.supportingDocument}>Supporting Documents</Grid>
					<Grid item >
						<LightTooltip title={
							<>
								<div>Acceptable Documents:</div>
								<ul className={classes.ul}>
									<li >
										<Grid item  xs={12} >
											<Typography className={classes.tooltiptext}>
                          						Government-issued document
											</Typography>
										</Grid>
									</li>
									<li >
										<Grid item  xs={12} >
											<Typography className={classes.tooltiptext}>
                          						Void Check
											</Typography>
										</Grid>
									</li>
									<li >
										<Grid item  xs={12} >
											<Typography className={classes.tooltiptext}>
                          						Bank Statement
											</Typography>
										</Grid>
									</li>
									<li>
										<Grid item  xs={12} >
											<Typography className={classes.tooltiptext}>
                        						Bank letter
											</Typography>
										</Grid>
									</li>
									<li>
										<Grid item  xs={12} >
											<Typography className={classes.tooltiptext}>
                          						Contact
											</Typography>
										</Grid>
									</li>
								</ul>
							</>
						} classes={{ tooltip: classes.customWidth }}>
							<HelpIcon className={classes.contentHelper} fontSize='small'/>
						</LightTooltip>
					</Grid>
				</Grid>
				<Grid item className={classes.text} style={{ marginBottom: '2%' }}>
					<Typography>
            			Maximum upload file size:8 MB.Accepted file types: .jpg, .png, .pdf, .tif, .tiff
					</Typography>
				</Grid>
				<DragAndDrop
					setBankInfo={setBankInfo}
					setPageError={setPageError}
					files={files}
					setFiles={setFiles}
					persistFiles={persistFiles}
					persitDocumentType={persitDocumentType}
					bankInfo={bankInfo}
					documentsType={documentsType}
					persistedFiles={persistedFiles}
				/>
			</DialogContent>

			<DialogActions>
				<Grid container direction='row'  alignItems='flex-end' justifyContent='flex-end' alignContent='flex-end' >
					<Grid item lg={2}  md={2}>
						<Button onClick={handleClose} className={classes.cancel} id={'cancel'}>
              				Cancel
						</Button>
					</Grid>
					<Grid item style={{ marginRight: '10px' }} >
						<Button
							id={buttonText}
							className={(!disableDialogButton) ? classes.disableDialogButton : classes.enableDialogButton}
							onClick={addBankAccount} style={{ color: COLORS.whiteText }}
							disabled={disableDialogButton}
						>
							{buttonText}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</div>
	);
};

BankInfoFields.propTypes = {
	handleClose: PropTypes.func,
	addBankAccount: PropTypes.func,
	buttonText: PropTypes.string,
	disableDialogButton: PropTypes.bool,
	setPageError: PropTypes.func,
	persistFiles: PropTypes.func,
	persitDocumentType: PropTypes.func,
	bankInfo: PropTypes.object,
	documentsType: PropTypes.object,
	persistedFiles: PropTypes.array,
	addAccManually: PropTypes.array

};

export default memo(BankInfoFields);
