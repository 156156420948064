import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import headerLogo from '../../assets/images/APX.png';
import Merchant from './Merchant';
import Tokens from './Tokens';
import SyncFees from './SyncFees';
import TransactionReport from './TransactionReport';
import Transactions from './Transactions';
import PayrixTransactions from './PayrixTransactions';
import Payers from './Payers';
import { clearApiToken } from '../utils/getApiUrl';
import AdminDisbursementReportsInput from './DisbursementReports';
import MerchantAccounts from './MerchantAccounts';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		marginTop: 32
	},
	mt3: {
	  marginTop: theme.spacing(3),
	},
	mb3: {
	  marginBottom: theme.spacing(3),
	}
}));

function BulkSync() {
	const classes = useStyles();
	const history = useHistory();
	const doLogout = () => {
		clearApiToken();
		history.push('/admin/login');
	};
	return (
		<Container maxWidth="lg">
			<div className={classes.root}>
				<Grid container spacing={3} justifyContent="flex-end">
					<Grid item md={11} xs={12}>
				<img src={headerLogo} style={{ marginBottom: '12px'}} />
				</Grid>
					<Grid item md={1} xs={12}>
				<Button size="small" variant="outlined" color="primary" onClick={doLogout}>Logout</Button>
				</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} lg={12}>
						<MerchantAccounts className={classes.mb3} />
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<Merchant className={classes.mb3} />
						<SyncFees className={classes.mb3} />
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<Tokens className={classes.mb3} />
						<PayrixTransactions className={classes.mb3} />
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<Transactions className={classes.mb3} />
						<Payers className={classes.mb3} />
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<TransactionReport className={classes.mb3} />
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<AdminDisbursementReportsInput className={classes.mb3} />
					</Grid>
				</Grid>
			</div>
		</Container>
	);
}

export default memo(BulkSync);
