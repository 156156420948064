import React from 'react';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import PropTypes from 'prop-types';
import { COLORS } from '../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

	root: {

		fontSize: 16,
		color: '#263238',
		lineHeight: '12px',
	},

}));

const RadioButton = ({
	selectedValue = '',
	handleChange = () => {},
	name = ''
}) => {
	const classes = useStyles();
	return (
		<Typography component='span' className={classes.root}>
			<Radio checked= { selectedValue ? true : false } onChange={handleChange} value={selectedValue} name={name}
				inputProps={{ 'aria-label': selectedValue }} style={{ color: COLORS.blueButtons, marginLeft: '-8px' }} />
			{name}
		</Typography>
	);
};

RadioButton.propTypes = {
	selectedValue: PropTypes.string,
	handleChange: PropTypes.func,
	name: PropTypes.string
};

export default RadioButton;