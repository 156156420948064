import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import DialogContent from '@material-ui/core/DialogContent';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import Typography from '@material-ui/core/Typography';
import printJS from 'print-js';
import InProgress from '../../forms/InProgress';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DialogTitle = withStyles(theme => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
	},
	title: {
		color: '#263238',
		fontFamily: 'Roboto',
		fontSize: '3.25vh',
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '20px'
	}
}))(props => {
	const { children, classes, onClose } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h6" className={classes.title}>{children}</Typography>
			{onClose ? (
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions);

const useStyles = makeStyles({
	container: {
		backgroundColor: '#353638',
	},
	gridContainer: {
		height: '2%'
	},
	plusAndMinus: {
		color: 'white',
		fontSize: '28px',
		cursor: 'pointer'
	},
	percentage: {
		color: 'white',
		fontSize: '15px'
	}
});

// eslint-disable-next-line require-jsdoc
function TermsAndConditionsPdf({
	showPdfBankDisclosure = true,
	setShowPdfBankDisclosure = () => { },
	pdf = '',
	pdfNameToggle = () => {},
	dialogName = '',
   	setShowLoader = () => {},
   	pdfName = ''
}) {
	const classes = useStyles();

	const [ numPages, setNumPages ] = useState(null);
	const [ scale, setScale ] = useState(1.5);
	const [ percentage, setPercentage ] = useState(100);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const listInnerRef = useRef();

	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
				pdfNameToggle(false);
			}
		}
	};

	function downloadPDF(pdf) {
		const linkSource = `data:application/pdf;base64,${pdf}`;
		const downloadLink = document.createElement('a');
		const fileName = pdfName;
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	}

	return (
		<>
		<Dialog
			maxWidth='lg'
			open={showPdfBankDisclosure}
			onClose={() => setShowPdfBankDisclosure(false)}

		>
			<DialogTitle id="customized-dialog-title" onClose={() => setShowPdfBankDisclosure(false)} >
				{dialogName}
			</DialogTitle >
			<Container  maxWidth='lg' className={classes.container}>
				<Grid container  alignItems='flex-end' justifyContent='flex-end' direction='row' className={classes.gridContainer}>
					<Grid item lg={1} md={1} onClick={() => {setScale(prev => prev - 0.25); setPercentage(prev => prev - 25);}}>
						<span className={classes.plusAndMinus}>-</span>
					</Grid>
					<Grid item lg={1} md={1} style={{ marginBottom: '1%' }}>
						<span className={classes.percentage}>{percentage}%</span>
					</Grid>
					<Grid item lg={3} md={2} onClick={() => {setScale(prev => prev + 0.25);setPercentage(prev => prev + 25);} }>
						<span className={classes.plusAndMinus} >+</span>
					</Grid>
					<Grid item lg={1} md={1} onClick={() => downloadPDF(pdf)}>
						<DownloadIcon style={{ color: 'white', cursor: 'pointer' }}/>
					</Grid>
					<Grid item lg={1}  md={2} onClick={() => printJS({ printable: pdf, type: 'pdf', base64: true })}>
						<PrintIcon style={{ color: 'white', cursor: 'pointer' }}/>
					</Grid>
				</Grid>
			</Container>
			<DialogContent onScroll={() => onScroll()} ref={listInnerRef}>
				<Document
					onLoadSuccess={onDocumentLoadSuccess}
					file={`data:application/pdf;base64,${pdf}`}
					externalLinkTarget='_blank'
					loading={<InProgress/>}
					onLoadProgress={() => setShowLoader(true)}
				>
					{Array.apply(null, Array(numPages))
						.map((x, i)=>i + 1)
						.map(page => <Page pageNumber={page} scale={scale}
					   	onLoadSuccess={() => setShowLoader(false)}
						/>)}
				</Document>
			</DialogContent>
		</Dialog>
			</>
	);
}

TermsAndConditionsPdf.propTypes = {
	showPdfBankDisclosure: PropTypes.bool,
	setShowPdfBankDisclosure: PropTypes.func,
	pdf: PropTypes.string,
	pdfNameToggle: PropTypes.func,
	dialogName: PropTypes.string,
	setShowLoader: PropTypes.string,
	pdfName: PropTypes.string
};

export default memo(TermsAndConditionsPdf);