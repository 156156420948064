import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../constants/Colors';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import plaidLogo from '../../../assets/images/plaidlogo.png';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SignupDialog from './SignupDialog';
import PlaidLink from './Plaid';
import * as _ from 'lodash';
import SignUpService from '../../services/SignUpService';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import AbacusTable from '../../shared/AbacusTable';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles((theme) => ({
	title: {
		color: '#2D4B69',
		fontWeight: 'bold',
		marginTop: '1%',
		fontSize: 20
	},
	text: {
		color: '#263238',
		marginTop: '3%',
		fontSize: 18
	},
	box: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: '#FFFFFF',
		height: 40,
		cursor: 'pointer'
	},

	BankInformation: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: '#FFFFFF',
		height: 60,
		'&:hover': {
			background: '#FDF6F5',
			border: '1px solid #E14126',
			cursor: 'pointer'
		},
	},

	BankInformationPlaid: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: '#FFFFFF',
		height: 60,
	},

	disabledBox: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: '#FFFFFF',
		height: 40,
	},
	boxSessionTimeOut: {
		boxSizing: 'border-box',
		border: '1px solid red',
		backgroundColor: '#FFFFFF',
		height: 60,
	},
	boxText: {
		color: '#263238',
		marginTop: 3,
		fontSize: 18
	},
	disabledBoxText: {
		color: '#90A4AE',
		marginTop: 3,
		fontSize: 18
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#263238',
		fontWeight: 'bold',
		letterSpacing: 0,
		marginBottom: '1%'
	},
	enterManually: {
		color: COLORS.darkGray,
		fontSize: '100%',
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '16px'
	},
	enterManuallyText: {
		color: '#263238',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1)
	},
	bankInformationText: {
		color: '#263238',
	},
	divider: {
		backgroundColor: COLORS.dividerColor
	},
	plaidIcon: {
		width: '50%',
		marginLeft: 26,
		marginTop: 15
	},
	bankInfo: {
		marginLeft: 24,
		marginTop: 10,
	},
	removeBankInfo: {
		color: '#90A4AE',
		marginTop: 15,
		cursor: 'pointer'
	},
	bankAccountLogo: {
		color: '#90A4AE',
		width: '75%',
		marginLeft: 20,
		marginTop: 15
	},
	container: {
		marginBottom: '4%'
	},
	hr: {
		marginTop: '7%',
		backgroundColor: 'gray',
		marginBottom: '7%'
	},
	or: {
		textAlign: 'center',
		marginTop: '2%',
	},
	controlPointIcon: {
		color: '#E14126',
		fontSize: 30,
		marginTop: 5
	},
	disabledControlPointIcon: {
		color: '#90A4AE',
		fontSize: 30,
		marginTop: 5
	},
	tooltiptext: {
		color: COLORS.blackText
	},
	contentHelper: {
		color: COLORS.blueButtons
	},
	ul: {
		color: '#E14126',
	},
}));

// eslint-disable-next-line require-jsdoc
function AddBankAccount({
	setDisableButton = () => {},
	token = '',
	applicationCustomerId = '',
	saveBankAccount = () => {},
	deleteBankAccount = () => {},
	bankInfo = {},
	setBankInfo = () => {},
	setPageNumber = () => {},
	plaidOperatingtimeOut = false,
	SetPlaidOperatingtimeOut = () => {},
	plaidTrustimeOut = false,
	SetPlaidTrusttimeOut = () => {},
	files = [],
	setFiles = () => {},
	setOperatingFiles = () => {},
	setTrustFiles = () => {},
	setOperatingDocumentsType = () => {},
	setTrustDocumentsType = () => {},
	operatingDocumentsType = {},
	trustDocumentsType = {},
	operatingFiles = [],
	setOperatingManually = () => {},
	addOperatingAccManually = [],
	trustFiles = [],
	updateBankAccount = () => {},
	setTrustingAccManually = () => {},
	addTrustingAccManually = []
}) {

	const history = useHistory();

	const classes = useStyles();
	const [ addOperatingAccPlaid, setOperatingAccPlaid ] = useState([]);
	const [ addTrustingAccPlaid, setTrustingAccPlaid ] = useState([]);

	const [ bankType, setBankType ] = useState('');
	const [ buttonText, setTexButton ] = useState('');
	const [ title, setTitle ] = useState('');
	const [ open, setOpen ] = useState(false);
	const [ disableDialogButton,setDisableDialogButton ] = useState(true);
	const [ bankPageError, setBankPageError ] = useState({});
	const [ plaidKey, setPlaidKey ] = useState('');
	const [ plaidEnv, setPlaidEnv ] = useState('');
	const [ application, setApplication ] = useState('');
	const [ plaidDialog, setPlaidDialog ] = useState(false);
	const [ disabledOperatingAccount, setDisabledOperatingAccount ] = useState(false);
	const [ hideOperatingAccountPlaid, setHideOperatingAccountPlaid ] = useState(false);
	const [ hideOperatingAccount, setHideOperatingAccount ] = useState(false);

	const [ disabledTrustAccount, setDisabledTrustAccount ] = useState(false);
	const [ hideTrustAccountPlaid, setHideTrustAccountPlaid ] = useState(false);
	const [ hideTrustAccount, setHideTrustAccount ] = useState(false);

	const [ plaidOperatingSession, setPlaidOperatingSession ] = useState();
	const [ plaidTrustSession, setPlaidTrustSession ] = useState();
	const [ isBankInfo, setIsbankInfo ] = useState(false);
	const [ openToEdit, setOpenToEdit ] = useState(false);
	const [ originalOperatingFiles, setOriginalOperatingFiles ] = useState([]);
	const [ originalOperatingDocumentsType, setOriginalOperatingDocumentsType ] = useState({});
	const [ originalTrustFiles, setOriginalTrustFiles ] = useState([]);
	const [ originalTrustDocumentsType, setOriginalTrustDocumentsType ] = useState({});

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	const handleSaveBankAccount = (data) => {
		saveBankAccount(data);
	};

	const handleEditBankAccount = (data) => {
		updateBankAccount(data);
	};

	/*
    * get merchant data from database
    * */
	useEffect(()=> {

		if (token && applicationCustomerId) {
			const getMerchantData = async (applicationCustomerId) => {
				const merchantData = await SignUpService.getMerchantInfo(applicationCustomerId, axiosConfig);
				if (merchantData.status === 'success') {
					setPlaidKey(merchantData?.data?.plaidKey);
					setPlaidEnv(merchantData?.data?.plaidEnv);
					setApplication(merchantData?.data?.application);
					for (const account of merchantData?.data?.merchantInfo?.bankAccounts) {

						if (account.isPlaid && account.type === '1') setOperatingAccPlaid([ account ]);
						if (account.isPlaid && account.type === '2') setTrustingAccPlaid([ account ]);
						if (!account.isPlaid && account.type === '1') setOperatingManually([ account ]);
						if (!account.isPlaid && account.type === '2') setTrustingAccManually([ account ]);
					}
				}
				else {
					if (merchantData.err.toString() === 'Error: Network Error') {
						history.push('/errorpage/serverDown');
					}
				}

			};
			getMerchantData(applicationCustomerId);
		}
	}, [ token, applicationCustomerId ]);

	const addBankAccount = () => {
		if (bankType === 'operating') {
			setOperatingManually(prev => ([ bankInfo, ...prev ]));
			setOpen(false);
			bankInfo.type = 1;
			bankInfo.isPlaid = false;
			handleSaveBankAccount(bankInfo);
		} else {
			setTrustingAccManually(prev => ([ bankInfo, ...prev ]));
			setOpen(false);
			bankInfo.type = 2;
			bankInfo.isPlaid = false;
			handleSaveBankAccount(bankInfo);
		}
	};

	const updateMerchantBankAccount = () => {
		if (bankType === 'operating') {
			setOperatingManually(prev => ([ bankInfo ]));
			setOpenToEdit(false);
			bankInfo.type = 1;
			bankInfo.isPlaid = false;
			handleEditBankAccount(bankInfo);
		} else {
			setTrustingAccManually(prev => ([ bankInfo ]));
			setOpenToEdit(false);
			bankInfo.type = 2;
			bankInfo.isPlaid = false;
			handleEditBankAccount(bankInfo);
		}
	};

	const togglePersistFiles = (bankType) => {
		if (bankType ===  'trust') return setTrustFiles;
		else return setOperatingFiles;
	};

	const toggleDocumentsType = (bankType) => {
		if (bankType ===  'trust') return setTrustDocumentsType;
		else return setOperatingDocumentsType;
	};

	const toggleAddAccManually = (bankType) => {
		if (bankType ===  'trust') return addTrustingAccManually;
		else return addOperatingAccManually;
	};

	const toggleDocumentType = (bankType) => {
		if (bankType ===  'trust') return trustDocumentsType;
		else return operatingDocumentsType;
	};

	const persistedFiles = (bankType) => {
		if (bankType ===  'trust') return trustFiles;
		else return operatingFiles;
	};

	const handleClickOpen = (bankType) => {

		if (bankType === 'trust') {
			setBankType('trust');
			setTexButton('Add Trust Account');
			setTitle('Add Trust Account Manually');
			setOpen(true);
		} else {
			setBankType('operating');
			setTexButton('Add Operating Account');
			setTitle('Add Operating Account Manually');
			setOpen(true);
		}
	};

	const handleEditOpen = (bankType) => {

		if (bankType === 'trust') {
			setOriginalTrustFiles(trustFiles);
			setOriginalTrustDocumentsType(trustDocumentsType);
			setBankType('trust');
			setTexButton('Update');
			setTitle(' Edit Trust Account');
			setOpenToEdit(true);
		} else {
			setOriginalOperatingFiles(operatingFiles);
			setOriginalOperatingDocumentsType(operatingDocumentsType);
			setBankType('operating');
			setTexButton('Update');
			setTitle('Edit Operating Account');
			setOpenToEdit(true);
		}
	};

	const plaidHandleClickOpen = () => {
		setPlaidDialog(true);
	};

	const removeBankInfo = (i, accountType, isPlaid, bankName, type, e) => {
		e.stopPropagation();
		e.preventDefault();

		deleteBankAccount(isPlaid, bankName, type);
		setDisableButton(true);
		setDisableDialogButton(true);
		if (accountType === 'operatingViaPlaid') {
			SetPlaidOperatingtimeOut(false);
			const newList = [ ...addOperatingAccPlaid ];
			newList.splice(i, 1);
			setOperatingAccPlaid(newList);
		} else if (accountType === 'trustViaPlaid') {
			SetPlaidTrusttimeOut(false);
			const newList = [ ...addTrustingAccPlaid ];
			newList.splice(i, 1);
			setTrustingAccPlaid(newList);

		}  else if (accountType === 'operating') {
			const newList = [ ...addOperatingAccManually ];
			newList.splice(i, 1);
			setOperatingManually(newList);
			setOperatingFiles([]);
			setOperatingDocumentsType({});

		} else {
			const newList = [ ...addTrustingAccManually ];
			newList.splice(i, 1);
			setTrustingAccManually(newList);
			setTrustDocumentsType({});
			setTrustFiles([]);
		}
	};

	const handleClose = () => {

		setOpen(false);
		if (isBankInfo) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}

		if (bankType ===  'trust') setTrustFiles([]);
		else setOperatingFiles([]);

		if (bankType ===  'trust') setTrustDocumentsType({});
		else setOperatingDocumentsType({});
	};

	const updateCanceled = () => {
		setOpenToEdit(false);
		if (isBankInfo) {
			setDisableButton(false);
		} else {
			setDisableButton(true);
		}

		if (bankType ===  'trust') {
			 setTrustFiles(originalTrustFiles);
		} else {
			setOperatingFiles(originalOperatingFiles);
		}

		if (bankType ===  'trust') {
			setTrustDocumentsType(originalTrustDocumentsType);
		} else {
			setOperatingDocumentsType(originalOperatingDocumentsType);
		}
	};

	const plaidHandleClose = () => {
		setPlaidDialog(false);
	};

	useEffect(() => {

		const checkIsSateEmpty = () => {
			const values = _.values(bankInfo);
			if (values.length) {
				for (let x = 0; x < values.length; x++) {
					if (checkForError(bankPageError) || (String(values[x]) === 'null' || String(values[x]) === 'undefined' || _.isEmpty(String(values[x])))) {
						setDisableDialogButton(true);
						return;
					} else {
						setDisableDialogButton(false);
					}
				}
			}

		};
		checkIsSateEmpty();
	}, [ bankInfo, bankPageError, operatingFiles.length, trustFiles.length ]);

	useEffect(() => {
		if ((addOperatingAccManually.length && operatingFiles.length) || addOperatingAccPlaid?.length) {
			if (addTrustingAccManually.length && !trustFiles.length) {
				setDisableButton(true);
				setIsbankInfo(false);
			} else {
				setDisableButton(false);
				setIsbankInfo(true);
			}

		} else {
			setDisableButton(true);
			setIsbankInfo(false);
		}

	}, [ addTrustingAccPlaid?.length, addOperatingAccManually.length, addOperatingAccPlaid?.length, addTrustingAccManually.length, operatingFiles.length, trustFiles.length, disableDialogButton ]);

	const checkForError = (bankPageError => {
		const values = _.values(bankPageError);
		if (values.length) {
			for (let x = 0; x < values.length; x++) {
				if (values[x]) return true;
			}
			return false;
		}
	});

	useEffect(() => {

		if (addTrustingAccPlaid.length || addTrustingAccManually.length) setDisabledTrustAccount(true);
		else setDisabledTrustAccount(false);

		if (addOperatingAccPlaid.length || addOperatingAccManually.length) setDisabledOperatingAccount(true);
		else setDisabledOperatingAccount(false);

		if (addOperatingAccManually.length) setHideOperatingAccount(true);
		else setHideOperatingAccount(false);

		if (addOperatingAccPlaid.length) {
			setHideOperatingAccountPlaid(true);
			if (!plaidOperatingtimeOut) {
				const plaidOperatingSession = setTimeout(function () {
					SetPlaidOperatingtimeOut(true);
					setPageNumber(3);
					setDisableButton(true);
				}, 30 * 60 * 1000);
				setPlaidOperatingSession(plaidOperatingSession);
			}
		} else {
			setHideOperatingAccountPlaid(false);
			clearTimeout(plaidOperatingSession);
		}

		if (addTrustingAccPlaid.length) {
			setHideTrustAccountPlaid(true);
			if (!plaidTrustimeOut) {
				const plaidTrustSession = setTimeout(function () {
					SetPlaidTrusttimeOut(true);
					setDisableButton(true);
					setPageNumber(3);
				}, 30 * 60 * 1000);
				setPlaidTrustSession(plaidTrustSession);
			}
		} else {
			setHideTrustAccountPlaid(false);
			clearTimeout(plaidTrustSession);
		}

		if (addTrustingAccManually.length) setHideTrustAccount(true);
		else setHideTrustAccount(false);

	},[ addTrustingAccPlaid,  addOperatingAccPlaid, addTrustingAccManually, addOperatingAccManually ]);

	const BankInformation = (props) => {
		return (
			<Grid container direction='row' alignItems='center' justifyContent='left'>
				<Grid item lg={1} xs={1} sm={1} md={1}>
					{ props.img === 'showplaidicon' && <img src={plaidLogo} alt="APX" className={classes.plaidIcon} />}
					{ props.img === 'showbanklogo' && <AccountBalanceIcon className={classes.bankAccountLogo}/>}
				</Grid>
				<Grid item lg={9} xs={10} md={9} sm={9} direction='row' className={classes.bankInfo}>
					<Grid container>
						<Grid item lg={12} xs={12} style={{ fontWeight: 'bold' }} className={classes.bankInformationText}>{props.field.bankName}</Grid>
						<Grid item lg={12} xs={12} className={classes.bankInformationText}>{`Acct #...${props.field.bankAccountNumber.substr(-4)} | ${props.field.bankAccountType}`}</Grid>
					</Grid>
				</Grid>
				<Grid item lg={1} xs={1} sm={1} md={1} className={classes.removeBankInfo}>
					<DeleteRoundedIcon onClick={(e) => removeBankInfo(props.idx, props.accountType, props.field.isPlaid, props.field.bankName, props.field.type, e)} />
				</Grid>
			</Grid>
		);
	};

	const DebitBlockInfo = () => {
		const columns = [ 'Bank', 'Originator', 'ACH Company ID' ];
		const data = [ [ 'Fifth Third Bank', 'WorldPay', '1310281170' ], [ 'Fifth Third Bank', 'WorldPay', '1043575881' ] ];
		const options = {
			responsive: 'standard', download: false, filter: false, sort: false, print: false, search: false,
			viewColumns: false, selectableRows: 'none', isRowSelectable: false, pagination: false
		};

		const themeOptions = {
			overrides: {
				MUIDataTableToolbarSelect: {
					root: {
						display: 'none',
					},
				},
				MUIDataTableHeadCell: {
					root: {
						backgroundColor: `${COLORS.lightGray} !important`,
						color: `${COLORS.darkGrayText} !important`,
						fontSize: 17,
						lineHeight: '12px',
					}
				},
				MuiTableRow: {
					root: {
						'&$selected': {
							backgroundColor: `${COLORS.blueButtons} !important`,
							'& > .MuiTableCell-root': {
								color: 'white !important',
							},
							'& .MuiSvgIcon-root': {
								color: 'white !important',
							},
						},
					},
				},
				MuiTableCell: {
					root: {
						fontSize: 16,
						lineHeight: '16px',
						color: `${COLORS.darkBlackText} !important`,
						padding: '8px'
					}
				}
			}
		};

		return (<>
			<Grid item className={classes.title} xs={12}>ACH Debit Block Information</Grid>
			<Grid item><Divider className={classes.divider} /></Grid>
			<Grid item className={classes.enterManuallyText}>
				<Typography>
				You may have ACH Debit Blocks or Filters in place to safeguard your bank account.
				If you do, you will need to add the following ACH Company ID’s to your safelist or whitelist.
				This will allow debits originating under your APX agreement to process properly and ensure proper functionality of your APX Services.
				Depending on your individual banking setup and relationship, you may need to contact your bank representative to further assistance.
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<AbacusTable data={data} columns={columns} options={options} themeOptions={themeOptions} />
			</Grid>
		</>);
	};

	return (
		<Grid container direction='column' spacing={2}>
			<Grid item  xs={12} style={{ marginTop: '15px' }}>Please add your bank account(s) via Plaid or manually. <br/>
				Plaid is an electronic platform that helps businesses instantly authenticate and connect their bank accounts for payments.
			</Grid>

			<Grid item className={classes.title} xs={12}>Operating Account</Grid>
			<Divider className={classes.divider}/>
			<Grid item className={classes.enterManuallyText}>
				<Typography>
					Your operating bank account is required and will be your primary bank account for payments.
				</Typography>
			</Grid>

			<Grid container direction='row' >

				<Grid container direction='row'  justifyContent='space-between' spacing={5} xs={12} className={classes.container}>
					{/* <Grid item lg={6} xs={6} >*/}
					{/*	<Grid container   className={classes.label}   direction='row'>*/}
					{/*		<Grid lg={1} md={1} xs={1} >Plaid</Grid>*/}
					{/*		<Grid  lg={11} md={12} xs={12} style={{ color: 'gray', fontSize: '95%' }}>(Securely connect your bank account to your APX application)</Grid>*/}
					{/*	</Grid>*/}

					{/*	{addOperatingAccPlaid.map((field, idx) => {*/}
					{/*		return (*/}
					{/*			<div className={plaidOperatingtimeOut ? classes.boxSessionTimeOut : classes.BankInformationPlaid}  key={idx}>*/}
					{/*				<BankInformation field={field} img='showplaidicon' idx={idx} accountType='operatingViaPlaid'/>*/}
					{/*			</div>*/}
					{/*		);*/}
					{/*	})}*/}
					{/*	{ plaidOperatingtimeOut && <Grid style={{ color: 'red' }}> Session Time Out. Please add your bank account again</Grid>}*/}
					{/*	{ !hideOperatingAccountPlaid && <Grid item xs={12} className={!disabledOperatingAccount ? classes.box : classes.disabledBox} >*/}
					{/*		<PlaidLink*/}
					{/*			plaidKey={plaidKey}*/}
					{/*			plaidEnv={plaidEnv}*/}
					{/*			setAccViaPlaid={setOperatingAccPlaid}*/}
					{/*			type='operating'*/}
					{/*			setBankInfo={setBankInfo}*/}
					{/*			persistPlaidBankInfo={handleSaveBankAccount}*/}
					{/*			disabledPlaid={disabledOperatingAccount}*/}
					{/*			disabledOperatingAccount={disabledOperatingAccount}*/}
					{/*			setPlaidDialog={setPlaidDialog}*/}
					{/*		/>*/}
					{/*	</Grid>}*/}
					{/* </Grid>*/}

					<Grid item lg={12} xs={12}  md={12}>
						<Grid container   className={classes.label}   direction='row' >
							<Grid item lg={1} md={1} xs={1} >Manually</Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Grid  item lg={10} md={12} xs={12} style={{ color: 'gray', fontSize: '95%' }}>
								(Upload required bank account document)
								<LightTooltip title={
									<>
										<div>Acceptable Documents:</div>
										<ul className={classes.ul}>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Government-issued document
													</Typography>
												</Grid>
											</li>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Void Check
													</Typography>
												</Grid>
											</li>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Bank Statement
													</Typography>
												</Grid>
											</li>
											<li>
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Bank letter
													</Typography>
												</Grid>
											</li>
											<li>
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Contact
													</Typography>
												</Grid>
											</li>
										</ul>
									</>
								} classes={{ tooltip: classes.customWidth }}>
									<HelpIcon className={classes.contentHelper} fontSize='small'/>
								</LightTooltip>
							</Grid>

						</Grid>
						{addOperatingAccManually.map((field, idx) => {
							return (
								<div className={classes.BankInformation} key={idx} onClick={() => handleEditOpen('operating')} >
									<BankInformation field={field} img='showbanklogo' idx={idx} accountType='operating'/>
								</div>
							);
						})}
						{ (addOperatingAccManually.length && !operatingFiles.length) ? <Grid style={{ color: 'red' }}> Please upload your supporting documents again</Grid> : ''}
						{ !hideOperatingAccount && <Grid
							item xs={12} lg={12}
							className={!disabledOperatingAccount ? classes.box : classes.disabledBox}
							onClick={ !disabledOperatingAccount ? () => handleClickOpen('operating') : null}>
							<Grid container direction='row'  alignItems='center' justifyContent='center' id={'add-operating-account-manually'} >
								<Grid item lg={1} xs={2} >
									<ControlPointIcon
										className={!disabledOperatingAccount ? classes.controlPointIcon : classes.disabledControlPointIcon}
									/>
								</Grid>
								<Grid item lg={10} xs={10} className={ !disabledOperatingAccount ? classes.boxText : classes.disabledBoxText}>
									Add operating account manually
								</Grid>
							</Grid>
						</Grid>}
					</Grid>
				</Grid>
			</Grid>
			{application !== 'OT' && application !== 'OTC' && <Grid item className={classes.title} xs={12}>Trust Account</Grid>}
			{application !== 'OT' && application !== 'OTC' && <Divider className={classes.divider}/>}
			{application !== 'OT' && application !== 'OTC' && <Grid item className={classes.enterManuallyText}>
				<Typography>
					Please add your trust bank account.
				</Typography>
			</Grid>}
			{application !== 'OT' && application !== 'OTC' && (<Grid container direction='row'  justifyContent='space-between' spacing={5} xs={12} className={classes.container}>

				{/* {application !== 'OT' && application !== 'OTC' && (*/}
				{/*	<Grid item lg={6} xs={6}>*/}
				{/*		<Grid container   className={classes.label}   direction='row'>*/}
				{/*			<Grid lg={1} md={1} xs={1} >Plaid</Grid>*/}
				{/*			<Grid  lg={11} md={12} xs={12} style={{ color: 'gray', fontSize: '95%' }}>(Securely connect your bank account to your APX application)</Grid>*/}
				{/*		</Grid>*/}

				{/*		{addTrustingAccPlaid.map((field, idx) => {*/}
				{/*			return (*/}
				{/*				<div className={plaidTrustimeOut ? classes.boxSessionTimeOut : classes.BankInformationPlaid} key={idx}>*/}
				{/*					<BankInformation field={field} img='showplaidicon' idx={idx} accountType='trustViaPlaid'/>*/}
				{/*				</div>*/}
				{/*			);*/}
				{/*		})}*/}
				{/*		{ plaidTrustimeOut && <Grid style={{ color: 'red' }}> Session Time Out. Please add your bank account again</Grid>}*/}
				{/*		{ !hideTrustAccountPlaid && <Grid item xs={12} className={!disabledTrustAccount ? classes.box : classes.disabledBox} >*/}
				{/*			<PlaidLink*/}
				{/*				plaidKey={plaidKey}*/}
				{/*				plaidEnv={plaidEnv}*/}
				{/*				setAccViaPlaid={setTrustingAccPlaid}*/}
				{/*				type='trust'*/}
				{/*				setBankInfo={setBankInfo}*/}
				{/*				persistPlaidBankInfo={handleSaveBankAccount}*/}
				{/*				disabledPlaid={disabledTrustAccount}*/}
				{/*				setPlaidDialog={setPlaidDialog}*/}
				{/*			/>*/}
				{/*		</Grid>}*/}
				{/*	</Grid>*/}
				{/* )}*/}

				{application !== 'OT' && application !== 'OTC' && (
					<Grid item lg={12} xs={12} md={12}>
						<Grid container   className={classes.label}   direction='row' >
							<Grid item lg={1} md={1} xs={1} >Manually</Grid>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							<Grid  item lg={10} md={12} xs={12} style={{ color: 'gray', fontSize: '95%' }}>
								(Upload required bank account document)
								<LightTooltip title={
									<>
										<div>Acceptable Documents:</div>
										<ul className={classes.ul}>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Government-issued document
													</Typography>
												</Grid>
											</li>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Void Check
													</Typography>
												</Grid>
											</li>
											<li >
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Bank Statement
													</Typography>
												</Grid>
											</li>
											<li>
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Bank letter
													</Typography>
												</Grid>
											</li>
											<li>
												<Grid item  xs={12} >
													<Typography className={classes.tooltiptext}>
															Contact
													</Typography>
												</Grid>
											</li>
										</ul>
									</>
								} classes={{ tooltip: classes.customWidth }}>
									<HelpIcon className={classes.contentHelper} fontSize='small'/>
								</LightTooltip>

							</Grid>

						</Grid>
				    {addTrustingAccManually.map((field, idx) => {
							return (
								<div  className={classes.BankInformation}  key={idx} onClick={() => handleEditOpen('trust')}>
				                <BankInformation field={field} img='showbanklogo'  idx={idx} accountType='trust'/>
				            </div>
							);
						})}
						{ (addTrustingAccManually.length && !trustFiles.length) ? <Grid style={{ color: 'red' }}> Please upload your supporting documents again</Grid> : ''}
						{ !hideTrustAccount && <Grid
							item xs={12} className={!disabledTrustAccount ? classes.box : classes.disabledBox}
							 onClick={ !disabledTrustAccount ? () => handleClickOpen('trust') : null}
						>
				    <Grid container direction='row' alignItems='center' justifyContent='center' id={'add-trust-account-manually'}>
				        <Grid item lg={1} xs={2}>
									<ControlPointIcon
										className={!disabledTrustAccount ? classes.controlPointIcon : classes.disabledControlPointIcon}
									/>
								</Grid>
				        <Grid item lg={10} xs={10} className={ !disabledTrustAccount ? classes.boxText : classes.disabledBoxText}>
							Add trust account manually
								</Grid>
				    </Grid>
				    </Grid>}
				 	</Grid>
				)}
			</Grid>)}
			<DebitBlockInfo />
			{open && <SignupDialog
				setOpen={setOpen}
				handleClose={handleClose}
				buttonText={buttonText}
				dialogOrigin='addbankinfo'
				addBankAccount={addBankAccount}
				handleClickOpen={handleClickOpen}
				open={open}
				disableDialogButton={disableDialogButton}
				setBankInfo={setBankInfo}
				title={title}
				setPageError={setBankPageError}
				setFiles={setFiles}
				files={files}
				bankInfo={bankInfo}
				persistFiles={togglePersistFiles(bankType)}
				persitDocumentType={toggleDocumentsType(bankType)}
				documentsType={toggleDocumentType(bankType)}
			/>}

			{plaidDialog && <SignupDialog
				setOpen={setPlaidDialog}
				handleClose={plaidHandleClose}
				dialogOrigin='plaid'
				handleClickOpen={plaidHandleClickOpen}
				open={plaidDialog}
				title={'Plaid Session'}
			/>}

			{openToEdit && <SignupDialog
				setOpen={setOpenToEdit}
				handleClose={updateCanceled}
				buttonText={buttonText}
				dialogOrigin='addbankinfo'
				addBankAccount={updateMerchantBankAccount}
				handleClickOpen={handleEditOpen}
				open={openToEdit}
				disableDialogButton={disableDialogButton}
				setBankInfo={setBankInfo}
				title={title}
				setPageError={setBankPageError}
				setFiles={setFiles}
				files={files}
				bankInfo={bankInfo}
				persistFiles={togglePersistFiles(bankType)}
				persitDocumentType={toggleDocumentsType(bankType)}
				documentsType={toggleDocumentType(bankType)}
				persistedFiles={persistedFiles(bankType)}
				addAccManually={toggleAddAccManually(bankType)}
			/>}
		</Grid>
	);
}

AddBankAccount.propTypes = {
	setDisableButton: PropTypes.func,
	bankInfo: PropTypes.object,
	setBankInfo: PropTypes.func,
	setPageNumber: PropTypes.func,
	plaidOperatingtimeOut: PropTypes.bool,
	SetPlaidOperatingtimeOut: PropTypes.func,
	plaidTrustimeOut: PropTypes.bool,
	SetPlaidTrusttimeOut: PropTypes.func,
	setOperatingFiles: PropTypes.func,
	setTrustFiles: PropTypes.func,
	setOperatingDocumentsType: PropTypes.func,
	operatingDocumentsType: PropTypes.object,
	trustDocumentsType: PropTypes.object,
	operatingFiles: PropTypes.array,
	setOperatingManually: PropTypes.func,
	addOperatingAccManually: PropTypes.array,
	trustFiles: PropTypes.array,
	updateBankAccount: PropTypes.func,
	addTrustingAccManually: PropTypes.array,
	setTrustingAccManually: PropTypes.func
};

export default memo(AddBankAccount);