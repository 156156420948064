import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Chart,
	PieSeries,
} from '@devexpress/dx-react-chart-material-ui';

import { Palette } from '@devexpress/dx-react-chart';

import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { getApiUrl } from '../../utils/getApiUrl';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { appendPendoUrl } from '../../services/Services';

const useStyles = makeStyles({
	startDate: {
		fontWeight: 'bold',
		fontSize: '90%',
		color: 'black'
	},
	nextPayment: {
		fontWeight: 'bold',
		fontSize: '90%',
		color: 'black'
	},
	lastPayment: {
		fontWeight: 'bold',
		fontSize: '90%',
		color: 'black'
	},
	status: {
		fontWeight: 'bold',
		fontSize: '90%',
		color: 'black'
	},
	text: {
		fontSize: '90%',
		color: '#455A64'
	},
	container: {
		marginTop: '1%',
		marginBottom: '1%'
	},
	totalAmount: {
		fontWeight: 'bold',
		fontSize: '90%',
		color: '#455A64'
	},
	chargeAndRemainAndFailed: {
		marginLeft: '3%',
		fontSize: '90%',
		color: '#455A64'
	},
	recurring: {
		fontWeight: 'bold',
		marginLeft: '26%',
		fontSize: '90%'
	},
	occur: {
		fontWeight: 'bold',
		fontSize: '90%',
	},
	paymentMethod: {
		marginLeft: '5%',
		fontSize: '90%'
	},
	parentDiv: {
		backgroundColor: 'white',
		border: '0.5px solid lightGray'
	}
});

function PieChart({
	selectedSchedule = {},
	token = '',
	applicationEntityId = '',
	tableHeight = ''
}) {
	const classes = useStyles();
	const history = useHistory();

	const [ totalCharged, setTotalCharged ] = useState('0.00');
	const [ totalRemaining, setTotalRemaining ] = useState('0.00');
	const [ totalAmount, setTotalAmount ] = useState('0.00');
	const [ totalFailedPayment, setTotalFailedPayment ] = useState('0.00');
	const [ payments, setPayments ] = useState({});
	const [ recurringInfo, setRecurringInfo ] = useState('');

	const data = [
		{ region: 'totalAmount', val: totalAmount },
		{ region: 'totalFailedPayment', val: totalFailedPayment },
		{ region: 'totalCharged', val: totalCharged },
	];
	const [ chartData, setChartData ] = useState(data);
	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	useEffect(() => {
		if (selectedSchedule?.paymentScheduleId && token) {
			async function fetchRecurringPayments() {
				await getRecurringPayments(selectedSchedule?.paymentScheduleId);
			}
			fetchRecurringPayments();
		}
	}, [ selectedSchedule?.paymentScheduleId, token ]);

	useEffect(() => {

		if (applicationEntityId && token) {
			async function fetchScheduleCreationData() {
				await getScheduleCreationData(applicationEntityId);
			}
			fetchScheduleCreationData();
		}
	}, [ applicationEntityId, token, selectedSchedule?.paymentScheduleId ]);
	
	useEffect(() => {

		const getExistingSchedule = async (paymentScheduleId) => {

			try {
				const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_payment_schedule?paymentScheduleId=${paymentScheduleId}`, axiosConfig);
				if (data.code === 200) {
					setRecurringInfo(data.paymentSchedule.description);
				}
			} catch (err) {
				if (err.toString() === 'Error: Network Error') {
					history.push('/errorpage/serverDown');
				}
				console.log(err);
			}
		};
		getExistingSchedule(selectedSchedule.paymentScheduleId);

	},[ selectedSchedule.paymentScheduleId ]);

	const getScheduleCreationData = async (applicationEntityId) => {

		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/applicationentity/get_schedule_creation_data?applicationEntityId=${applicationEntityId}`, axiosConfig);
			if (data.code === 200) {
				const paymentMethod = _.find(data.paymentMethods, (paymentMethod) => { return paymentMethod.tokenId === selectedSchedule?.paymentMethodId; });
				let paymentList = {};
				const type = paymentMethod.hasOwnProperty('creditCardType') ? paymentMethod.creditCardType : 'ACH';
				paymentList = { paymentMethod: `${type}····${paymentMethod.accountNumber}`, name: ` (${paymentMethod.first} ${paymentMethod.last})` };

				setPayments(paymentList);
			}
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const getRecurringPayments = async (paymentScheduleId) => {

		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_recurring_payments?paymentScheduleId=${paymentScheduleId}`, axiosConfig);
			if (data.code === 200) {
				setTotalCharged(data?.totalCharged);
				setTotalRemaining(data?.totalRemaining);
				setTotalAmount(data?.totalAmount);
				setTotalFailedPayment(data?.totalFailedPayment);
			}

		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	useEffect(() => {

		setChartData([
			{ region: 'totalRemaining', val: totalRemaining },
			{ region: 'totalFailedPayment', val: totalCharged },
			{ region: 'totalCharged', val: totalFailedPayment },
		]);

	}, [ totalCharged,totalRemaining, totalFailedPayment, totalAmount ]);

	const ProgressBar = (props) => {

		const containerStyles = {
			height: 8,
			width: '98%',
			backgroundColor: '#CFD8DC',
			borderRadius: 50,
			marginTop: 10,
			marginLeft: 5,
		};

		const fillerStyles = {
			height: '100%',
			width: `${(totalCharged / totalAmount) * 100}%`,
			backgroundColor: '#41B832',
			borderRadius: '50 50 30 5',
			textAlign: 'right'
		};

		const fillerStyles1 = {
			height: '100%',
			width: `${(totalFailedPayment / totalAmount) * 100}%`,
			backgroundColor: '#B00020',
			borderRadius: '0',
			textAlign: 'right'
		};

		const labelStyles = {
			padding: 5,
			color: 'white',
			fontWeight: 'bold'
		};

		return (
			<Grid container direction='row'  style={containerStyles}>
				<div style={fillerStyles}></div>
				<div style={fillerStyles1}></div>
			</Grid>
		);
	};

	return (
		<div style={{ height: `${tableHeight - 1.25}px` }} className={classes.parentDiv}>
			<Grid container direction='column' style={{ marginLeft: '2%', marginTop: '5%', width: '100%' }}>
				<div item lg={11} md={11} style={{ whiteSpace: 'normal' }}>
					<span style={{ fontWeight: 'bold' }}>{selectedSchedule.entity}</span><br/>
					<span style={{ whiteSpace: 'normal' }}>{recurringInfo}</span>
				</div>

				<Divider style={{ marginTop: '2%', marginBottom: '2%', width: '95%', height: '1px', backgroundColor: '#CFD8DC' }}/>

				<Grid container direction='column' style={{ width: '100%' }}>
					<Grid container direction='row'  className={classes.container}>
						<Grid item className={classes.text} lg={4} md={5}>Start Date</Grid>
						<Grid item className={classes.startDate} lg={8} md={7}>{moment(selectedSchedule.startDate).format('ddd, MMM D/YYYY') }</Grid>
					</Grid>
					<Grid container direction='row'  className={classes.container}>
						<Grid item className={classes.text} lg={4} md={5}>Next Payment</Grid>
						<Grid item className={classes.nextPayment} lg={8} md={7}>{moment(selectedSchedule.nextPayment).format('ddd, MMM D/YYYY')}</Grid>
					</Grid>
					<Grid container direction='row' className={classes.container}>
						<Grid item className={classes.text} lg={4} md={5}>Last Payment</Grid>
						<Grid item className={classes.lastPayment} lg={8} md={7}>{moment(selectedSchedule.lastPayment).format('ddd, MMM D/YYYY')}</Grid>
					</Grid>
					<Grid container direction='row'  className={classes.container}>
						<Grid item className={classes.text} lg={4} md={5}>Status</Grid>
						<Grid item lg={8} md={7} className={classes.status} style={selectedSchedule.status === 'Canceled' ? { color: 'red' } : null}>{selectedSchedule.status}</Grid>
					</Grid>
				</Grid>

				<Divider style={{ marginBottom: '2%',  width: '95%', height: '1px', backgroundColor: '#CFD8DC' }}/>

				<Grid container direction='row' style={{ width: '95%' }}>
					<Grid item className={classes.text} lg={4} md={5}> Recurrence<br/>Progress</Grid>
					<Grid item lg={8} md={7}>
						<Grid container direction='column' >
							<Grid item className={classes.recurring} > &nbsp;{selectedSchedule.recurring}</Grid>
							<Grid item className={classes.occur} > &nbsp;{selectedSchedule.occur.split('/')[0]} of
								{selectedSchedule.occur.split('/')[1]} occurrence(s)</Grid>
							<Grid item >{ProgressBar()}</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Divider style={{ marginTop: '2%', marginBottom: '2%', width: '95%', height: '1px', backgroundColor: '#CFD8DC' }}/>

				<Grid container direction='row' className={classes.container} >
					<Grid item className={classes.text} md={12} lg={4}>Pymt.Method</Grid>
					<Grid item className={classes.paymentMethod} lg={8} md={12} >
						<span style={{ fontWeight: 'bold' }} >{payments?.paymentMethod}</span>
						{payments?.name}
					</Grid>
				</Grid>

				<Divider style={{ marginTop: '2%', marginBottom: '2%',  width: '95%', height: '1px', backgroundColor: '#CFD8DC' }}/>
				<Grid container='row' style={{ marginTop: '2%', marginBottom: '2%' }}>
					<Grid item lg={4} md={5}>
						<Chart data={chartData}
							height={100}
							width={100}
							style={{ marginTop: '10%' }}
						>
							<Palette scheme={[ '#CFD8DC', '#41B832', '#B00020' ]} />
							<PieSeries
								valueField="val"
								argumentField="region"
								innerRadius={0.7}
								outerRadius={1}
							/>
							<div className={classes.text}> Total Payment</div>
						</Chart>
					</Grid>
					<Grid item  lg={6} md={7}>
						<Grid container direction='column'>
							<Grid item className={classes.totalAmount}>${totalAmount}</Grid>
							<Grid item style={{ marginTop: '16%' }}>
								<Grid container direction='row'>
									<Box sx={{ width: '2vh', height: '2vh', backgroundColor: '#41B832' }}/>
									<div className={classes.chargeAndRemainAndFailed}>Charged ${totalCharged}</div>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container direction='row'>
									<Box sx={{ width: '2vh', height: '2vh', backgroundColor: '#CFD8DC' }}/>
									<div className={classes.chargeAndRemainAndFailed}>Remaining ${totalRemaining}</div>
								</Grid>
							</Grid>
							<Grid item>
								<Grid container direction='row'>
									<Box sx={{ width: '2vh', height: '2vh', backgroundColor: '#B00020' }}/>
									<div className={classes.chargeAndRemainAndFailed}>Failed ${totalFailedPayment}</div>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

PieChart.propTypes = {
	selectedSchedule: PropTypes.object,
	token: PropTypes.string,
	applicationEntityId: PropTypes.string,
	tableHeight: PropTypes.string
};

export default memo(PieChart);
