import axios from 'axios';
import React, { useEffect, useState, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import { useQuery } from '../hooks/useQuery';
import { getMerchantData, initializePendo } from '../services/Services';
import SharedForm from '../shared/SharedForm';
import { getApiUrl } from '../utils/getApiUrl';

const useStyles = makeStyles((theme) => ({
	page: {
		backgroundColor: COLORS.grayContainer,
	},
	root: {
		flexGrow: 1,
		fontFamily: 'Roboto,sans-serif',
		// marginTop: '10px',
		backgroundColor: COLORS.secureBackground
	},
	paper: {
		padding: theme.spacing(3),
		color: theme.palette.text.secondary,
	},
	container: {
		maxWidth: '100%'
	}
}));

function PaymentMethod() {

	const classes = useStyles();
	const history = useHistory();
	const initialBillingAddressState = {
		name: '',
		address1: '',
		city: '',
		state: '',
		postalCode: '',
		country: '',
		email: '',
	};

	const [ payerId, setPayerId ] = useState('');
	const [ tempPaymentMethodId, setTempPaymentMethodId ] = useState('');
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ validateRequired, setValidateRequired ] = useState(false);
	const [ billingAddressInfo, setBillingAddressInfo ] = useState(initialBillingAddressState);

	const params = useParams();
	const query = useQuery();
	const merchantId = query.get('merchantId');

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${params.merchant}`
		}
	};

	useEffect(() => {
		setPayerId(query.get('payerId'));
		setTempPaymentMethodId(query.get('tempPaymentMethodId'));
	}, [ params ]);

	useEffect(() => {
		if (payerId) {
			async function fetchBillingAddress() {
				await getBillingAddress(tempPaymentMethodId);
			}

			if (tempPaymentMethodId) fetchBillingAddress();

			async function fetchMerchant(axiosConfig) {
				const customer = await getMerchantData(merchantId, axiosConfig);
				initializePendo(customer?.application, customer?.customerId, customer?.customerName);
			}

			fetchMerchant(axiosConfig);
		}
	}, [ payerId ]);

	const getBillingAddress = async (tempPaymentMethodId) => {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_payment_billing_address?tempPaymentMethodId=${tempPaymentMethodId}`, axiosConfig);
			if (data.code === 200) {
				const { address, email, name, city, country, state, postalCode } = data.result;
				setBillingAddressInfo({ name, address1: address, city, state, postalCode, country, email });
			}
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}

			console.log(err);
		}
	};

	const requiredFieldValidated = (billingAddressInfo) => {
		const requiredFields = Object.keys(billingAddressInfo);
		for (const field of requiredFields) {

			if (field !== 'address2' && field !== 'username' && !billingAddressInfo[field]) {
				setValidateRequired(true);
				setIsEditMode(true);
				return false;
			}
		}
		setValidateRequired(false);
		return true;
	};

	const handleOnEdit = () => {
		setIsEditMode(!isEditMode);
	};

	return (
		<div className={classes.page}>
			<Container className={classes.container}>
				<Card className={classes.root}>
					{<Paper className={classes.paper}>
						<Container maxWidth='xl'>
							<SharedForm
								requiredFieldValidated={requiredFieldValidated}
								validateRequired={validateRequired}
								requestOfPayment={billingAddressInfo}
								billingAddressInfo={billingAddressInfo}
								handleOnEdit={handleOnEdit}
								isPayNow={false}
								buttonText='SAVE'
								merchantId={merchantId}
								payerId={payerId}
								tempPaymentMethodId={tempPaymentMethodId}
								axiosConfig={axiosConfig}
							/>
						</Container>
					</Paper>}
				</Card>
			</Container>
		</div>
	);
}

export default memo(PaymentMethod);
