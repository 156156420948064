import { getApiUrl } from '../utils/getApiUrl';
import axios from 'axios';

class PayNowService {
	constructor() {}

	async sendEmailNewLinkRequest(merchantInfo, axiosConfig) {

		try {
			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/paynow_expired_token_email`, merchantInfo , axiosConfig);

			return {
				status: 'success',
				isNewPayNowLinkRequested: data.isNewPayNowLinkRequested
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

}

export default new PayNowService();