import React from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

const GetChip = ({
	selectedMuiItems = 0,
	selectedNumber = 0,
	handleStartDateChange = () => {},
	handleToDateChange = () => {},
	dateOne = '',
	label = '',
	dateTwo = '',
	setSelectedMuiItems = ''

}) => {

	const date = (startDate, endDate) => {
		handleStartDateChange(startDate);
		if (!endDate) handleToDateChange(startDate);
		else handleToDateChange(endDate);
	};

	return (<Chip
		style={selectedMuiItems === selectedNumber ? {
			margin: '3% 2% 2% 2%',
			border: '1px solid #AFCFF2',
			borderRadius: '20px',
			padding: '1% 2% 1% 2%',
			color: '#064180',
			backgroundColor: '#E2F1FE'
		} : {
			margin: '3% 2% 2% 2%',
			borderRadius: '20px',
			padding: '1% 2% 1% 2%',
		}
		}
		label={label}
		variant="outlined"
		onClick={() => {
			date(dateOne, dateTwo);
			setSelectedMuiItems(selectedNumber);}}
	/>);

};
GetChip.propTypes = {
	selectedMuiItems: PropTypes.number,
	selectedNumber: PropTypes.number,
	handleStartDateChange: PropTypes.func,
	handleToDateChange: PropTypes.func,
	dateOne: PropTypes.string,
	label: PropTypes.string,
	dateTwo: PropTypes.string,
	setSelectedMuiItems: PropTypes.string
};

export default GetChip;