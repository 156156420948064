import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _ from 'lodash';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import visa from '../../assets/images/visa.png';
import amex from '../../assets/images/american-express.png';
import mastercard from '../../assets/images/mastercard.png';
import discover from '../../assets/images/discover.png';
import PropTypes from 'prop-types';
import { IsRefundDisbursement } from '../constants/Disbursement';
import Tooltip from '@material-ui/core/Tooltip';
import StraightIcon from '@mui/icons-material/Straight';

const useStyles = makeStyles((theme) => ({
	transactionDetails: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: 18,
		lineHeight: '14px',
		color: '#404547'
	},
	paymentType: {
		height: 'auto',
		width: 30,
		marginTop: 5
	},
	customTooltip: {
		maxWidth: 400,
		fontSize: 14,
		color: '#545353',
		backgroundColor: '#ffffea',
		boxShadow: '2px 5px 5px #545353',
		left: '2%',
		bottom: 15,
		position: 'relative',
		whiteSpace: 'pre-line'
	},
	feeIcons: {
		display: 'inline-flex',
		position: 'relative',
		alignItems: 'center'
	},
	internationIcon: {
		color: '#1eadff',
		position: 'relative',
		bottom: 3
	},
	upSellIcon: {
		position: 'relative',
		color: '#ff7c7a',
	}
}));

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
        theme.palette.mode === 'dark'
        	? 'rgba(255, 255, 255, .05)'
        	: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const DisbursementEntries = ({ disbursementEntriesDetails = {}, merchantInfo = {} }) => {

	const [ expanded1, setExpanded1 ] = useState('');
	const classes = useStyles();

	const handleChange2 = (panel) => (event, newExpanded1) => {
		setExpanded1(newExpanded1 ? panel : false);
	};

	const renderAccountTypeLogo = (type, number) => {
		if (!type) return null;

		if (type.indexOf('Checking') > -1 || type.indexOf('Saving') > -1) return <AccountBalanceTwoToneIcon color='primary' fontSize='large' className={classes.bankAccount} />;

		switch (type) {
			case 'Visa': return (
				<Tooltip title='Visa' placement="bottom-start" classes={{ tooltip: classes.customTooltip }}>
					<Grid container direction='row'>
						<img src={visa} className={classes.paymentType}/>
						<span style={{ marginTop: '5px', marginLeft: '4px' }}>{number}</span>
					</Grid>
				</Tooltip>
			);
			case 'American Express': return ((
				<Tooltip title='American Express' placement="bottom-start" classes={{ tooltip: classes.customTooltip }}>
					<Grid container direction='row'>
						<img src={amex} className={classes.paymentType}/>
						<span style={{ marginTop: '5px', marginLeft: '4px' }}>{number}</span>
					</Grid>
				</Tooltip>
			));
			case 'MasterCard': return (
				<Tooltip title='MasterCard' placement="bottom-start" classes={{ tooltip: classes.customTooltip }}>
					<Grid container direction='row'>
						<img src={mastercard} className={classes.paymentType}/>
						<span style={{ marginTop: '5px', marginLeft: '4px' }}>{number}</span>
					</Grid>
				</Tooltip>
			);
			case 'Discover': return (
				<Tooltip title='Discover' placement="bottom-start" classes={{ tooltip: classes.customTooltip }}>
					<Grid container direction='row' >
						<img src={discover} className={classes.paymentType}/>
						<span style={{ marginTop: '5px', marginLeft: '4px' }}>{number}</span>
					</Grid>
				</Tooltip>
			);

			default: return '';
		}

	};

	const getFeesToolTipMessage = (transactionInfo) => {

		let message = '';

		if (transactionInfo?.isUpSell) message += `3.5% fee for AmEx/Corporate Card tran.\n`;
		if (transactionInfo?.isInternational) message += `1.5% fee for international tran.`;

		return message;
	}

	const getFeesIcons = (transactionInfo) => {
		return (
			<span className={classes.feeIcons}>
				{transactionInfo?.isInternational && <Typography style={{ fontSize: '18px', left: transactionInfo.isUpSell ? 5: -4 }} className={classes.internationIcon}>¡</Typography>}
				{transactionInfo?.isUpSell && <StraightIcon style={{ fontSize: '17px', top: !transactionInfo.isInternational ? 2: 0 }} className={classes.upSellIcon}/>}
			</span>
		)

	}

	const amount = parseFloat(disbursementEntriesDetails.transactionInfo?.originalAmount) ?? 0
	const surcharge = parseFloat(disbursementEntriesDetails.transactionInfo?.surcharge) ?? 0
	const subTotal = parseFloat(disbursementEntriesDetails.transactionInfo?.amount) ?? 0
	const fees = disbursementEntriesDetails?.transactionInfo?.fees ? parseFloat(disbursementEntriesDetails?.transactionInfo?.fees) : 0
	const netTotal = subTotal - fees

	return (
		<List component="div" disablePadding>
			<Accordion expanded1={expanded1 === 'panel2'} onChange={handleChange2('panel2')} >
				<AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ width: '100%' }}>
					<Grid container direction='row' alignItems='center'>
						<Grid item lg={2} md={2} sm={2}>
							<Typography sx={{ fontSize: '15px' }}>{moment(disbursementEntriesDetails?.eventId.captured).format('MM/DD/YYYY h:mm a')}</Typography>
						</Grid>
						<Grid lg={10} md={10} sm={10}>
							<Grid container direction='row' spacing={1} alignItems='center' justifyContent={'space-between'}>
								<Grid item lg={2} md={2}>
									<Typography sx={{ fontSize: '15px' }}>{disbursementEntriesDetails.transactionInfo[merchantInfo.entity]}</Typography>
								</Grid>
								<Grid item lg={1} md={1}>
									<Typography sx={{ fontSize: '15px' }}>
										{renderAccountTypeLogo(disbursementEntriesDetails.transactionInfo?.method, disbursementEntriesDetails.transactionInfo?.accountNumber)}
									</Typography>
								</Grid>
								<Grid item lg={1} md={1}>
									<Typography sx={{ fontSize: '15px' }}>
										{disbursementEntriesDetails.transactionInfo?.payee}
									</Typography>
								</Grid>
								<Grid item lg={1} md={1}>
									{ !IsRefundDisbursement(disbursementEntriesDetails?.event)
									&& <Typography sx={{ fontSize: '15px' }}>
										{amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
									</Typography>
									}
								</Grid>
								<Grid item lg={1} md={1}>
									{ !IsRefundDisbursement(disbursementEntriesDetails?.event)
									&& <Typography sx={{ fontSize: '15px' }}> 
										{surcharge.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
									</Typography>
									}
								</Grid>
								<Grid item lg={1} md={1}>
									{ !IsRefundDisbursement(disbursementEntriesDetails?.event)
									&& <Typography sx={{ fontSize: '15px' }}>
										{subTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
									</Typography>
									}
								</Grid>
								<Tooltip title={getFeesToolTipMessage(disbursementEntriesDetails?.transactionInfo)} placement="bottom-start" classes={{ tooltip: classes.customTooltip }}>
									<Grid item lg={1} md={1}>
										{ !IsRefundDisbursement(disbursementEntriesDetails?.event)
										&& <Typography sx={{ fontSize: '15px' }}>
											{getFeesIcons(disbursementEntriesDetails?.transactionInfo)}
											{`-${fees.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
										</Typography>
										}
									</Grid>
								</Tooltip>
								<Grid item lg={1} md={1}>
									<Typography sx={{ fontSize: '15px' }}>
                                                    -{ IsRefundDisbursement(disbursementEntriesDetails.event) ? Math.abs(disbursementEntriesDetails.amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) :
											(0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
									</Typography>
								</Grid>
								<Grid item lg={1} md={1}>
									{ !IsRefundDisbursement(disbursementEntriesDetails?.event)
									&& <Typography sx={{ fontSize: '15px' }}>
										{netTotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
									</Typography>
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AccordionSummary>
				<AccordionDetails style={{ backgroundColor: '#ECEDED' }}>
					<Grid container direction="row" spacing={2} style={{ margin: 'auto', width: '90%' }}>
					{ IsRefundDisbursement(disbursementEntriesDetails.event) ?
						<Grid item lg={3} className={classes.transactionDetails}>
                                       Type:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
								{ disbursementEntriesDetails.event === 8 || disbursementEntriesDetails.event === 22 ? 'Refund' :
								disbursementEntriesDetails.event === 11 ? 'Dispute' : disbursementEntriesDetails.event === 23 ? 'Return' : '' }
							</span>
						</Grid> : null}
						<Grid item lg={IsRefundDisbursement(disbursementEntriesDetails.event)? 3: 4} className={classes.transactionDetails}>
                                        Confirmation Number:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
								{disbursementEntriesDetails.transactionInfo?.confirmationNumber}
							</span>
						</Grid>
						<Grid item lg={IsRefundDisbursement(disbursementEntriesDetails.event)? 3: 4} className={classes.transactionDetails}>
							{merchantInfo.name}:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
								{disbursementEntriesDetails.transactionInfo[merchantInfo.name]}
							</span>
						</Grid>
						<Grid item lg={IsRefundDisbursement(disbursementEntriesDetails.event)? 3: 4} className={classes.transactionDetails}>
							{merchantInfo.entityId}:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
								{disbursementEntriesDetails.transactionInfo[merchantInfo.entityId]}
							</span>
						</Grid>
					</Grid>
					<Grid container direction="row" spacing={2} style={{ margin: 'auto', width: '90%' }}>
						<Grid item lg={4} md={4} className={classes.transactionDetails}>
                                        Bank account:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
                                            op...{disbursementEntriesDetails.transactionInfo?.merchantAccountInfo?.account}
							</span>
						</Grid>
						<Grid item lg={4} md={4} className={classes.transactionDetails}>
                                        Tran Id:&nbsp;
							<span style={{  fontWeight: 'bold' }}>
								{disbursementEntriesDetails.transactionInfo?.transactionId}
							</span>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</List>);
};

DisbursementEntries.propTypes = {
	disbursementEntriesDetails: PropTypes.object,
	merchantInfo: PropTypes.object
};

export default DisbursementEntries;
