import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../constants/Colors';

import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import SignUpService from '../../services/SignUpService';
import AboutOwnerFields from './AboutOwnerFields';
import { Typography } from '@material-ui/core';
import InProgress from '../../forms/InProgress';

const useStyles = makeStyles((theme) => ({
	container: {
		height: 'auto',
		width: '100%',
	},
	lastname: {
		marginLeft: 10
	},
	required: {
		color: '#B90023',
		// fontSize: '2vh',
		letterSpacing: 0,
		// lineHeight: '2vh',
		textAlign: 'right'
	},
	title: {
		color: '#2D4B69',
		// fontSize: '2vh',
		fontWeight: 'bold',
		letterSpacing: 0,
		// lineHeight: '2vh',
		marginBottom: '1%'
	},
	remove: {
		color: '#E14126',
		fontWeight: 500,
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: '0.50%',
		cursor: 'pointer'
	},
	addressText: {
		color: '#455A64',
		marginTop: 5
	},
	divider: {
		backgroundColor: COLORS.dividerColor
	},
	text: {
		color: COLORS.darkGray,
		marginTop: '3%'
	},
	progressBar: {
		height: '70vh',
		marginTop: '3%'
	},
	progressBarText: {
		color: 'gray',
		marginTop: '5%'
	}
}));

// eslint-disable-next-line require-jsdoc
function AboutOwner({
	signupFields = {},
	setSignupFields = () => { },
	setDisableButton = () => { },
	ownerNumber = 1,
	removeOwner = () => { },
	aboutOwnerPage = [ 1 ],
	aboutOwner = {},
	setAboutOwner = () => {},
	field = 1,
	token = '',
	applicationCustomerId = '',
	setAboutOwnerError = () => {},
	aboutOwnerError = {},
	setresetAboutOwnerPage = () => {},
	resteAboutOwnerPageNumber = true,
	disableOwnerShip = false,
	defaultValueForOwnerShip = '',
	setShowProgressBar = () => {},
	showProgressBar = true
}) {
	const classes = useStyles();
	const [ aboutOwnerSavedData, setAboutOwnerSavedData ] = useState({});
	const history = useHistory();

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	useEffect(() => {
		delete aboutOwner[`middleName${field}`];

		const checkIsSateEmpty = () => {
			const values = _.values(aboutOwner);
			if (values.length) {
				for (let x = 0; x < values.length; x++) {
					if (checkForError(aboutOwnerError) || (String(values[x]) === 'null' || String(values[x]) === 'undefined' || _.isEmpty(String(values[x])))) {
						setDisableButton(true);
						return;
					} else setDisableButton(false);
				}
			}
		};
		checkIsSateEmpty();
		return () => { setDisableButton(true);};
	});

	/*
    * get merchant data from database
    * */
	useEffect(()=> {
		if (token && applicationCustomerId) {
			const getMerchantData = async (applicationCustomerId) => {
				setShowProgressBar(true);
				const merchantData = await SignUpService.getMerchantInfo(applicationCustomerId, axiosConfig);

				if (merchantData.status === 'success') {
					setAboutOwnerSavedData(merchantData?.data?.merchantInfo);
					setresetAboutOwnerPage(merchantData?.data?.merchantInfo);
					setShowProgressBar(false);
				} else {
					if (merchantData.err.toString() === 'Error: Network Error') {
						history.push('/errorpage/serverDown');
					}
				}

			};
			getMerchantData(applicationCustomerId);
		}
	}, [ token, applicationCustomerId ]);

	const checkForError = (aboutOwnerError => {
		const values = _.values(aboutOwnerError);
		if (values.length) {
			for (let x = 0; x < values.length; x++) {
				if (values[x]) return true;
			}
			return false;
		}
	});

	useEffect(() => {
		if (resteAboutOwnerPageNumber) window.scrollTo(0, 0);
	}, [ resteAboutOwnerPageNumber ]);

	if (showProgressBar) {
		return (
			<div className={classes.progressBar}>
				<InProgress/>
				<Grid container alignContent='center' alignItems='center' justifyContent='center' >
					<Grid item className={classes.progressBarText}>Please wait while data is loading...</Grid>
				</Grid>
			</div>
		);
	}

	return (
		<>
			<Grid item md={12} lg={12} xs={12} className={classes.text}><Typography>
			To help the government fight the funding of terrorism and money laundering activities,
			federal law requires all financial institutions to obtain, verify,
			and record information that identifies each individual or business who opens an account.
			What this means for you: when you open an account, we will ask for your identifiable information including your full name,
			address, date of birth, and other business information that will allow us to identify you.
			We may also ask to see your Identification Card, Driver's License, and/or other identifying documents.
			</Typography>
			</Grid>

			<Grid item md={12} lg={12} xs={12} className={classes.text}><Typography>
		The following information must be provided for the Sole Proprietors or each individual, if any,
		who directly or indirectly owns twenty-five percent (25%) or more of the ownership interest of legal Entity in this application as well as
		an individual with significant responsibility. A legal Entity includes a general partnership, a corporation, limited liability company
		or other entity that is formed by a filing of a public document with a secretary of state or similar office,
		and any similar business entity formed in the United States.
			</Typography>
			</Grid>
			{aboutOwnerPage.map((field, idx) => {
				return (
					<div key={field}>
						<AboutOwnerFields
							setDisableButton={setDisableButton}
							aboutOwnerPage={aboutOwnerPage}
							field={field}
							signupFields={signupFields}
							setSignupFields={setSignupFields}
							ownerNumber={idx + 1}
							removeOwner={removeOwner}
							aboutOwner={aboutOwner}
							setAboutOwner={setAboutOwner}
							token={token}
							applicationCustomerId={applicationCustomerId}
							setAboutOwnerError={setAboutOwnerError}
							aboutOwnerError={aboutOwnerError}
							setresetAboutOwnerPage={setresetAboutOwnerPage}
							resteAboutOwnerPageNumber={resteAboutOwnerPageNumber}
							disableOwnerShip={disableOwnerShip}
							defaultValueForOwnerShip={defaultValueForOwnerShip}
							aboutOwnerSavedData={aboutOwnerSavedData}
						/>
					</div>
				);
			})}
		</>
	);
}
AboutOwner.propTypes = {
	signupFields: PropTypes.object,
	setSignupFields: PropTypes.func,
	setDisableButton: PropTypes.func,
	ownerNumber: PropTypes.number,
	removeOwner: PropTypes.func,
	aboutOwnerPage: PropTypes.array,
	aboutOwner: PropTypes.object,
	setAboutOwner: PropTypes.func,
	field: PropTypes.number,
	params: PropTypes.object,
	setAboutOwnerError: PropTypes.func,
	aboutOwnerError: PropTypes.object,
	setresetAboutOwnerPage: PropTypes.func,
	resteAboutOwnerPageNumber: PropTypes.bool,
	disableOwnerShip: PropTypes.bool,
	defaultValueForOwnerShip: PropTypes.any,
	setShowProgressBar: PropTypes.func,
	showProgressBar: PropTypes.bool
};

export default memo(AboutOwner);