import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '@material-ui/core/Button';
import { Divider } from '@mui/material';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles((theme) => ({
	submitConfirmation: {
		fontWeight: 'bold',
		fontSize: '20px',
		marginTop: '2%',
		marginBottom: '1%'
	},
	divider: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		width: '10%',
		marginTop: '2%'
	},
	container: {
		color: COLORS.blueButtons,
		marginTop: '2%'
	},
	launchButton: {
		fontSize: '100%',
		marginLeft: '2%',
		'&:hover': {
			background: COLORS.onHoverBlueButtons,
		},
	},
	launchIcon: {
		color: COLORS.blueButtons,
		marginBottom: 3
	},
	launchText: {
		color: COLORS.blueButtons,
		textTransform: 'none'
	}

}));

const SignUpCongratulationsPage = () => {

	const classes = useStyles();

	return (
		<>
			<Container  maxWidth='lg'>
				<Grid className={classes.submitConfirmation}> Submit Confirmation</Grid>
				<Divider className={classes.divider}/>
				<Grid style={{ marginTop: '2%' }}>Your APX account has been submitted successfully and once it is approved, you will receive a welcome email.</Grid>
				<Grid style={{ marginTop: '2%' }}>Not confident with APX?</Grid>
				<Grid>We are here to help you anytime. Book a training session with our support team.</Grid>
				<Grid container className={classes.container} direction='row'>
					<Grid item lg={4}  >
						<Button id={'book-a-training-session'} onClick={() => window.open('https://calendly.com/apx-6by-proserv','_blank')} className={classes.launchIcon}>
						    <Typography className={classes.launchText}>Book a training session</Typography>
						    <LaunchIcon className={classes.launchIcon}/>
						</Button>
					</Grid>
				</Grid>
				<Grid style={{ marginTop: '2%' }}>The process is completed. You can close this window now.</Grid>
			</Container>
		</>

	);
};

SignUpCongratulationsPage.propTypes = {
};

export default SignUpCongratulationsPage;