import React, { useState, memo, useEffect } from 'react';

import PropTypes from 'prop-types';

import axios from 'axios';
import { getApiUrl } from '../../utils/getApiUrl';
import { formatAmountCurrency } from '../../utils/formatUtils';
import AbacusTable from '../../shared/AbacusTable';
import Messages from '../../forms/Messages';
import { COLORS } from '../../constants/Colors';
import Summary from '../Summary';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Loader from '../landingpage/Loader';

let themeOptions = {
	overrides: {
		MUIDataTableToolbarSelect: {
			root: {
				display: 'none',
			},
		},
		MUIDataTableHeadCell: {
			root: {
				backgroundColor: `${COLORS.lightGray} !important`,
				borderColor: COLORS.grayBorder,
				color: `${COLORS.darkGrayText} !important`,
				fontSize: 15,
				lineHeight: '13px',
			}
		},
		MuiTableRow: {
			root: {
				'&$selected': {
					backgroundColor: `${COLORS.blueButtons} !important`,
					'& > .MuiTableCell-root': {
						color: 'white !important',
					},
				},
			},
		},
		MuiTableCell: {
			root: {
				fontSize: 16,
				lineHeight: '16px',
				color: `${COLORS.darkBlackText} !important`,
				padding: '8px'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontSize: "14px",
				color: `${COLORS.lightGray}`,
				backgroundColor: `${COLORS.darkGrayText}`
			}
		}
	}
};

const useStyles = makeStyles((theme) => ({
	btnRetry: {
		backgroundColor: COLORS.whiteBackground,
		color: COLORS.blackText,
		cursor: 'pointer',
		border: `2px solid ${COLORS.grayBorder}`,
		padding: '0px',
		height: '22px',
		textTransform: 'none',
		'&:hover': {
			backgroundColor: COLORS.blueButtons,
			color: COLORS.whiteText,
		},
		'&:focus': {
			backgroundColor: COLORS.blueButtonFocused,
			color: COLORS.whiteText,
		},
		'&:disabled': {
			backgroundColor: COLORS.lightBackgroundRed,
			color: COLORS.darkGrayText
		},
	},
	success: {
		color: COLORS.greenSuccessIcon,
		padding: '0px',
		height: '22px',
		fontSize: '22px'
	},
	failed: {
		color: COLORS.error,
		padding: '0px',
		height: '22px',
		fontSize: '22px',
		verticalAlign: 'middle'
	},
	iconRetrying: {
		color: COLORS.darkGrayText,
		padding: '0px',
		height: '22px',
		fontSize: '22px'
	}
}));

// eslint-disable-next-line require-jsdoc
function PaymentsList({
	payments = [],
	totalCharged = 0,
	totalRemaining = 0,
	totalAmount = 0,
	token = '',
	isLoading = false,
}) {
	const classes = useStyles();

	const [isRetryingPayment, setIsRetryingPayment] = useState(false);
	const [isMessageOpen, setIsMessageOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState('');

	useEffect(() => {
		if (isLoading) {
			themeOptions = {
				overrides: {
					...themeOptions.overrides,
					MuiTableCell: {
						root: {
							padding: '8px 0',
							border: 'none !important'
						},
					}
				}
			}
		}
	}, [isLoading]);

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	const successfulPaymentStatus = 'Successful';
	const failedPaymentStatus = 'Failed';
	const retryingPaymentStatus = 'Retrying...';

	const columns = [
		{
			label: '#',
			name: 'index',
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => { return { style: { fontWeight: 'bold' } }; },
				setCellProps: () => { return { style: { padding: '8px' } }; },
			}
		},
		{
			label: '',
			name: '',
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: (index) => {
					if (payments[index]?.isRetrying) {
						return (
							<span><InfoOutlinedIcon className={classes.iconRetrying} /></span>
						);
					}
					else if (payments[index]?.status === successfulPaymentStatus) {
						return (
							<span><CheckCircleIcon className={classes.success} /></span>
						);
					}
					else if (payments[index]?.status === failedPaymentStatus) {
						return (
							<Tooltip title={payments[index]?.failureMessage ?? ''}>
								<span><ErrorOutlineIcon className={classes.failed} /></span>
							</Tooltip>
						);
					}
				},
				setCellProps: () => { return { style: { padding: '0px' } }; },
			}
		},
		{
			label: 'Date',
			name: 'paymentDate',
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => { return { style: { fontWeight: 'bold' } }; },
				setCellProps: () => { return { style: { padding: '8px' } }; },
			}
		},
		{
			label: 'Amount',
			name: 'amount',
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: (index) => {
					return (
						<span>{formatAmountCurrency(payments[index]?.amount)}</span>
					);
				},
				setCellHeaderProps: () => { return { style: { fontWeight: 'bold', textAlign: 'right', paddingRight: '16px' } }; },
				setCellProps: () => {
					return {
						style: {
							paddingTop: '8px',
							paddingBottom: '8px',
							paddingLeft: '8px',
							paddingRight: '16px',
							textAlign: 'right'
						}
					};
				},
			}
		},
		{
			label: 'Status',
			name: 'status',
			options: {
				filter: false,
				sort: false,
				setCellHeaderProps: () => { return { style: { fontWeight: 'bold' } }; },
				setCellProps: () => { return { style: { padding: '8px' } }; },
			}
		},
		{
			label: '',
			name: '',
			options: {
				filter: false,
				sort: false,
				customBodyRenderLite: (index) => {
					if (payments[index]?.status === failedPaymentStatus) {
						if (payments[index]?.isRetrying) {
							return (
								<span></span>
							);
						}
						else {
							return (
								<span>
									<Button id={'retry'} disabled={(isRetryingPayment ?? true)} onClick={() => onClickRetryPayment(payments[index])} className={classes.btnRetry}>Retry</Button>
								</span>
							);
						}
					}
				},
				setCellProps: () => { return { style: { padding: '2px' } }; },
			}
		},
	];

	const options = {
		download: false,
		filter: false,
		print: false,
		search: false,
		tableBodyHeight: '50vh',
		tableBodyMaxHeight: '50%',
		viewColumns: false,
		selectableRows: 'single',
		selectableRowsHideCheckboxes: true,
		// selectableRowsOnClick: true,
		pagination: false,
		customFooter: () => {
			return (
				<Summary
					totalCharged={totalCharged}
					totalRemaining={totalRemaining}
					totalAmount={totalAmount}
				/>
			);
		},
		setRowProps: (row) => {
			if ((row[4] !== failedPaymentStatus) && (row[4] !== successfulPaymentStatus) && (row[4] !== retryingPaymentStatus)) {
				return {
					style: { opacity: '0.3' }
				};
			}

			if (row[4] === failedPaymentStatus) {
				return { style: { background: COLORS.lightBackgroundRed } };
			} else if (row[4] === successfulPaymentStatus) {
				return { style: { background: COLORS.lightBackgroundGreen } };
			} else if (row[4] === retryingPaymentStatus) {
				return { style: { background: COLORS.lightBackgroundGray } };
			} else {
				return { style: { background: COLORS.whiteBackground } };
			}
		},
		textLabels: {
			body: {
			  noMatch: <Loader isLoading={isLoading} message='Getting Recurring Payments Data ...' />
			}
		}
	};

	const handleMessageClose = () => {
		setIsMessageOpen(false);
	};

	const onClickRetryPayment = async (payment) => {
		if (isRetryingPayment) {
			return;
		}
		if (!payment || payment?.isRetrying) {
			return;
		}

		await retryFailedPayment(payment);
	};

	const retryFailedPayment = async (payment) => {
		setIsRetryingPayment(true);
		payment.isRetrying = true;
		payment.status = retryingPaymentStatus;

		await axios.post(`${getApiUrl()}/api/merchantwebapi/recurringpayments/retry_failed_payment?recurringPaymentId=${payment.recurringPaymentId}`, {}, axiosConfig)
			.then(response => {
				if (response.status === 200) {
					let retriedRecurringPayment = response.data?.retriedRecurringPayment;
					payment.status = retriedRecurringPayment?.isSuccess ? successfulPaymentStatus : failedPaymentStatus;
					if (retriedRecurringPayment?.isSuccess) {
						payment.paymentDate = moment(retriedRecurringPayment.paymentDate).format('LLLL');
					}

					setMessage('Retrying failed payment has completed successfully');
					setIsMessageOpen(true);
					setSeverity('success');
					return true;
				} else {
					throw new Error(`Retrying failed payment failed with status: ${response.status}.`);
				}
			})
			.catch(error => {
				payment.status = failedPaymentStatus;

				let errorMessage = error?.response?.data?.error ? error?.response?.data?.error?.toString() : error?.toString();
				console.log(error?.toString());
				setMessage(errorMessage);
				setIsMessageOpen(true);
				setSeverity('error');
				return false;
			})
			.finally(() => {
				payment.isRetrying = false;
				setIsRetryingPayment(false);
			});
	};

	return (
		<div>
			<Messages message={message} severity={severity} isOpen={isMessageOpen} handleClose={handleMessageClose} />
			<AbacusTable
				columns={columns}
				data={payments}
				options={options}
				themeOptions={themeOptions}
			/>
		</div>
	);
}

PaymentsList.propTypes = {
	emptyDataMessage: PropTypes.string,
	payments: PropTypes.array,
	onRowClick: PropTypes.func,
	isLoading: PropTypes.bool
};

export default memo(PaymentsList);