export const COUNTRIES = [
	'USA'
];
export const COUNTRIES_PAYMENTS = [
	{ key: 'Canada', value: 'CAN' },
	{ key: 'United Kingdom', value: 'GBR' },
	{ key: 'United States', value: 'USA' },
	{ key: 'Others', value: 'Others' }
];

export const STATES = [ 'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY' ];
export const STATESCA = [ 'NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU' ];
export const BUSINESSTYPE = [ 'SOLE PROPRIETOR', 'CORP', 'LLC', 'PARTNER', 'NONPROFIT', 'GOV' ];
export const BUSINESSCATEGORY = [ '8931-Accounting,Auditing, bookkeeping Service', '7276-Tax preparation Service', '8111-Legal Service and Attorneys' ];
export const ENVIRONMENT = [ 'Merchant accepts cards in person primarily', 'Customers enetr their payment information online primarily ',
	'merchant is a fuel, petrol, gas station', 'Merchant takes payment information over the phone or mail primarily',
	'merchant is a restaurant', 'Service stations', 'Merchant is a supermarket' ];
export const EINTYPE = [ 'SSN', 'TIN'];
export const ANNUALPROCESSINGVOLUME = [ 'SOLE PROPRIETOR', 'CORP', 'LLC', 'PARTNER', 'NONPROFIT', 'GOV' ];
export const SIGNIFICATIONRESPONSIBILITY = [ 'No', 'Yes' ];
export const ANNUALPROCESSING = [ '10k - 100k', '100k - 200k', '200k - 300k', '300k - 400k', '400k - 500k', '500k - 600K',
	'600k - 700k', '700k - 800k', '800k - 900k', '900k - 1000k'];
export const DOCUMENTTYPE = [ 'General', 'Personal ID','Void Check', 'Bank Statement','Bank Letter', 'Contract', 'Tax Document' ];
export const BANKACCOUNTTYPE = [ 'Business Checking', 'Business Saving', 'Personal Checking', 'Personal Saving' ];
export const IRSNONPROFITSTATUS= ['Active', 'Not Active'];
export const BUSINESSHASEMPLOYEES = ['Yes', 'No']
export const WEBSITE_OPTIONS = [
	{ label: 'Yes, I do have a business website', value: 'hasBusinessWebsite' },
	{ label: 'No, I do not have a business website', value: 'doNotHaveBusinessWebsite' }
];
