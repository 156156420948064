import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

	congratulationHeader: {
		boxSizing: 'border-box',
		paddingTop: '1.75%',
		paddingBottom: '1.75%',
		border: '1px solid #CFD8DC',
		backgroundColor: '#ECF7EA',
		margin: '1%'
	},
	successfulMessage: {
		color: '#29961D',
		fontSize: 16,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: '1.25%',
		marginLeft: '1%'
	},
	successfulIcon: {
		height: 40,
		width: 40,
		backgroundColor: '#41B832',
	},
	box: {
		width: '100%',
		height: 'auto',
		border: '1px solid #C6C8C9',
		marginTop: '2%'
	},
	container: {
		marginTop: '2%',
		borderTop: '1px solid #C6C8C9',
		paddingBottom: '1.5%'
	},
	emailNotifications: {
		fontWeight: 'bold',
		fontSize: 24,
		fontFamily: 'Roboto'
	}
}));

const SuccessfulTestTransactionPage = ({ merchantAccount = {}, emailList = {} }) => {
	const classes = useStyles();
	const history = useHistory();

	const [ accountType, setAccountType ] = useState('');

	useEffect(() => {
		if (merchantAccount.accountType === 8) setAccountType('Checking');
		if (merchantAccount.accountType === 9) setAccountType('Saving');
		if (merchantAccount.accountType === 10) setAccountType('Corporate Checking');
		if (merchantAccount.accountType === 11) setAccountType('Corporate Savings');

	}, [ merchantAccount.accountType ]);

	useEffect(() => {
		if (!history.location.state) {
			history.location.state = emailList.notificationEmailTo;
		}
	}, [ emailList ]);

	return (
		<>
			<Grid container direction='column'  alignItems="center" spacing={1} >
				<Grid container direction='column'  alignItems="center" >
					<Typography  sx={{ fontSize: { lg: 24, md: 20, sm: 18, xs: 18 },
						lineHeight: '19px',
						fontWeight: 'bold',
						fontFamily: 'Roboto'
					}}>
						 {history.location.state}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={11} md={9} lg={7} style={{ width: '100%' }}>
					<Box className={classes.box}>
						<Grid item >
							<Typography  sx={{
								fontSize: { lg: 25, md: 24, sm: 20, xs: 18 },
								backgroundColor: '#ECEDED',
								padding: '12px',
								borderBottom: '1px solid #C6C8C9',
								fontWeight: 'bold'
							}} >
                        Transaction Details
							</Typography>
						</Grid>
						<Grid container direction='row'  alignItems="center">
							<Grid item lg={5} md={5} sm={5}  xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#5F6365',
									padding: '12px',
								}} >
                            Transaction Date
								</Typography>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#13191C',
									padding: '12px',
								}} >
									{moment().format('MM/DD/YYYY')}
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction='row'  alignItems="center" >
							<Grid item lg={5} md={5} sm={5}  xs={12} style={{ marginTop: '3%' }} >
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#5F6365',
									padding: '12px',
								}} >
                            Transaction Amount
								</Typography>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#13191C',
									padding: '12px',
								}} >
								$5.00
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction='row'  alignItems="center" >
							<Grid item lg={5} md={5} sm={5}  xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#5F6365',
									padding: '12px',
								}} >
                            Transaction Fee
								</Typography>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#13191C',
									padding: '12px',
								}} >
                            $0.15
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction='row'  alignItems="center" >
							<Grid item lg={5} md={5} sm={5}  xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#5F6365',
									padding: '12px',
								}} >
                            Description
								</Typography>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#13191C',
									padding: '12px',
								}} >
                            APX Test Transaction
								</Typography>
							</Grid>
						</Grid>
						<Grid container direction='row'  alignItems="center" className={classes.container} >
							<Grid item lg={5} md={5} sm={5}  xs={12} style={{ marginTop: '3%' }}>
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '12px',
									color: '#5F6365',
									padding: '12px',
								}} >
                            Deposit To
								</Typography>
							</Grid>
							<Grid item lg={7} md={7} sm={7} xs={12} style={{ marginTop: '3%' }} >
								<Typography  sx={{
									fontSize: { lg: 24, md: 24, sm: 22, xs: 18 },
									fontFamily: 'Roboto',
									lineHeight: '18px',
									color: '#13191C',
									padding: '12px',
								}} >
									 {`Acct # ...${merchantAccount.accountNumber || ''} | ${accountType}`}
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				<Grid container justifyContent='center'>
					<Grid sm={11} sx={12} md={9} lg={7} >
						<Alert  sx={{
							marginTop: '3%',
							marginBottom: '5%',
							background: '#CFE4F6',
							border: '1px solid #96C0E8',
						}}
						iconMapping={{
							success: <InfoIcon style={{ color: '#1070D7', width: '35px', height: '35px' }}/>,
						}}
						>
							<Typography  sx={{
								fontSize: { lg: 24, md: 21, sm: 19, xs: 18 },fontFamily: 'Roboto', fontWeight: '500',color: '#1070D7', }}>
                    Transaction will occur in your bank account in 2 business days.
							</Typography>
						</Alert>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};
SuccessfulTestTransactionPage.propTypes = {
	merchantAccount: PropTypes.object,
	emailList: PropTypes.object
};
export default SuccessfulTestTransactionPage;