import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import abacusnextlogo from '../../assets/images/AbacusNextlogo.png';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: 'white',
        cursor: 'pointer'
    },
    paper1: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: 'white',
        cursor: 'pointer',
    },
}));

function Footer(props) {
    const classes = useStyles();
    return (
        <div className={classes.root} style={{backgroundColor: '#05121D'}}>
            <Container maxWidth="lg">
            <Grid container spacing={2} direction='row' >
                <Grid item xs={6} md={2} lg={2} sm={4}style={{marginTop: '2vh'}}>
                    <Grid item><div className={classes.paper} style={{fontWeight: 'bold'}}>Resources</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/software/','_blank')}>Products</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/solutions/','_blank')}>Solutions</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/solutions/cloud-hosting-3/','_blank')}>Cloud Hosting</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/webinars/','_blank')}>Webinars</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/blog/','_blank')}>Blog</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/library/','_blank')}>Resource Library</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/partner-program/','_blank')}>Find a Partner</div></Grid>
                </Grid>
                <Grid  item xs={6} md={2} lg={2}  sm={4} style={{marginTop: '2vh'}}>
                    <Grid item><div className={classes.paper} style={{fontWeight: 'bold'}} >Tech & Support</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/support/','_blank')}>Support</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://portal.abacusnext.com/?_ga=2.150230774.1536905866.1631127330-1758185790.1629228689#/login','_blank')}>Account Portal</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/contact/','_blank')}>Contact</div></Grid>
                </Grid>
                <Grid  item xs={6} md={2} lg={2}  sm={4} style={{marginTop: '2vh'}}>
                    <Grid item><div className={classes.paper} style={{fontWeight: 'bold'}} >United States</div></Grid>
                    <Grid item><div className={classes.paper}>AbacusNext HQ 4850 Eastgate Mall San Diego, CA 92121, USA</div></Grid>
                    <Grid item><div className={classes.paper}>Tel: 1-800-726-3339</div></Grid>
                </Grid>
                <Grid  item xs={6} md={2} lg={2}  sm={4} style={{marginTop: '2vh'}}>
                    <Grid item><div className={classes.paper} style={{fontWeight: 'bold'}}>United Kingdom</div></Grid>
                    <Grid item><div className={classes.paper}>AbacusNext Int'l 14 S Charlotte St Edinburgh EH2 4AX, UK</div></Grid>
                    <Grid item><div className={classes.paper}>Tel: 1-800-726-3339</div></Grid>
                </Grid>
                <Grid  item  xs={6} md={2} lg={2}  sm={4} style={{marginTop: '2vh'}}>
                    <Grid item><div className={classes.paper} style={{fontWeight: 'bold'}}>Canada</div></Grid>
                    <Grid item><div className={classes.paper}>Abacus Data Systems Canada, Inc. 1 Yonge St, Suite 1801 Toronto, ON M5E 1W7, Canada</div></Grid>
                    <Grid item><div className={classes.paper}>Tel: 1-800-726-3339</div></Grid>
                </Grid>
                <Grid   item xs={6} md={2} lg={2}  sm={4} style={{marginTop: '2vh'}}>
                    <Grid item onClick={() => window.open('https://www.abacusnext.com/about/#top','_blank')}><div className={classes.paper} style={{fontWeight: 'bold'}}>
                        <img src={abacusnextlogo} alt="APX" style={{width: '70%'}}/>
                    </div>
                    </Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/about/','_blank')}>About</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/careers/','_blank')}>Careers</div></Grid>
                    <Grid item><div className={classes.paper} onClick={() => window.open('https://www.abacusnext.com/sitemap/','_blank')}>Site Map</div></Grid>
                </Grid>
            </Grid>
            </Container>

                <Grid item>
                    <div  style={{fontSize: '1.5vh'}} className={classes.paper1}>© {new Date().getFullYear()} - AbacusNext, All Rights Reserved</div></Grid>
                <Container maxWidth="md">
                    <Grid container direction='row' alignContent='flex-start'alignItems='flex-start' justifyContent='flex-start' spacing={0}>
                        <Grid item xs={12} md={12} lg={12} style={{marginBottom: '3vh'}}>
                            <div className={classes.paper1} style={{fontSize: '1.35vh'}}>
                                <span onClick={() => window.open('https://www.abacusnext.com/terms/','_blank')}>Terms and Conditions</span> |
                                <span onClick={() => window.open('https://www.abacusnext.com/cookie-policy/','_blank')}>Cookie Policy</span> |
                                 <span onClick={() => window.open('https://www.abacusnext.com/accessibility/','_blank')}>accessibility</span> |
                                <span onClick={() => window.open('https://www.abacusnext.com/manage-my-data/','_blank')}>Do Not Sell My Personal Information</span> |
                                <span onClick={() => window.open('https://www.abacusnext.com/privacy-policy/','_blank')}>Privacy Policy</span></div>
                        </Grid>
                    </Grid>
                </Container>
        </div>
    );
}
export default Footer
