import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(3, 3, 1),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(0.5),
		color: theme.palette.grey[500],
	},
	cancel: {
		right: 1
	}
});
function FormDialog({
	cancelButton = () => {},
	openModal = false,
	children
}) {

	return (
		<Grid container>
			<Dialog onClose={cancelButton} aria-labelledby='customized-dialog-title'
				open={openModal} 
				style={{ backgroundColor: 'rgba(38,50,56,0.30)' }}
				maxWidth='xs' 
				fullWidth
			>
				{children}
			</Dialog>
		</Grid>
	);
}

export default FormDialog;

