import React from 'react';

import { Switch } from 'react-router-dom';
import './App.css';
import Routes from './routes/Routes';

function App() {
	return (
		<div className="App">
			<Switch>
				<Routes />
			</Switch>
		</div>
	);
}

export default App;
