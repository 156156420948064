import axios from 'axios';
import * as _ from 'lodash';
import { getApiUrl } from '../utils/getApiUrl';

class SignUpService {
	constructor() {}

	async getMerchantInfo(applicationCustomerId, axiosConfig) {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_merchant_info?applicationCustomerId=${applicationCustomerId}`, axiosConfig);
			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async saveMerchantData(applicationCustomerId, merchantData, pageNumber, axiosConfig) {
		let merchantInfo = {};
		if (pageNumber === 1) merchantInfo = merchantData.aboutBusiness;

		if (pageNumber === 2) {
			delete merchantData['aboutBusiness'];

			merchantInfo.primaryContact = merchantData?.aboutOwner?.primaryContact;
			merchantInfo.owners = _.values(_.omit(merchantData, [ 'aboutOwner' ]));

			const ownersNewArray = [];
			for (let i = 0; i < merchantInfo.owners.length; i++) {
				const owner = {};
				for (let property in merchantInfo.owners[i]) {
					let newOwnerProperty = property.replace(/\d+$/, '') + (i + 1);
					owner[newOwnerProperty] = merchantInfo.owners[i][property];
				}
				ownersNewArray.push(owner);
			}

			merchantInfo.owners = ownersNewArray;
		}
		if (pageNumber === 3) merchantInfo = merchantData;
		try {
			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/save_merchant_data?applicationCustomerId=${applicationCustomerId}`, merchantInfo, axiosConfig);
			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async getPdf(pdfName, axiosConfig) {
		try {
			const data = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_bank_pdf?pdfName=${pdfName}`,axiosConfig);
			return {
				status: 'success',
				data: data.data
			};
		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async deleteMerchantBankAccount(applicationCustomerId, isPlaid, bankName, type, axiosConfig) {

		try {
			const { data } = await axios.delete(`${getApiUrl()}/api/merchantwebapi/delete_extended_merchant_bankAccount?applicationCustomerId=${applicationCustomerId}&type=${type}&isPlaid=${isPlaid}&bankName=${bankName}`, axiosConfig);
			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async submitApplication(applicationCustomerId,documents,documentTypes, documentDescriptions, axiosConfig) {
		try {
			for (let x = 0; x < documentTypes.length; x++) {
				if (documentTypes[x] === 'General') documentTypes[x] = 'general';
				if (documentTypes[x] === 'Personal ID') documentTypes[x] = 'personalId';
				if (documentTypes[x] === 'Void Check') documentTypes[x] = 'voidCheck';
				if (documentTypes[x] === 'Bank Statement') documentTypes[x] = 'bankStatement';
				if (documentTypes[x] === 'Bank Letter') documentTypes[x] = 'bankLetter';
				if (documentTypes[x] === 'Contract') documentTypes[x] = 'contract';
				if (documentTypes[x] === 'Tax Document') documentTypes[x] = 'taxDocument';
			}

			const { data } = await axios.post(`${getApiUrl()}/api/merchantwebapi/submit_merchant_application?applicationCustomerId=${applicationCustomerId}`, { documentTypes: documentTypes, documentDescriptions: documentDescriptions }, axiosConfig);
			if (documents?.length && data?.noteDocumentsIds) {
				for (let x = 0; x < documents?.length; x++) {
					const note = await this.uploadDocument(documents[x], data.noteDocumentsIds[x], data.apiKey, data.baseURL);

				}
			}

			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async uploadDocument(file, noteDocumentsIds, apiKey, baseURL) {

		try {
			const payload = new FormData();
			payload.append('file', file);
			const { data } = await axios.post(`${baseURL.replace(/["]+/g, '')}/files/noteDocuments/${noteDocumentsIds.replace(/["]+/g, '')}`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
					APIKEY: apiKey
				}
			});

			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async validateUserName(userName, axiosConfig) {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/validate_username?userName=${userName}`, axiosConfig);
			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

	async updateMerchantBankInfo(applicationCustomerId, updatedBankInfo, axiosConfig) {

		try {
			const { data } = await axios.put(`${getApiUrl()}/api/merchantwebapi/update_merchant_bank_info?applicationCustomerId=${applicationCustomerId}`, updatedBankInfo, axiosConfig);
			return {
				status: 'success',
				data: data
			};

		} catch (error) {
			return {
				status: 'error',
				err: error?.response?.data?.error?.toString()
			};
		}
	}

}

export default new SignUpService();