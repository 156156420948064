import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import { Divider } from '@mui/material';
import CheckIcon from '@material-ui/icons/Check';
import * as _ from 'lodash';
import HelpIcon from '@material-ui/icons/Help';
import { styled } from '@mui/material/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
	dropDown: {
		// marginLeft: 20,
		marginTop: 10,
	},
	select: {
		backgroundColor: COLORS.whiteText,
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		},
		'& .MuiSelect-select': {
			display: 'flex'
		},
		'& .MuiSelect-select.Mui-disabled': {
			color: COLORS.footerText,
			backgroundColor: COLORS.lightGray
		},
		'& legend': {
			width: 0
		}
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	label: {
		color: COLORS.secureMessage,
	},
	selectedLabel: {
		color: COLORS.blueButtons,
	},
	labelGray: {
		color: COLORS.roboto,
		marginLeft: 2
	},
	divider: {
		backgroundColor: 'lightgray',
		width: '100%',
		height: 0.15
	},
	selectedCountry: {
		fontWeight: 'bold'
	},
	checkIcon: {
		color: 'green',
		paddingLeft: '55%',
	},
	root: {
		whiteSpace: 'unset',
		wordBreak: 'break-all'
	},
	tooltiptext: {
		color: COLORS.blackText
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 15,
	},
	contentHelper: {
		marginLeft: 5,
		color: COLORS.blueButtons
	},
});

function FormDropDown({
	list = [],
	title = '',
	value = '',
	label = '',
	id = '',
	onChange = () => {},
	required = true,
	validateRequired = false,
	disable = false,
	width = '',
	showInfo = false,
  	paymentInfo = ''
}) {

	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);
	const [ selectedValue, setSelectedValue ] = useState('');
	const checkIsRequiredError = () => {
		if (required && validateRequired) return !value;
		else return false;
	};

	const renderMenuitem = (list, title) => {
		if (title === 'Country') {
			return (list.map((item, index) => {
				return <MenuItem value={item.value} style={index === 3 ? { borderTop: '1px solid Lightgray' } : null} key={index}>
					<Grid container direction='row'>
						<Grid item xs={11} className={item.value === selectedValue ? classes.selectedCountry : null}>
							{item.key}
						</Grid>
						<Grid item xs={1}>
							{item.value === selectedValue && <CheckIcon className={classes.checkIcon}/>}
						</Grid>
					</Grid>
				</MenuItem>;
			})
			);
		}
		if (title === 'Payment Method') {
			return (list.map((item, index) => {
				return <MenuItem key={item.paymentMethodId} value={item.paymentMethodId} style={index !== 0 ? { borderTop: '1px solid Lightgray' } : null} key={index}>
					<Grid container direction='row'>
						<Grid item xs={11}>
							<Grid container direction='column'>
								<Grid item xs={12}>
									{item?.paymentMethod} {item?.bankAccountType}  {item?.expiration} | {item?.address1},
									{item?.city}, {item?.state}, {item?.zip}
								</Grid>
								<Grid item className={classes.labelGray} xs={12}>{item?.name} {item?.email}</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							{item?.paymentMethodId === selectedValue && <CheckIcon className={classes.checkIcon}/>}
						</Grid>
					</Grid>
				</MenuItem>;
			})
			);
		}
	};

	const renderValue = (value, title, list) => {
		if (title === 'Country')  return _.find(list, function(o) { return o?.value === value;})?.key;
		if (title === 'Payment Method') {
			return (list.map((item, index) => {
				if (item.paymentMethodId === value) {
					return <Grid container direction='row' key={index}>
						<Grid item xs={12}>
							{item?.paymentMethod}  {item?.bankAccountType} {item?.expiration} | {item?.address1},
							{item?.city}, {item?.state}, {item?.zip}
						</Grid>
						<Grid item className={classes.labelGray} xs={12}>{item?.name} {item?.email}</Grid>
					</Grid>;
				}
				
			})
			);
		}
	};

	return (
		<Grid item className={classes.dropDown} >
			<FormControl required fullWidth={true} variant="outlined" size="small" error={checkIsRequiredError()}>
				<Typography className={selected ? classes.selectedLabel : classes.label}>{title && (required ?
					<span className={classes.asterisk} >*</span> : null)}{title}

				{ showInfo && <Tooltip  title={paymentInfo} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon className={classes.contentHelper} fontSize='small'/>
				</Tooltip >}
			
				</Typography>
				{ (title === 'Payment Method' || title === 'Country') ? <Select
					style={{ width: width }}
					disabled={disable}
					labelId={id}
					id={id}
					label={label}
					value={value}
					renderValue={value => renderValue(value, title, list)}
					className={classes.select}
					onChange={onChange}
					onFocus={() => {setSelected(true);}}
					onBlur={() => setSelected(false)}
					onOpen={() => setSelectedValue(value)}
					onClose={() => setSelectedValue('')}
				>

					{renderMenuitem(list, title)}
				</Select> : <Select
					style={{ width: width }}
					disabled={disable}
					labelId={id}
					id={id}
					label={label}
					value={value}
					className={classes.select}
					onChange={onChange}
					onFocus={() => setSelected(true)}
					onBlur={() => setSelected(false)}
				>
					{list.map((item, index) => {
						if (typeof item === 'object') {
							return <MenuItem key={Object.values(item)[1]} value={Object.values(item)[1]}>
								{Object.values(item)[0]}
								<div className={classes.labelGray}>{Object.values(item)[2]}</div>
							</MenuItem>;
						} else return <MenuItem key={item + index} value={item}>{item}</MenuItem>;
					})
					}
				</Select>}
			</FormControl>
		</Grid>
	);

}

FormDropDown.propTypes = {
	list: PropTypes.array,
	title: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	validateRequired: PropTypes.bool,
	showInfo: PropTypes.bool,
	paymentInfo: PropTypes.string
};

export default memo(FormDropDown);