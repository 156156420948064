import React, { useEffect, useState, memo } from 'react';
import axios from 'axios';
import { getApiUrl, getApiToken } from '../utils/getApiUrl';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
const useStyles = makeStyles({
    table: {
       border: '1px solid black',
        marginTop: 20,
        marginBottom: 20
    },
    TableCell : {
        border: '1px solid black',
    },
    text : {
        marginLeft: '45%',
        marginTop: '10px'
    }
});



function Merchants() {
    const [allMerchants, setAllMerchants] = useState([]);
    const history = useHistory();
    const classes = useStyles();

    const fetchAllMerchants = async () => {
        try {
            const response = await axios.get(`${getApiUrl()}/api/admin/get_all_merchants`, {
                headers: {'Authorization': `Bearer ${getApiToken()}`}
            })
            if (response.data.code === 200) {
                setAllMerchants(response.data.allMerchants)
            }
        } catch (err) {
            if (err.toString() === 'Error: Network Error') {
                history.push('/errorpage/serverDown');
            }
            console.log(err)
        }
    }

    useEffect(  () => {

        async function  getAllMerchants() {
            await fetchAllMerchants();
        }
        getAllMerchants()
    }, [])


    return  (
        <Container >
            <Grid item className={classes.text}> All Merchants </Grid>
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow className={classes.TableCell}>
                    <TableCell  className={classes.TableCell}>First name</TableCell>
                    <TableCell align="center"className={classes.TableCell}>Last name</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Business name</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Merchant ID</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Practice App</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Email</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Phone</TableCell>
                    <TableCell align="center" className={classes.TableCell}>Status</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {allMerchants.map((merchant) => (
                    <TableRow key={merchant.merchantId}>
                        <TableCell className={classes.TableCell}>{merchant.firstname}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.lastname}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.name}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.merchantId}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.applicationId}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.email}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.phone}</TableCell>
                        <TableCell align="center" className={classes.TableCell}>{merchant.merchantStatus}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Container>
);
}

export default memo(Merchants);