import React, { memo,useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FormDialog from '../forms/FormDialog';
import ExistingPaymentMethodInfo from './ExistingPaymentsMethodInfo';
import _ from 'lodash';

import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { getApiUrl } from '../utils/getApiUrl';
import PropTypes from 'prop-types';
import { Radio } from '@material-ui/core';
import { COLORS } from '../constants/Colors';
import Container from '@material-ui/core/Container';
import PayNowDeletePaymentMethod from './PayNowDeletePaymentMethod';
import PayNowDeletePaymentMethodResponse from './PayNowDeletePaymentMethodResponse';

const useStyles = makeStyles((theme) => ({

	root: {
		width: '100%',
		maxWidth: '100%',
		position: 'relative',
		overflow: 'auto',
		maxHeight: 400,
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		borderRadius: 2,
		backgroundColor: '#F6F7F8'
	},
	listSection: {
		backgroundColor: 'inherit',
	},
	ul: {
		backgroundColor: 'inherit',
		padding: 0,
	},
	paper: {
		marginTop: 15,
		height: 115,
		width: '100%',
		border: '1px solid #CFD8DC',
		borderRadius: 4,
		boxSizing: 'border-box',
		backgroundColor: COLORS.whiteBackground
	},
	deleteButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
		cursor: 'pointer',
		marginTop: '30px'
	}
}));

const ExistingPaymentsMethods = ({
	handleSelectedTokenChange = () => {},
	selectedToken = '',
	getExistingPaymentsMethods = () => {},
	paymentMethods = [],
	axiosConfig = {}
}) => {
	const classes = useStyles();
	const history = useHistory();
	const [ openModal, setOpenModal ] = useState(false);
	const [ cardToDelete, setCardToDelete ] = useState();
	const [ openModalForSuccessfulDeletion, setOpenModalForSuccessfulDeletion ] = useState(false);
	const [ confirmationMessage, setConfirmationMessage ] = useState('');
	const [ popUpTitle, setPopUp ] = useState('');

	const deletePaymentMethod = async (tokenId) => {
		setOpenModal(!openModal);
		try {
			const { data } = await axios.delete(`${getApiUrl()}/api/merchantwebapi/delete_payment_method?paymentMethodId=${tokenId}`, axiosConfig);
			if (data.code === 200) {
				setConfirmationMessage('Your payment method was deleted successfully.');
				setPopUp('Payment method was deleted');
				setOpenModalForSuccessfulDeletion(true);
				await getExistingPaymentsMethods();
			}

		} catch (err) {

			if (err.response.data.status === 403) {
				setConfirmationMessage(err.response.data.error);
				setPopUp('Payment method cannot be deleted');
				setOpenModalForSuccessfulDeletion(true);
				await getExistingPaymentsMethods();
			}

			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
		}
	};

	const deleteCard = async (token) => {
		const cardToDelete = await _.find(paymentMethods, (paymentMethod) => paymentMethod.tokenId === token);
		setCardToDelete(cardToDelete);
		setOpenModal(!openModal);
	};

	const cancelButton = () => {
		setOpenModal(!openModal);
	};

	const cancelConfirmationButton = () => {
		setOpenModalForSuccessfulDeletion(!openModalForSuccessfulDeletion);
	};
	return (
		<>
			<List className={classes.root} subheader={<li />}>
				<li className={classes.listSection}>
					<ul className={classes.ul}>
						{paymentMethods.map((payment, index) => (
							<Container style={{ marginTop: '-6px' }} key={payment.tokenId}>
								<ListItem className={classes.paper}
									style={selectedToken === payment.tokenId ? { border: ' solid #1070D7' } : null} key={payment.tokenId}>
									<Grid container alignContent='space-between'  style={{ marginLeft: '-9px', paddingRight: '10px' }}>
										<Grid item xs={2} lg={1} >
											<Radio
												checked={selectedToken === payment.tokenId}
												onChange={handleSelectedTokenChange}
												value={payment.tokenId}
												color='primary'
												style={{ color: COLORS.blueButtons , marginTop: '17px' }}
											/>
										</Grid>
										<Grid item xs={9} lg={10}> <ExistingPaymentMethodInfo payment={payment} isCreditCard={payment.hasOwnProperty('creditCardType')} /></Grid>
										<Grid item xs={1} className={classes.deleteButton}><DeleteRoundedIcon onClick={() => deleteCard(payment.tokenId)}/></Grid>
									</Grid>
								</ListItem>
							</Container>

						))}
					</ul>
				</li>
			</List>
			{cardToDelete && openModal &&
			<FormDialog
				openModal={openModal}
				cancelButton={cancelButton}
			>
				<PayNowDeletePaymentMethod
					deleteButton={deletePaymentMethod}
					cardToDeleteInfo={cardToDelete}
					cancelButton={cancelButton}
				/>
			</FormDialog>
			}
			{ openModalForSuccessfulDeletion &&
			<FormDialog
				openModal={openModalForSuccessfulDeletion}
				cancelButton={cancelConfirmationButton}
			>
				<PayNowDeletePaymentMethodResponse
					cancelButton={cancelConfirmationButton}
					message={confirmationMessage}
					title={popUpTitle}
				/>
			</FormDialog>}
		</>

	);
};

ExistingPaymentsMethods.propTypes = {
	handleSelectedTokenChange: PropTypes.func,
	selectedToken: PropTypes.string,
	getExistingPaymentsMethods: PropTypes.func,
	paymentMethods: PropTypes.array,
	axiosConfig: PropTypes.object
};
export default memo(ExistingPaymentsMethods);