import React, { useEffect, useState, memo } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import { COLORS } from '../../constants/Colors';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Divider, Typography } from '@material-ui/core';
import Header from '../landingpage/Header';
import { getApiUrl } from '../../utils/getApiUrl';
import ListActionsToolbar from '../ListActionsToolbar';
import Button from '@material-ui/core/Button';
import PaymentsList from './PaymentsList';

import recurringHeaderSchedule from '../../../assets/images/APX_header_popup_page@2x.png';
import { appendPendoUrl } from '../../services/Services';

function Overview({
	selectedSchedule = {},
	open = false,
	onDialogClose = () => {},
	token = '',
	isRefreshRecurringPayments = false
}) {

	const useStyles = makeStyles((theme) => ({
		root: {
			'& .MuiPaper-root': {
				boxShadow: 'none',
				border: `1px solid ${COLORS.grayBorder}`
			}
		},
		page: {
			marginTop: '16px',
			marginLeft: '3%',
			maxWidth: '94%',
		},
		close: {
			fontWeight: 900,
			fontSize: 15,
			width: 100,
			textTransform: 'capitalize',
			backgroundColor: COLORS.blueButtons,
			color: COLORS.whiteText,
			marginBottom: 10,
			marginTop: 10,
			marginRight: '2.5%',
			'&:hover': {
				background: COLORS.onHoverBlueButtons,
			},
		},
		divider: {
			marginBottom: '2px',
			color: COLORS.dividerColor,
		},
		headerDivider: {
			color: COLORS.grayBorder,
			height: '2px',
			marginTop: '0px',
			marginBottom: '2px'
		},
		header: {
			backgroundColor: COLORS.whiteBackground
		},
		footerDivider: {
			color: COLORS.grayBorder,
			height: '2px',
			marginTop: '12px',
			marginBottom: '2px'
		},
		footer: {
			marginTop: '10px',
			backgroundColor: COLORS.whiteBackground
		},
		dialog: {
			backgroundColor: COLORS.grayContainer,
		},
		verticalDivider: {
			color: COLORS.dividerColor,
			width: 1,
			height: 40,
			position: 'relative'
		},
		status: {
			fontWeight: 900,
			left: 30,
			top: 10,
			position: 'relative'
		},
		entity: {
			fontWeight: 900,
			left: '4%',
			top: 10,
			position: 'relative'
		},
		statusDiv: {
			display: 'flex',
			// eslint-disable-next-line no-nested-ternary
			color: selectedSchedule?.status === 'Canceled' ? COLORS.error : selectedSchedule?.status === 'Completed' ? COLORS.greenText : COLORS.blackText,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: selectedSchedule?.status === 'Canceled' ? COLORS.lightBackgroundRed : selectedSchedule?.status === 'Completed' ? COLORS.lightBackgroundGreen : COLORS.whiteBackground
		}
	}));

	const classes = useStyles();
	const history = useHistory();

	const [ totalCharged, setTotalCharged ] = useState('0.00');
	const [ totalRemaining, setTotalRemaining ] = useState('0.00');
	const [ totalAmount, setTotalAmount ] = useState('0.00');
	const [ payments, setPayments ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(false);

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	useEffect(() => {

		if (isRefreshRecurringPayments && selectedSchedule?.paymentScheduleId && token) {
			async function fetchRecurringPayments() {
				await getRecurringPayments(selectedSchedule?.paymentScheduleId);
			}
			fetchRecurringPayments();
		}
	}, [ isRefreshRecurringPayments, selectedSchedule?.paymentScheduleId, token ]);

	const getRecurringPayments = async (paymentScheduleId) => {

		try {
			setIsLoading(true);
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/recurringpayments/get_recurring_payments?paymentScheduleId=${paymentScheduleId}`, axiosConfig);
			if (data.code === 200) {
				setPayments(data?.recurringPayments);
				setTotalCharged(data?.totalCharged);
				setTotalRemaining(data?.totalRemaining);
				setTotalAmount(data?.totalAmount);

				appendPendoUrl('/Overview');
			}
			setIsLoading(false);

		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	return (
		<Dialog open={open} maxWidth='md' className={classes.root}>
			<Grid container direction='column' xs={12} justifyContent='center' className={classes.dialog}>
				<Grid item xs={12}>
					<Header title='Payment Overview' headerLogo={recurringHeaderSchedule} isPopup={true}/>
				</Grid>
				<Grid container className={classes.header} direction='row'>
					<Grid item xs={10}>
						<Typography className={classes.entity}>{(`${selectedSchedule?.entity} - ${selectedSchedule?.recurring}`)}</Typography>
					</Grid>
					<Grid item xs={2}>
						<div className={classes.statusDiv}>
							<Divider orientation='vertical' className={classes.verticalDivider}/>
							<Typography className={classes.status}>{selectedSchedule?.status}</Typography>
						</div>
					</Grid>
				</Grid>
				<Divider orientation='horizontal' className={classes.headerDivider}/>
				<Grid item xs={11} className={classes.page}>
					<ListActionsToolbar displayActions={false} />
					<PaymentsList
						payments={payments}
						totalAmount={totalAmount}
						totalCharged={totalCharged}
						totalRemaining={totalRemaining}
						token={token}
						isLoading={isLoading}
					/>
				</Grid>
				<Divider orientation='horizontal' className={classes.footerDivider} />
				<Grid container className={classes.footer} alignContent='flex-end' justifyContent='flex-end' justify='flex-end'>
					<Divider orientation='horizontal' className={classes.divider} />
					<Button
						variant='contained'
						className={classes.close}
						onClick={onDialogClose}
						id={'close'}
					>
						Close
					</Button>
				</Grid>
			</Grid>
		</Dialog>
	);
}

export default memo(Overview);