import React, { memo } from 'react';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { COLORS } from '../constants/Colors';
import { formatAmountCurrency } from '../utils/formatUtils';

const useStyles = makeStyles( {
    root: {
        lineHeight: 10,
        height: 20,
        backgroundColor: COLORS.grayContainer,
        borderColor: COLORS.grayBorder,
        border: '1px solid'
    },
    text: {
        color: COLORS.darkGray,
        lineHeight: '11px',
        display: 'inline-flex'
    },
	amount: {
		fontWeight: 900,
		display: 'inline-flex',
		paddingLeft: '6px'
	},
    leftItems: {
        position: 'relative',
        left: 0,
        textAlign: 'right'
    }
});

function Summary({
    count = 0,
    totalCharged = 0,
    totalRemaining = 0,
    totalAmount = 0
}) {
    const classes = useStyles();

    return (
        <TableFooter className={classes.root}>
            <TableRow>
                <TableCell >
                    <Grid container direction='row'>
                        <Grid item xs={3} md={3} lg={count ? 3 : 4} sm={3}>
                            <Typography className={classes.text}>Total Charged $</Typography>
                            <Typography className={classes.amount}>{totalCharged}</Typography>
                        </Grid>
                        <Grid item xs={3} md={4} lg={count ? 3 : 4} sm={3}>
                            <Typography className={classes.text}>Total Remaining $</Typography>
                            <Typography className={classes.amount}>{totalRemaining}</Typography>
                        </Grid>
                        <Grid item xs={3} md={3} lg={count ? 3 : 4} sm={3}>
                            <Typography className={classes.text}>Total Amount $</Typography>
                            <Typography className={classes.amount}>{totalAmount}</Typography>
                        </Grid>
                        {count ? (
                            <Grid item xs={3} md={2} lg={count ? 3 : 4} sm={3} className={classes.leftItems}>
                            <Typography className={classes.text}>{count} Item(s)</Typography>
                        </Grid>
                        ) : null }
                    </Grid>
                </TableCell>
            </TableRow>
        </TableFooter>
    )
}

export default memo(Summary);