import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APXlogosignup from '../../assets/images/APXlogosignup.png';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { COLORS } from '../constants/Colors';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 55
	},
	text: {
		fontWeight: 700,
		color: COLORS.whiteText,
		margin: 'auto 0 auto 1%',
		fontSize: 22,
		fontFamily: 'Roboto'
	},
	divider: {
		height: 55,
		backgroundColor: 'gray',
	},
	container: {
		backgroundColor: COLORS.headerDarkBlue
	}
}));

function Header() {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid item lg={12}  md={12} xs={12} sm={12} className={classes.container}>
				<Container maxWidth='xl'>
					<Grid container direction='row' alignItems='center'>
						<Grid item md={2} xs={3} lg={1} xl={1} sm={3}>
							<img src={APXlogosignup} alt="APX" style={{ width: '75%' }}/>
						</Grid>
						<Divider orientation="vertical" flexItem className={classes.divider}/>
						<Grid className={classes.text}  xs={8}>
							<Typography  sx={{ fontSize: { lg: 25, md: 20, sm: 18, xs: 15 } }}>
                            Abacus Payment Exchange
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
}
export default Header;