import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { useForm,  Controller } from 'react-hook-form';
import { TextField, Checkbox } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { parse, isDate } from 'date-fns';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles({
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	selectedLabel: {
		color: COLORS.blueButtons,
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#455A64',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	}
});

// eslint-disable-next-line require-jsdoc
function SignUpNumber({
	savedData = { },
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	type = '',
	showInfo = true,
	info = '',
	label = '',
	regex = '',
	name = '',
	componentName = '',
	format = '',
	formatType = '',
	setPageError = () => {},
	prefix = '',
	suffix = '',
	disableOwnerShip = false,
	defaultName = '',
	ownerShipfieldName = '',
	maximumDate = '',
	pageNumber = 1
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);

	const regularExpression = {
		phone: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
		ein: /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/,
		tin: /^(?![-])(?!.*[-]$)(?!.*[-]{2})[0-9-]+$/,
		ssnandtin: /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7}|\d{9})$/,
		ssn: /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
		percentage: /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)?%/,
		dollarSign: /^(\$[0-9]+)$/,
	};

	yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
		return this.transform(function (value, originalValue) {
			value = moment(originalValue, formats, parseStrict);
			return value.isValid() ?  value.toDate() : new Date('');
		});
	});

	const schema = fieldValidationRules(type,regex, label);
	const { watch, control, reset, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			[name]: defaultName
		},
		resolver: yupResolver(schema)

	});

	useEffect(()=> {
		if (ownerShipfieldName && disableOwnerShip) {
			reset({ [ownerShipfieldName]: defaultName });
		} else {
			if (savedData?.[name] && String(savedData?.[name]) !== 'undefined') {
				reset(savedData);
			}
		}
	}, [ defaultName, savedData ]);

	// eslint-disable-next-line require-jsdoc
	function fieldValidationRules(type, regex, label) {

		const pattern = regularExpression[regex];
		switch (type) {
			case 'string':
				return yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`)
					});
			case 'email':
				return yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).email()
					});
			case 'website':
				return yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).url()
					});
			case 'number':
				return yup.object().shape(
					{
						[name]: yup.number().required(`${label} is required`)
					});
			case 'regex':
				return yup.object().shape(
					{
						[name]: yup.string()
							.matches(pattern, function(props) { return `${label} is not in the right format`;})
							.required(`${label} is required`)
					});
			case 'date' :
				return yup.object().shape(
					{
						[name]: yup.date()
							.required(`${label} is required`)
							.transform(function (_, value) {
								return value ? isDate(value) ? value : parse(value, 'MM-dd-yyyy', new Date()) : undefined;
							})
							.typeError(`${label} must be of format: MM-DD-YYYY`)
							.max(maximumDate, function(props) {
								return `${label} must not be earlier than ${moment(props.max).format('MM-DD-YYYY')}`;
							}),
					});
			default:
				return yup.object().shape({ [name]: '' });
		}
	}

	const onSubmitted = (data) => {
		setSelected(false);
		if (data) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: moment(data[name], 'MM-DD-YYYY', true).isValid() ? moment(data[name]).format('MM-DD-YYYY') : data[name]
				}
			}));}
	};

	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: watch()?.[name] }));

		if (pageNumber === 2) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: moment(watch()?.[name], 'MM-DD-YYYY', true).isValid() ? moment(watch()?.[name]).format('MM-DD-YYYY') : watch()?.[name]
				}
			}));

		} else {
			if (watch()?.[name]) handleSubmit(onSubmitted)();
		}
	}, [ watch()?.[name] ]);

	useEffect(() => {
		setPageError(prev => ({ ...prev, [name]: errors[name]?.message }));
	}, [ errors[name]?.message ]);

	return (
		<>
			<Typography className={selected ? classes.selectedLabel : classes.label}>
				<span className={classes.asterisk} >*</span>
				{label}  { showInfo && <LightTooltip  title={info} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon style={{ color: COLORS.blueButtons }} fontSize='small'/>
				</LightTooltip >}
			</Typography>
			<Controller control={control} name={name}
				render={({ field }) => (
					<NumberFormat
						format={format}
						customInput={TextField} {...field} onFocus={() => setSelected(true)} type={type}
						placeholder={format}
						onBlur={ handleSubmit(onSubmitted)}
						fullWidth
						variant='outlined'
						size='small'
						InputProps={{ classes: { root: classes.selectedBorder } }}
						helperText={errors[name]?.message}
						error={!!errors[name]?.message}
						type={formatType}
						prefix={prefix}
						suffix ={suffix}
						disabled={disableOwnerShip}
					/>
				)}
			/>
		</>
	);

}

SignUpNumber.propTypes = {
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	type: PropTypes.string,
	regex: PropTypes.string,
	name: PropTypes.string,
	componentName: PropTypes.string,
	format: PropTypes.string,
	formatType: PropTypes.string,
	showIcon: PropTypes.bool,
	setPageError: PropTypes.func,
	prefix: PropTypes.string,
	suffix: PropTypes.string,
	businessType: PropTypes.string,
	disableOwnerShip: PropTypes.bool,
	defaultName: PropTypes.any,
	ownerShipfieldName: PropTypes.string,
	maximumDate: PropTypes.any,
	pageNumber: PropTypes.number
};

export default memo(SignUpNumber);