
import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReportNotFounddata from '../../assets/images/ReportNotFounddata.png';

const ReportsNotFoundData = ({}) => {

	return (<Grid container justifyContent='center' alignItems='center'>
		<Grid lg={12} md={12} sm={12} xs={12}>
			<Grid container justifyContent='center' alignItems='center'>
				<img src={ReportNotFounddata} alt="APX" style={{ width: '17%', marginTop: '1%' }}/>
			</Grid>
		</Grid>
		<Grid style={{ marginTop: '1%', marginBottom: '1%', fontWeight: 'bold' }}>No result found.</Grid>
	</Grid>);
};

export default ReportsNotFoundData;