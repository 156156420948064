import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import notfoundpage from '../../assets/images/not-found-page.png'

const useStyles = makeStyles((theme) => ({
    typography: {
        height: '100vh',
        backgroundColor: 'white',
        borderLeft: '1px solid #cdced1',
        borderRight: '1px solid #cdced1',
        margin: 0.5
    },
    image: {
        maxWidth: '40%',
        maxHeight:'auto',
        marginTop: 35,
        marginLeft: '29%',
    },
    title: {
        marginTop: 20,
        fontWeight: 900,
    },
    textBox: {
        marginTop: 15,
        maxWidth: '70%',
    },
    text: {
        fontSize: '70%',
        color: theme.palette.grey[800],
        fontFamily:'Times New Roman',
        fontWeight: 550
    }
}));

function NotFoundPage({ title, messages }) {
    const classes = useStyles();

    const showMessages = () => {
        return messages.map((message, index) => {
            return <Grid item key={index} className={classes.text}>{message}</Grid>;
        });
    }

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm">
                <Typography component="div" className={classes.typography} >
                    <Grid container direction='column' alignItems='center'>
                        <Grid item><img src={notfoundpage} className={classes.image}/></Grid>

                        <Grid item className={classes.title}>{title}</Grid>
                        <div className="textBox"></div>
                        {showMessages()}
                    </Grid>
                </Typography>
            </Container>
        </React.Fragment>
    )
}

export default NotFoundPage;
