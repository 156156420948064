import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';

import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useForm,  Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';


const useStyles = makeStyles({
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	selectedLabel: {
		color: COLORS.blueButtons,
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#455A64',
		fontFamily: 'Roboto',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1.4%'
	},
	text: {
		color: '#455A64',
		fontFamily: 'Roboto',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: '14px',
		position: 'relative',
		backgroundColor: '#ECEFF1'
	}
});

// eslint-disable-next-line require-jsdoc
function SignUpBindedField({
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	label = '',
	name = '',
	data = '',
	componentName = '',
	prefix = '',
   disabledField = true
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);

	const { control, formState: { } } = useForm();
	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: prefix + data }));
		setSignupFields(prev => ({ ...prev,
			[componentName]: {
				...prev[componentName],
				[name]: prefix + data
			}
		}));

	}, [ data ]);
	return (
		<div style={{ marginTop: '1%' }}>
			<Typography className={selected ? classes.selectedLabel : classes.label} style={{ marginLeft: '2%' }}>
				{label}
			</Typography>
			<Controller control={control} name={label}
				render={({ field }) => (
					<TextField {...field} className={classes.text} style={{ position: 'relative' }}
						fullWidth
						variant='outlined'
						size='small'
						value={(prefix && data) ? prefix + data : data}
						InputProps={{ classes: { root: classes.selectedBorder },
							startAdornment: (
								<InputAdornment position="start">
									{ prefix && <VisibilityIcon />}
								</InputAdornment>
							)
						}}
						disabled={disabledField}
					/>
				)}
			/>
		</div>
	);

}

SignUpBindedField.propTypes = {
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	data: PropTypes.string,
	componentName: PropTypes.string,
	prefix: PropTypes.string,
	disabledField: PropTypes.bool
};

export default memo(SignUpBindedField);