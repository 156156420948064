import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Button, Container, Typography } from '@material-ui/core';
import Header from './Header';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { COLORS } from '../constants/Colors';
import Footer from './Footer';
import { getApiUrl } from '../utils/getApiUrl';
import axios from 'axios';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import InProgress from '../forms/InProgress';
import useWindowSize from '../hooks/useWindowSize';
import ReceiptPreview from './ReceiptPreview';

const useStyles = makeStyles((theme) => ({
	page: {
		backgroundColor: '#ECEFF1'
	},
	bold: {
		fontWeight: 'bold',
	},
	paper: {
		padding: theme.spacing(3),
		textAlign: 'center'
	},
	greenContainer: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: COLORS.greenText,
		backgroundColor: COLORS.greenBackground,
		fontWeight: 'bold',
		borderColor: COLORS.greenBorder,
		border: '2px solid',
	},
	blueContainer: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: COLORS.blueText,
		backgroundColor: COLORS.blueBackground,
		fontWeight: 'bold',
		borderColor: COLORS.blueBorder,
		border: '2px solid',
	},
	bgWhite: {
		backgroundColor: COLORS.whiteBackground,
		border: '1px solid  #CFD8DC',
	},
	authCode: {
		color: COLORS.blackText,
		textAlign: 'center',
	},
	authBorder: {
		borderColor: COLORS.grayBorder,
		border: '1px dashed', padding: theme.spacing(2),
	},
	btnDownload: {
		fontWeight: 600,
		color: COLORS.blueButtons,
		borderColor: COLORS.blueButtons
	},
	footer: {
		backgroundColor: '#E3E5E8',
		color: COLORS.footerText,
		padding: theme.spacing(1)
	},
	spacing: {
		padding: theme.spacing(2)
	},
	header: {
		padding: theme.spacing(2),
		boxShadow: '0 2px 2px 0 rgba(38,50,56,0.20)'
	},
	previewContainer: {
		border: `1px solid ${COLORS.grayBorder}`,
		borderLeftWidth: 0,
		borderRightWidth: 0,
		backgroundColor: COLORS.lightGray,
		overflow: 'auto',
		width: '100%',
	},
	previewPageContainer: {
		border: `1px solid ${COLORS.grayBorder}`,
		borderTopWidth: 0,
		borderBottomWidth: 0,
		backgroundColor: 'white',
		padding: theme.spacing(3),
		margin: '0 auto',
		width: '100%',
		maxWidth: '300px',
	},
	invoice: {
		borderColor: COLORS.grayBorder,
		border: '1px solid',
		backgroundColor: COLORS.whiteBackground,
		width: '80%',
		margin: '0 auto'
	},
	email: {
		fontWeight: 'bold',
		fontStyle: 'italic'
	},
	textBottom: {
		color: theme.palette.text.secondary,
		fontStyle: 'italic'
	},
	summary: {
		backgroundColor: COLORS.grayContainer,
		padding: theme.spacing(1),
		borderColor: COLORS.grayBorder,
		border: '1px solid',
		marginLeft: '5%',
		width: '90%'
	},
	marginSummary: {
		marginBottom: '15px',
		borderColor: COLORS.grayBorder,
		border: '1px solid',
		marginLeft: '5%',
		width: '90%'
	},
	summaryItem: {
		borderTop: '1px solid',
		borderColor: COLORS.grayBorder,
		padding: '10px',
		flexWrap: 'wrap',
		overflowWrap: 'break-word'
	},
	blankRow: {
		borderTop: '1px solid',
		borderColor: COLORS.grayBorder,
		padding: '5px',
		height: '30px'
	},
	headerIcon: {
		verticalAlign: 'bottom',
		marginRight: '5px',
		color: '#41B832',
		fontWeight: 'bold'
	},
	headerBold: {
		fontWeight: 600
	}

}));
function Receipt() {
	const classes = useStyles();
	const location = useLocation();
	const history = useHistory();
	const [ showProgressBar, setShowProgressBar ] = useState(false);

	const merchantInfo = location.state?.data.merchantInfo;
	const transaction = location.state?.data.transaction;
	const axiosConfig = location.state?.data.axiosConfig;
	const invoiceId = location.state?.data.invoiceId;
	const amount = location.state?.data.amount;
	const email = location.state?.data.email;
	const requestOfPayment = location.state?.requestOfPayment;

	useEffect(() => {
		if (!transaction || !transaction.transactionDate) {
			const data = { email: requestOfPayment.email };
			history.push('/paid-receipt', { data });
		}
		transaction.transactionDate = moment(transaction.transactionDate).format('MM/DD/YYYY');
	}, [ transaction ]);

	const downloadReceipt = async () => {
		setShowProgressBar(true);
		const data = { merchantInfo, transaction, amount, invoiceId };
		axios.post(`${getApiUrl()}/api/merchantwebapi/generate_receipt`, data, axiosConfig)
			.then(({ data }) => window.open(data.downloadUrl))
			.finally(() => setShowProgressBar(false));
	};

	const { width } = useWindowSize();
	const ContainerTag = width >= 600 ? Container : 'div';

	return (
		<>
			<div className={classes.page}>
				<ContainerTag maxWidth='md' >
					<Grid container direction='row' className={classes.bgWhite}>
						<Container maxWidth='md' className={classes.header} >
							<Header requestOfPayment={requestOfPayment} />
						</Container>
						{transaction.invoiceAlreadyPaid ?
							<Grid item xs={12} className={classes.blueContainer}>
								<Typography>
									<InfoIcon className={classes.headerIcon} />
                                    Invoice was Paid
								</Typography>
							</Grid> :
							<Grid item xs={12} className={classes.greenContainer}>
								<Typography className={classes.headerIcon}><CheckCircleIcon className={classes.headerIcon} /> Payment Confirmation</Typography>
							</Grid>
						}
						<Grid item xs={12} className={classes.paper}>
							<Typography>We sent payment confirmation details to this email address:</Typography>
							<Typography className={classes.email}>{email || transaction.email}</Typography>
						</Grid>
						<div className={classes.previewContainer}>
							<div className={classes.previewPageContainer}>
								<ReceiptPreview transaction={{ ...transaction, amount }} merchantInfo={merchantInfo} />
							</div>
							{showProgressBar && <InProgress/>}
						</div>
						<Grid item xs={12} className={classes.paper}>
							<Button id={'download'} variant='outlined' className={classes.btnDownload} onClick={async () => { await downloadReceipt(); }}>
                                DOWNLOAD
							</Button>
						</Grid>
						<InProgress/>
						<Grid item xs={12} lg={12} md={12} >
							<Footer />
						</Grid>
					</Grid>
				</ContainerTag>
			</div>
		</>
	);
}

export default memo(Receipt);
