import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';

const useStyles = makeStyles((theme) => ({
	paymentType: {
		height: 'auto',
		width: 50,
		marginTop: 2
	},
	bankAccount: {
		marginLeft: 4,
		color: COLORS.roboto
	},
	gridItem: {
		color: '#263238',
		fontSize: 14,
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: 7
	},
	name: {
		height: 12,
		// width: 90,
		color: '#263238',
		fontSize: 15,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '12px',
		marginTop: 5
	},
	labelMargin: {
		marginTop: 4
	},
	email: {
		marginTop: 4,
		textDecoration: 'underline'
	}
}));

const ExistingPaymentMethodInfo = (props) => {
	const classes = useStyles();

	const expiration = props.payment.expiration ? `${props.payment.expiration.slice(0,2)} / ${props.payment.expiration.slice(2)}` : '';

	return (
		< >
			<Grid className={classes.container}>
				<Grid item className={classes.name} xs={10}>{props.payment.first} {props.payment.last}</Grid>
				{props.isCreditCard ? (
					<Grid item xs={12} className={classes.gridItem}>
						<Grid container>
							<Grid item xs={12}> {`${props.payment.creditCardType || ''} ...${props.payment.accountNumber || ''} | Expires ${expiration}`}</Grid>
							{ props.payment.city && props.payment.address1 ?
								<Grid item xs={12} className={classes.labelMargin}>{`${props.payment.address1 || ''}, ${props.payment.city || ''}`}</Grid>
								: null
							}
							<Grid item xs={12} className={classes.email}>{`${props.payment.email || ''}`}</Grid>
						</Grid>
					</Grid>
				) : (<Grid item xs={12} className={classes.gridItem}>
					<Grid container>
						<Grid item xs={12}>{`ACH ...${props.payment.accountNumber || ''} | ${props.payment.bankAccountType || ''}`}</Grid>
						<Grid item xs={12} className={classes.labelMargin}>{`${props.payment.address1 || ''}, ${props.payment.city || ''}`}</Grid>
						<Grid item xs={12} className={classes.email}>{`${props.payment.email || ''}`}</Grid>
					</Grid>
				</Grid>)
				}
			</Grid>
		</>);
};

export default ExistingPaymentMethodInfo;
