import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APXlogoSignup from '../../assets/images/APXlogosignup.png';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { COLORS } from '../constants/Colors';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 50
	},
	text: {
		fontWeight: 700,
		color: COLORS.whiteText,
		marginLeft: '2%'
	},
	divider: {
		height: 50,
		backgroundColor: 'gray',
	},
	container: {
		backgroundColor: COLORS.headerDarkBlue
	}
}));

function Header() {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid item lg={12}  md={12} xs={12} sm={12} className={classes.container}>
				<Container maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justifyContent='center'>
						<Grid item md={2} xs={3} lg={1} xl={1} sm={2}>
							<img src={APXlogoSignup} alt="APX" style={{ width: '75%' }}/>
						</Grid>
						<Divider orientation="vertical" flexItem className={classes.divider}/>
						<Grid item md={8} xs={8} lg={10} sm={8} className={classes.text}>
							Abacus Payment Exchange Account Signup
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
}
export default Header;