import React, { memo,useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SignUpTextField from './SignUpTextField';
import Grid from '@material-ui/core/Grid';
import SignUpSelectField from './SignUpSelect';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {TextField} from '@material-ui/core';

import CancelIcon from '@material-ui/icons/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { DOCUMENTTYPE } from '../../constants/Locations';


const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions);

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#263238',
		letterSpacing: 0,
	},
	supportingDocument: {
		color: '#263238',
		fontWeight: 'bold',
		letterSpacing: 0,
		marginBottom: 20,
		marginTop: 20
	},
	rectangleUploadDocument: {
		boxSizing: 'border-box',
		border: '2px dashed #B0BEC5',
		borderRadius: 4,
		backgroundColor: '#FFFFFF',
		height: '9vh',
		marginBottom: 20,
		width: '99%',
		marginLeft: '0.25vh'
	},
	dragText: {
		color: '#90A4AE',
		fontWeight: 500,
		cursor: 'pointer',
	},
	browseFileText: {
		color: '#0E60B2',
		fontWeight: 700,
		lineHeight: '16px',
		cursor: 'pointer',
		textTransform: 'none'
	},
	dragAndDrop: {
		color: '#90A4AE', 
		height: 60,
		width: 50,
		cursor: 'pointer'
	},
	cancelButton : {
		cursor: 'pointer',
	},
	filename: {
		backgroundColor: '#ECEFF1'
	}
}));

const DragAndDrop = ({
		setBankInfo = () => {},
		setPageError = () => {},
		files = [],
		setFiles = () => {},
	 	persistFiles = () => {},
	 	persitDocumentType = () => {},
	 	bankInfo = {},
	 	documentsType = {},
		persistedFiles= [],
}) => {
	const classes = useStyles();


	const uploadFile = (e) => {
			window.fileElem.click();
	};

	const onInputChange = (e) => {
		const uploadedFiles = e.target.files;
		const filesObj = {...files};
		let number = _.keys(filesObj)[_.keys(filesObj).length - 1] ? _.keys(filesObj)[_.keys(filesObj).length - 1] : 0
		for (let i = 0; i < uploadedFiles.length; i++) {
			const file = uploadedFiles.item(i);
			number = +number + 1
			filesObj[number] = file
		}
		setFiles(filesObj)
	};

	const drop = (e) => {
		e.stopPropagation();
		e.preventDefault();
		const dt = e.dataTransfer;
		const uploadedFiles = dt.files;
		const filesObj = {...files};
		let number = _.keys(filesObj)[_.keys(filesObj).length - 1] ? _.keys(filesObj)[_.keys(filesObj).length - 1] : 0
		for (let i = 0; i < uploadedFiles.length; i++) {
			const file = uploadedFiles.item(i);
			number = +number + 1
			filesObj[number] = file
		}
		setFiles(filesObj)
	};

	const onDrag = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const deleteDocument = (fileKey) => {

		delete documentsType?.documentTypes?.[`DocumentType${fileKey}`]
		delete documentsType?.notes?.[`Note${fileKey}`]
		const newDocumentsTypeLength = _.values(documentsType.documentTypes).length
		let number = 1
		const newDocumentsType= {
			documentTypes: {},
			notes: {}
		}
		for (let i = 0; i < newDocumentsTypeLength; i++) {
			newDocumentsType.documentTypes[`DocumentType${number}`] = _.values(documentsType.documentTypes)[i]
			newDocumentsType.notes[`Note${number}`] = _.values(documentsType.notes)[i]
			number = number + 1
		}

		persitDocumentType(newDocumentsType)

		const newBankIfo = {...bankInfo}
		delete newBankIfo?.[`DocumentType${fileKey}`]
		delete newBankIfo?.[`Note${fileKey}`]
		setBankInfo(newBankIfo)

		delete files[fileKey]

		let number1 = 1
		const filesLength = _.values(files).length
		const newFiles= {}
		for (let i = 0; i < filesLength; i++) {
			newFiles[number1] = _.values(files)[i]
			number1 = number1 + 1
		}

		setFiles(newFiles)

	}



	useEffect(() => {
		 setBankInfo(prev => ({ ...prev, fileElem: _.values(files) }));
		persistFiles(_.values(files))
	},[ files ]);

	useEffect(() => {
		let number = 1
		const newOperatingFiles= {}
		for (let i = 0; i < persistedFiles.length; i++) {
			newOperatingFiles[number] = persistedFiles[i]
			number = number + 1
		}

		setFiles(newOperatingFiles)
	}, [])

	const documentType = [ ..._.values(documentsType?.documentTypes)];
	const notes = [ ..._.values(documentsType?.notes) ];


	return (
		<>
			<Grid container className={classes.rectangleUploadDocument} direction='row' spacing={1}  alignItems='center' justifyContent='center'
				onDragOver={onDrag} onDragEnter={onDrag} onDropCapture={drop}>
				<Grid item >
					<Grid container direction='row' spacing={1}  alignItems='center' justifyContent='center'>
						<Grid item>
							<CloudUploadIcon className={classes.dragAndDrop}/>
						</Grid>
						<Grid item className={classes.dragText}  >Drag and Drop here</Grid>
					</Grid>
				</Grid>
				<Grid item className={classes.dragText} >or</Grid>
				<Button id={'upload'} variant="text" onClick={uploadFile} size="small" style={{width: '25%', marginTop: '4%', height: '60%'}} component="span" disableRipple>

					<Grid  container= 'row' className={classes.browseFileText}  >
						<Grid item  >Browse files</Grid>
						<Grid item  >
							<input type="file" id="fileElem" multiple accept="image/*,.pdf, .tif, .tiff"
								   onChange={onInputChange} style={{opacity: '0', height: '0', width: '10%'}}/>
						</Grid>
					</Grid>
				</Button>

			</Grid>
			{_.map(files,(file, fileNumber) => {

				return (
					<div key={file.name + fileNumber} >
						{ fileNumber ? <hr/> : null}
						<Grid container direction='row' spacing={2} style={{marginTop: '3%'}}>
							<Grid item  xs={12} md={6}>
								<SignUpSelectField
									label='Document Type'
									componentName='documentTypes'
									name={`DocumentType${fileNumber}`}
									type='string'
									setCheckIsSateEmpty={setBankInfo}
									showInfo={false}
									list={DOCUMENTTYPE}
									setPageError={setPageError}
									setSignupFields={persitDocumentType}
									defaultName={documentType[fileNumber -1]}
								/>
							</Grid>
							<Grid item xs={12} md={6} style={{marginTop: '1%'}}>
								<SignUpTextField
									label='Note'
									name={`Note${fileNumber}`}
									componentName='notes'
									type='string'
									showInfo={false}
									setSignupFields={persitDocumentType}
									setCheckIsSateEmpty={setBankInfo}
									defaultName={notes[fileNumber -1]}
								/>
							</Grid>
						</Grid>

						<Grid container direction='row' style={{marginTop: '1%'}}>
							<Grid item  xs={12} md={12} lg={12}>
								<TextField className={classes.filename}
									fullWidth
									variant='outlined'
									size='small'
									value={file?.name || ''}
									InputProps={{ classes: { root: classes.selected },
										endAdornment: (
											<InputAdornment >
												<CancelIcon onClick={() =>  deleteDocument(fileNumber)} className={classes.cancelButton}/>
											</InputAdornment>
										)
									}}
								/>
							</Grid>
						</Grid>
					</div>
				);
			})}

		</>
	);
};

DragAndDrop.propTypes = {
	setBankInfo: PropTypes.func,
	setPageError: PropTypes.func,
	files: PropTypes.array,
	setFiles: PropTypes.func,
	persistFiles: PropTypes.func,
	persitDocumentType: PropTypes.func,
	bankInfo: PropTypes.func,
	documentsType: PropTypes.object,
	persistedFiles: PropTypes.array,
};

export default memo(DragAndDrop);

