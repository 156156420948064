import React, { useState, memo } from 'react';
import SyncCard from '../forms/SyncCard';
import axios from 'axios';
import { getApiToken, getApiUrl } from '../utils/getApiUrl';
import FormTextField from '../forms/FormTextField';
import { FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { COLORS } from '../constants/Colors';

function MerchantAccounts(props) {
	const [isSyncing, setIsSyncing] = useState(false);
	const [merchantId, setMerchantId] = useState('');
	const [merchantAccounts, setMerchantAccounts] = useState([])
	const [open, setOpen] = useState(false);
	const [saveText, setSaveText] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleOk = () => {
		updateAccounts().then(() => {
			handleClose(); // Close on error or success, error caught in called method
		});
	};

	// Pull accounts from Payrix for display in UI
	const sync = async () => {
		setIsSyncing(true);
		setAccountInfo({}); // Reset account info
		setMerchantAccounts({}); // Reset account info
		setSaveText(''); // Reset save text, e.g. hide from user
		try {
			const response = await axios.get(`${getApiUrl()}/api/admin/get_merchant_accounts?merchantId=${merchantId}`, {
				headers: { Authorization: `Bearer ${getApiToken()}` }
			});
			if (response.data.code === 200) {
				setMerchantAccounts(response.data.accounts);
				if (response.data.accounts?.length > 0){
					setSaveText('Save to APX'); // Show save text, allowing user to persist.
				}
			}
			setIsSyncing(false);
		} catch (err) {
			console.log(err);
			setIsSyncing(false);
			this.throw('Error retrieving accounts from Payrix, please try again.');
		}
	};

	// Attempt to update accounts in APX database according to selections made by end-user
	// this will also set isTrust downstream in Payrix and APX is user selected a Trust account
	const updateAccounts = async () => {
		const accounts = merchantAccounts.map((obj) => {
			return {
				accountId: obj.id,
				accountType: accountInfo[obj.account.id],
				token: obj.token,
				routing: obj.account.routing,
				account: obj.account.number
			};
		});
		setIsSyncing(true);
		try {
			await axios.put(`${getApiUrl()}/api/admin/update_merchant_account_type`, { merchantId, accounts }, {
				headers: { Authorization: `Bearer ${getApiToken()}` }
			});
			setIsSyncing(false);
		} catch (err) {
			console.log(err);
			setIsSyncing(false);
			this.throw('Error updating accounts in APX, please try again.');
		}
	};

	const onInputChange = ({ target: { value } }) => {
		setMerchantId(value);
	};
	const [accountInfo, setAccountInfo] = useState({});

	const handleChange = (event) => {
		const { target: { value, name } } = event
		setAccountInfo(prev => ({ ...prev, [name]: value }))
	};

	const merchantField = () => {
		return <div>
			<FormTextField required={false} placeholder='Merchant Id' id='merchantId' value={merchantId} onChange={onInputChange} />
			{ merchantAccounts.length > 0 && <FormLabel>
					<Grid container md={12} style={{ marginTop: '10px' }}>
						<Grid item md={12}>
							<Typography style={{ marginTop: '4px' }}>Select the account type (Trust, Operating) for each account(s) found in Payrix, use the save button below to persist this into the APX database.</Typography>
						</Grid>
						<Grid item md={12}>
							<Typography style={{ marginTop: '10px', fontWeight: 'bold' }}>Payrix accounts found:</Typography>
						</Grid>
						{merchantAccounts.map(function (obj, i) {
							return <Grid container md={12} alignItems="center" style={{ padding: '4px 12px', border: '1px solid #ccc', marginBottom: '10px', background: '#f5f5f5' }}>
								<Grid item md={6}>
									<Typography style={{ fontWeight: 'bold' }}>{i + 1}. Routing # x{obj.account.routing} / Acct # x{obj.account.number}</Typography>
								</Grid>
								<Grid item md={4}>
									{/* -- Use account id to prevent collisions -- */}
									<RadioGroup row name={obj.account.id} onChange={handleChange}>
										<FormControlLabel value="Operating" control={<Radio style={{ color: COLORS.blueButtons, marginLeft: '-8px' }} name={obj.account.id} />} label="Operating" />
										<FormControlLabel value="Trust" control={<Radio style={{ color: COLORS.blueButtons, marginLeft: '-8px' }} name={obj.account.id} />} label="Trust" />
									</RadioGroup>
								</Grid>
								<Grid item md={2}>
									{/* -- May be helpful to end user to know which is set as the primary in Payrix -- */}
									{obj.primary == 1 && <div style={{ fontWeight: 'bold', color: '#55aa88' }}> ⭐ PRIMARY</div>}
								</Grid>
							</Grid>
						})}
					</Grid>
				</FormLabel>
			}
			{ /* Confirmation Dialog */}
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">
					{"Save Changes?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to save these account changes to APX?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleOk} autoFocus>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	};

	return (
		<SyncCard 
			title="Map Merchant Bank Accounts 🆕"
			sync={sync}
			actionTitle="Pull Accounts from Payrix"
			actionTitle2={saveText}
			showRecordsCount={false}
			isSyncing={isSyncing}
			refresh={handleOpen}
			inputField={merchantField}
			{...props} />
	);
}

export default memo(MerchantAccounts);