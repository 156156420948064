export const WelcomePageIconsInfo = {
        pciDataSecurityStandard: `Your customers can be confident that their data is secure. 
        We are fully in compliance with industry standards 
        like the Payment Card Industry Data Security Standard (PCI DSS).`,
        transparentPricing:`We keep things simple with a fixed rate and no hidden fees.`,
        advanceReporting:`Manage and review transactions, do reconciliation, configure your 
        account, view statements, and download reports through our password-protected portal.`,
        trustOperatingAccount:`We understand the responsibility that comes with handling client funds. 
        APX ensures trust and operating account balances stay accurate.`,
        payNowOnlinePayment: `Unlike other payment solutions, we provide online payment and PayNow options 
        to process your accounts and bill clients. This means you’ll get paid as quickly as your bank allows`,
        recurringPayments:`Create and manage recurring payments schedules. More revenue while saving time and cost.`

};

