import React from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';

import BankInfoFields from './BankInfoFields';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {COLORS} from "../../constants/Colors";

const DialogTitle = withStyles(theme => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
		color: theme.palette.grey[500],
	},
	title: {
		color: '#263238',
		fontWeight: 'bold',
		letterSpacing: 0,
	}
}))(props => {
	const { children, classes, onClose } = props;

	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h6" className={classes.title}>{children}</Typography>
			{onClose ? (
				<IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		borderTop: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit,
	},
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
	successfulMessage: {
		color: '#29961D',
		fontSize: 16,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: '1%',
		marginLeft: '1%'
	},
	successfulIcon: {
		height: 30,
		width: 30,
		backgroundColor: '#41B832',
	},
	congratulationHeader: {
		boxSizing: 'border-box',
		height: 65,
		border: '1px solid #CFD8DC',
		backgroundColor: '#ECF7EA'
	},

}));


const SignupDialog = ({
	handleClose = () => {},
	title = '',
	open = false,
	addBankAccount = () => {},
	buttonText = '',
	dialogOrigin = '',
	disableDialogButton = true,
	setBankInfo = () => {},
	setPageError = () => {},
  	persistFiles = () => {},
  	persitDocumentType = () => {},
  	bankInfo = {},
  	documentsType= {},
  	persistedFiles = [],
  	addAccManually = []
}) => {
	const classes = useStyles();
	const history = useHistory();

	const renderDialog = (dialogOrigin) => {
		switch (dialogOrigin) {
			case 'addbankinfo':
				return (
					<div >
						<Dialog
							onClose={handleClose}
							aria-labelledby="customized-dialog-title"
							open={open}
						>
							<DialogTitle id="customized-dialog-title" onClose={handleClose} >
								{title}
							</DialogTitle >
							<BankInfoFields
								handleClose={handleClose}
								title={title}
								buttonText={buttonText}
								addBankAccount={addBankAccount}
								disableDialogButton={disableDialogButton}
								setBankInfo={setBankInfo}
								setPageError={setPageError}
								persistFiles={persistFiles}
								persitDocumentType={persitDocumentType}
								bankInfo={bankInfo}
								documentsType={documentsType}
								persistedFiles={persistedFiles}
								addAccManually={addAccManually}
							/>
						</Dialog>
					</div>
				);

			case 'plaid':
				return (
					<div >
						<Dialog
							onClose={handleClose}
							aria-labelledby="customized-dialog-title"
							open={open}
						>
							<DialogTitle id="customized-dialog-title" onClose={handleClose} >
								{title}
							</DialogTitle >
							<Container className={classes.congratulationHeader} direction='row' >
								<Grid container  direction='row'  spacing={2}  style={{ marginTop: '2%', marginBottom: '2%' }}>
									<Grid item  >
										<Avatar className={classes.successfulIcon}>
											<CheckIcon style={{ color: 'white' }}/>
										</Avatar>
									</Grid>
									<Grid item classes={classes.successfulMessage}>Bank Account successfully added!</Grid>
								</Grid>
							</Container>
							<DialogContent >
								<Grid>
							Plaid session has started.<br/><br/>
							Ensure you submit your application within <span style={{ fontWeight: 'bold' }}> 30 minutes</span>,<br/>
							otherwise you will need to add bank account again.
								</Grid>
							</DialogContent>

							<DialogActions>
								<Grid item >
									<Button
										onClick={handleClose}
										id={'ok'}
										style={{ background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)', color: 'white' }}
									>
											Ok
									</Button>
								</Grid>
							</DialogActions>
						</Dialog>
					</div>
				);
			default:
				return null;
		}
	};
	return (renderDialog(dialogOrigin));
};

SignupDialog.propTypes = {
	setOpen: PropTypes.func,
	title: PropTypes.string,
	handleClickOpen: PropTypes.func,
	handleClose: PropTypes.func,
	open: PropTypes.bool,
	addBankAccount: PropTypes.func,
	buttonText: PropTypes.string,
	dialogOrigin: PropTypes.string,
	disableDialogButton: PropTypes.bool,
	setBankInfo: PropTypes.func,
	setPageError: PropTypes.func,
	persistFiles: PropTypes.func,
	persitDocumentType: PropTypes.func,
	bankInfo: PropTypes.object,
	documentsType: PropTypes.object,
	persistedFiles: PropTypes.array,
	addAccManually: PropTypes.array

};

export default SignupDialog;
