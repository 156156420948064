import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import * as yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles({

	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	dropDown: {
		marginLeft: 20,
		marginTop: 10,
	},
	select: {
		backgroundColor: COLORS.whiteText,
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	label: {
		color: '#455A64',
		fontFamily: 'Roboto',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1vh'
	},
	selectedLabel: {
		color: COLORS.blueButtons,
		fontFamily: 'Roboto',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1vh'
	},

});

// eslint-disable-next-line require-jsdoc
function SignUpSelectField({
	savedData = { },
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	showInfo = true,
	info = '',
	label = '',
	list = [],
	name = '',
	componentName = '',
	setPageError = () => {},
	defaultName = '',
	restPrimaryContact = false,
	setrestPrimaryContact = () => {},
	pageNumber = 1,
	optionSelected = () => {},
	hasKeyValuePair = false
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);
	const schema = yup.object().shape({
		[name]: yup.string().required(`${label} is required`) });
	const { watch, control, reset, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			[name]: savedData?.[name] || defaultName
		},
		resolver: yupResolver(schema)

	});

	useEffect(()=> {
		if (savedData?.[name]) {
			reset(savedData);
		}
	}, [ savedData ]);

	useEffect(()=> {
		if (restPrimaryContact) {
			reset({ primaryContact: '' });
		}
	}, [ restPrimaryContact ]);

	const onSubmitted = (data) => {
		setSelected(false);
		if (data) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: data[name]
				}
			}));
		}
		optionSelected(data[name] || '');
	};

	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: watch()?.[name] }));
		if (pageNumber === 2) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: watch()?.[name]
				}
			}));

		} else {
			if (watch()?.[name]) handleSubmit(onSubmitted)();
		}
		if (name === 'primaryContact' && watch()?.[name]) setrestPrimaryContact(false);

	},  [ watch()?.[name] ]);

	useEffect(() => {
		setPageError(prev => ({ ...prev, [name]: errors[name]?.message }));
	}, [ errors[name]?.message ]);

	return (
		<FormControl required fullWidth={true} variant="outlined" size="small" error={!!errors[name]?.message} >
			<Typography className={selected ? classes.selectedLabel : classes.label}>
				<span className={classes.asterisk} >*</span>
				{label}  { showInfo && <LightTooltip title={info} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon style={{ color: COLORS.blueButtons }} fontSize='small'/>
				</LightTooltip>}
			</Typography>
			<Controller control={control} name={name}
				render={({ field }) => (
					<Select
						fullWidth
						variant='outlined'
						size='small'
						className={classes.select}  onFocus={() => setSelected(true)}
						{...field} onBlur={handleSubmit(onSubmitted)} >
						{list.map((item, index) => {
							const key = hasKeyValuePair ? item.value : item;
							return <MenuItem key={key + index} value={key}>
								{hasKeyValuePair ? item.label : item}
							</MenuItem>;
						})}
					</Select>
				)}
			/>
			<FormHelperText id="my-helper-text">{errors[name]?.message}</FormHelperText>
		</FormControl>
	);

}

SignUpSelectField.propTypes = {
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	list: PropTypes.array,
	name: PropTypes.string,
	componentName: PropTypes.string,
	setPageError: PropTypes.func,
	defaultName: PropTypes.string,
	restPrimaryContact: PropTypes.bool,
	setrestPrimaryContact: PropTypes.func,
	pageNumber: PropTypes.number,
	optionSelected: PropTypes.func
};

export default memo(SignUpSelectField);