import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';

const useStyles = makeStyles({
	currency: {
		marginLeft: 10,
		fontWeight: 900,
		float: 'left'
	},
	surcharge: {
		fontStyle: 'italic',
		color: COLORS.darkGray,
		marginLeft: '4px',
	},
	amount: {
		marginLeft: 16,
	},
	paymentDetails: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		borderRadius: 2,
		backgroundColor: '#DCEDFE',
	}
});

function PaymentDetails({
	currency = '$',
	amount = '0',
	hasSurcharge = false,
	isTrustTransaction = false,
	isCreditCard = false
}) {

	const classes = useStyles();

	return (
		<div>
			<Box component="span" display="flex" p={1} className={classes.paymentDetails}>
				<Typography className={classes.amount}>Total Payment Amount:</Typography>
				<Typography className={classes.currency}>{`${currency} ${Number(amount).toFixed(2)}`} </Typography>
				{hasSurcharge && !isTrustTransaction && isCreditCard ? <Typography className={classes.surcharge} > (Includes 3% surcharge on applicable items)</Typography> : null}
			</Box>
		</div>

	);
}

PaymentDetails.propTypes = {
	currency: PropTypes.string,
	amount: PropTypes.string,
	isCreditCard: PropTypes.bool,
};

export default memo(PaymentDetails);