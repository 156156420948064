import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { useForm, Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles({
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	selectedLabel: {
		color: COLORS.blueButtons,
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#455A64',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	}
});

// eslint-disable-next-line require-jsdoc
function SignUpDatePicker({
	savedData = { },
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	showInfo = true,
	info = '',
	label = '',
	name = '',
	componentName = '',
	setPageError = () => {},
	disableOwnerShip = false,
	defaultName = '',
	ownerShipfieldName = '',
	maximumDate = '',
	pageNumber = 1
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);
	const [ value, setValue ] = useState(null);
	
	const { watch, control, reset, handleSubmit, formState: { errors }, clearErrors, setError } = useForm({
		defaultValues: {
			[name]: defaultName
		},

	});

	useEffect(()=> {
		if (ownerShipfieldName && disableOwnerShip) {
			reset({ [ownerShipfieldName]: defaultName });
		} else {
			if (savedData?.[name] && String(savedData?.[name]) !== 'undefined') {
				reset(savedData);
				setValue(savedData?.[name]);
			}
		}
	}, [ defaultName, savedData ]);
	
	const onSubmitted = (data) => {
		setSelected(false);
		if (data) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: moment(data[name], 'MM-DD-YYYY', true).isValid() ? moment(data[name]).format('MM-DD-YYYY') : data[name]
				}
			}));}
	};

	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: watch()?.[name] }));
		if (pageNumber === 2) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: moment(watch()?.[name], 'MM-DD-YYYY', true).isValid() ? moment(watch()?.[name]).format('MM-DD-YYYY') : watch()?.[name]
				}
			}));

		} else {
			if (watch()?.[name]) handleSubmit(onSubmitted)();
		}
	}, [ watch()?.[name] ]);

	useEffect(() => {
		setPageError(prev => ({ ...prev, [name]: errors[name]?.message }));
	}, [ errors[name]?.message ]);

	return (
		<>
			<Typography className={selected ? classes.selectedLabel : classes.label}>
				<span className={classes.asterisk} >*</span>
				{label}  { showInfo && <LightTooltip title={info} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon style={{ color: COLORS.blueButtons }} fontSize='small'/>
				</LightTooltip>}
			</Typography>
			
			<Controller
				control={control}
				name={name}
				render={({ field }) => (
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
						<KeyboardDatePicker
							name={name}
							openTo={'year'}
							autoOk
							variant="dialog"
							inputVariant="outlined"
							format="MM-dd-yyyy"
							value={value}
							InputAdornmentProps={{ position: 'start' }}
							onChange={(e) => {field.onChange(e); setValue(e); clearErrors(`${name}`); }}
							size='small'
							fullWidth
							maxDate={maximumDate}
							disableToolbar="true"
							autoComplete='off'
							allowKeyboardControl={false}
							onError={(error, value) => {
								if (error && !errors[name]?.message) setError(`${name}`, { type: 'string', message: `${label} ${error}` });}}
							onBlur={() => {
								if (!value) setError(`${name}`, { type: 'string', message: `${label} is required` });}
							}

							helperText={errors[name]?.message}
							error={!!errors[name]?.message}
							maxDateMessage={`should not be after ${moment(maximumDate).format('MM/DD/YYYY')}  `}
							invalidDateMessage={'should be of the form MM-DD-YYYY'}

						/>
					</MuiPickersUtilsProvider>
				)}
			/>
			
		</>
	);

}

SignUpDatePicker.propTypes = {
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	type: PropTypes.string,
	regex: PropTypes.string,
	name: PropTypes.string,
	componentName: PropTypes.string,
	required: PropTypes.bool,
	signupFields: PropTypes.object,
	setPageError: PropTypes.func,
	numberLengthRegx: PropTypes.any,
	errorMessage: PropTypes.string,
	isValidUserName: PropTypes.bool,
	checkIsValidUserName: PropTypes.func,
	setIsValidUserName: PropTypes.func,
	minimumLength: PropTypes.number,
	placeHolder: PropTypes.string,
	pageNumber: PropTypes.number
};

export default memo(SignUpDatePicker);