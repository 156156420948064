import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../constants/Colors';
import { useForm,  Controller } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as _ from 'lodash';
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses} from "@mui/material/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles({
	label: {
		color: '#263238',
		fontSize: '90%',
		letterSpacing: 0,
		lineHeight: '14px',
		'&.Mui-disabled': {
			color: '#263238',
			fontSize: '90%',
			letterSpacing: 0,
			lineHeight: '14px',
		},
	},
	customWidth: {
		maxWidth: 150,
		fontSize: 12
	},
});

// eslint-disable-next-line require-jsdoc
function SignUpCheckedBox({
  	savedData = { },
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	label = '',
	name = '',
	componentName = '',
  	disable = false,
  	color = 'blueButtons',
  	termsAndConditionsCheckBoxState = false,
  	showInfo = false,
  	info = ''
}) {
	const classes = useStyles();
	const schema = yup.object().shape({
		[name]: yup.boolean(),
	});

	const { watch, control, reset, handleSubmit, formState: { errors } } = useForm(
		{ defaultValues: { [name]: savedData?.[name] || termsAndConditionsCheckBoxState },
			resolver: yupResolver(schema)
		});

	useEffect(()=> {
		if (!_.isEmpty(savedData) && savedData?.[name]) {
			reset(savedData);
		}
	}, [ savedData ]);


	const onSubmitted = (data) => {
		if (data) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: data[name]
				}
			}));}
	};
	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: watch()?.[name] }));
	}, [ watch()?.[name] ]);

	return (
		<>
			<Controller
				name={name}
				control={control}
				render={({ field }) =>
					<FormControlLabel
						classes={{ label: classes.label }}
						label={label}
						control={<Checkbox
							checked={ watch()?.[name] || false}
							{...field}
							onBlur={ handleSubmit(onSubmitted)}
							style={watch()?.[name] === true ? { color: COLORS[color] } : null }
							disabled={disable}
						/>}
					/>
				}
			/>
			{ showInfo && <LightTooltip title={info} classes={{ tooltip: classes.customWidth }} >
				<HelpIcon style={{ color: COLORS.blueButtons, marginTop: '1.75%', marginLeft: '-2%' }} fontSize='small'/>
			</LightTooltip>}
		</>
	);

}

SignUpCheckedBox.propTypes = {
	signupFields: PropTypes.object,
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	name: PropTypes.string,
	componentName: PropTypes.string,
	disable: PropTypes.bool,
	color: PropTypes.string,
	termsAndConditionsCheckBoxState: PropTypes.bool
};

export default memo(SignUpCheckedBox);