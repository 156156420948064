import React from 'react';
import TextField from '@mui/material/TextField';

import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';

import CancelIcon from '@mui/icons-material/Cancel';
import InputAdornment from '@material-ui/core/InputAdornment';
import HelpIcon from '@material-ui/icons/Help';
import { COLORS } from '../constants/Colors';
import { Typography } from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DisbursementInfo from './DisbursementInfo';
import PropTypes from 'prop-types';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 15
	},
}));

const ControlledTooltip = styled(({ className, ...props }) => (
	<Tooltip arrow
		{...props} classes={{ popper: className }}
		disableFocusListener
		disableHoverListener
		disableTouchListener
	/>
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#263238',
		color: '#FFFFFF',
		boxShadow: theme.shadows[1],
		fontSize: 15,
		Transparency: '85%',
		padding: 25
	},
}));

const useStyles = makeStyles((theme) => ({
	tooltip: {
		color: '#747779',
		fontFamily: 'Roboto',
		fontWeight: '400px',
		fontSize: '16px',
		lineHeight: '14px',
		textTransform: 'none',
		marginBottom: '1%'
	}

}));

const ReportSearch = ({
	setClientValue = () => {},
	clientValue = '',
	merchantInfo = {},
	setSearch = () => {},
	setDisbursementRow = () => {},
	search = false
}) => {

	const classes = useStyles();

	const handleClientValue = (event) => {
		setClientValue(event.target.value);
	};

	const handleTooltipClose = () => {
		setSearch(false);
		setDisbursementRow(false);
	};

	return (<>
		<Typography  className={classes.tooltip}>
            Search
			<LightTooltip title={`Search Disbursement report by inputting a ${merchantInfo.entity} name (full/partial ${merchantInfo.entity} name).`} >
				<HelpIcon style={{ color: COLORS.blueButtons, marginLeft: '1.75%' }} fontSize='small'/>
			</LightTooltip>
		</Typography>
		<ControlledTooltip
			title={
				<>
					<Typography>
                        Search Disbursement report by inputting a  {merchantInfo.entity} name
					</Typography>
					<Grid container direction='row' style={{ marginTop: '1.5%' }}>
						<Grid item sm={6} style={{ marginTop: '1.5%' }}> 2 of 5</Grid>
						<Grid item sm={6}>
							<Grid container direction='row' justifyContent='flex-end'>
								<Button id='skip-search'
									style={{ fontFamily: 'Roboto',
										fontWeight: 700,
										color: '#CDDBFF'
									}}
									onClick={handleTooltipClose}
								>
                                    Skip
								</Button>
								<Button id='skip-search' style={{
									backgroundColor: '#CDDBFF',
									borderRadius: 4,
									fontFamily: 'Roboto',
									fontWeight: 700,
									color: '#151D33'
								}}
								onClick={() => {handleTooltipClose(); setDisbursementRow(true);}}
								>
                                    Next
								</Button>
							</Grid>
						</Grid>

					</Grid>
				</>
			}
			open={search}
			onClose={handleTooltipClose}
		>
			<TextField
				placeholder={`${merchantInfo.entity || ''} Name`.trim()}
				id="outlined-basic"
				variant="outlined"
				fullWidth
				size="small"
				value={clientValue}
				onChange={handleClientValue}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{clientValue && <CancelIcon
								style={{ color: 'blue' }}
								onClick={() => setClientValue('')}
							/>}
						</InputAdornment>
					)
				}}
			/>
		</ControlledTooltip>
	</>
	);
};

ReportSearch.propTypes = {
	setClientValue: PropTypes.func,
	clientValue: PropTypes.string,
	merchantInfo: PropTypes.object,
	setSearch: PropTypes.func,
	setDisbursementRow: PropTypes.func,
	search: PropTypes.bool
};

export default ReportSearch;
