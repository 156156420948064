import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { COLORS } from '../constants/Colors';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
	barColorPrimary: {
		backgroundColor: COLORS.blueButtons,
	}
});

export default function InProgress() {
	const classes = useStyles()
	return (
		<div>
			<LinearProgress classes={{ barColorPrimary: classes.barColorPrimary}} />
		</div>
	);
}