import { Redirect } from "react-router-dom";
import { getApiToken } from "../utils/getApiUrl";

function CheckLogin({ children }) {
    const token = getApiToken();
    return token ? children : (
        <Redirect to="/admin/login" />
    );
}

export default CheckLogin;
