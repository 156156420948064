import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import APXlogosignup from '../../assets/images/APXlogosignup.png';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { COLORS } from '../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	root: {
		height: 55
	},
	text: {
		fontWeight: 700,
		color: COLORS.whiteText,
		margin: 'auto 0 auto 1%',
		fontSize: 22,
		fontFamily: 'Roboto'
	},
	divider: {
		height: 55,
		backgroundColor: 'gray',
	},
	container: {
		backgroundColor: COLORS.headerDarkBlue
	}
}));

function Header({ showReports = false, setOpenSelectTooltip = () => {}, openSelectTooltip = false }) {
	const classes = useStyles();

	return (
		<Grid container className={classes.root}>
			<Grid item lg={12}  md={12} xs={12} sm={12} className={classes.container}>
				<Container maxWidth='xl'>
					<Grid container direction='row' alignItems='center' style={{ width: '101%', position: 'relative' }}>
						<Grid item md={2} xs={3} lg={1} xl={1} sm={2}>
							<img src={APXlogosignup} alt="APX" style={{ width: '75%', marginTop: '7%' }}/>
						</Grid>
						<Divider orientation="vertical" flexItem className={classes.divider}/>
						<Grid className={classes.text} lg={10} md={8} sm={8} >
                            Reports
						</Grid>
						{showReports && <Grid style={{ position: 'absolute', right: '1%' }}>
							<HelpIcon
								style={{ color: 'white' }}
								fontSize='small'
								onClick={() => setOpenSelectTooltip(!openSelectTooltip)}
							/>
						</Grid>}
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
}

Header.propTypes = {
	showReports: PropTypes.bool,
	setOpenSelectTooltip: PropTypes.func,
	openSelectTooltip: PropTypes.bool
};
export default Header;