import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {COLORS} from "../constants/Colors";
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import serverisdown from '../../assets/images/server_is_down.png';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    typography: {
        height: '100vh',
        backgroundColor: 'white',
        borderLeft: '1px solid #cdced1',
        borderRight: '1px solid #cdced1',
        margin: 0.5
    },
    image: {
        maxWidth: '40%',
        maxHeight:'auto',
        marginTop: 35,
        marginLeft: '29%',
    },
    textone: {
        marginTop: 20,
        fontWeight: 900,
        maxWidth: '80%',
        fontSize: '80%',
    },
    texttow: {
        marginTop: 15,
        maxWidth: '75%',
        fontSize: '75%',
        color: theme.palette.grey[800],
        fontFamily:'Times New Roman',
        fontWeight: 550,
    },
    textthree: {
        fontSize: '75%',
        color: theme.palette.grey[800],
        fontFamily:'Times New Roman',
        fontWeight: 550,
    },
    retryIcon: {
        fontSize: '75%',
        fontFamily:'Times New Roman',
        fontWeight: 550,
        marginTop: 10,
        color: COLORS.blueButtons,
        cursor: 'pointer'
    },
    retryText: {
        fontSize:'70%',
        marginLeft:'5px'
    }
}));

function ServerDownErrorPage() {
    const classes = useStyles();
    const history = useHistory();
    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm">
                <Typography component="div" className={classes.typography} >
                    <Grid container direction='column' alignItems='center'>
                        <Grid item><img src={serverisdown} className={classes.image}/></Grid>
                        <Grid item className={classes.textone}>This site is currently down for maintenance</Grid>
                        <Grid item className={classes.texttow} > We apologize for any inconveniences caused.</Grid>
                        <Grid item  className={classes.textthree}>Please try again in a few minutes.</Grid>
                        <Grid item onClick={() => history.goBack()}>
                        <Grid container direction='row' alignItems='center' className={classes.retryIcon}>
                            <Grid item><RefreshIcon fontSize='inherit' style={{marginTop:'5px'}}/></Grid>
                            <Grid item className={classes.retryText}>RETRY</Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </Typography>
            </Container>
        </React.Fragment>
    )
}



export default ServerDownErrorPage;