import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
	inputGrid: {
		marginTop: 10,
	},
	input: {
		'& .MuiInputBase-root': {
			backgroundColor: COLORS.whiteText,
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: COLORS.footerText,
			backgroundColor: COLORS.lightGray
		},
		'& .MuiOutlinedInput-input': {
			'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
			}
		}
	},
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	asteriskDisabled: {
		color: COLORS.footerText,
		marginRight: 5
	},
	label: {
		color: COLORS.secureMessage,
	},
	selectedLabel: {
		color: COLORS.blueButtons,
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		},
		'&.Mui-error fieldset': {
			borderColor: '#B00020 !important'
		}
	},
	customWidth: {
		maxWidth: 200,
	},
	help: {
		color: COLORS.blueButtons,
		opacity: '85%'
	},
});
const useHelperTextStyles = makeStyles(() => ({
	root: {
		color: '#B00020 !important',
		fontFamily: 'Roboto',
		fontSize: 17,
	}
}));

function FormTextField({
	label = '',
	value = '',
	id = '',
	fullWidth = true,
	placeholder = '',
	size = 12,
	onChange = () => { },
	onFocusOut = () => { },
	onKeyUp = () => { },
	required = true,
	validateRequired = false,
	type = '',
	showInfo = false,
	info = '',
	isDisabled = false,
	isMultiline = false,
	rows = 1,
	helperText = '',
	width = '',
	wordsLimit = 1000
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);
	const helperTextStyles = useHelperTextStyles();

	const checkIsRequiredError = () => {
		if (helperText.length > 0) return true;
		if ((required && validateRequired)) return !value;
		else return false;
	};

	return (
		<Grid item xs={size} className={classes.inputGrid}>
			<Typography className={selected ? classes.selectedLabel : classes.label}>{label && (required ?
				<span className={isDisabled ? classes.asteriskDisabled : classes.asterisk} >*</span> : null)}
			{label}  {showInfo && <Tooltip title={info} classes={{ tooltip: classes.customWidth }}>
				<HelpIcon className={classes.help} fontSize='small' /></Tooltip>}</Typography>
			<TextField
				inputProps={{ maxLength: wordsLimit }}
				style={{ width: width }}
				onFocus={() => setSelected(true)}
				onBlur={(e) => { setSelected(false); onFocusOut(e); }}
				onKeyUp={(e) => onKeyUp(e)}
				className={classes.input}
				error={checkIsRequiredError()}
				required={required}
				fullWidth={fullWidth}
				id={id}
				value={value}
				variant='outlined'
				size='small'
				InputProps={{ classes: { root: classes.selectedBorder } }}
				disabled={isDisabled}
				placeholder={placeholder ?? label}
				onChange={onChange}
				type={type}
				multiline={isMultiline}
				minRows={rows}
				helperText={helperText}
				FormHelperTextProps={{
					classes: {
						root: helperTextStyles.root
					}
				}}
			/>

		</Grid>
	);

}

FormTextField.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	id: PropTypes.string,
	fullWidth: PropTypes.bool,
	size: PropTypes.number,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	validateRequired: PropTypes.bool,
	type: PropTypes.string,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	wordsLimit: PropTypes.number
};

export default memo(FormTextField);