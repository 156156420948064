import React from 'react';
import Grid from '@material-ui/core/Grid';
import ReportsEmptyImage from '../../assets/images/ReportsEmptyImage.png';

const ReportsEmptyPage = ({}) => {
	return (
		<Grid container justifyContent='center' alignItems='center'>
			<Grid lg={12} md={12} sm={12} xs={12}>
				<Grid container justifyContent='center' alignItems='center'>
					<img src={ReportsEmptyImage} alt="APX" style={{ width: '17%', marginTop: '1%' }}/>
				</Grid>
			</Grid>
			<Grid style={{ marginTop: '1%', marginBottom: '1%', fontWeight: 'bold' }}>
            Please select a Date Range and click "Run Report.
			</Grid>
		</Grid>
	);
};

export default ReportsEmptyPage;