import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#000000',
		height: 50
	},
	text: {
		color: '#CFD8DC',
		fontSize: 12,
		lineHeight: '10px'
	}
}));

// eslint-disable-next-line require-jsdoc
function Footer() {
	const classes = useStyles();
	return (
		<Grid container direction='row' alignItems= 'center' className={classes.root}>
			<Container maxWidth='lg'>
				<Grid container direction='row' alignItems='flex-start' alignContent='flex-start' justifyContent="flex-start">
					<Grid item xs={12} className={classes.text}>
                Copyright © {new Date().getFullYear()} - AbacusNext, All Rights Reserved
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
}

export default memo(Footer);