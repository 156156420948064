import React, { memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaymenDetails from './PaymentDetail';
import SharedPaymentForm from '../shared/SharedPaymentForm';
import ProcessPayment from '../shared/ProcessPayment';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../constants/Colors';
import axios from 'axios';
import { createPaymentMethodDataCallback, createReceiptData, createPaymentMethodErrorCallback,
	createTransactionDataCallback, createTransactionErrorCallback, trackPendoEvent, createReceiptDataMultipleTxns } from '../services/Services';
import { getApiUrl } from '../utils/getApiUrl';
import InProgress from '../forms/InProgress';
import Messages from '../forms/Messages';
import { STATES, STATESCA } from '../constants/Locations';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	header: {
		display: 'flex',
		backgroundColor: COLORS.blueHeader,
		color: COLORS.whiteText,
		padding: 8,
		marginTop: 15,
		maxHeight: 40,
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		borderRadius: '2px 2px 0 0',
	},
	editIcon: {
		cursor: 'pointer',
		marginLeft: 40
	},
	title: {
		fontWeight: 900,
		marginLeft: 16
	},
	pleasefilloutthe: {
		color: '#263238',
		lineHeight: '1.25em',
		paddingTop: 15,
		paddingBottom: 10
	},
	surchargeNote: {
		color: '#263238',
		lineHeight: '1.25em',
		paddingBottom: 10
	}
}));

function SharedForm({
	requestOfPayment = {},
	handleOnEdit = () => { },
	requiredFieldValidated = () => { },
	validateRequired = false,
	isPayNow = true,
	buttonText = 'PAY NOW',
	merchantId = '',
	payerId = '',
	axiosConfig = {},
	merchantInfo = {},
	handleAddressUpdate = () => { },
	tempPaymentMethodId = '',
	applicationName
}) {
	const initialBankInfoState = {
		username: '',
		routingNumber: '',
		accountNumber: '',
		accountType: '',
		address: '',
		city: '',
		state: '',
		postalCode: '',
		country: 'USA',
		email: ''
	};
	const initialCCInfoState = {
		username: '',
		address: '',
		address2: '',
		city: '',
		state: '',
		postalCode: '',
		country: '',
		email: ''
	};
	const classes = useStyles();
	const history = useHistory();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ bankInfo, setBankInfo ] = useState(initialBankInfoState);
	const [ creditCardInfo, setCreditCardInfo ] = useState(initialCCInfoState);
	const [ selectedValue, setSelectedValue ] = useState('creditcard');
	const [ expanded, setExpanded ] = useState('creditcard');
	const [ paymentMethods, setPaymentMethods ] = useState([]);
	const [ selectedToken, setSelectedToken ] = useState();
	const [ showExistingPaymentMethods, setExistingPaymentMethods ] = useState(true);
	const [ validCreditCard, setValidCreditCard ] = useState({ emailError: '' });
	const [ validBankAccount, setValidBankAccount ] = useState({ emailError: '', zipError: '' , routingError: '' });
	const [ isMessageOpen, setIsMessageOpen ] = useState(false);
	const [ message, setMessage ] = useState('');
	const [ ccStates, setCCStates ] = useState([]);
	const [ surchargePayment, setSurchargePayment ] = useState({ amount: '', surchargeFee: 0, isDebitCard: false });

	useEffect(() => {
		if (requestOfPayment.country) {
			if (requestOfPayment.country.toLowerCase() === 'canada' || requestOfPayment.country.toUpperCase() === 'CAN') requestOfPayment.country = 'CAN';
			else if (requestOfPayment.country.toLowerCase() === 'united kingdom' || requestOfPayment.country.toUpperCase() === 'GBR' || requestOfPayment.country.toUpperCase() === 'UK') requestOfPayment.country = 'GBR';
			else if (requestOfPayment.country.toLowerCase() === 'united states' || requestOfPayment.country.toUpperCase() === 'USA') requestOfPayment.country = 'USA';
			else requestOfPayment.country = '';
		}

		if (requestOfPayment?.country === 'USA' || requestOfPayment?.country === '') setCCStates(STATES);
		else if (requestOfPayment?.country === 'CAN') setCCStates(STATESCA);
		else setCCStates([]);

		setBankInfo(prev => ({
			...prev,
			username: requestOfPayment.country === 'USA' ? requestOfPayment?.name?.trim() || `${requestOfPayment?.firstname || ''} ${requestOfPayment?.lastname || ''}`.trim() : '',
			address: requestOfPayment.country === 'USA' ? requestOfPayment?.address1 : '',
			city: requestOfPayment.country === 'USA' ? requestOfPayment?.city : '',
			state: requestOfPayment.country === 'USA' ? requestOfPayment?.state : '',
			postalCode: requestOfPayment.country === 'USA' ? requestOfPayment?.postalCode : '',
			country: 'USA',
			email: requestOfPayment?.email
		}));

		setCreditCardInfo(prev => ({
			...prev,
			username: requestOfPayment.country === 'USA' ? requestOfPayment?.name?.trim() || `${requestOfPayment?.firstname || ''} ${requestOfPayment?.lastname || ''}`.trim() : '',
			address: requestOfPayment?.address1,
			address2: requestOfPayment?.address2,
			city: requestOfPayment?.city,
			state: requestOfPayment?.state,
			postalCode: requestOfPayment?.postalCode,
			country: requestOfPayment?.country ? requestOfPayment?.country : '',
			email: requestOfPayment?.email
		}));
	}, [ requestOfPayment ]);

	const deletePaymentMethod = async (tokenId) => {
		try {
			await axios.delete(`${getApiUrl()}/api/merchantwebapi/delete_payment_method?paymentMethodId=${tokenId}`, axiosConfig);
		} catch (err) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed to delete payment method' }, err.message);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const handleSelectedTokenChange = async ({ target: { value } }) => {
		let isCreditCard = false;
		setSelectedToken(value);
		if (value) {
			const selectedPayment = paymentMethods.filter((payment) => {
				return payment.tokenId === value;
			});
			isCreditCard = selectedPayment[0]?.hasOwnProperty('creditCardType');
			setSelectedValue(isCreditCard ? 'creditcard' : 'bankaccount');
		}
		else {
			setSelectedValue('creditcard');
		}

		if (isSurchargeTransferEnabled(isCreditCard)) await getSurchargeAmount(value);
		else setSurchargePayment({ amount: '', surchargeFee: 0  });
	};

	const isSurchargeTransferEnabled = (isCrediCard) => {
		if (requestOfPayment.isTrustTransaction || (isCrediCard === false)) return false;
		return requestOfPayment.transferSurcharge || (requestOfPayment.operatingAmountSurchargeEnabled !== null && requestOfPayment.operatingAmountSurchargeEnabled > 0);
	};

	const getSurchargeAmount = async (paymentMethodId) => {
		try {
			const response = await axios.post(`${getApiUrl()}/api/merchantwebapi/calculate_surcharge_amount`, 
				{ paymentMethodId, merchantId: requestOfPayment.merchantId, requestOfPaymentMethodId: requestOfPayment.requestOfPaymentMethodId },
				axiosConfig);
			if (response.data.code !== 200) {
				trackPendoEvent('apxn_pay_now_web', { message: 'Failed to get surcharge amount.' }, response?.data?.error);
				setMessage(`Failed - ${response.data.error}`);
				setIsMessageOpen(true);
			}
			else {
				setSurchargePayment(response.data.payment);
			}
			return response.data.payment;
		} catch (err) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed to get surcharge amount' }, err.message);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const handleMessageClose = () => {
		setIsMessageOpen(false);
	};

	const handleOnSubmit = async () => {
		setIsLoading(true);
		if (selectedToken) {
			const result = await processPayment(selectedToken);
			if (result) {
				let transaction;
				if (result.data?.transaction != null) {
					transaction = createReceiptData(result?.data?.paymentMethod?.dataValues, result?.data?.transaction?.transactionId,
						false, result?.data?.transaction?.confirmationNumber, result?.data?.transaction);
				} else {
					transaction = createReceiptDataMultipleTxns(result?.data?.paymentMethod?.dataValues, false, result?.data?.transactions);
				}
				
				const data = {
					merchantInfo,
					invoiceId: requestOfPayment.invoiceId,
					amount: requestOfPayment.amount,
					transaction: transaction,
					axiosConfig
				};
				history.push('/receipt', { data: data, email: requestOfPayment.firmEmail, requestOfPayment: requestOfPayment });
			}
		}
		else {
			if (selectedValue === 'creditcard') {
				if (validCreditCard.emailError === '')
					await submitCreditCardForm(createPaymentMethodErrorCallback, createPaymentMethodDataCallback);
				else
					setIsLoading(false);
			}
			else {
				if (requiredFieldValidated(bankInfo) && validateBankAccount())
					await submitBankAccountForm(createPaymentMethodErrorCallback, createPaymentMethodDataCallback);
				else
					setIsLoading(false);
			}
		}
	};

	const getExistingPaymentsMethods = async () => {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_existing_payment_methods_for_paynow?payerId=${payerId}&merchantId=${requestOfPayment.merchantId || merchantId}`, axiosConfig);
			if (data.code === 200) {
				setPaymentMethods(data.paymentMethods);
			}
			if (!data.paymentMethods.length) {
				setExistingPaymentMethods(!showExistingPaymentMethods);
			}
		} catch (err) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed to get existing payment methods' }, err.message);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const validateBankAccount = () => {
		return validBankAccount.emailError === '' &&  validBankAccount.routingError === '';
	};

	const addBillingAddress = () => {
		window.PayFields.config.billingAddress = {
			address: creditCardInfo.address2 ? `${creditCardInfo.address}, ${creditCardInfo.address2}` : creditCardInfo.address,
			city: creditCardInfo.city,
			state: creditCardInfo.state,
			zip: creditCardInfo.postalCode,
			email: creditCardInfo.email,
			country: creditCardInfo.country,
		};
	};

	const saveCreditCard = async (creditCard) => {
		const todayDate = new Date();

		const apxGeneratedInvoiceId = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
		try {
			creditCard.customer.email = creditCardInfo.email;
			return await axios.post(`${getApiUrl()}/api/merchantwebapi/save_credit_card`, {
				card: creditCard,
				isPayNow,
				invoiceId: requestOfPayment.invoiceId || apxGeneratedInvoiceId,
				requestOfPaymentMethodId: requestOfPayment.requestOfPaymentMethodId || '',
				merchantId: requestOfPayment.merchantId || merchantId,
				payerId,
				tempPaymentMethodId,
			}, axiosConfig);

		} catch (err) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed to save Credit Card' }, err.message);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			} else {
				await saveCreditCardError(err?.message);
			}
		}
	};

	const saveCreditCardError = async (error) => {
		try {
			await axios.post(`${getApiUrl()}/api/merchantwebapi/save_credit_card_error`, {
				merchant: requestOfPayment.merchantId || merchantId,
				error
			}, axiosConfig);

		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const submitCreditCardForm = (errorCallback, callback) => {
		const email = { email: creditCardInfo.email };

		if ((creditCardInfo.country !== 'Others' && requiredFieldValidated(creditCardInfo)) || (creditCardInfo.country === 'Others' && requiredFieldValidated(email))) {
			submitCreditCard(errorCallback, callback);
		} else setIsLoading(false);
	};

	const submitCreditCard = (errorCallback, callback)=>{
		// setIsLoading(true);
		if (creditCardInfo.country !== 'Others') addBillingAddress();
		else window.PayFields.config.billingAddress = {};

		// Start adding Level 2 and Level 3 transaction information here
		window.PayFields.config.order = uuidv4();
		window.PayFields.config.tax = Number(requestOfPayment.tax) ? Number(requestOfPayment.tax * 100).toFixed(2) : 0;
		window.PayFields.config.shipping = 0;
		window.PayFields.config.duty = 0;
		window.PayFields.config.discount = 0;
		// window.PayFields.config.amount = amount;
		window.PayFields.config.items = [
			{
				productCode: 'services',
				item: applicationName,
				quantity: 1,
				um: 'EACH', // UM - is unit measure - can be in KG, Meter, EACH - per requirement document I am using EACH
				total: window.PayFields.config.amount,
				discount: 0,

				// The commodity is hardcoded and can be found at this location
				// https://payrix.atlassian.net/plugins/servlet/servicedesk/customer/confluence/shim/download/attachments/614727681/nigplist.pdf?version=1&modificationDate=1598283185054&cacheVersion=1&api=v2
				// this code is 961-49-00 Legal Services, Attorneys and you can find it in the link given above
				commodityCode: '9614900',
				price: window.PayFields.config.amount,
			}
		];

		window.PayFields.submit();

		window.PayFields.onSuccess = async (response) => {
			try {
				const creditCardResponse = await saveCreditCard(response.data[0]);

				if (creditCardResponse?.data?.creditCard) callback(creditCardResponse.data.creditCard, true);

				if (creditCardResponse?.data?.code === 200) {
					const billingAddress = {
						address1: creditCardInfo.address,
						address2: creditCardInfo.address2,
						city: creditCardInfo.city,
						state: creditCardInfo.state,
						postalCode: creditCardInfo.postalCode,
						country: creditCardInfo.country,
						email: creditCardInfo.email,
					};
					handleAddressUpdate(billingAddress);
					if (isPayNow && creditCardResponse?.data?.creditCard) {
						getExistingPaymentsMethods();
						setExistingPaymentMethods(!showExistingPaymentMethods);
					}
				}

				setIsLoading(false);
			} catch (err) {
				trackPendoEvent('apxn_pay_now_web', { message: 'Unexpected Error Occurred' }, err.message);
				if (err.toString() === 'Error: Network Error') {
					history.push('/errorpage/serverDown');
				}
				setIsLoading(false);
				if (axios.isAxiosError(err)) {
					setMessage(err?.response?.data?.error);
					setIsMessageOpen(true);
					const error = err?.response?.data;
					errorCallback(error.code, error.error, false);
				} else {
					errorCallback(err.status, err.error, false);
				}
			}

		};

		window.PayFields.onFailure = async (response) => {
			const error = response.errors?.[0];
			const field = error?.field ? `${error?.field} :` : '';

			trackPendoEvent('apxn_pay_now_web', { message: 'Payment Failure' }, `${field} - ${error?.msg}`);
			setMessage(`Failed - ${field} ${error?.msg}`);
			setIsMessageOpen(true);
			setIsLoading(false);

			if (isPayNow) createTransactionErrorCallback(error?.code, error?.msg);
			else errorCallback(error?.code, error?.msg, true);

			if (response?.data[0]) {
				const creditCardResponse = await saveCreditCard(response.data[0]);
			}

			await saveCreditCardError(error?.msg);
		};
		window.PayFields.onValidationFailure = (response) => {
			setIsLoading(false);
		};
		// window.PayFields.onFinish = (response) => {
		// 	// setIsLoading(false);
		// };
	};

	const submitBankAccountForm = async (errorCallback, callback) => {
		const nameArray = bankInfo.username ? bankInfo.username.trim().split(' ') : [];
		const payNowPayload = {
			payerId: requestOfPayment.payerId || payerId,
			tempPaymentMethodId,
			total: requestOfPayment.amount,
			type: 7,
			origin: 2,
			customer: {
				first: nameArray[0] ? nameArray[0] : '',
				last: nameArray[1] ? nameArray[1] : '',
				address1: bankInfo.address,
				city: bankInfo.city,
				state: bankInfo.state,
				country: bankInfo.country,
				zip: bankInfo.postalCode,
				email: bankInfo.email,
				merchant: requestOfPayment.merchantId || merchantId,
			},
			payment: {
				method: bankInfo.accountType,
				number: bankInfo.accountNumber,
				routing: bankInfo.routingNumber,
			},
		};
		setIsLoading(true);
		try {
			const response = await axios.post(`${getApiUrl()}/api/merchantwebapi/create_bank_account`, payNowPayload, axiosConfig);

			if (response.data.code === 200)
				handleAddressUpdate(payNowPayload.customer);
			if (response.data.code !== 200) {
				trackPendoEvent('apxn_pay_now_web', { message: 'Failed to add bank account' }, response?.data?.error);
				setMessage(`Failed - The bank account was not added.${response.data.error}`);
				setIsMessageOpen(true);
			}
			else if (isPayNow && response.data.bankAccount) {
				getExistingPaymentsMethods();
				setExistingPaymentMethods(!showExistingPaymentMethods);
				setExpanded('creditcard');
			}

			callback(response.data.bankAccount, false);
			setIsLoading(false);
		} catch (err) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed payment while using bank account' }, err?.response?.data?.error);
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			setMessage(err?.response?.data?.error);
			setIsMessageOpen(true);
			setIsLoading(false);
			const error = err?.response?.data;
			errorCallback(error.code, error.error, false);
		}
	};

	useEffect(() => {
		setSelectedToken(paymentMethods[0]?.tokenId);
		if (paymentMethods.length > 0) {
			const isCreditCard = paymentMethods[0]?.hasOwnProperty('creditCardType');
			setSelectedValue(isCreditCard ? 'creditcard' : 'bankaccount');
			if (isSurchargeTransferEnabled(isCreditCard)) getSurchargeAmount(paymentMethods[0]?.tokenId);
			else setSurchargePayment({ amount: '', surchargeFee: 0  });
		}
	}, [ paymentMethods[0]?.tokenId ]);

	const getInvoiceNumbers = (payNowLink) => {
		const invoiceNumbers = [];

		// parse the payNowLink to get the jwt token
		let start = payNowLink.lastIndexOf('payNow/') + 'payNow/'.length;
		let end = payNowLink.indexOf('?payNowTokenId');

		let jwtToken = payNowLink.substring(start, end);

		// decode the token to get the object and invoice numbers
		let decodedToken = JSON.parse(atob(jwtToken.split('.')[1]));

		// get the invoice numbers, else set invoiceNumbers to empty array
		if (decodedToken?.invoiceNumbers) {
			invoiceNumbers.push(...decodedToken.invoiceNumbers);
		}

		return invoiceNumbers;
	}

	const processPayment = async (tokenId) => {
		const todayDate = new Date();

		const invoiceNumbers = getInvoiceNumbers(requestOfPayment.payNowLink);

		const payNowPayment = {
			payment: {
				amount: requestOfPayment.amount,
				paymentMethodId: tokenId,
				externalId: requestOfPayment.invoiceId || todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString(),
				isTrustTransaction: requestOfPayment.isTrustTransaction,
				isPayNow: isPayNow,
				entityInfo: requestOfPayment.entityInfo,
				customerId: requestOfPayment?.customerId,
				requestOfPaymentMethodId: requestOfPayment.requestOfPaymentMethodId,
				transferSurcharge: requestOfPayment?.transferSurcharge,
				invoiceNumbers: invoiceNumbers,
			}
		};
		try {
			const response = await axios.post(`${getApiUrl()}/api/merchantwebapi/process_payment`, payNowPayment, axiosConfig);

			setIsLoading(false);
			createTransactionDataCallback(response?.data?.transaction);
			return response;
		} catch (e) {
			trackPendoEvent('apxn_pay_now_web', { message: 'Failed to process payment' }, e.response?.data?.error);
			setMessage(`Failed - Payment could not be processed: ${e.response?.data?.error}`);
			setIsMessageOpen(true);
			createTransactionErrorCallback(e.response?.data?.status, e.response?.data?.error);
			setIsLoading(false);
		}
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(panel);
		setSelectedValue(panel);
	};

	return (
		<>
			{isLoading && <InProgress />}
			<Grid container direction='row'>
				{isPayNow && (
					<Grid item xs={12} className={classes.pleasefilloutthe}>
						{showExistingPaymentMethods ? 'Please select a payment method or add a new one to proceed with payment.' : 'Please fill out the following information to make a payment. All fields are required.'}
					</Grid>
				)}
				{ (isPayNow && isSurchargeTransferEnabled()) ?
					<Grid item xs={12} className={classes.surchargeNote}>
						Note: 3% surcharge will be applied for credit card.
					</Grid> : null
				}
				<Messages message={message} severity='error' isOpen={isMessageOpen} handleClose={handleMessageClose} />
				<SharedPaymentForm
					requestOfPayment={requestOfPayment}
					handleOnEdit={handleOnEdit}
					requiredFieldValidated={requiredFieldValidated}
					validateRequired={validateRequired}
					isPayNow={isPayNow}
					merchantId={requestOfPayment.merchantId || merchantId}
					selectedValue={selectedValue}
					setSelectedValue={setSelectedValue}
					setBankInfo={setBankInfo}
					setCreditCardInfo={setCreditCardInfo}
					setIsLoading={setIsLoading}
					handleChange={handleChange}
					expanded={expanded}
					bankInfo={bankInfo}
					creditCardInfo={creditCardInfo}
					payerId={payerId}
					handleSelectedTokenChange={handleSelectedTokenChange}
					selectedToken={selectedToken}
					axiosConfig={axiosConfig}
					merchantInfo={merchantInfo}
					paymentMethods={paymentMethods}
					showExistingPaymentMethods={showExistingPaymentMethods}
					getExistingPaymentsMethods={getExistingPaymentsMethods}
					setExistingPaymentMethods={setExistingPaymentMethods}
					setValidCreditCard={setValidCreditCard}
					setValidBankAccount={setValidBankAccount}
					validCreditCard={validCreditCard}
					validBankAccount={validBankAccount}
					ccStates={ccStates}
					setCCStates={setCCStates}
				/>
				
				{isPayNow && showExistingPaymentMethods ? (<Grid item xs={12} className={classes.header} style={{ border: '1px solid  #CFD8DC' }}>
					<Grid item xs={11}><Typography className={classes.title}>Payment Details</Typography></Grid>
				</Grid>) : null}
				{isPayNow && showExistingPaymentMethods ? (<Grid item xs={12}>
					<PaymenDetails currency={requestOfPayment.currencyCode} 
						amount={ Number(surchargePayment.surchargeFee) > 0 ? surchargePayment.amount : requestOfPayment.amount}
						hasSurcharge={surchargePayment.surchargeFee > 0}
						isTrustTransaction={requestOfPayment.isTrustTransaction}
						isCreditCard = {surchargePayment.surchargeFee > 0}
					/>
				</Grid>) : null}
				<Grid item xs={12}>
					<ProcessPayment
						buttonText={!isPayNow ? buttonText : showExistingPaymentMethods ? 'PAY NOW' : 'Add Payment Method'}
						onSubmit={handleOnSubmit}
						isLoading={isLoading}
					/>
				</Grid>
			</Grid>
		</>
	);
}

SharedForm.propTypes = {
	requestOfPayment: PropTypes.object,
	handleOnEdit: PropTypes.func,
	requiredFieldValidated: PropTypes.func,
	validateRequired: PropTypes.bool,
	isPayNow: PropTypes.bool,
	buttonText: PropTypes.string,
	merchantId: PropTypes.string,
	payerId: PropTypes.string,
	setIsEditMode: PropTypes.func,
	axiosConfig: PropTypes.object,
	merchantInfo: PropTypes.object,
	tempPaymentMethodId: PropTypes.string,
	applicationName: PropTypes.string,
};

export default memo(SharedForm);
