import React, { useEffect, useState, memo } from 'react';
import SyncCard from '../forms/SyncCard';
import axios from 'axios';
import { getApiToken, getApiUrl } from '../utils/getApiUrl';
import { useHistory } from 'react-router-dom';
import FormTextField from '../forms/FormTextField';

function Transactions(props) {
	const [ records, setRecords ] = useState(0);
	const [ isSyncing, setIsSyncing ] = useState(false);
	const [ customerId, setCustomerId ] = useState('');
	const history = useHistory();

	const sync = async () => {
		setIsSyncing(true);
		try {
			const response = await axios.post(`${getApiUrl()}/api/admin/sync_legacy_transactions?customerId=${customerId}`, null, {
				headers: { Authorization: `Bearer ${getApiToken()}` }
			});
			if (response.data.code === 200) {
				setRecords(response.data.records);
			}
			setIsSyncing(false);
		} catch (err) {
			console.log(err);
			setIsSyncing(false);
		}
	};

	const refresh = async () => {
		await getTotalRecords();
	};

	useEffect(() => {

		async function fetchTotalRecords() {
			await getTotalRecords();
		}
		fetchTotalRecords();
	}, []);

	const getTotalRecords = async () => {
		try {
			const response = await axios.get(`${getApiUrl()}/api/admin/get_transactions_count`, {
				headers: { Authorization: `Bearer ${getApiToken()}` }
			});
			if (response.data.code === 200) {
				setRecords(response.data.records);
			}

		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const onInputChange = ({ target: { value } }) => {
		setCustomerId(value);
	};

	const merchantField = () => {
		return  <FormTextField  required={false} placeholder='Customer Id' id='customerId' value={customerId} onChange={onInputChange} />;
	};

	return (
		<SyncCard records={records}
				  title="Map Legacy Transactions"
				  sync={sync}
				  isSyncing={isSyncing}
				  refresh={refresh}
				  inputField={merchantField}
				  {...props} />
	);
}

export default memo(Transactions);