import React, { useState } from 'react';
import axios from "axios";
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";

import { COLORS } from '../constants/Colors';
import FormTextField from "../forms/FormTextField";
import Button from "@material-ui/core/Button";
import { getApiUrl } from "../utils/getApiUrl";
import InProgress from "../forms/InProgress";
import Message from "../forms/Messages";

const useStyles = makeStyles({
    card: {
        backgroundColor: COLORS.secureBackground,
        marginTop: 100,
        marginBottom: 10,
    },
});

function ForgotPassword() {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [severity, setSeverity] = useState('error');
    const [isLoading, setIsLoading] = useState(false);

    const forgotPassword = async () => {
        setIsLoading(true);
        setError('');
        try {
            await axios.post(`${getApiUrl()}/api/admin/forgot_password`, { email });
            setSeverity('success');
            const message = 'We have send you a reset email link. Please reset your password as link is only valid for 24 hours.';
            setError(message);
        } catch (e) {
            console.error(e);
            setSeverity('error');
            const message = e.response?.status === 400 ? e.response?.data?.error : 'Internal server error.';
            setError(message);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Container maxWidth="xs">
            <Message isOpen={!!error} handleClose={() => setError('')} message={error} severity={severity} />
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="h3">Forgot Password</Typography>
                        <Typography gutterBottom variant="caption" component="p">
                            Please enter your email address to get a reset link via email.
                        </Typography>
                        <FormTextField
                            required
                            placeholder="Email Address"
                            id="login-email"
                            value={email}
                            onChange={({ target: { value } }) => setEmail(value)}
                        />

                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button disabled={isLoading} size="small" color="primary" onClick={forgotPassword}>Submit</Button>
                </CardActions>
                {isLoading && <InProgress />}
            </Card>
        </Container>
    )
}

export default ForgotPassword;
