export const COLORS = {
	blueHeader: '#2D4B69',
	whiteText: '#FFFFFF',
	grayContainer: '#F6F7F8',
	blueButtons: '#1070D7',
	blueButtonFocused: '#074DA0',
	onHoverBlueButtons: '#105FC5',
	asteriskRed: '#db3131',
	blackText: '#262328',
	grayText: '#495057',
	selectedExpend: '#D9EDFF',
	darkGray: '#263238',
	whiteInvoice: '#263238',
	secureBackground: 'rgb(223, 229, 232)',
	secureMessage: 'rgba(0, 0, 0, 0.54)',
	footerBackground: '#DFE5E8',
	footerText: '#546E7A',
	roboto: '#607D8B',
	greenText: '#41B832',
	greenSuccessIcon: '#00C853',
	greenBackground: '#ECF7EA',
	greenBorder: '#ADDCA7',
	grayBorder: '#CFD8DC',
	whiteBackground: '#FFFFFF',
	blueText: '#0F6DD1',
	blueBackground: '#CFE4F6',
	blueBorder: '#96C0E8',
	headerDarkBlue: '#091B2C',
	darkGrayText: '#455A64',
	darkBlackText: '#000000',
	lightBackgroundRed: '#F7E5E8',
	lightBackgroundGreen: '#E9F7E9',
	lightBackgroundGray: '#e0e7eb',
	error: '#B00020',
	lightGray: '#ECEFF1',
	blueHover: '#1F3954',
	pressedBackground: '#0B253C',
	disabledIcon: '#8193A5',
	dividerColor: '#2D4B69',
	cancelButton: '#E14126',
	disabledBlue: '#70A9E7',
	selectedPdfCheckbox: '#E14126',
	help:'#95C1EF',
	helpHover:'#D9EDFF',
	redText:'#E14126',
	redBorder:'#EFACAB',
	redBg: '#FBD4D3',
	darkBlueHeader:'#01579B',
	linkText:'#083F7A',
	darkerGray: '#404547',
	textBrown: '#660C26',
	textPink:'#E61C55',
};