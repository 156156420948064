import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
	boxText: {
		color: '#263238',
		fontSize: 18
	},
	icon: {
		marginTop: 5,
		fontSize: 18
	},
	controlPointIcon: {
		color: '#E14126',
		fontSize: 30,
	},
	disabledControlPointIcon: {
		color: '#90A4AE',
		fontSize: 30,
	},
	disabledBoxText: {
		color: '#90A4AE',
		fontSize: 18
	}
}));

const PlaidLink = ({
	setAccViaPlaid = () => {},
	type = '',
	persistPlaidBankInfo = () => {},
	plaidKey = '',
	plaidEnv = '',
	disabledPlaid = false,
	setPlaidDialog = () => {},
}) => {
	const classes = useStyles();
	const onSuccess = useCallback(async (public_token, metadata) => {
		setAccViaPlaid(prev => ([ ...prev, {
			bankAccountId: metadata.account.id,
			bankName: metadata?.institution?.name,
			bankAccountNumber: metadata.account.mask,
			bankAccountType: metadata.account.subtype,
			isPlaid: true
		} ]));

		persistPlaidBankInfo({
			bankAccountId: metadata?.account?.id,
			bankName: metadata?.institution?.name,
			bankAccountNumber: metadata?.account?.mask,
			public_token: metadata?.public_token,
			bankAccountType: metadata.account.subtype,
			type: type === 'trust' ? 2 : 1,
			isPlaid: true
		});

		setTimeout(function () {
			setPlaidDialog(true);
		}, 1000);
	}, []);

	const config = {
		onSuccess,
		onLoad: () => {},
		onExit: (err, metadata) => {},
		onEvent: (eventName, metadata) => {},
		clientName: 'Plaid Payrix - AbacusNext',
		countryCodes: [ 'US' ],
		env: plaidEnv,
		key: plaidKey,
		product: [ 'auth' ],
		// webhook: 'https://requestb.in',
		language: 'en',
	};

	const openPlaid = () => {
		const plaidLink = window.Plaid.create(config);
		plaidLink.open();
	};

	return (
		<Grid item xs={12} onClick={ !disabledPlaid ? () => openPlaid() : null}>
			<Grid container direction='row' alignItems='center' justifyContent='center' id={`add-${type}-account-via-plaid`}>
				<Grid item lg={1} xs={2} className={classes.icon}>
					<ControlPointIcon className={!disabledPlaid ? classes.controlPointIcon : classes.disabledControlPointIcon}/>
				</Grid>
				<Grid item lg={10} xs={10} className={!disabledPlaid ? classes.boxText : classes.disabledBoxText}>{`Add ${type} account via Plaid`}</Grid>
			</Grid>
		</Grid>

	);
};

PlaidLink.propTypes = {
	setAccViaPlaid: PropTypes.func,
	disabledPlaid: PropTypes.bool,
	setPlaidDialog: PropTypes.func,
};

export default PlaidLink;