import { makeStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { COLORS } from '../constants/Colors';
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{
			borderBottom: '1px solid #CFD8DC',
			position: 'relative',
		}}
		{...other}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 3,
						top: 7,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(1,3),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		padding: theme.spacing(1, 15),
		backgroundColor: '#ECEFF1',
		borderTop: '1px solid #CFD8DC'
	},
}))(MuiDialogActions);
const useStyles = makeStyles((theme) => ({
	paymentMethodInfo: {
		fontSize: 15,
		color: '#455A64',
		lineHeight: '18px'
	},
	paymentMethodText: {
		fontSize: 15,
		color: '#263238',
		lineHeight: '18px'
	},
	cancelButton: {
		right: 0,
		fontWeight: 900,
		color: '#1070D7',
	},
	deleteButton: {
		position: 'absolute',
		right: 10,
		backgroundColor: COLORS.blueButtons,
		fontWeight: 900
	}

}));

function PayNowDeletePaymentMethod({
	cancelButton = () => {},
	cardToDeleteInfo = {},
	deleteButton = () => {},

}) {

	const classes = useStyles();
	const [ cardToDelete, setCardToDelete ] = useState(cardToDeleteInfo);

	useEffect(() => {
		setCardToDelete(cardToDeleteInfo);
	},[ cardToDeleteInfo ]);
	const expiration = cardToDelete?.expiration ? `${cardToDelete.expiration.slice(0,2)} / ${cardToDelete.expiration.slice(2)}` : '';

	return (
		<>
			<BootstrapDialogTitle id='customized-dialog-title' onClose={cancelButton} >
				Delete payment method?
			</BootstrapDialogTitle>
			<DialogContent >
				<Typography gutterBottom component={'span'} >
					<Grid container direction={'column'} >
						<Grid item  className={classes.paymentMethodText}> {cardToDelete.bankAccountType ? 'eCheck/ACH details' : 'Credit Card details'}:</Grid>
						{cardToDelete.bankAccountType && <Grid container direction={'row'}>
							<Grid item xs={5} className={classes.paymentMethodInfo} style={{ marginTop: 8 }}>Bank name</Grid>
							<Grid item xs={7} className={classes.paymentMethodText} style={{ marginTop: 8 }}> {cardToDelete?.bankName}</Grid>
						</Grid>}
						<Grid container direction={'row'}>
							<Grid item xs={5} className={classes.paymentMethodInfo}>{cardToDelete.bankAccountType ? 'Account owner' : 'Name on Card'} </Grid>
							<Grid item xs={7} className={classes.paymentMethodText}> {cardToDelete?.first} {cardToDelete?.last}</Grid>
						</Grid>
						{ cardToDelete.bankAccountType && <Grid container direction='row'>
							<Grid item xs={5} className={classes.paymentMethodInfo}> Routing number </Grid>
							<Grid item xs={7}  className={classes.paymentMethodText}>{cardToDelete?.routing}</Grid>
						</Grid>}
						<Grid container direction='row'>
							<Grid item xs={5} className={classes.paymentMethodInfo}>{cardToDelete.bankAccountType ? 'Account type' : 'Card type'} </Grid>
							<Grid item xs={7}  className={classes.paymentMethodText}>{cardToDelete?.creditCardType || cardToDelete.bankAccountType}</Grid>
						</Grid>
						<Grid container direction='row'>
							<Grid item xs={5} className={classes.paymentMethodInfo}  style={{ marginBottom: '10px' }}>{cardToDelete.bankAccountType ? 'Account number' : 'card number'}</Grid>
							<Grid item xs={7}  className={classes.paymentMethodText}  style={{ marginBottom: '10px' }}>{cardToDelete?.accountNumber || ''}</Grid>
						</Grid>
						{cardToDelete?.creditCardType && (<Grid container direction='row'>
							<Grid item xs={5} className={classes.paymentMethodInfo} style={{ marginBottom: '10px' }}>Expiration</Grid>
							<Grid item xs={7}  className={classes.paymentMethodText} style={{ marginBottom: '10px' }}>{expiration}</Grid>
						</Grid>)}
					</Grid>
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={cancelButton} className={classes.cancelButton} id={'cancel'}>CANCEL</Button>
				<Button onClick={() => deleteButton(cardToDelete.tokenId)} variant='contained' color='primary' className={classes.deleteButton} id={'delete'}>
						DELETE
				</Button>
			</DialogActions>
		</>
	);
}

export default PayNowDeletePaymentMethod;