import moment from 'moment';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

	root: {
		fontSize: '.75em',
	},

	address: {
		fontStyle: 'normal',
	},

	header: {
		marginBottom: '15px',
		width: '100%',
	},

	footer: {
		width: '100%',
	},

	footerText: {
		color: '#747779',
		fontStyle: 'italic',
		textAlign: 'center',
		marginBottom: 0,
	},

	firmName: {
		fontSize: '1.25em',
		lineHeight: '1em',
		marginBottom: '10px',
		marginTop: 0,
	},

	table: {
		border: '1px solid #C6C8C9',
		borderCollapse: 'collapse',
		width: '100%',

		'& tbody': {
			borderTop: '1px solid #C6C8C9',
		},

		'& tbody td': {
			fontWeight: 500,
		},

		'& thead': {
			background: '#ECEDED',
		},

		'& thead th': {
			padding: '5px',
		},

		'& th': {
			textAlign: 'left',
		},

		'& tbody td, & tbody th': {
			padding: '5px 5px 0 5px',
		},

		'& tbody tr:last-child td, & tbody tr:last-child th': {
			paddingBottom: '10px',
		},

		'& th[scope="row"]': {
			color: '#404547',
			fontWeight: 300,
			verticalAlign: 'top',
		},
	}
});

function ReceiptPreview({ transaction, merchantInfo }) {
	const classes = useStyles();
	const transactionDate = moment(transaction.transactionDate || null);
	return (
		<div className={classes.root}>
			<header className={classes.header}>
				<time style={{ float: 'right' }}>{transactionDate.format('MMMM D, YYYY')}</time>
				<address className={classes.address}>
					<h1 className={classes.firmName}>{merchantInfo.name}</h1>
					<p>
						{merchantInfo.address1}<br />
						{merchantInfo.city}, {merchantInfo.state} {merchantInfo.zip}, {merchantInfo.country}<br />
						{merchantInfo.phone}
					</p>
				</address>
			</header>
			<table className={classes.table}>
				<thead>
					<tr>
						<th colSpan="2">Payment Details</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row">Payment Amount</th>
						<td>${transaction.amount}</td>
					</tr>
					{transaction.surcharge !== '0' && transaction.surcharge !== '0.00' ? <tr>
						<th scope="row">Surcharge</th>
						<td>${transaction.surcharge}</td>
					</tr> : null
					}
					{transaction.totalPaid !== transaction.amount ? 
						<tr>
						<th scope="row">Total Paid</th>
						<td>${transaction.totalPaid}</td>
					</tr> : null
					}
					
					<tr>
						<th scope="row">Payment Date</th>
						<td>{transactionDate.format('MM/DD/YYYY')}</td>
					</tr>
					<tr>
						<th scope="row">Confirmation Number</th>
						<td>{transaction.confirmationNumber}</td>
					</tr>
				</tbody>
				<tbody>
					<tr>
						<th scope="row">Account Owner</th>
						<td>{transaction.name}</td>
					</tr>
					<tr>
						<th scope="row">Payment Method</th>
						<td>{transaction.type} ... {transaction.number}</td>
					</tr>
				</tbody>
				<tbody>
					{transaction.country && transaction.country != 'Others' && (
						<tr>
							<th scope="row">Billing Address</th>
							<td>
								{transaction.address1}, {transaction.address2}<br />
								{transaction.city}, {transaction.state}, {transaction.zip}<br />
								{transaction.country}
							</td>
						</tr>
					)}
					<tr>
						<th scope="row">Email</th>
						<td>{transaction.email}</td>
					</tr>
				</tbody>
			</table>
			<footer className={classes.footer}>
				<p className={classes.footerText}>Please retain for future reference</p>
			</footer>
		</div>
	);
}

export default ReceiptPreview;
