import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { COLORS } from '../../constants/Colors';
import Divider from '@material-ui/core/Divider';

import SignUpCheckedBox from './SignUpCheckBox';
import TermsAndConditionsPdf from './TermsAndConditionsPdf';
import PropTypes from 'prop-types';
import SignUpService from '../../services/SignUpService';
import InProgress from '../../forms/InProgress';

const useStyles = makeStyles((theme) => ({
	title: {
		color: '#2D4B69',
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '3%',
		marginTop: '3%',
		fontSize: 18,
		marginBottom: '1%',
	},
	ul: {
		color: '#E14126',
	},
	container: {
		height: '4vh',
		width: '100%',
		// marginBottom: 40
	},
	divider: {
		backgroundColor: COLORS.dividerColor
	},
	message: {
		marginTop: '3%',
	},
	text: {
		color: COLORS.blackText,
	},
	span: {
		color: COLORS.blueButtons,
		textDecoration: 'underline',
		cursor: 'pointer',
		marginLeft: 5,
		marginRight: 5
	}
}));

function TermsAndConditions({
	token = '',
	setSubmitButtonDisabled = () => {},
	termsAndConditionsCheckBoxState = false,
	disable= true,
	setDisable = () => {},
	checkButtonOneSate= {},
	setCheckButtonOneSate = () => {},
	checkButtonTwoSate = {},
	setCheckButtonTwoSate = () => {}
}) {

	const classes = useStyles();
	const [ showPdfBankDisclosure, setShowPdfBankDisclosure ] = useState(false);
	const [ pdf, setPdf ] = useState('');
	const [ isBankDisclosuresScrolledDawn, setIsBankDisclosuresScrolledDawn, ] = useState(true);
	const [ isTermsSubMerchantScrolledDawn, setIsTermsSubMerchantScrolledDawn ] = useState(true);
	const [ pdfName, setPdfName ] = useState('');
	const [ dialogName, setDialogName ] = useState('');
	const [showLoader, setShowLoader] = useState(false)

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};
	const getPdf = async (pdfName, axiosConfig) => {
		setShowLoader(true);
		const response = await SignUpService.getPdf(pdfName, axiosConfig);
		setShowLoader(false);
		setPdf(response?.data?.contents);
		setShowPdfBankDisclosure(true);
	};

	useEffect(() => {
		if (!isBankDisclosuresScrolledDawn && !isTermsSubMerchantScrolledDawn) {
			setDisable(false);
		}

	},[ isBankDisclosuresScrolledDawn, isTermsSubMerchantScrolledDawn ]);

	useEffect(() => {
		if (checkButtonOneSate?.['publicCompany'] && checkButtonTwoSate?.['publicCompany']) setSubmitButtonDisabled(false);
		else setSubmitButtonDisabled(true);
	}, [ checkButtonOneSate?.['publicCompany'], checkButtonTwoSate?.['publicCompany'] ]);

	const pdfNameToggle = (pdfName) => {
		if (pdfName === 'APX Merchant-Bank Disclosure Document') return setIsBankDisclosuresScrolledDawn;
		else return setIsTermsSubMerchantScrolledDawn;
	};

	const renderTermsAndConditionsFields = () => {
		return (
			<Grid container direction='row' >
				<Grid item className={classes.title} xs={4} > Terms And Conditions</Grid>

				<Divider className={classes.divider}/>

				<Grid item xs={12} className={classes.message}>
					<Typography>Please take a moment to read the
						<span style={{ fontWeight: 'bold' }} id={'bank-disclosure'}> Bank Disclosure </span>
						and
						<span style={{ fontWeight: 'bold' }} id={'terms-and-conditions'}> Merchant Terms and Conditions </span>
						documents to the very end before you continue
					</Typography>
				</Grid>

				<ul className={classes.ul}>
					<li >
						<Grid item  xs={12} >
							<Typography className={classes.text}>
							I have read and understood the
								<span
									onClick={() => {getPdf('PayrixBankDisclosures', axiosConfig);
										setPdfName('APX Merchant-Bank Disclosure Document'); setDialogName('Bank Disclosures');}}
									className={classes.span}>
							Bank Disclosure
								</span>provided
							</Typography>
						</Grid>
					</li>
					<li> <Grid item  xs={12} >
						<Typography className={classes.text}>I have read and understood the
							<span
								onClick={() => {getPdf('PayrixTermsSubMerchant', axiosConfig);
									setPdfName('APX Merchant-Terms and Condition'); setDialogName('Terms and Conditions');}}
								className={classes.span}>
							Merchant Terms and Conditions
							</span> provided
						</Typography>
					</Grid>
					</li>
				</ul>

				<Grid item  xs={12}>
					<Typography>
						By clicking<span style={{ fontWeight: 'bold' }}> I Agree </span>
						 below you acknowledge that you have read, understand,
                    	and agree to be bound by the terms above
					</Typography>
				</Grid>

				<Grid item  >
					<SignUpCheckedBox
						disable={disable}
						id={'agree-first-file'}
						label=" I Agree to the above information"
						name="publicCompany"
						componentName='aboutBusiness'
						setCheckIsSateEmpty={setCheckButtonOneSate}
						color='selectedPdfCheckbox'
						termsAndConditionsCheckBoxState={termsAndConditionsCheckBoxState}
					/>
				</Grid>

				<Grid item lg={12} md={12}>
					<SignUpCheckedBox
						disable={disable}
						id={'agree-second-file'}
						label='I attest that the information provided in this application is
						correct to the best of my knowledge as an authorized signer for the business.'
						name="publicCompany" 
						componentName='aboutBusiness'
						setCheckIsSateEmpty={setCheckButtonTwoSate}
						color='selectedPdfCheckbox'
					  	termsAndConditionsCheckBoxState={termsAndConditionsCheckBoxState}
					/>
				</Grid>

				{showPdfBankDisclosure && pdf &&
				<TermsAndConditionsPdf
					setDisable={setDisable}
					pdfNameToggle={pdfNameToggle(pdfName)}
					showPdfBankDisclosure={showPdfBankDisclosure}
					setShowPdfBankDisclosure={setShowPdfBankDisclosure} pdf={pdf}
					dialogName={dialogName}
					showLoader={showLoader}
					setShowLoader={setShowLoader}
					pdfName={pdfName}
				/>}
			</Grid>
		);
	};

	return (
		<>
			{renderTermsAndConditionsFields()}
			{ showLoader && <InProgress/>}
	</>
	);
}

TermsAndConditions.propTypes = {
	token: PropTypes.string,
	setSubmitButtonDisabled: PropTypes.func,
	termsAndConditionsCheckBoxState: PropTypes.bool,
	disable: PropTypes.bool,
	setDisable: PropTypes.func,
	checkButtonOneSate: PropTypes.object,
	setCheckButtonOneSate: PropTypes.func,
	checkButtonTwoSate: PropTypes.object,
	setCheckButtonTwoSate: PropTypes.func
};

export default memo(TermsAndConditions);