import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Typography } from '@material-ui/core';
import { COLORS } from '../constants/Colors';
import InProgress from '../forms/InProgress';

const useStyles = makeStyles((theme) => ({
	cancel: {
		fontWeight: 900,
		borderColor: COLORS.blueButtons,
		color: COLORS.blueButtons,
		marginRight: 10,
		textTransform: 'capitalize',
		backgroundColor: COLORS.lightGray,
	},
	submit: {
		marginRight: 10,
		width: '20%',
		fontWeight: 900,
		color: COLORS.whiteText,
		textTransform: 'capitalize',
		backgroundColor: COLORS.blueButtons,
		borderColor: COLORS.blueButtons,
		'&:hover': {
			background: COLORS.onHoverBlueButtons,
		},
	},
	actions: {
		marginTop: 30,
		backgroundColor: COLORS.lightGray
	},
	title: {
		paddingTop: 0,
	},
	message: {
		marginTop: -30,
		marginBottom: 30
	}
}));

export default function AlterSchedule({
	open = false,
    title = '',
	message = '',
    onCancel = () => {},
    onSubmit = () => {},
    isLoading = false
}) {

	const classes = useStyles();

	return (
		<Grid container>
			<Dialog open={open} maxWidth='xs' fullWidth>
				{ isLoading && <InProgress /> }
				<DialogTitle className={classes.title}>
					<h4>
					{title}
					</h4>
				</DialogTitle>
				<DialogContent className={classes.message}>
					<Typography variant='subtitle1'>
					{message}
					</Typography>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Button variant='outlined' onClick={onCancel} className={classes.cancel} id={'close'}>
						Close
					</Button>
					<Button disabled={isLoading} variant='contained' color='primary' onClick={onSubmit} className={classes.submit} id={'confirm'}>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
}