import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import * as _ from 'lodash';
import AboutBusiness from './AboutBusiness';
import AboutOwner from './AboutOwner';
import Footer from '../Footer';
import Header from '../Header';
import AddBankAccount from './AddBankAccount';
import SignUpCongratulationsPage from './SignUpCongratulationsPage';
import { SignupFieldsInfo } from './SignupFieldsInfo';
import SignUpSelectField from './SignUpSelect';
import TermsAndConditions from './TermsAndConditions';
import { COLORS } from '../../constants/Colors';
import Messages  from '../../forms/Messages';
import InProgress from '../../forms/InProgress';
import { appendPendoUrl } from '../../services/Services';
import SignUpService from '../../services/SignUpService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles(() => ({
	selectedAvatar: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		color: COLORS.whiteBackground,
		fontWeight: 'bold',
	},
	notSelectedAvatar: {
		backgroundColor: COLORS.lightGray,
		color: COLORS.darkGrayText,
		fontWeight: 'bold',
	},
	nextButton1: {
		background: 'linear-gradient(90deg, #ED1C24 0%, #F15A29 100%)',
		width: 150,
		height: 40,
		color: COLORS.whiteText,
		marginTop: 20,
		marginBottom: 20,
		textTransform: 'none'

	},
	nextButton2: {
		background: 'linear-gradient(90deg, #F68D91 0%, #F8AC94 100%)',
		width: 150,
		height: 40,
		marginTop: 20,
		marginBottom: 20,
		color: COLORS.whiteText,
		textTransform: 'none'
	},
	cancelButton: {
		boxSizing: 'border-box',
		width: 150,
		height: 40,
		border: '1px solid #E14126',
		borderRadius: 4,
		color: COLORS.cancelButton,
		fontWeight: 'bold',
		textAlign: 'center',
		textTransform: 'none'
	},
	backButton: {
		boxSizing: 'border-box',
		width: 150,
		height: 40,
		border: '1px solid #E14126',
		borderRadius: 4,
		color: COLORS.cancelButton,
		fontWeight: 'bold',
		marginTop: 20,
		marginBottom: 20,
		textTransform: 'none'

	},
	selectedAvatarText: {
		color: COLORS.whiteInvoice,
		fontWeight: 700,
		textAlign: 'right'
	},
	notSelectedAvatarText: {
		color: COLORS.darkGrayText,
		marginLeft: 10,
		textAlign: 'center'
	},
	page: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: '"#ECEFF1"',
	},
	avatar: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: COLORS.whiteBackground,
	},

	congratulationHeader: {
		boxSizing: 'border-box',
		height: 65,
		border: '1px solid #CFD8DC',
		backgroundColor: '#ECF7EA'
	},
	boardingPages: {
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		backgroundColor: COLORS.whiteBackground,
	},
	avatarsIcons: {
		marginBottom: '1%',
		marginTop: '1%',
	},
	text: {
		color: COLORS.darkGray,
		marginTop: '3%'
	},
	additionalOwner: {
		color: COLORS.cancelButton,
		fontWeight: 500,
		letterSpacing: 0,
		marginTop: '0.2%',
		textAlign: 'left',
		marginLeft: '1.5%'
	},
	required: {
		color: COLORS.cancelButton,
		letterSpacing: 0,
		textAlign: 'right'
	},
	title: {
		color: COLORS.blueHeader,
		fontWeight: 'bold',
		letterSpacing: 0,
		marginBottom: 10
	},
	avatarBorder: {
		boxSizing: 'border-box',
		borderLeft: '1px solid #CFD8DC',
		backgroundColor: COLORS.whiteBackground,
		borderRight: `1px solid ${COLORS.grayBorder}`,
	},
	footer: {
		position: 'fixed',
		left: 0,
		bottom: 0,
		width: '100%',

	},
	successfulMessage: {
		color: '#29961D',
		fontSize: 16,
		fontWeight: 'bold',
		letterSpacing: 0,
		lineHeight: '14px',
		marginTop: '1.25%',
		marginLeft: '1%'
	},
	successfulIcon: {
		height: 40,
		width: 40,
		backgroundColor: '#41B832',
	 },
	 horizontallyCenter: {
		 display: 'flex',
		 alignItems: 'center'
	 },
	 info: {
		 color: 'gray',
		 fontSize: '0.85em'
	 }
}));

// eslint-disable-next-line require-jsdoc
function SignUp() {
	const classes = useStyles();
	const location = useLocation();
	const applicationCustomerId = location.state?.applicationCustomerId;

	const [ pageNumber, setPageNumber ] = useState(1);
	const [ disableButton, setDisableButton ] = useState(true);
	const [ signupFields, setSignupFields ] = useState({});
	const [ pageState, setPageState ] = useState({ 1: false, 2: false, 3: false, 4: false });
	const [ aboutOwnerPage, setAboutOwnerPage ] = useState([ 1 ]);
	const [ aboutOwner, setAboutOwner ] = useState({});
	const [ params, setParams ] = useState({});
	const [ submitButtonDisabled, setSubmitButtonDisabled ] = useState(true);
	const [ aboutOwnerError, setAboutOwnerError ] = useState({});
	const [ termsAndConditionsCheckBoxState, setTermsAndConditionsCheckBoxState ] = useState(false);
	const [ disable, setDisable ] = useState(true);
	const [ checkButtonOneSate, setCheckButtonOneSate ] = useState({});
	const [ checkButtonTwoSate, setCheckButtonTwoSate ] = useState({});
	const [ primaryContact, setPrimaryContact ] = useState([ 'Owner 1' ]);
	const [ bankInfo, setBankInfo ] = useState({});
	const [ isMessageOpen, setIsMessageOpen ] = useState(false);
	const [ message, setMessage ] = useState('');
	const [ severity, setSeverity ] = useState('');
	const [ isLoading, setIsLoading ] = useState(false);
	const [ plaidOperatingtimeOut, SetPlaidOperatingtimeOut ] = useState(false);
	const [ plaidTrustimeOut, SetPlaidTrusttimeOut ] = useState(false);
	const [ isValidUserName, setIsValidUserName ] = useState(false);
	const [ operatingFiles, setOperatingFiles ] = useState([]);
	const [ trustFiles, setTrustFiles ] = useState([]);
	const [ operatingDocumentsType, setOperatingDocumentsType ] = useState({});
	const [ trustDocumentsType, setTrustDocumentsType ] = useState({});
	const [ userNameMessageError, setUserNameMessageError ] = useState('');
	const [ resteAboutOwnerPage, setresetAboutOwnerPage ] = useState({});
	const [ savedOwnersNumber, setsavedOwnersNumber ] = useState([ 1 ]);
	const [ resteAboutOwnerPageNumber, setresetAboutOwnerPageNumber ] = useState(true);
	const [ restPrimaryContact, setrestPrimaryContact ] = useState(false);
	const [ disableOwnerShip, setDisableOwnerShip ] = useState(false);
	const [ defaultValueForOwnerShip, setDefaultValueForOwnerShip ] = useState('');
	const [ addOperatingAccManually, setOperatingManually ] = useState([]);
	const [ addTrustingAccManually, setTrustingAccManually ] = useState([]);
	const [ showProgressBar, setShowProgressBar ] = useState(true);

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${params?.customer}`
		}
	};

	useEffect(() => {
		const newAboutOwnerPageNumber = [];
		let number = 0;
		for (let property in resteAboutOwnerPage) {
			let newOwnerProperty = property.replace(/\d+$/, '');
			if (newOwnerProperty === 'DOB') {
				number = number + 1;
				newAboutOwnerPageNumber.push(number);
			}
		}

		if (newAboutOwnerPageNumber.length && resteAboutOwnerPageNumber) {
			const primaryContact = [];
			for (let x = 0; x < newAboutOwnerPageNumber.length; x++) {
				primaryContact.push(`Owner ${newAboutOwnerPageNumber[x]}`);
			}
			setPrimaryContact(primaryContact);
			setAboutOwnerPage(newAboutOwnerPageNumber);
			setsavedOwnersNumber(newAboutOwnerPageNumber);
		}
	}, [ resteAboutOwnerPage, resteAboutOwnerPageNumber ]);

	useEffect(() => {
		appendPendoUrl('/AboutBusiness');
	}, [ params ]);

	useEffect(() => {
		const primaryContactList = [];
		for (let x = 0; x < savedOwnersNumber.length; x++) {
			primaryContactList.push(`Owner ${x + 1}`);
		}
		setPrimaryContact(primaryContactList);

	}, [ savedOwnersNumber ]);

	const onButtonNextSubmit = async () => {
		if (pageNumber === 1) appendPendoUrl('/AboutOwner');
		if (pageNumber === 2) appendPendoUrl('/AddBankAccount');
		if (pageNumber === 3) appendPendoUrl('/TermsAndConditions');
		if (pageNumber !== 3) {
			const response = await SignUpService.saveMerchantData(applicationCustomerId, signupFields, pageNumber, axiosConfig);
			if (response.status === 'error') {
				errorOut(response.err);
			} else {
				if (pageNumber === 2) {
					setAboutOwner({});
					setSignupFields({});
					setAboutOwnerPage(savedOwnersNumber);
					setresetAboutOwnerPageNumber(true);
				}
			}
		}
		setPageNumber(pageNumber => pageNumber + 1);
		setPageState(prevPageState => ({ ...prevPageState, [[ pageNumber ]]: true }));
	};

	const onButtonBackSubmit = () => {
		// if (pageNumber === 1) appendPendoUrl('/AboutBusiness');
		if (pageNumber === 2) appendPendoUrl('/AboutBusiness');
		if (pageNumber === 3) appendPendoUrl('/AboutOwner');
		if (pageNumber === 4) appendPendoUrl('/AddBankAccount');
		setPageNumber(pageNumber => pageNumber - 1);
		setPageState(prevPageState => ({ ...prevPageState, [[ pageNumber - 1 ]]: false }));
		setresetAboutOwnerPageNumber(true);
	};

	const addOwner = () => {
		const ownerAddedNumber = aboutOwnerPage[aboutOwnerPage.length - 1] + 1;
		const saveOwnerNumber = savedOwnersNumber[aboutOwnerPage.length - 1] + 1;
		setAboutOwnerPage([ ...aboutOwnerPage, ownerAddedNumber ]);
		setPrimaryContact(prev => ([ ...prev, `Owner ${saveOwnerNumber}` ]));
		setsavedOwnersNumber([ ...savedOwnersNumber,  saveOwnerNumber ]);
		setresetAboutOwnerPageNumber(false);
		setrestPrimaryContact(false);
	};

	useEffect(() => {
		setParams(location.state.params);
	}, [ location.state.params ]);

	const removeOwner = (i) => {

		const index = aboutOwnerPage.findIndex((owner) => owner === i);
		const savedOwnersNumberNewList = [ ...savedOwnersNumber ];
		savedOwnersNumberNewList.pop();

		setsavedOwnersNumber(savedOwnersNumberNewList);
		const newList = [ ...aboutOwnerPage ];
		newList.splice(index, 1);
		setAboutOwnerPage(newList);

		const keys = _.keys(aboutOwner);
		if (keys.length) {
			for (let x = 0; x < keys.length; x++) {
				if (_.includes(String(keys[x]), i)) {
					delete aboutOwner[String(keys[x])];
				}
			}
		}

		if (signupFields[`aboutOwner${i}`]) delete signupFields[`aboutOwner${i}`];
		setrestPrimaryContact(true);
		setAboutOwner(prev => ({ ...prev, primaryContact: '' }));
	};

	useEffect(() => {
		if (!submitButtonDisabled) {
			setPageState(prevPageState => ({ ...prevPageState, 4: !submitButtonDisabled }));
			setTermsAndConditionsCheckBoxState(!submitButtonDisabled);
			setDisable(false);
		}
	}, [ submitButtonDisabled ]);

	useEffect(() => {

		if (signupFields?.aboutBusiness?.businessType === 'GOV') {

			setDisableOwnerShip(true);
			setDefaultValueForOwnerShip('0%');
		} else if (signupFields?.aboutBusiness?.businessType === 'SOLE PROPRIETOR') {

			setDisableOwnerShip(true);
			setDefaultValueForOwnerShip('100%');
		} else if (resteAboutOwnerPage.businessType !== 'GOV' &&  resteAboutOwnerPage.businessType !== 'SOLE PROPRIETOR') {
			setDisableOwnerShip(false);
			setDefaultValueForOwnerShip('');
		}

	},[ signupFields?.aboutBusiness?.businessType, resteAboutOwnerPage.businessType ]);

	const saveBankAccount = async (bankInfo) => {
		const response = await SignUpService.saveMerchantData(applicationCustomerId, bankInfo, pageNumber, axiosConfig);
		if (response.status === 'error') errorOut(response.err);
	};

	const updateBankAccount = async (bankInfo) => {

		const response = await SignUpService.updateMerchantBankInfo(applicationCustomerId, bankInfo, axiosConfig);
		if (response.status === 'error') errorOut(response.err);
	};

	const deleteBankAccount = async (isPlaid, bankName, type) => {
		await SignUpService.deleteMerchantBankAccount(applicationCustomerId, isPlaid, bankName, type, axiosConfig);
	};

	const submitApplication = async () => {
		const filesToSave = [ ...operatingFiles, ...trustFiles ];
		const documentTypeToSave = [ ..._.values(operatingDocumentsType?.documentTypes), ..._.values(trustDocumentsType?.documentTypes) ];
		const notes = [ ..._.values(operatingDocumentsType?.notes), ..._.values(trustDocumentsType?.notes) ];
		setIsLoading(true);
		setSubmitButtonDisabled(true);
		const response = await SignUpService.submitApplication(applicationCustomerId, filesToSave, documentTypeToSave, notes, axiosConfig);
		setIsLoading(false);
		setSubmitButtonDisabled(false);
		if (response.status === 'error') errorOut(response.err);
		else {
			setPageNumber(5);
		}
	};

	const errorOut = (err) => {
		if (err === 'entities[0].accounts[0].account Could not retrieve account information from designated platform') {
			setPageNumber(3);
			setMessage('Plaid session timed out due to inactivity, kindly try adding your bank details with Plaid again');
		} else {
			setMessage(err);
		}
		setIsMessageOpen(true);
		setSeverity('error');
	};

	const handleMessageClose = () => {
		setIsMessageOpen(false);
	};

	const checkIsValidUserName = async () => {
		const response = await SignUpService.validateUserName(signupFields['aboutBusiness']?.username, axiosConfig);
		if (response.status === 'success') {
			setIsValidUserName(response?.data?.isUserNameValid);
			setUserNameMessageError(response?.data?.userNameMessageError);
		}
	};

	const DisplayAvatar = () => {
		return (
			<Container maxWidth='lg' className={classes.avatarsIcons} >

				<Grid item lg={11} xl={10} md={12}>
					<Grid container direction="row" spacing={0} >
						<Grid item xs={6} lg={3} md={3} xl={3}>
							<Grid container direction="row" alignItems='center' justifyContent='center' >

								<Grid item md={1} lg={1} xl={1}>
									<Avatar className={(pageNumber === 1 || pageState[1]) ? classes.selectedAvatar : classes.notSelectedAvatar}>
										{(pageNumber !== 1) ? <CheckIcon/> : <span>1</span>}
									</Avatar>
								</Grid>

								<Grid item xs={12} md={9} lg={9} xl={9} className={(pageState[1]) ? classes.selectedAvatarText : classes.notSelectedAvatarText}>
									About the Business
								</Grid>

							</Grid>
						</Grid>

						<Grid item xs={6} lg={3} md={3} xl={3} >
							<Grid container direction="row" spacing={0} alignItems='center' justifyContent='center'>
								<Grid item xs={1} md={1} xl={1} style={{ color: '#B0BEC5' }} >—</Grid>
								<Grid item xs={1} md={2} lg={1} xl={1}>
									<Avatar className={(pageNumber === 2 || pageState[2]) ? classes.selectedAvatar : classes.notSelectedAvatar}>
										{(pageState[2]) ? <CheckIcon/> : <span>2</span>}
									</Avatar>
								</Grid>
								<Grid xs={12} md={8} lg={9} xl={8} item className={(pageState[2]) ? classes.selectedAvatarText : classes.notSelectedAvatarText}>
									About The Owner
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={6} lg={3} md={3} xl={3}>
							<Grid container direction="row" spacing={0} alignItems='center' justifyContent='center'>
								<Grid item xs={1}  lg={1} md={1} xl={1} style={{ color: '#B0BEC5' }}>—</Grid>
								<Grid item xs={1} md={2} lg={1} xl={1}>
									<Avatar className={(pageNumber === 3 || pageState[3]) ? classes.selectedAvatar : classes.notSelectedAvatar}>
										{(pageState[3]) ? <CheckIcon/> : <span>3</span>}
									</Avatar>
								</Grid>
								<Grid item xs={12}   md={8} lg={9} xl={8} className={(pageState[3]) ? classes.selectedAvatarText : classes.notSelectedAvatarText}  >
									Add Bank Account
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={6} lg={3} xl={3} md={3}  >
							<Grid container direction="row" spacing={0} alignItems='center' justifyContent='center'>
								<Grid item xs={1} lg={1} md={1} style={{ color: '#B0BEC5' }}>—</Grid>
								<Grid item xs={1} md={1} lg={1} >
									<Avatar className={pageNumber === 4 || pageState[4] ? classes.selectedAvatar : classes.notSelectedAvatar}>
										{(pageState[4]) ? <CheckIcon/> : <span>4</span>}
									</Avatar>
								</Grid>
								<Grid item style={{ marginLeft: '18px' }} xs={12} md={9} lg={9} xl={9} className={(pageState[4]) ? classes.selectedAvatarText : classes.notSelectedAvatarText}>
									Terms and Conditions
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container >
		);
	};

	const renderBoardingPages = (pageNumber) => {
		switch (pageNumber) {
			case 1:
				return (
					<Container maxWidth='lg'>
						<AboutBusiness
							token={params?.customer}
							applicationCustomerId={applicationCustomerId}
							setDisableButton={setDisableButton}
							disableButton={disableButton}
							signupFields={signupFields}
							setSignupFields={setSignupFields}
							isValidUserName={isValidUserName}
							checkIsValidUserName={checkIsValidUserName}
							setIsValidUserName={setIsValidUserName}
							userNameMessageError={userNameMessageError}
						/>
					</Container>
				);
			case 2:
				return (
					<Container maxWidth='lg'>

						<AboutOwner
							setDisableButton={setDisableButton}
							disableButton={disableButton}
							aboutOwnerPage={aboutOwnerPage}
							signupFields={signupFields}
							setSignupFields={setSignupFields}
							removeOwner={removeOwner}
							aboutOwner={aboutOwner}
							setAboutOwner={setAboutOwner}
							token={params?.customer}
							applicationCustomerId={applicationCustomerId}
							setAboutOwnerError={setAboutOwnerError}
							aboutOwnerError={aboutOwnerError}
							setresetAboutOwnerPage={setresetAboutOwnerPage}
							resteAboutOwnerPageNumber={resteAboutOwnerPageNumber}
							disableOwnerShip={disableOwnerShip}
							defaultValueForOwnerShip={defaultValueForOwnerShip}
							setShowProgressBar={setShowProgressBar}
							showProgressBar={showProgressBar}
						/>
						{ signupFields?.aboutBusiness?.businessType !== 'SOLE PROPRIETOR' && !showProgressBar && primaryContact.length < 5 &&
							<Grid container direction='row' style={{ marginTop: '2%' }}>
								<Grid item xs={12} className={classes.horizontallyCenter}>
									<div onClick={() => addOwner()} style={{ cursor: 'pointer' }} className={classes.horizontallyCenter}>
										<AddCircleOutlineIcon />
										<span style={{ color: '#B90023', 'text-transform': 'uppercase', marginLeft: '0.75em' }}>
											Add Additional owner
										</span>
									</div>&nbsp;
									<i className={classes.info}>(Please provide names of individuals who own 25% and more in the business, you can add upto 5 owners)</i>
								</Grid>
							</Grid>}

						<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
							<Grid item xs={12} md={6}>
								<SignUpSelectField
									label='Select Primary Contact of the Business'
									componentName='aboutOwner'
									name='primaryContact'
									type='string'
									setSignupFields={setSignupFields}
									signupFields={signupFields}
									list={primaryContact}
									setCheckIsSateEmpty={setAboutOwner}
									savedData={resteAboutOwnerPage}
									setPageError={setAboutOwnerError}
									restPrimaryContact={restPrimaryContact}
									setrestPrimaryContact={setrestPrimaryContact}
									info={SignupFieldsInfo.primaryContact}
									pageNumber={2}
								/>
							</Grid>
						</Grid>
					
					</Container>
				);
			case 3:
				return (
					<Container maxWidth='lg'>
						<AddBankAccount
							token={params?.customer}
							applicationCustomerId={applicationCustomerId}
							setDisableButton={setDisableButton}
							disableButton={disableButton}
							signupFields={signupFields}
							saveBankAccount={saveBankAccount}
							deleteBankAccount={deleteBankAccount}
							bankInfo={bankInfo}
							setBankInfo={setBankInfo}
							setPageNumber={setPageNumber}
							plaidOperatingtimeOut={plaidOperatingtimeOut}
							SetPlaidOperatingtimeOut={SetPlaidOperatingtimeOut}
							plaidTrustimeOut={plaidTrustimeOut}
							SetPlaidTrusttimeOut={SetPlaidTrusttimeOut}
							setOperatingFiles={setOperatingFiles}
							setTrustFiles={setTrustFiles}
							setOperatingDocumentsType={setOperatingDocumentsType}
							setTrustDocumentsType={setTrustDocumentsType}
							operatingDocumentsType={operatingDocumentsType}
							trustDocumentsType={trustDocumentsType}
							operatingFiles={operatingFiles}
							setOperatingManually={setOperatingManually}
							addOperatingAccManually={addOperatingAccManually}
							trustFiles={trustFiles}
							updateBankAccount={updateBankAccount}
							addTrustingAccManually={addTrustingAccManually}
							setTrustingAccManually={setTrustingAccManually}
						/>
					</Container>
				);
			case 4:
				return (
					<Container maxWidth='lg' style={{ height: '73.3vh' }}>
						<TermsAndConditions
							token={params?.customer}
							setSubmitButtonDisabled={setSubmitButtonDisabled}
							termsAndConditionsCheckBoxState={termsAndConditionsCheckBoxState}
							disable={disable}
							setDisable={setDisable}
							checkButtonOneSate={checkButtonOneSate}
							setCheckButtonOneSate={setCheckButtonOneSate}
							checkButtonTwoSate={checkButtonTwoSate}
							setCheckButtonTwoSate={setCheckButtonTwoSate}
						/>
					</Container>
				);
			case 5 :
				return (
					<Container maxWidth='lg' style={{ height: '100vh' }}>
						<SignUpCongratulationsPage/>
					</Container>
				);
			default:
				return null;
		}
	};
	return (
		<div className={classes.page}>
			<Header/>
			{ pageNumber !== 5  ? <Container maxWidth='lg' className={classes.avatar}>
				<DisplayAvatar/>
				{ isLoading && <InProgress /> }
			</Container> :
				<Container maxWidth='lg'  direction='row' className={classes.congratulationHeader}>
					<Container maxWidth='lg' style={{ marginLeft: '2%', marginTop: '1%' }}>
						<Grid container  direction='row' >
							<Grid item  >
								<Avatar  className={classes.successfulIcon}>
									<CheckIcon style={{ color: 'white' }}/>
								</Avatar>
							</Grid>
							<Grid item className={classes.successfulMessage}> You have successfully completed APX Application!</Grid>
						</Grid>
					</Container>
				</Container> }
			<Messages message={message} severity={severity} isOpen={isMessageOpen} handleClose={handleMessageClose} />
			<Container maxWidth='lg' className={classes.boardingPages}>

				{renderBoardingPages(pageNumber)}

				<Container maxWidth='lg'>
					<Grid item container direction='row' alignItems='flex-end' justifyContent='flex-end'>

						{ (pageNumber > 1 && pageNumber < 5) ? <Grid  item  lg={2} md={3}>
							<Button 
								onClick={onButtonBackSubmit}
								className={classes.backButton}
								id={`back-to-step-${pageNumber - 1}`}
							>
								Back To Step {pageNumber - 1}
							</Button>
						</Grid> : null}

						{(pageNumber < 4) && <Grid item >
							<Button
								onClick={onButtonNextSubmit}
								className={(!disableButton) ? classes.nextButton1 : classes.nextButton2}
								disabled={disableButton} style={{ color: COLORS.whiteText }}
								id={`go-to-step-${pageNumber + 1}`}
							>
							Go To Step {pageNumber + 1}
							</Button>
						</Grid>}

						{ (pageNumber === 4) && <Grid item >
							<Button
								id={'submit'}
								className={(!submitButtonDisabled) ? classes.nextButton1 : classes.nextButton2}
								disabled={submitButtonDisabled} style={{ color: COLORS.whiteText }}
								onClick={submitApplication}
							>
							Submit
							</Button>
						</Grid>}

					</Grid>
				</Container>
			</Container>
			<div >
				<Footer/>
			</div>
		</div>
	);
}

export default SignUp;