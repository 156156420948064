import React, { useState, memo } from 'react';
import SyncCard from '../forms/SyncCard';
import axios from 'axios';
import { getApiToken, getApiUrl } from '../utils/getApiUrl';
import FormTextField from '../forms/FormTextField';

const AdminDisbursementReportsInput = (props) => {
	const [ isSyncing, setIsSyncing ] = useState(false);
	const [ merchantId, setMerchantId ] = useState('');

	const sync = async () => {
		setIsSyncing(true);
		try {
			const response = await axios.get(`${getApiUrl()}/api/admin/get_reports_url?merchantId=${merchantId}`, {
				headers: { Authorization: `Bearer ${getApiToken()}` }
			});

			if (response.data.code === 200) {
				window.open(response.data.merchantReportsUrl,'_blank');
			}
			setIsSyncing(false);
		} catch (err) {
			console.log(err);
			setIsSyncing(false);
		}
	};

	const onInputChange = ({ target: { value } }) => {
		setMerchantId(value);
	};

	const merchantField = () => {
		return  <FormTextField  required={false} placeholder='Merchant Id' id='AdminDisbursementReportsInputId' value={merchantId} onChange={onInputChange}  />;
	};

	return (
		<SyncCard 
			title="AdminDisbursementReportsInput"
			actionTitle="Generate"
			sync={sync}
			isSyncing={isSyncing}
			showRefresh={false}
			showRecordsCount={false}
			inputField={merchantField}
			{...props} />
	);
};

export default memo(AdminDisbursementReportsInput);