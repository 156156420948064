import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';

import { makeStyles } from '@material-ui/core/styles';

import ReportsSelect from './ReportSelect';
import ReportSearch from './ReportSearch';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
	item: {
		margin: '3% 2% 2% 2%',
		border: '1px solid #AFCFF2',
		borderRadius: '20px',
		padding: '1% 2% 1% 2%',
	},
	totalTransactions: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #0DC0D6',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalDisbursement: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #5DB761',
		paddingTop: '10px',
		borderRadius: '8px',
	},
	totalRefund: {
		border: '1px solid grey',
		margin: '1% 1% 0.25% 1%',
		borderBottom: '8px solid #E14428',
		paddingTop: '10px',
		borderRadius: '8px',

	},
	apply: {
		backgroundColor: 'Lightblue',
		marginRight: '4%'
	},
	commonMargin: {
		marginBottom: '2%',
		marginTop: '2%'
	},
	tooltip: {
		color: '#747779',
		fontFamily: 'Roboto',
		fontWeight: '400px',
		fontSize: '16px',
		lineHeight: '14px',
		textTransform: 'none',
		marginBottom: '1%'
	},
	runReports: {
		backgroundColor: '#1070D7',
		borderRadius: 4,
		fontFamily: 'Roboto',
		fontWeight: 700,
		fontSize: 16,
		lineHeight: '14px',
		color: '#FFFFFF',
		textTransform: 'none',
		padding: 10
	},
	expandAll: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: 16,
		lineHeight: '14px',
		textDecorationLine: 'underline',
		color: '#1070D7',
		marginTop: '3%',
		marginBottom: '0.5%'
	},
	total: {
		fontFamily: 'Roboto',
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '12px',
		color: '#747779',
		margin: 'auto'
	}

}));

const ReportsFields = ({
	value = '',
	setValue = () => {},
	getMerchantReports = () => {},
	merchantId = '',
	setClientValue = () => {},
	clientValue = '',
	merchantInfo = {},
	openSelectTooltip = false,
	setOpenSelectTooltip = () => {},
	setDisbursementRow = () => {}

}) => {

	const classes = useStyles();
	const [ search, setSearch ] = useState(false);

	return (
		<>
			<Grid container direction='row'  style={{ borderBottom: '1px solid Lightgray' }}>
				<Grid lg={6} md={8} sm={8} xs={12} style={{ marginBottom: '0.25%' }}>
					<Grid container direction='row' justifyContent={'space-between'} >
						<Grid lg={5} md={5} sm={5} xs={6}>
							<ReportsSelect
								value={value}
								setValue={setValue}
								openSelectTooltip={openSelectTooltip}
								setOpenSelectTooltip={setOpenSelectTooltip}
								setSearch={setSearch}
							/>
						</Grid>
						<Grid lg={5} md={5} sm={5} xs={6}  style={{ marginBottom: '1%', marginRight: '1%' }}>
							<ReportSearch
								setClientValue={setClientValue}
								clientValue={clientValue}
								merchantInfo={merchantInfo}
								setOpenSelectTooltip={setOpenSelectTooltip}
								setDisbursementRow={setDisbursementRow}
								search={search}
								setSearch={setSearch}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid lg={6} md={4} sm={4} xs={12} style={{ marginTop: '2%' }}>
					<Grid container direction='row' justifyContent={'flex-end'}>
						<Button id='run-report'
							disabled={value ? false : true}
							variant="contained"
							onClick={() => {getMerchantReports(merchantId);}}
							className={classes.runReports}
							style={{ textTransform: 'none' }}
						>
                            Run Report
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

ReportsFields.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func,
	getMerchantReports: PropTypes.func,
	merchantId: PropTypes.string,
	setClientValue: PropTypes.func,
	clientValue: PropTypes.string,
	merchantInfo: PropTypes.object,
	openSelectTooltip: PropTypes.bool,
	setOpenSelectTooltip: PropTypes.func,
	setDisbursementRow: PropTypes.func

};
export default ReportsFields;
