import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

const useStyles = makeStyles((theme) => ({
	bold: {
		fontWeight: 600
	},
	container: {
		color: 'black',
		padding: theme.spacing(2),

	},
}));
function Header({
	requestOfPayment = {}
}) {
	const classes = useStyles();
	const address = [];
	[ 'firmAddress1', 'firmAddress2', 'firmCity', 'firmState', 'firmZIP' ].forEach(x => {
		if (requestOfPayment[x]) {
			address.push(requestOfPayment[x]);
		}
	});
	return (
		<div className={classes.container}>
			<Typography className={classes.bold}>
				{requestOfPayment?.firmName || ''}
			</Typography>
			<Typography>
				{address.join(', ')}
			</Typography>
			{requestOfPayment?.firmPhone && (
				<Typography>
					{requestOfPayment.firmPhone.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
				</Typography>
			)}
		</div>
	);
}

Header.propTypes = {
	requestOfPayment: PropTypes.object
};

export default memo(Header);