import React, { useState, memo } from 'react';
import SyncCard from '../forms/SyncCard';
import axios from 'axios';
import {getApiToken, getApiUrl} from '../utils/getApiUrl';
import FormTextField from '../forms/FormTextField';

function PayrixTransactions() {
	const [isSyncing, setIsSyncing] = useState(false);
	const [merchantId, setMerchantId] = useState('');

	const sync = async () => {
		setIsSyncing(true);
		try {
			const response = await axios.post(`${getApiUrl()}/api/admin/sync_payrix_transactions?merchantId=${merchantId}`, null, {
				headers: {'Authorization': `Bearer ${getApiToken()}`}
			})
			if (response.data.code === 200) {
                setIsSyncing(false);
			}
		} catch (err) {
            window.alert(err);
			setIsSyncing(false);
		}
	}


	const onInputChange = ({ target: { value } }) => {
		setMerchantId(value)
	}

	const merchantField = () => {
		return  <FormTextField  required={false} placeholder='Merchant Id' id='merchantId' value={merchantId} onChange={onInputChange} />
	}

	return (
		<SyncCard showRecordsCount={false} showRefresh={false} title="Sync Merchant's Payrix Transactions" sync={sync} isSyncing={isSyncing} inputField={merchantField}/>
	)
}

export default memo(PayrixTransactions);