import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/Colors';
import HelpIcon from '@material-ui/icons/Help';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 17,
	},
}));

const useStyles = makeStyles({
	asterisk: {
		color: COLORS.asteriskRed,
		marginRight: 5
	},
	selectedLabel: {
		color: COLORS.blueButtons,
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	},
	selectedBorder: {
		'&.Mui-focused fieldset': {
			borderColor: `${COLORS.blueButtons} !important`
		}
	},
	customWidth: {
		maxWidth: 200,
		fontSize: 12
	},
	label: {
		color: '#455A64',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	}
});

// eslint-disable-next-line require-jsdoc
function SignUpTextField({
	savedData = { },
	setSignupFields = () => { },
	setCheckIsSateEmpty = () => {},
	type = '',
	showInfo = true,
	info = '',
	label = '',
	regex = '',
	name = '',
	componentName = '',
	required = true,
	signupFields = {},
	setPageError = () => {},
	numberLengthRegx = '',
	errorMessage = '',
	isValidUserName = true,
	checkIsValidUserName = () => {},
	setIsValidUserName = () => {},
	minimumLength = 0,
	placeHolder = '',
	defaultName = '',
	pageNumber = 1
}) {
	const classes = useStyles();
	const [ selected, setSelected ] = useState(false);

	const regularExpression = {
		phone: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
		ein: /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/,
		ssn: /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/,
		dob: /^(?:0[1-9]|[12]\d|3[01])([\/.-])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
		letter: /^[A-Za-z]+$/,
		zip: /(^\d{5}$)|(^\d{5}-\d{4}$)/
	};
	const schema = fieldValidationRules(type,regex, label);

	const { watch, control, handleSubmit, reset, formState: { errors } } = useForm({
		defaultValues: {
			[name]: savedData?.[name]  || defaultName
		},
		resolver: yupResolver(schema)
	});

	useEffect(()=> {
		if (name === 'website' || savedData?.[name]) {
			reset(savedData);
		}
	}, [ savedData ]);

	/**
	 *
	 * @param routingNumber
	 * @returns {boolean}
	 * this function validate bank routing number for more information please check out this link
	 * https://www.wikihow.com/Calculate-the-Check-Digit-of-a-Routing-Number-from-an-Illegible-Check#:~:text=To%20calculate%20the%20last%20digit,with%20the%20remaining%20six%20digits.
	 */

	const validateRoutingNumber = (routingNumber) =>{
		let sum = 0;
		const len = routingNumber.length;

		if (len !== 9) {
			return false;
		} else {
			sum = (7 * (parseInt(routingNumber.charAt(1)) + parseInt(routingNumber.charAt(4)) + parseInt(routingNumber.charAt(7))) +
				(3 * (parseInt(routingNumber.charAt(0)) + parseInt(routingNumber.charAt(3)) + parseInt(routingNumber.charAt(6)))) +
				(parseInt(routingNumber.charAt(2)) + parseInt(routingNumber.charAt(5)) + parseInt(routingNumber.charAt(8))));

			return sum % 10 === 0;

		}
	};

	// eslint-disable-next-line require-jsdoc
	function fieldValidationRules(type, regex, label) {
		const pattern = regularExpression[regex];
		switch (type) {
			case 'string':
				return  yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`)
					});
			case 'stringMinimumLength':
				return  yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).min(minimumLength)
					});
			case 'email':
				return  yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).email(`${label} is not in the right format`)
					});
			case 'website':
				return  yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).url('Website must be a valid URL with http:// or https://')
					});
			case 'number':
				return   yup.object().shape(
					{
						[name]: yup.number(`${label} must be a number`).typeError(`${label} must be a number`).positive(`${label} must be positive number`)
							.required(`${label} is required`)
					});
			case 'regex':
				return   yup.object().shape(
					{
						[name]: yup.string().matches(pattern, function(props) {return `${label} is not in the right format`;}).required(`${label} is required`)
					});
			case 'date' :
				return  yup.date().required(`${label} is required`);
			case 'numberLength':
				return   yup.object().shape(
					{
						[name]: yup.string().required(`${label} is required`).matches(numberLengthRegx, errorMessage)
					});
			case 'routingNumber':
				return   yup.object().shape(
					{
						[name]: yup.number()
							.test('noSpace', `${label} should not have space`,
								(value, { originalValue }) => originalValue.indexOf(' ') === -1
							)
							.typeError(`${label} is required and must be a number`)
							.required(`${label} is required`)
							.test('maxDigitsAfterDecimal', `${label} must be a valid bank routing number`,
								(value, { originalValue }) => validateRoutingNumber(originalValue.toString())
							)
					});
			case 'bankAccountNumber':
				return   yup.object().shape(
					{
						[name]: yup.string()
							.required(`${label} is required`)
							.matches(/^\d+$/, 'Spaces and Characters are not allowed')
							.matches(numberLengthRegx, errorMessage)
					});
			default:
				return  yup.object().shape({ [name]: '' });
		}
	}
	const onSubmitted = (data) => {
		setSelected(false);
		if (data) {
			setSignupFields(prev => ({ ...prev,
				[componentName]: {
					...prev[componentName],
					[name]: data[name]
				}
			}));
		}
	};

	useEffect(() => {
		setCheckIsSateEmpty(prev => ({ ...prev, [name]: watch()?.[name] }));
		setIsValidUserName(true);
		if (pageNumber === 2) {
			if (name === 'firstname1' || name === 'lastname1' || name === 'Role1' || name === 'contactEmail1') {
				if (watch()?.[name]) handleSubmit(onSubmitted)();
			} else {
				setSignupFields(prev => ({ ...prev,
					[componentName]: {
						...prev[componentName],
						[name]: watch()?.[name]
					}
				}));
			}

		} else {
			if (name === 'website' || watch()?.[name]) {
				handleSubmit(onSubmitted)();
			}
		}

	}, [ watch()?.[name] ]);

	useEffect(() => {
		setPageError(prev => ({ ...prev, [name]: errors[name]?.message }));
	}, [ errors[name]?.message ]);

	return (
		<>
			<Typography className={selected ? classes.selectedLabel : classes.label}>
				{required && <span className={classes.asterisk} >*</span>}
				{label}  { showInfo && <LightTooltip title={info} classes={{ tooltip: classes.customWidth }}>
					<HelpIcon style={{ color: COLORS.blueButtons }} fontSize='small'/>
				</LightTooltip>}
			</Typography>
			<Controller control={control} name={name}
				render={({ field }) => (
					<TextField {...field} onFocus={() => setSelected(true)}
						onBlur={ () => {handleSubmit(onSubmitted)(); if (name === 'username') checkIsValidUserName();}}
						fullWidth
						variant='outlined'
						size='small'
						InputProps={{ classes: { root: classes.selectedBorder } }}
						helperText={errors[name]?.message}
						error={!!errors[name]?.message || !isValidUserName}
						placeholder={placeHolder}
					/>
				)}
			/>
		</>
	);

}

SignUpTextField.propTypes = {
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	showInfo: PropTypes.bool,
	info: PropTypes.string,
	label: PropTypes.string,
	setCheckIsSateEmpty: PropTypes.func,
	type: PropTypes.string,
	regex: PropTypes.string,
	name: PropTypes.string,
	componentName: PropTypes.string,
	required: PropTypes.bool,
	signupFields: PropTypes.object,
	setPageError: PropTypes.func,
	numberLengthRegx: PropTypes.any,
	errorMessage: PropTypes.string,
	isValidUserName: PropTypes.bool,
	checkIsValidUserName: PropTypes.func,
	setIsValidUserName: PropTypes.func,
	minimumLength: PropTypes.number,
	placeHolder: PropTypes.string,
	pageNumber: PropTypes.number
};

export default memo(SignUpTextField);