import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import Disbursement from './ReportsFields';

/**
 * @author Ashish Tulsankar
 * Used in disbursement report to show dialog box for selecting report format.
 * Current User options = 1. PDF 2. Excel 
 * @param {*} props includes
 * isOpen - to determine if we display/hide the dialog box
 * onClose - set the isOpen to false and hence hide the dialog box
 * onSubmit - Send back the user selected option to proceed further and close the dialog
 */

const ReportOptions = ({
	isOpen = false,
	onSumbit = () => {},
	onClose = () => {}
}) => {

	const [ reportOption, setReportOption ] = useState('pdf');

	const handleChange = (event) => {
		setReportOption(event.target.value);
	};

	return (
		<div >
			<Dialog open={isOpen}>
				<DialogTitle>Download Report</DialogTitle>
				<DialogContent>
					<FormControl  style={{
						backgroundColor: '#FFFFFF',
						borderRadius: 4,
						width: '100%'
					}}>
						<FormLabel id="radio-buttons-group-label"> Select a format to export</FormLabel>
						<RadioGroup
							row
							aria-labelledby="radio-buttons-group-label"
							name="radio-buttons-group"
							value={reportOption}
							onChange={handleChange}
						>
							<FormControlLabel value="pdf" control={<Radio />} label="PDF" />
							<FormControlLabel value="excel" control={<Radio />} label="Excel" />
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose()}
						style={{
							background: '#F6F7F8',
							border: '1px solid #1070D7',
							boxSizing: 'border-box',
							borderRadius: 4,
							fontFamily: 'Roboto',
							fontStyle: 'normal',
							fontWeight: 700,
							textAlign: 'center',
							color: '#1070D7'
						}}
					>Cancel</Button>
					<Button onClick={() => onSumbit(reportOption)}
						style={{
							background: '#1070D7',
							border: '1px solid #1070D7',
							boxSizing: 'border-box',
							borderRadius: 4,
							fontFamily: 'Roboto',
							fontWeight: 700,
							textAlign: 'center',
							color: '#FFFFFF'
						}}
					>OK</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
ReportOptions.propTypes = {
	isOpen: PropTypes.bool,
	onSumbit: PropTypes.func,
	onClose: PropTypes.func
};

export default ReportOptions;
