import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../constants/Colors';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormTextField from "../forms/FormTextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { getApiUrl, setApiToken } from "../utils/getApiUrl";
import InProgress from "../forms/InProgress";
import Message from "../forms/Messages";
import { useHistory, Link } from "react-router-dom";
import headerLogo from '../../assets/images/APX.png';

const useStyles = makeStyles({
    card: {
        backgroundColor: COLORS.secureBackground,
        marginTop: 100,
        marginBottom: 10,
    },
});

function Login() {
    const history = useHistory();
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isBusy, setIsBusy] = useState(false);
    const saveToken = (token) => {
        setApiToken(token);
        history.push('/admin/sync');
    };
    const submitLogin = async () => {
        setIsBusy(true);
        setError('');
        try {
            const response = await axios.post(`${getApiUrl()}/api/admin/login`, { email, password });
            if (response.status !== 200 || !response.data?.token) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('Failed to authenticate with server.');
            }

            saveToken(response.data.token);
        } catch (e) {
            console.error(e);
            const message = e.response?.status === 400 ? e.response?.data?.error : 'Internal server error.';
            setError(message);
        } finally {
            setIsBusy(false);
        }
    };
    return (
        <Container maxWidth="xs">            
            <Message isOpen={!!error} handleClose={() => setError('')} message={error} severity="error" />
            <Card className={classes.card}>
                <CardActionArea>
                    <CardContent>
                        <div style={{ textAlign: 'center', margin: '12px'}}>
                            <img src={headerLogo} style={{ marginBottom: '12px' }} />
                        </div>
                        <Typography gutterBottom variant="h6" component="h3">Login</Typography>
                        <Typography gutterBottom variant="caption" component="p">
                            Please enter your administration credentials to proceed.
                        </Typography>
                        <FormTextField
                            required
                            placeholder="name@example.com"
                            id="login-email"
                            value={email}
                            onChange={({ target: { value } }) => setEmail(value)} />
                        <FormTextField
                            required
                            type="password"
                            placeholder="******"
                            id="login-password"
                            value={password}
                            onChange={({ target: { value } }) => setPassword(value)} />
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button disabled={isBusy} size="small" color="primary" onClick={submitLogin}>Login</Button>
                    <Button size="small" color="primary" component={Link} to="/admin/forgot-password">Forgot Password</Button>
                </CardActions>
                {isBusy && <InProgress />}
            </Card>
        </Container>
    )
}

export default Login;
