import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { COLORS } from '../constants/Colors';
import useWindowSize from '../hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
	bold: {
		fontWeight: 500
	},
	divider: {
		height: 2,
		width: 146,
		opacity: 0.82,
		backgroundColor: '#CFD8DC',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	btnwidth: {
		width: '100%',
		fontWeight: 600,
		color: COLORS.blueButtons,
		borderColor: COLORS.blueButtons
	},
	invoiceSummary: {
		padding: theme.spacing(2),
		paddingTop: 0,
	},
	invoiceSummaryBox: {
		borderBottom: '1px solid #CFD8DC',
	},
	invoiceSummaryHeading: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: theme.spacing(2),

		'@media(max-width: 600px)': {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},

		'& span': {
			color: '#1070D7',
			fontWeight: 'bold',
		}
	},
	space: {
		marginTop: 5,
		fontSize: 15,
	},
	value: {
		textAlign: 'right'
	},
	roboto: {
		textAlign: 'right',
		color: '#607D8B'
	},
	boldValue: {
		fontWeight: 600,
		textAlign: 'right',
	}
}));

function Invoice({
	requestOfPayment = {}
}) {
	const classes = useStyles();
	const [ collapsed, setCollapsed ] = useState(true);
	const toggleInvoiceSummary = () => setCollapsed(!collapsed);
	const { width } = useWindowSize();

	if (collapsed && width <= 600) {
		return (
			<div className={classes.invoiceSummaryBox}>
				<Box className={classes.invoiceSummaryHeading} onClick={toggleInvoiceSummary}>
					<span>Invoice Summary</span>
					{collapsed ? <ArrowDropDown /> : <ArrowDropUp />}
				</Box>
			</div>
		);
	}

	return (
		<div className={classes.invoiceSummaryBox}>
			<Box className={classes.invoiceSummaryHeading} onClick={toggleInvoiceSummary}>
				<span>Invoice Summary</span>
				{width <= 600 ? collapsed ? <ArrowDropDown /> : <ArrowDropUp /> : null}
			</Box>
			<div className={classes.invoiceSummary}>
				<Grid container direction='row'>
					<Grid item xs={12}>
						<Grid container direction='row' className={classes.space}>
							<Grid item xs={7}><Typography>Invoice #</Typography></Grid>
							<Grid item xs={5} className={classes.value}><Typography>{requestOfPayment.invoiceId}</Typography></Grid>
						</Grid>
						<Grid container direction='row' className={classes.space}>
							<Grid item xs={7}><Typography>Issued</Typography></Grid>
							<Grid item xs={5} className={classes.value}><Typography>{requestOfPayment.createdAt && moment(requestOfPayment.createdAt , 'YYYY-MM-DD').format('MM/DD/YYYY')}</Typography></Grid>
						</Grid>
						<Grid container direction='row' className={classes.space}>
							<Grid item xs={7}><Typography>Amount</Typography></Grid>
							<Grid item xs={5} className={classes.value}><Typography>${Number(requestOfPayment.amount || 0).toFixed(2)}</Typography></Grid>
						</Grid>
					</Grid>
					<Grid item className={classes.divider} xs={12}><Divider/></Grid>
					<Grid item xs={12}>
						<Grid container direction='row' className={classes.space}>
							<Grid item xs={6} className={classes.bold}>Total</Grid>
							<Grid item xs={6} className={classes.boldValue}>{`${requestOfPayment.currencyCode  || 'US'} ${Number(requestOfPayment.amount || 0).toFixed(2)}`}</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}

Invoice.propTypes = {
	requestOfPayment: PropTypes.object,
};

export default memo(Invoice);