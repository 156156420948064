import React, { useEffect, useState, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Header from './Header';
import Invoice from './Invoice';
import Footer from './Footer';
import { COLORS } from '../constants/Colors';
import useQuery from '../hooks/useQuery';
import useWindowSize from '../hooks/useWindowSize';
import { createReceiptData, initializePendo } from '../services/Services';
import SharedForm from '../shared/SharedForm';
import { getApiUrl } from '../utils/getApiUrl';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		maxHeight: '100%',
	},
	paper: {
		borderRight: '1px solid #CFD8DC',
		backgroundColor: '#FFFFFF',
		lineHeight: '12px',
		maxHeight: '100%',
		borderTop: '1px solid #CFD8DC',
	},
	header: {
		color: theme.palette.text.secondary,
		boxShadow: '0 2px 2px 0 rgba(38,50,56,0.20)',
		height: '9vh',
		borderLeft: '1px solid #CFD8DC',
		borderRight: '1px solid #CFD8DC',
		backgroundColor: 'white',

	},
	secureMessage: {
		marginTop: 3,
		marginLeft: 10
	},
	secure: {
		marginTop: 8,
		marginBottom: 5,
		color: COLORS.secureMessage,
		display: 'flex',
		marginLeft: '40%',
	},
	container: {
		maxWidth: '65%',
		backgroundColor: 'red',
		height: '100%'
	}
}));
function PayNow() {
	const classes = useStyles();
	const history = useHistory();

	const initialBillingAddressState = {
		clientName: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		postalCode: '',
		country: '',
		email: '',
	};

	const [ requestOfPaymentMethodId, setRequestOfPaymentMethodId ] = useState('');
	const [ application, setApplication ] = useState('');
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ validateRequired, setValidateRequired ] = useState(false);
	const [ requestOfPayment, setRequestOfPayment ] = useState({});
	const [ billingAddressInfo, setBillingAddressInfo ] = useState(initialBillingAddressState);
	const [ merchantInfo, setMerchantInfo ] = useState({});
	const [ payNowStatus, setPayNowStatus ] = useState('');
	const [ isLoading, setIsLoading ] = useState(true);

	const params = useParams();
	const query = useQuery();

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${params.paynow}`
		}
	};

	const handleOnEdit = () => {
		setIsEditMode(!isEditMode);
	};

	useEffect(() => {
		const { address1, address2, email, city, country, state, postalCode } = requestOfPayment;
		const clientName = requestOfPayment.firmName;
		setBillingAddressInfo({ clientName, address1, address2, city, state, postalCode, country, email });
		if (application) initializePendo(application, requestOfPayment?.customerId, clientName, requestOfPayment?.entityInfo?.entityId, requestOfPayment?.entityInfo?.entity);

	}, [ requestOfPayment, application ]);

	useEffect(() => {
		if (params.paynow) {
			async function fetchData() {
				try {
					setIsLoading(true);
					await getRequestOfPayment(query.get('payNowTokenId'));
					setIsLoading(false);
				} catch (err) {
					const status = err.response?.data?.status;
					setIsLoading(false);
					if (err.toString() === 'Error: Network Error') history.push('/errorpage/serverDown');
					else if (status === 404) history.push('/errorpage/pageNotFound');
					else if ([ 401, 400 ].includes(status)) history.push({
						pathname: '/errorpage/pageNotFound',
						state: {
							title: 'Sorry, this PayNow link is not available',
							messages: [ 'Please contact the business that sent you this link.' ]
						}
					});

					console.error(err);
				}
			}

			fetchData();
		}
	}, [ params ]);

	const getPaymentMethod = async (transactionId) => {
		try {
			const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_transaction_status?transactionId=${transactionId}`, axiosConfig);
			if (data.code === 200) {
				const result = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_any_payment_method?paymentMethodId=${data.response.paymentMethodId}`, axiosConfig);
				return result?.data.paymentMethod;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getTokenStatus = async (requestOfPayment, isExpired, merchantInfo, isNewPayNowLinkRequested, payNowTokenId) => {
		try {
			if (isExpired) {
				history.push('/expired', { merchantInfo, requestOfPayment, axiosConfig, isNewPayNowLinkRequested, payNowTokenId });
			}
			if (requestOfPayment.status === 'Paid') {
				const paymentMethod = await getPaymentMethod(requestOfPayment.transactionId);
				const data = { merchantInfo, email: paymentMethod.email };
				history.push('/paid-receipt', { data });
			}
			if (requestOfPayment.status === 'Open' && requestOfPayment.transactionId) {
				const paymentMethod = await getPaymentMethod(requestOfPayment.transactionId);
				if (paymentMethod) {
					const transaction = createReceiptData(paymentMethod, requestOfPayment.transactionId, requestOfPayment.country,requestOfPayment.postalCode,true);
					const data = { merchantInfo, transaction, amount: requestOfPayment.amount, invoiceId: requestOfPayment.invoiceId, axiosConfig };
					history.push('/receipt', { data: data, email: requestOfPayment.firmEmail, requestOfPayment: requestOfPayment });
				}
			}
			setPayNowStatus('Payment');
		} catch (err) {
			console.log(err);
		}
	};

	const getRequestOfPayment = async () => {
		const { data } = await axios.get(`${getApiUrl()}/api/merchantwebapi/get_request_of_payment?paynowTokenId=${query.get('payNowTokenId')}`, axiosConfig);
		if (data.code === 200) {
			setRequestOfPayment(data.requestOfPayment);
			setMerchantInfo(data.merchantInfo);
			setRequestOfPaymentMethodId(data.requestOfPayment.requestOfPaymentMethodId);
			setApplication(data.application);
			await getTokenStatus(data.requestOfPayment, data.isExpired, data.merchantInfo, data.isNewPayNowLinkRequested, data.payNowTokenId);
		}
	};

	const updateBillingAddress = async (billingAddressInfo) => {
		try {
			const { data } = await axios.put(`${getApiUrl()}/api/merchantwebapi/update_billing_address`, {
				requestOfPaymentMethodId, billingAddressInfo
			}, axiosConfig);

			if (data.code === 200) setRequestOfPayment(data?.requestOfPayment?.dataValues);
		} catch (err) {
			if (err.toString() === 'Error: Network Error') {
				history.push('/errorpage/serverDown');
			}
			console.log(err);
		}
	};

	const requiredFieldValidated = (billingAddressInfo) => {
		const requiredFields = Object.keys(billingAddressInfo);
		for (const field of requiredFields) {
			if (field !== 'address2' && field !== 'username' && !billingAddressInfo[field]) {
				setValidateRequired(true);
				return false;
			}
		}
		setValidateRequired(false);
		return true;
	};

	const handleAddressUpdate = async(address)=>{
		setBillingAddressInfo(address);
		await updateBillingAddress(address);
	};

	const { width } = useWindowSize();
	const ContainerTag = width >= 600 ? Container : 'div';

	if (isLoading) {
		return (
			<div className='loading'>
				<div className='loader'></div>
			</div>
		);
	}

	return (
		<ContainerTag maxWidth='md'>
			{payNowStatus === 'Payment' && (
				<div className={classes.root}>
					<Grid container direction='column'>
						<Grid item xs={12} className={classes.header}>
							<Header requestOfPayment={requestOfPayment}/>
						</Grid>
						<Grid item xs={12}>
							<div>
								<Grid container direction='row'>
									{requestOfPayment.invoiceId && (
										<Grid item xs={12} md={3} lg={3} className={classes.paper} style={{ borderLeft: '1px solid #CFD8DC' }}>
											<Invoice requestOfPayment={requestOfPayment}/>
										</Grid>
									)}
									<Grid item xs={12} md={requestOfPayment.invoiceId ? 9 : 12} lg={requestOfPayment.invoiceId ? 9 : 12}>
										{<Paper className={classes.paper} square>
											<ContainerTag>
												<SharedForm
													requiredFieldValidated={requiredFieldValidated}
													validateRequired={validateRequired}
													requestOfPayment={requestOfPayment}
													billingAddressInfo={billingAddressInfo}
													handleOnEdit={handleOnEdit}
													isPayNow={true}
													setIsEditMode={setIsEditMode}
													payerId={requestOfPayment.payerId}
													axiosConfig={axiosConfig}
													merchantInfo={merchantInfo}
													handleAddressUpdate={handleAddressUpdate}
													applicationName={application}
												/>
											</ContainerTag>
										</Paper>}
									</Grid>
								</Grid>
							</div>
						</Grid>
						<Footer/>
					</Grid>
				</div>
			)}
		</ContainerTag>
	);
}
export default memo(PayNow);
