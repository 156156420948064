import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PaymentMethod from '../shared/PaymentMethod';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { COLORS } from '../constants/Colors';
import ExistingPaymentsMethods from '../paynow/ExistingPaymentsMethods';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import HelpIcon from '@material-ui/icons/Help';
import { IconButton, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useWindowSize from '../hooks/useWindowSize';
import { appendPendoUrl } from '../services/Services';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	header: {
		display: 'flex',
		backgroundColor: COLORS.blueHeader,
		color: COLORS.whiteText,
		padding: 8,
		maxHeight: 40,
		boxSizing: 'border-box',
		border: '1px solid #CFD8DC',
		borderRadius: '2px 2px 0 0',
		position: 'relative'
	},
	editIcon: {
		cursor: 'pointer',
		marginRight: 16

	},
	title: {
		fontWeight: 900,
		marginLeft: 18
	},
	plusCrossIcon: {
		cursor: 'pointer',
		right: 28,
		position: 'absolute',
	},
	containerbackground: {
		boxSizing: 'border-box',
		height: '105px',
		width: '501px',
		border: '1px solid #CFD8DC',
		borderRadius: 2,
		backgroundColor: '#F6F7F8'
	},
	help: {
		'&:hover': {
			color: COLORS.helpHover,
		},
		color: COLORS.help,
		height: '0.8em'

	},
	iconBtn: {
		padding: 0,
		marginLeft: '8px'
	},
	tooltip: {
		color: COLORS.whiteText,
		backgroundColor: COLORS.darkGray,
		fontSize: '14px',
		width: '220px'
	}
}));

function HelpIconWithToolTip({ classes }) {
	const { width } = useWindowSize();
	const [ open, setOpen ] = useState(false);
	const handleTooltipClose = () => setOpen(false);
	const handleTooltipOpen = () => setOpen(true);

	if (width >= 600) {
		return (
			<Tooltip
				classes={{ tooltip: classes.tooltip }}
				title='Billing address have to match address on your payment method.'
			>
				<IconButton className={classes.iconBtn}>
					<HelpIcon className={classes.help} />
				</IconButton>
			</Tooltip>
		);
	}
    
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<Tooltip
				classes={{ tooltip: classes.tooltip }}
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title='Billing address have to match address on your payment method.'
			>
				<IconButton className={classes.iconBtn} onClick={handleTooltipOpen}>
					<HelpIcon className={classes.help} />
				</IconButton>
			</Tooltip>
		</ClickAwayListener>
	);
}

function SharedPaymentForm({
	requestOfPayment = {},
	handleOnEdit = () => { },
	validateRequired = false,
	isPayNow = true,
	merchantId = '',
	selectedValue = '',
	handleChange = () => { },
	setIsLoading = () => { },
	setBankInfo = () => { },
	setCreditCardInfo = () => { },
	bankInfo = {},
	creditCardInfo = {},
	expanded = '',
	payerId = '',
	handleSelectedTokenChange = () => { },
	selectedToken = '',
	axiosConfig = {},
	merchantInfo = {},
	showExistingPaymentMethods = true,
	paymentMethods = [],
	getExistingPaymentsMethods = () => { },
	setExistingPaymentMethods = () => { },
	setValidCreditCard = () => { },
	setValidBankAccount = () => {},
	validCreditCard = {},
	validBankAccount = {},
	ccStates = [],
	setCCStates = () => {},
}) {

	const classes = useStyles();
	const showPaymentMethods = () => {
		appendPendoUrl('/AddPaymentMethod');
		handleSelectedTokenChange({ target: { value: '' } });
		setExistingPaymentMethods(!showExistingPaymentMethods);
	};

	const hideExistingPaymentMethods = () => {
		appendPendoUrl('');
		setExistingPaymentMethods(!showExistingPaymentMethods);
	};

	const showAddCrossButton = () => {
		if (showExistingPaymentMethods && isPayNow && paymentMethods.length) {
			return (<Grid item xs={12} className={classes.header}>
				<Grid item xs={12}>
					<Typography className={classes.title}>Payment Method
						<HelpIconWithToolTip classes={classes} />
					</Typography>
				</Grid>
				<Grid item >
					<AddRoundedIcon onClick={showPaymentMethods} className={classes.plusCrossIcon} />
				</Grid>
			</Grid>);
		} else if (!showExistingPaymentMethods && isPayNow && paymentMethods.length) {
			return (
				<Grid item xs={12} className={classes.header}>
					<Grid item xs={12}><Typography className={classes.title}>New Payment Method
						<HelpIconWithToolTip classes={classes} /></Typography></Grid>
					<Grid item ><CloseRoundedIcon onClick={hideExistingPaymentMethods} className={classes.plusCrossIcon} />
					</Grid>
				</Grid>
			);
		} else if (!showExistingPaymentMethods && isPayNow && !paymentMethods.length) {
			return (<Grid item xs={12} className={classes.header}>
				<Grid item xs={12}><Typography className={classes.title}>Payment Method
					<HelpIconWithToolTip classes={classes} /></Typography></Grid>
			</Grid>);
		} else if (showExistingPaymentMethods && !isPayNow) {
			return (
				<Grid item xs={12} className={classes.header}>
					<Grid item xs={12}><Typography className={classes.title}>New Payment Method
						<HelpIconWithToolTip classes={classes} /></Typography></Grid>
				</Grid>
			);
		}
	};

	useEffect(() => {
		if (payerId && isPayNow) {
			async function fetchPaymentMethods() {
				await getExistingPaymentsMethods();
			}

			fetchPaymentMethods();
		}

	}, [ payerId ]);

	return (
		<>
			{showAddCrossButton()}
			<Grid item xs={12}>
				{(!showExistingPaymentMethods || !isPayNow) ? (<PaymentMethod
					className={classes.header}
					selectedValue={selectedValue}
					handleChange={handleChange}
					expanded={expanded}
					amount={requestOfPayment.amount}
					merchantId={requestOfPayment.merchantId || merchantId}
					setIsLoading={setIsLoading}
					setBankInfo={setBankInfo}
					setCreditCardInfo={setCreditCardInfo}
					bankInfo={bankInfo}
					creditCardInfo={creditCardInfo}
					validateRequired={validateRequired}
					invoiceId={requestOfPayment.invoiceId}
					requestOfPaymentMethodId={requestOfPayment.requestOfPaymentMethodId}
					isPayNow={isPayNow}
					payerId={payerId}
					showExistingPaymentMethods={showExistingPaymentMethods}
					axiosConfig={axiosConfig}
					merchantInfo={merchantInfo}
					setValidCreditCard={setValidCreditCard}
					setValidBankAccount={setValidBankAccount}
					validCreditCard={validCreditCard}
					validBankAccount={validBankAccount}
					ccStates={ccStates}
					setCCStates={setCCStates}
				/>) : <ExistingPaymentsMethods
					handleSelectedTokenChange={handleSelectedTokenChange}
					selectedToken={selectedToken}
					getExistingPaymentsMethods={getExistingPaymentsMethods}
					paymentMethods={paymentMethods}
					axiosConfig={axiosConfig}
				/>}
			</Grid>
		</>
	);
}

SharedPaymentForm.propTypes = {
	requestOfPayment: PropTypes.object,
	handleOnEdit: PropTypes.func,
	requiredFieldValidated: PropTypes.func,
	validateRequired: PropTypes.bool,
	isPayNow: PropTypes.bool,
	merchantId: PropTypes.string,
	bankInfo: PropTypes.object,
	setBankInfo: PropTypes.func,
	setIsLoading: PropTypes.func,
	expanded: PropTypes.string,
	selectedValue: PropTypes.string,
	payerId: PropTypes.string,
	handleSelectedTokenChange: PropTypes.func,
	selectedToken: PropTypes.string,
	axiosConfig: PropTypes.object,
	merchantInfo: PropTypes.object,
	showExistingPaymentMethods: PropTypes.bool,
	paymentMethods: PropTypes.array,
	getExistingPaymentsMethods: PropTypes.func,
	setExistingPaymentMethods: PropTypes.func,
	setValidCreditCard: PropTypes.func,
	setValidBankAccount: PropTypes.func,
	validBankAccount: PropTypes.object,
	ccStates: PropTypes.array,
	setCCStates: PropTypes.func
};

export default memo(SharedPaymentForm);