import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {
	COUNTRIES,
	STATES,
	BUSINESSTYPE,
	BUSINESSCATEGORY,
	EINTYPE,
	ANNUALPROCESSING,
	IRSNONPROFITSTATUS,
	WEBSITE_OPTIONS
} from '../../constants/Locations';
import Divider from '@material-ui/core/Divider';
import SignUpTextField from './SignUpTextField';
import SignUpSelectField from './SignUpSelect';
import SignUpCheckedBox from './SignUpCheckBox';
import SignUpAccount from './SignupAccount';
import SignUpBindedField from './SignupBindedField';
import { SignupFieldsInfo } from './SignupFieldsInfo';
import SignUpNumber from './SignupNumber';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import SignUpService from '../../services/SignUpService';
import { TextField, Typography } from '@material-ui/core';
import { COLORS } from '../../constants/Colors';

const useStyles = makeStyles((theme) => ({
	title: {
		color: '#2D4B69',
		fontWeight: 'bold',
		lineHeight: '12px'
	},
	text: {
		color: '#263238',
		marginTop: '3%'
	},
	required: {
		color: '#B90023',
		lineHeight: '12px',
		textAlign: 'right',
		marginBottom: '1%'
	},
	addressText: {
		color: '#455A64',
		marginTop: 5
	},
	userNameError: {
		color: 'red',
		marginTop: 5,
		fontSize: 14
	},
	divider: {
		backgroundColor: COLORS.dividerColor,
		width: '100%',
		height: 0.5
	},
	container: {
		height: '76vh',
		overflow: 'scroll',
	},
	label: {
		color: '#455A64',
		fontSize: 16,
		letterSpacing: 0,
		lineHeight: '14px',
		marginBottom: '1%'
	}
}));

// eslint-disable-next-line require-jsdoc
function AboutBusiness({
	setDisableButton = () => {},
	signupFields = {},
	setSignupFields = () => {},
	token = '',
	applicationCustomerId = '',
	isValidUserName = true,
	checkIsValidUserName = () => {},
	setIsValidUserName = () => {},
	userNameMessageError = ''
}) {

	const classes = useStyles();
	const history = useHistory();
	const [ aboutBusiness, setAboutBusiness ] = useState({});
	const [ showFormFields, setShowFields ] = useState(false);
	const [ showBusinessDescriptor, setShowBusinessDescriptor ] = useState(false);
	const [ nonProfitFields, setNonProfitFields ] = useState(false);
	const [ aboutBusinessSavedData, setAboutBusinessSavedData ] = useState({});
	const [ aboutBusinessError, setAboutBusinessError ] = useState({});
	const [ einFormat, setEinFormat ] = useState('##-#######');

	const axiosConfig = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		}
	};

	useEffect(() => {
		checkIsSateEmpty();
		return () => { setDisableButton(true);};
	}, [ aboutBusiness, aboutBusinessError, isValidUserName ]);

	useEffect(() => {
		const showFields = () => {
			if (aboutBusiness['businessType']) {
				setShowFields(true);
				if (aboutBusiness['businessType'] !== 'SOLE PROPRIETOR') {
					setShowBusinessDescriptor(true);
				} else {
					setShowBusinessDescriptor(false);
				}

				if (aboutBusiness['businessType'] === 'NONPROFIT') setNonProfitFields(true);
				else {
					setNonProfitFields(false);
					delete aboutBusiness['irsNotForProfitLetterStatus'];
					delete aboutBusiness['irsNotForProfitLetterIssueDate'];
					setAboutBusinessError(prev => ({ ...prev, irsNotForProfitLetterIssueDate: undefined }));
					setAboutBusinessError(prev => ({ ...prev, irsNotForProfitLetterStatus: undefined }));
				}
			}
		};
		showFields();
	}, [ aboutBusiness['businessType'] ]);

	useEffect(()=> {
		if (token && applicationCustomerId) {
			const getMerchantData = async (applicationCustomerId) => {
				const merchantData = await SignUpService.getMerchantInfo(applicationCustomerId, axiosConfig);

				if (merchantData.status === 'success') setAboutBusinessSavedData(merchantData?.data?.merchantInfo);
				else {
					if (merchantData.err.toString() === 'Error: Network Error') {
						history.push('/errorpage/serverDown');
					}
				}

			};
			getMerchantData(applicationCustomerId);
		}
	}, [ token, applicationCustomerId ]);

	useEffect(() => {
		if (aboutBusiness['EINType'] === 'SSN') {
			setEinFormat('###-##-####');
		} else if (aboutBusiness['EINType'] === 'TIN') {
			setEinFormat('##-#######');
		}
	}, [ aboutBusiness['EINType'] ]);

	const checkForError = (aboutBusinessError => {
		const values = _.values(aboutBusinessError);
		if (values.length) {
			for (let x = 0; x < values.length; x++) {
				if (values[x]) return true;
			}
			return false;
		}
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const selectedWebsiteOption = (option) => {
		if (option === 'doNotHaveBusinessWebsite') {
			setAboutBusiness(oldValue => {
				oldValue.website = 'http://nowebsite.com';
				return oldValue;
			});
			setSignupFields(oldValues => {
				oldValues.aboutBusiness.website = 'http://nowebsite.com';
				return oldValues;
			});
			setAboutBusinessError(error => ({ ...error, website: undefined }));
		} else if (signupFields?.aboutBusiness?.website === 'http://nowebsite.com') {
			setAboutBusiness(oldValue => ({ ...oldValue, website: '' }));
			setSignupFields(oldValues => {
				oldValues.aboutBusiness.website = '';
				return oldValues;
			});
		}
	};

	const checkIsSateEmpty = () => {
		const values = _.values(aboutBusiness);

		if (values.length) {
			for (let x = 0; x < values.length; x++) {
				if (checkForError(aboutBusinessError) || !isValidUserName || (String(values[x]) === 'null' || _.isEmpty(String(values[x])))) {
					setDisableButton(true);
					return;
				} else {
					setDisableButton(false);
				}
			}
		}
	};

	const renderAboutBusinessFields = () => {
		return (
			<Grid container direction='row'>
				<Grid item md={12} lg={12} xs={12} className={classes.text}>
					<Typography>Please take a moment to fill out the following information and you will onboard immediately. We will automatically save
						entered information for you on this device and you won't need to enter it again.</Typography>
				</Grid>

				<Grid container style={{ marginTop: '3%' }}  justifyContent='space-between'>
					<Grid item md={9} xs={7} className={classes.title}>Tell us about your Business</Grid>
					<Grid item md={3} xs={5} className={classes.required}>* Required Field</Grid>
				</Grid>

				<Divider className={classes.divider} />

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Business Type'
							componentName='aboutBusiness'
							name='businessType'
							type='string'
							info={SignupFieldsInfo.businessType}
							list={BUSINESSTYPE}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					{showFormFields  && <Grid item xs={12} md={6} style={{ marginTop: '3%' }}>
						<Grid container  alignContent='flex-start' alignItems='flex-start' justifyContent='flex-start'>
							<SignUpCheckedBox
								label="This is public company"
								name="publicCompany"
								componentName='aboutBusiness'
								setSignupFields={setSignupFields}
								savedData={aboutBusinessSavedData}
								setCheckIsSateEmpty={setAboutBusiness}
								setPageError={setAboutBusinessError}
								showInfo={true}
								info='Publicly traded company in a US stock exchange (trading either Nasdaq or the NYSE)'
							/>
						</Grid>
					</Grid>}

				</Grid>
				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item  xs={12} md={6}>
						<SignUpTextField
							label='Business Legal Name - IRS Filing Name'
							name='name'
							componentName='aboutBusiness'
							type='string'
							info={SignupFieldsInfo.businessName}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Business Category'
							componentName='aboutBusiness'
							name='businessCategory'
							type='string'
							info={SignupFieldsInfo.businessCategory}
							list={BUSINESSCATEGORY}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
				</Grid>

				{showFormFields  && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Business DBA Name - Statement Descriptor'
							componentName='aboutBusiness'
							name='statementDescriptor'
							type='string'
							info={SignupFieldsInfo.statementDescriptor}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					{showFormFields && showBusinessDescriptor && <Grid item xs={12} md={6}>
						<SignUpBindedField
							label=' DBA Descriptor Preview'
							prefix='SPL*'
							componentName='aboutBusiness'
							data={aboutBusiness?.['statementDescriptor']?.toUpperCase()}
							name='descriptorPreview'
							type='string'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							info={SignupFieldsInfo.descriptorPreview}
							setPageError={setAboutBusinessError}
						/>
					</Grid>}
				</Grid>}

				{showFormFields && nonProfitFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item  xs={12} md={6}>
						<SignUpSelectField
							label='IRS Not-for-profit Letter Status'
							componentName='aboutBusiness'
							name='irsNotForProfitLetterStatus'
							type='string'
							info={SignupFieldsInfo.irsNotProfitLetterStatus}
							list={IRSNONPROFITSTATUS}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							format="##-##-####"
							formatType='text'
							showIcon={true}
							label='Issued Date of the IRS Not-for-profit Letter'
							name='irsNotForProfitLetterIssueDate'
							componentName='aboutBusiness'
							setSignupFields={setSignupFields}
							signupFields={signupFields}
							info={SignupFieldsInfo.irsNotProfitLetterIssuedDate}
							type='date'
							// regex='dob'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							maximumDate = {new Date()}

						/>
					</Grid>
				</Grid>}

				<Grid container direction='row' spacing={3}  style={{ marginTop: '1%' }}>

					<Grid item xs={12} md={6} style={{ marginTop: '0.5%' }}>
						<Typography className={classes.label}> Environment</Typography>
						<TextField style={{ backgroundColor: '#ECEFF1' }}
							fullWidth
							variant='outlined'
							size='small'
							value='Customers enter online their payment information'
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Business Description'
							componentName='aboutBusiness'
							name='businessDescription'
							type='string'
							info={SignupFieldsInfo.businessDescriptor}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
				</Grid>

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Business EIN Type'
							type='string'
							componentName='aboutBusiness'
							name='EINType'
							info={SignupFieldsInfo.einType}
							list={EINTYPE}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							label='Business EIN'
							format={einFormat}
							componentName='aboutBusiness'
							name='EIN'
							type='regex'
							regex='ssnandtin'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							info={SignupFieldsInfo.ein}
							setPageError={setAboutBusinessError}
						/>

					</Grid>
				</Grid>}

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Annual Processing Volume'
							componentName='aboutBusiness'
							name='annualProcessingVolume'
							type='string'
							info={SignupFieldsInfo.annualProcessingVolume}
							list={ANNUALPROCESSING}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							format={null}
							formatType='text'
							label='Average Transaction Amount'
							componentName='aboutBusiness'
							name='averageTransactionAmount'
							type='regex'
							regex='dollarSign'
							info={SignupFieldsInfo.averageTransactionAmount}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							prefix='$'
						/>
					</Grid>
				</Grid>}

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Business Email'
							componentName='aboutBusiness'
							name='businessEmail'
							type='email'
							info={SignupFieldsInfo.businessEmail}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							label='Business Phone'
							formatType='tel'
							format="(###) ###-####"
							componentName='aboutBusiness'
							name='businessPhone'
							type='regex'
							regex='phone'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							info={SignupFieldsInfo.businessPhone}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
				</Grid>}
				<Grid container style={{ marginTop: '3%' }}  justifyContent='space-between'>
					<Grid item md={9} xs={7} className={classes.title}>Business Address</Grid>
					<Grid item md={3} xs={5} className={classes.required}>* Required Field</Grid>
				</Grid>
				<Divider className={classes.divider}/>
				<Grid item xs={12} md={12} style={{ marginTop: '1%' }} className={classes.text}>
					Use the address registered for this business with the IRS.
					The business registration document would be the best place to get the address.
				</Grid>
				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={12} lg={12}>
						<SignUpTextField
							label='Street Address'
							componentName='aboutBusiness'
							name='businessAddress'
							type='string'
							info={SignupFieldsInfo.businessAddress}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							showInfo={true}
							placeHolder='Building Number, Street Name, Unit/Apt. Number'
						/>
						<Grid item md={12} className={classes.addressText}>
							PO Boxes are not acceptable for onboarding. Using PO box will delay your application.
						</Grid>
					</Grid>
				</Grid>}

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='City'
							componentName='aboutBusiness'
							name='businessCity'
							type='string'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							showInfo={false}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='State'
							componentName='aboutBusiness'
							name='businessState'
							type='string'
							list={STATES}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							showInfo={false}
						/>
					</Grid>
				</Grid>}

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='ZIP'
							name='businessZip'
							componentName='aboutBusiness'
							type='number'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							type= 'regex'
							regex='zip'
							showInfo={false}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							label='Country'
							componentName='aboutBusiness'
							name='businessCountry'
							type='string'
							info={SignupFieldsInfo.country}
							list={COUNTRIES}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							defaultName='USA'
							showInfo={false}
						/>
					</Grid>
				</Grid>}

				{showFormFields && <Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpSelectField
							type='string'
							label='Website'
							hasKeyValuePair={true}
							list={WEBSITE_OPTIONS}
							name='websiteOption'
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							componentName='aboutBusiness'
							info={SignupFieldsInfo.website}
							optionSelected={(e) => {
								selectedWebsiteOption(e);
							}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SignUpNumber
							label='Customer Service Phone'
							formatType='tel'
							format="(###) ###-####"
							componentName='aboutBusiness'
							info={SignupFieldsInfo.customerServicePhone}
							name='customerServicePhone'
							type='regex'
							regex='phone'
							savedData={aboutBusinessSavedData}
							setSignupFields={setSignupFields}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>
					{aboutBusiness?.websiteOption === 'hasBusinessWebsite' &&
						<Grid item xs={12}>
							<SignUpTextField
								label='Website URL (accepted format http:// or https://)'
								componentName='aboutBusiness'
								type='website'
								name='website'
								setSignupFields={setSignupFields}
								savedData={aboutBusinessSavedData}
								setCheckIsSateEmpty={setAboutBusiness}
								setPageError={setAboutBusinessError}
								showInfo={false}
							/>
						</Grid>
					}
				</Grid>}

				<Grid container style={{ marginTop: '3%' }}  justifyContent='space-between'>
					<Grid item md={9} xs={7} className={classes.title}>APX Merchant Account Setup</Grid>
					<Grid item md={3} xs={5} className={classes.required}>* Required Field</Grid>
				</Grid>

				<Divider className={classes.divider}/>

				<Grid item xs={12} md={12} style={{ marginTop: '1%' }} className={classes.text}>
					APX has a merchant portal where you can update your business profile and get access to all APX transactions. In order to create an
					APX account, provide your unique username and email address. We will send an email to activate your account.
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<Grid item xs={12} md={6}>
						<SignUpTextField
							label='Username'
							type='stringMinimumLength'
							name='username'
							componentName='aboutBusiness'
							minimumLength={8}
							info={SignupFieldsInfo.username}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
							isValidUserName={isValidUserName}
							checkIsValidUserName={checkIsValidUserName}
							setIsValidUserName={setIsValidUserName}
						/>
						{ !isValidUserName && !aboutBusinessError['username'] &&
						<Grid className={classes.userNameError}>{userNameMessageError}</Grid>}
					</Grid>
					{showFormFields && <Grid xs={12} item md={6}>
						<SignUpTextField
							label='Login and Notification Email'
							componentName='aboutBusiness'
							name='loginAndNotificationEmail'
							type='email'
							info={SignupFieldsInfo.loginAndNotificationEmail}
							setSignupFields={setSignupFields}
							savedData={aboutBusinessSavedData}
							setCheckIsSateEmpty={setAboutBusiness}
							setPageError={setAboutBusinessError}
						/>
					</Grid>}
				</Grid>

				<Grid container direction='row' spacing={3} style={{ marginTop: '1%' }}>
					<SignUpAccount
						setSignupFields={setSignupFields}
						signupFields={signupFields}
						setCheckIsSateEmpty={setAboutBusiness}
						savedData={aboutBusinessSavedData}
						setPageError={setAboutBusinessError}
					/>
				</Grid>
			</Grid>
		);
	};

	return (renderAboutBusinessFields());
}

AboutBusiness.propTypes = {
	savedData: PropTypes.object,
	setSignupFields: PropTypes.func,
	setDisableButton: PropTypes.func,
	isValidUserName: PropTypes.bool,
	checkIsValidUserName: PropTypes.func,
	setIsValidUserName: PropTypes.func,
	userNameMessageError: PropTypes.string
};

export default memo(AboutBusiness);